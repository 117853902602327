import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  STAGING_LIST_INIT,
  STAGING_SITE_UPDATE_INIT,
  STAGING_SITE_STAGE_INIT,
  STAGING_ACCOUNT_DETAIL_INIT,
  STAGING_ACCOUNT_DETAIL_SUCCESS,
  STAGING_ACCOUNT_DETAIL_ERROR,
  // STAGING_DETAIL_INIT,
  // STAGING_DELETE_INIT,
  // STAGING_START_INIT,
  // stagingError,
  stagingListSuccess,
  stagingSiteUpdateSuccess,
  stagingSiteStageSuccess,
  stagingAccountDetailSuccess,
  // stagingDetailError,
  // stagingDetailSuccess,
  STAGING_UPDATE_LOG,
  stagingUpdateLogSuccess,
  STAGING_AUTOSELECT_SAVE,
  stagingAutoSelectSaveSuccess,
  STAGING_AREA_LIST,
  stagingAreaListSuccess,
  stagingDeleteSuccess,
  STAGING_DELETE_INIT,
  stagingCheckSalesforceSuccess,
  STAGING_CHECK_SALESFORCE_INIT,
  STAGING_AUTOSELECT_AUTOMATION_SAVE,
  stagingAutoSelectAutomationSaveSuccess,
  stagingAccountDetailAutomationSuccess,
  STAGING_ACCOUNT_DETAIL_AUTOMATION_INIT,
  STAGING_DELETE_EMAIL_INIT,
  stagingDeleteEmailSuccess,
  STAGING_CHECK_SERVER_INIT,
  stagingCheckServerSuccess,
  STAGING_AUTOMATION_STATUS_INIT,
  STAGING_AUTOMATION_STATUS_CHANGE_INIT, stagingAutomationStatusSuccess, stagingAutomationStatusChangeSuccess,
} from "./actions";


import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
import manageStagingSaga from './ManageStaging/saga';
import {browserRedirect, browserRedirectSamePage} from "../../helpers/helpers";


//Staging API calls

function stagingAutomationStatus() {
  return request('get', urls.STAGING + '/stagingautomationstatus');
}

function stagingAutomationChangeStatus(payload) {
  return request('post', urls.STAGING + '/stagingautomationchangestatus', payload);
}




function stagingCheckSalesforce(payload) {
  // return request('get', urls.STAGING + '/stagingwhmlist');
  return request('post', urls.STAGING + '/stagingchecksalesforce', payload);
}

function stagingCheckServerCall() {
  return request('get', urls.STAGING + '/stagingwhmlist');
  // return request('get', urls.STAGING + '/stagingcheckemail');
}

function stagingAreaListCall() {
  return request('get', urls.STAGING + '/stagingarealist');
}

function stagingListCall(payload) {
  // return request('get', urls.STAGING + '/stagingwhmlist', payload);
  return request('post', urls.STAGING + '/stagingsitelist', payload);
}

function stagingAccountDetailCall(payload) {
  return request('post', urls.STAGING + '/stagingaccountdetail', payload);
}

function stagingAccountDetailAutomationCall(payload) {
  return request('post', urls.STAGING + '/stagingaccountdetailautomation', payload);
}

function stagingSiteUpdateCall(payload) {
  return request('post', urls.STAGING + '/stagingsiteupdate', payload);
}

function stagingSiteStageCall(payload) {
  return request('post', urls.STAGING + '/stagingsitestage', payload);
}

function stagingUpdateLogCall() {
  return request('get', urls.STAGING + '/updatelog');
}

function stagingDeleteCall(payload) {
  return request('delete', urls.STAGING+'/deletestaging', payload);
}

function stagingDeleteEmailCall(payload) {
  return request('delete', urls.STAGING+'/deleteemailstaging', payload);
}
// function stagingDetailCall(id) {
//   return request('get', urls.STAGING+'/'+id);
// }


//stagingStart API call
// function stagingStartCall(id) {
//   return request('post', urls.STAGING+'/'+id);
// }



// Staging Workers
function* stagingAutomationStatusWorker() {
  try {
    let response = yield call(stagingAutomationStatus);
    response = response.data.data;
    console.log(" stagingAutomationStatus response = ")
    console.log(response)
    yield put(stagingAutomationStatusSuccess(response));
  } catch (err) {
    console.log('err in stagingAutomationStatusWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingAutomationStatusChangeWorker(payload) {
  try {
    let response = yield call(stagingAutomationChangeStatus, payload);
    response = response.data.data;
    console.log(" stagingAutomationStatusChangeWorker response = ")
    console.log(response)
    yield put(stagingAutomationStatusChangeSuccess(response));
  } catch (err) {
    console.log('err in stagingAutomationStatusChangeWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}






// Staging Workers
function* stagingCheckSalesforceWorker(payload) {
  try {
    let response = yield call(stagingCheckSalesforce, payload);
    response = response.data.data;
    console.log(" stagingCheckSalesforceWorker response = ")
    console.log(response)
    yield put(stagingCheckSalesforceSuccess(response));
  } catch (err) {
    console.log('err in stagingCheckSalesforceWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}



function* stagingCheckServerWorker() {
  try {
    let response = yield call(stagingCheckServerCall);
    response = response.data.data;
    console.log(" stagingCheckServerCall response = ")
    console.log(response)
    yield put(stagingCheckServerSuccess(response));
  } catch (err) {
    console.log('err in stagingCheckSalesforceWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}


// Staging Workers
function* stagingListWorker(payload) {
  try {
    let response = yield call(stagingListCall, payload.payload);
    response = response.data.data;
    console.log(" stagingListWorker response = ")
    console.log(response)
    yield put(stagingListSuccess(response));
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingSiteUpdateWorker(payload) {
  try {
    let response = yield call(stagingSiteUpdateCall, payload.payload);
    response = response.data.data;
    console.log(" stagingListWorker response = ")
    console.log(response)
    // yield put(stagingSiteUpdateSuccess(response));
    yield call(browserRedirect,'/clients/staging');
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingSiteStageWorker(payload) {
  try {
    let response = yield call(stagingSiteStageCall, payload.payload);
    response = response.data.data;
    // console.log(" stagingListWorker response = ")
    console.log(response)
    // yield put(stagingSiteStageSuccess(response));
    yield call(browserRedirect,'/clients/staging');
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingUpdateLogWorker() {
  try {
    let response = yield call(stagingUpdateLogCall);
    response = response.data.data;
    console.log(" stagingUpdateLogCall response = ")
    console.log(response)
    yield put(stagingUpdateLogSuccess(response));
  } catch (err) {
    console.log('err in stagingUpdateLogSuccess in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingAreaListWorker() {
  try {
    let response = yield call(stagingAreaListCall);
    response = response.data.data;
    console.log(" stagingAreaListCall response = ")
    console.log(response)
    yield put(stagingAreaListSuccess(response));
  } catch (err) {
    console.log('err in stagingAreaListSuccess in clientSite saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingAccountDetailWorker(payload) {
  try {
    let response = yield call(stagingAccountDetailCall, payload.payload);
    response = response.data.data;
    console.log(" stagingAccountDetailCall response = ")
    console.log(response)
    yield put(stagingAccountDetailSuccess(response));
  } catch (err) {
    console.log('err in stagingAccountDetailWorker in staging saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Workers
function* stagingAccountDetailAutomationWorker(payload) {
  try {
    let response = yield call(stagingAccountDetailAutomationCall, payload.payload);
    response = response.data.data;
    console.log(" stagingAccountDetailAutomationCall response = ")
    console.log(response)
    // yield put(stagingAccountDetailAutomationSuccess(response));
  } catch (err) {
    console.log('err in stagingAccountDetailWorker in staging saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}

// Staging Selection
function* stagingAutoSelectSaveWorker(payload) {
  try {
    // console.log(payload.payload)
    yield put(stagingAutoSelectSaveSuccess(payload.payload));
  } catch (err) {
    console.log('err in stagingAutoSelectSaveWorker in staging saga' + err)
  }
}


// Staging Workers
function* stagingAutoSelectAutomationSaveWorker(payload) {
  try {
    // let response = payload.payload;
    // response = response.data.data;
    console.log("stagingAutoSelectAutomationSaveWorker response = ")
    console.log(payload.payload)
    yield put(stagingAutoSelectAutomationSaveSuccess(payload.payload));
  } catch (err) {
    console.log('err in stagingAutoSelectSaveWorker in staging saga' + err)
    // yield put(stagingError(err.response.data.data));
  }
}


function* stagingDeleteInitWorker({payload}) {
  try {
    console.log(`payload in delete saga`)
    console.log(payload)
    let response = yield call(stagingDeleteCall, payload);
    response = response.data;
    console.log(`stagingDeleteCall response:`)
    console.log(response)
    yield put(stagingDeleteSuccess(response));
    /* success will have the launch page reload with the new results */
    // yield put(launchPageInit());
  } catch (err) {
    console.log(`error: ${err.message}`);
    // yield put(launchStartError(err.response.data));
  }
}



function* stagingDeleteEmailInitWorker({payload}) {
  try {
    console.log(`payload in delete saga`)
    console.log(payload)
    let response = yield call(stagingDeleteEmailCall, payload);
    response = response.data;
    console.log(`stagingDeleteCall response:`)
    console.log(response)
    yield put(stagingDeleteEmailSuccess(response));
    /* success will have the launch page reload with the new results */
    // yield put(launchPageInit());
  } catch (err) {
    console.log(`error: ${err.message}`);
    // yield put(launchStartError(err.response.data));
  }
}



// Staging Watcher
export default function* stagingSaga() {
  yield all([
    takeLatest(STAGING_AUTOMATION_STATUS_INIT, stagingAutomationStatusWorker),
    takeLatest(STAGING_AUTOMATION_STATUS_CHANGE_INIT, stagingAutomationStatusChangeWorker),


    takeLatest(STAGING_AUTOSELECT_SAVE, stagingAutoSelectSaveWorker),
    takeLatest(STAGING_AUTOSELECT_AUTOMATION_SAVE, stagingAutoSelectAutomationSaveWorker),
    takeLatest(STAGING_CHECK_SALESFORCE_INIT, stagingCheckSalesforceWorker),
    takeLatest(STAGING_CHECK_SERVER_INIT, stagingCheckServerWorker),
    takeLatest(STAGING_LIST_INIT, stagingListWorker),
    takeLatest(STAGING_AREA_LIST, stagingAreaListWorker),
    takeLatest(STAGING_SITE_UPDATE_INIT, stagingSiteUpdateWorker),
    takeLatest(STAGING_SITE_STAGE_INIT, stagingSiteStageWorker),
    takeLatest(STAGING_ACCOUNT_DETAIL_AUTOMATION_INIT, stagingAccountDetailAutomationWorker),
    takeLatest(STAGING_ACCOUNT_DETAIL_INIT, stagingAccountDetailWorker),
    takeLatest(STAGING_UPDATE_LOG, stagingUpdateLogWorker),
    takeLatest(STAGING_DELETE_INIT, stagingDeleteInitWorker),
    takeLatest(STAGING_DELETE_EMAIL_INIT, stagingDeleteEmailInitWorker),
    // takeLatest(STAGING_START_INIT, stagingStartWorker),
    // takeEvery(STAGING_START_REQUESTING, stagingStartWorker),
    manageStagingSaga()
  ]);
}