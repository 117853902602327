import {
    MENU_OPEN_STATUS,
    MENU_CLOSED_STATUS
} from './actions';

export const initialState = {
    menuOpenStatus: false,
};


export default function (state = initialState, actions) {
    switch (actions.type) {
        case MENU_OPEN_STATUS:
            return {...state, menuOpenStatus: true};
        case MENU_CLOSED_STATUS:
            return {...state, menuOpenStatus: false};
        default:
            return state;
    }
}