import React, {useEffect} from "react";
// import {Box, Button, Container} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

// import Typography from "@mui/material/Typography";

// import CacheResults from './CacheResults'

import LeaderboardService from "./LeaderboardService";
import LeaderboardSales from "./LeaderboardSales";

// import Salesforce from "../Salesforce/Login";
// import MouseTracking from "../EventTracking/MouseTracking";

function Dashboard({message}) {
    // let sf_session_accessToken = localStorage.getItem("sf_session_accessToken");
    // let sf_session_instanceUrl = localStorage.getItem("sf_session_instanceUrl");
    // let sf_session_refreshToken = localStorage.getItem("sf_session_refreshToken");

    return (
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            // align="center"
            id="mainLeaderboardContainer"
        >
            <Grid>
                <LeaderboardSales/>
            </Grid>
            <Grid>
                <LeaderboardService/>
            </Grid>
        </Grid>
    )
// : (
//         <Grid container
//               direction="row"
//               justifyContent="center"
//         >
//             <Grid>
//                 <Typography gutterBottom variant="h5" component="div">
//                     Please sign into Salesforce to access the Leaderboards
//                 </Typography>
//                 <Salesforce/>
//             </Grid>
//         </Grid>
//     );
}

Dashboard.defaultProps = {message: ""};

export default Dashboard;
