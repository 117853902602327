import {
    CLIENTCASE_PAGE_INIT,
    CLIENTCASE_ERROR,
    CLIENTCASE_SUCCESS,
    CLIENTCASE_LIST_INIT,
    CLIENTCASE_LIST_SUCCESS,
    CLIENTCASE_LIST_ERROR,
    CLIENTCASE_UPDATE_SUCCESS,
    CLIENTCASE_UPDATE_ERROR,
    CLIENTCASE_LAUNCH_SUCCESS,
    CLIENTCASE_LAUNCH_ERROR,
    CLIENTCASE_START_ERROR,
    CLIENTCASE_START_SUCCESS,
    RELEASE_STATE_DATA, CLIENTCASE_LIST_REFRESH_SUCCESS
} from './actions';
import { combineReducers } from "redux";


// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    clientCases: [],
    selectedClientCase: {},
    selectedClientCaseError: {},
    deleteClientCase: {}
  };

const clientCaseReducer = function(state = initialState,actions){
    switch(actions.type){
        case CLIENTCASE_PAGE_INIT:
            return {...state, errors:[], clientCases: []};
        case CLIENTCASE_SUCCESS:
            return {...state, successful: true, clientCases:[...actions.payload]};
        case CLIENTCASE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};





        case CLIENTCASE_LIST_INIT:
            return {...state, errors:[], clientCases: []};

        case CLIENTCASE_LIST_SUCCESS:
            return {...state, successful: true, clientCases:[...actions.payload]};

        case CLIENTCASE_LIST_REFRESH_SUCCESS:
            return {...state, successful: true, clientCases:[...actions.payload]};

        case CLIENTCASE_LIST_ERROR:
            return {...state, successful: false, errors:[...actions.error]};








        // case CLIENTCASE_UPDATE_INIT:
        //     return {...state, errors:[], clientCases: []};

        case CLIENTCASE_UPDATE_SUCCESS:
            return {...state, successful: true, clientCases:[...actions.payload]};

        case CLIENTCASE_UPDATE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};



        case CLIENTCASE_LAUNCH_SUCCESS:
            return {...state, successful: true, clientCases:[...actions.payload]};

        case CLIENTCASE_LAUNCH_ERROR:
            return {...state, successful: false, errors:[...actions.error]};



        // case CLIENTCASE_DETAIL_INIT:
        //     return {...state, selectedClientCaseError:{}, selectedClientCase: {}};
        // case CLIENTCASE_DETAIL_SUCCESS:
        //     return {...state, selectedClientCase: {...actions.payload}};
        // case CLIENTCASE_DETAIL_ERROR:
        //     return {...state, selectedClientCaseError:{...actions.error}};
        // case CLIENTCASE_DETAIL_CLOSE:
        //     return {...state, selectedClientCaseError:{}, selectedClientCase: {}};
        // case CLIENTCASE_DELETE_SUCCESS:
        //     return {...state, deleteClientCase: {...actions.payload}};
        // case CLIENTCASE_DELETE_ERROR:
        //     return {...state, selectedClientCaseError:{...actions.error}};
        case CLIENTCASE_START_SUCCESS:
            return {...state, startClientCase: {...actions.payload}};
        case CLIENTCASE_START_ERROR:
            return {...state, selectedClientCaseError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], clientCases: [], selectedClientCase: {},selectedClientCaseError: {},deleteClientCase: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_clientCases : clientCaseReducer
});