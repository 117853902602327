import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from "react-redux";
// import './index.css';
// import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {RoleProvider} from "./helpers/roleContext"
import App from './App.js';
// import AllPossibleRoutes from './helpers/AllPossibleRoutes';
import store from "./config/store";
import WebSocketProvider from './socketHandlers/WebSocket';
import CustomThemeProvider from './modules/CustomThemeProvider/CustomThemeProvider'
// import {FpjsProvider} from '@fingerprintjs/fingerprintjs-pro-react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {LicenseInfo} from '@mui/x-data-grid-pro';
import AuthProvider from "./helpers/AuthProvider";

LicenseInfo.setLicenseKey(
    'c8a904f576d40ef62232e7d40b8edb53Tz03MzA3NyxFPTE3MjQwODA5NzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
// require('dotenv').config();
const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            {/*<FpjsProvider loadOptions={{apiKey: "afAzDZWtfvY3x9My6pw6"}}>*/}
                <WebSocketProvider>
                    <BrowserRouter>
                        <AuthProvider>
                            <RoleProvider>
                                <CustomThemeProvider>
                                    <App/>
                                </CustomThemeProvider>
                            </RoleProvider>
                        </AuthProvider>
                    </BrowserRouter>
                </WebSocketProvider>
            {/*</FpjsProvider>*/}
        </GoogleOAuthProvider>
    </Provider>
    // </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
