import {
    TERRITORY_PAGE_INIT,
    TERRITORY_ERROR,
    TERRITORY_SUCCESS,
    TERRITORY_DETAIL_INIT,
    TERRITORY_DETAIL_ERROR,
    TERRITORY_DETAIL_SUCCESS,
    TERRITORY_DETAIL_CLOSE,
    TERRITORY_DELETE_ERROR,
    TERRITORY_DELETE_SUCCESS,
    RELEASE_STATE_DATA
} from './actions';
import { combineReducers } from "redux";
// import  manageTerritoryReducer  from './ManageTerritory/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    territorys: [],
    selectedTerritory: {},
    selectedTerritoryError: {},
    deleteTerritory: {}
  };

const territoryReducers = function(state = initialState,actions){
    switch(actions.type){
        case TERRITORY_PAGE_INIT:
            return {...state, errors:[], territorys: []};
        case TERRITORY_SUCCESS:
            return {...state, successful: true, territorys:[...actions.payload]};
        case TERRITORY_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        case TERRITORY_DETAIL_INIT:
            return {...state, selectedTerritoryError:{}, selectedTerritory: {}};
        case TERRITORY_DETAIL_SUCCESS:
            return {...state, selectedTerritory: {...actions.payload}};
        case TERRITORY_DETAIL_ERROR:
            return {...state, selectedTerritoryError:{...actions.error}};
        case TERRITORY_DETAIL_CLOSE:
            return {...state, selectedTerritoryError:{}, selectedTerritory: {}};
        case TERRITORY_DELETE_SUCCESS:
            return {...state, deleteTerritory: {...actions.payload}};
        case TERRITORY_DELETE_ERROR:
            return {...state, selectedTerritoryError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], territorys: [], selectedTerritory: {},selectedTerritoryError: {},deleteTerritory: {}}
        default:
            return state;
    }
}

export default combineReducers({
    list_territory : territoryReducers,
    // manage_territory: manageTerritoryReducer
});
