import React, {useState, useEffect} from 'react';
import {
    DataGridPro,
    GridActionsCellItem, GridToolbarColumnsButton,
    // GridEvents,
    // GridToolbar,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import {useDispatch, useSelector} from "react-redux";
// import {adminDetailInit} from "../ClientSite/actions";
import {Box, Select} from "@mui/material";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import {adminAddUserInit, adminDeleteInit} from "./actions";
import MenuItem from "@mui/material/MenuItem";
import Roles from '../../helpers/Roles'
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
        </GridToolbarContainer>
    );
}

function EditToolbar(props) {
    const {apiRef} = props;

    const handleClick = () => {
        function randomId() {
            let result = "";
            let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
            let charactersLength = characters.length;
            for (let i = 0; i < 24; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        const _id = randomId();

        apiRef.current.updateRows([{_id, isNew: true}]);
        apiRef.current.setRowMode(_id, 'edit');
        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });

            apiRef.current.setCellFocus(_id, 'firstName');
        });
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                Add User
            </Button>
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    apiRef: PropTypes.shape({
        current: PropTypes.object.isRequired,
    }).isRequired,
};


export default function DataTableMaterial({selectedData}) {
    const dispatch = useDispatch();


    const addUserInitiator = (payload) => dispatch(adminAddUserInit(payload));
    const adminDeleteInitiator = (payload) => dispatch(adminDeleteInit(payload));


    const apiRef = useGridApiRef();

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellFocusOut = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'edit');
    };

    const handleSaveClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.commitRowChange(id);
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        // console.log('row')
        // console.log(row)
        addUserInitiator({payload: row})
        apiRef.current.updateRows([{...row, isNew: false}]);
    };

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        console.log(row)
        adminDeleteInitiator({payload: row})
        apiRef.current.updateRows([{...row, _action: 'delete'}]);
    };

    const handleCancelClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        if (row.isNew) {
            apiRef.current.updateRows([{...row, _action: 'delete'}]);
        }
    };

    // function getFullName(params) {
    //     // console.log("params")
    //     // console.log(params)
    //     return `${params.getValue(params.id, 'firstName') || ''} ${
    //         params.getValue(params.id, 'lastName') || ''
    //     }`;
    // }

    let widthOfCol = 100;

    let col = [
        // {
        //     field: 'fullName',
        //     headerName: 'Full Name',
        //     valueGetter: getFullName,
        //     // minWidth: 160,
        //     flex: 0.2,
        //     sortComparator: (v1, v2, cellParams1, cellParams2) => getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
        //     sortable: false
        // },
        {field: 'firstName', minWidth: widthOfCol, flex: 0.10, headerName: 'firstName', editable: true},
        {field: 'lastName', minWidth: widthOfCol, flex: 0.10, headerName: 'lastName', editable: true},
        {field: 'email', minWidth: widthOfCol, flex: 0.10, headerName: 'Email', editable: true},
        {field: 'hasVersion', minWidth: widthOfCol, flex: 0.10, headerName: 'hasVersion', editable: true},
        {field: 'familyName', minWidth: widthOfCol, flex: 0.10, headerName: 'familyName', editable: true},
        {field: 'givenName', minWidth: widthOfCol, flex: 0.10, headerName: 'givenName', editable: true},
        {field: 'name', minWidth: widthOfCol, flex: 0.10, headerName: 'name', editable: true},
        {field: 'displayName', minWidth: widthOfCol, flex: 0.10, headerName: 'displayName', editable: true},
        {field: 'condensedName', minWidth: widthOfCol, flex: 0.10, headerName: 'condensedName', editable: true},
        {field: 'currentCardImage', minWidth: widthOfCol, flex: 0.10, headerName: 'currentCardImage', editable: true},
        // {field: 'roles', minWidth: widthOfCol, flex: 0.25, headerName: 'Role', editable: true},
        {
            field: 'roles', minWidth: 250, flex: 0.10, headerName: 'Role',
            // sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <FormControl
                        // fullwidth
                        sx={{
                            // minWidth: "120px"
                            // width: "100%",
                            // fontSize: "30px"
                        }}>
                        {/*<InputLabel>Role</InputLabel>*/}
                        <Select
                            autoWidth
                            // sx={{width: "100%"}}
                            labelId="roles"
                            // type="date"
                            defaultValue="GUEST"
                            value={params.getValue(params.id, 'roles')}
                            // InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                params.api.updateRows([{...params.row, roles: e.target.value}])
                            }
                        >
                            {/*{Object.entries(Roles).forEach(entry => {*/}
                            {/*    const [key, stringValue] = entry;*/}
                            {/*    // console.log(key, stringValue);*/}
                            {/*    <MenuItem value={stringValue}>`${stringValue}`</MenuItem>*/}
                            {/*})}*/}
                            {/*{forEach(Roles) => (*/}
                            <MenuItem disabled value='SUPER_ADMIN'>SUPER ADMIN</MenuItem>
                            <MenuItem value='ADMIN'>ADMIN</MenuItem>
                            <MenuItem value='ACCOUNT_MANAGER'>ACCOUNT MANAGER</MenuItem>
                            <MenuItem value='SALES_MANAGER'>SALES MANAGER</MenuItem>
                            <MenuItem value='SALES_IMC'>SALES IMC</MenuItem>
                            <MenuItem value='GUEST'>GUEST</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            field: 'theme', minWidth: 250, headerName: 'Theme', editable: true,
            renderCell: (params) => {
                return (
                    <FormControl
                        // fullwidth
                        sx={{
                            // minWidth: "120px"
                            width: "100%",
                        }}>
                        {/*<InputLabel>Role</InputLabel>*/}
                        <Select
                            autoWidth
                            // sx={{width: "100%"}}
                            labelId="roles"
                            // type="date"
                            defaultValue="default"
                            value={params.getValue(params.id, 'roles')}
                            // InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                params.api.updateRows([{...params.row, roles: e.target.value}])
                            }
                        >
                            {/*{Object.entries(Roles).forEach(entry => {*/}
                            {/*    const [key, stringValue] = entry;*/}
                            {/*    // console.log(key, stringValue);*/}
                            {/*    <MenuItem value={stringValue}>`${stringValue}`</MenuItem>*/}
                            {/*})}*/}
                            {/*{forEach(Roles) => (*/}
                            <MenuItem value="normal">default</MenuItem>
                            <MenuItem value="typewriter"><em>typewriter</em></MenuItem>
                            <MenuItem value="nightowl">nightowl</MenuItem>
                            <MenuItem value="batman">Batman</MenuItem>
                            <MenuItem value="wonderwoman">Wonder woman</MenuItem>
                            <MenuItem value="blackpanther">Black Panther</MenuItem>
                            <MenuItem value="chicagoblackhawks">Chicago Blackhawks</MenuItem>
                            <MenuItem value="ironman">Iron Man</MenuItem>
                            <MenuItem value="miamiheat">Miami heat</MenuItem>
                            <MenuItem value="newenglandpatriots">New England Patriots</MenuItem>
                            <MenuItem value="seattlekraken">Seattle Kraken</MenuItem>
                            <MenuItem value="superman">Superman</MenuItem>
                            <MenuItem value="venom">Venom</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            // cellClassName: classes.actions,
            getActions: ({id}) => {
                const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            // className={classes.textPrimary}
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        // className={classes.textPrimary}
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        // {field: 'clientIsLive', minWidth: widthOfCol, flex: 0.25, headerName: 'Completed Launch'}
    ]
    // {/*{...props.list}*/}


    return (
        <Box style={{height: "80vh", width: '90vw'}}>
            <Box style={{display: 'flex', height: '100%'}}>
                <Box style={{flexGrow: 1}}>
                    <DataGridPro
                        apiRef={apiRef}
                        columns={col}
                        rows={selectedData}
                        getRowId={(row) => row._id}
                        editMode="row"
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onCellFocusOut={handleCellFocusOut}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar: {apiRef},
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}
