import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from '../../helpers/requests';
import { browserRedirect } from '../../helpers/helpers';
import { urls } from '../../helpers/urls';
import {
    leadgeneratorStateListSuccess,
    LEADGENERATOR_STATE_LIST_INIT,
    LEADGENERATOR_BUSINESS_LIST_INIT,
    LEADGENERATOR_BUSINESS_LIST_SCANNED_INIT,
    leadgeneratorBusinessListScannedSuccess,
    leadgeneratorBusinessListSuccess,
    LEADGENERATOR_BUSINESS_LIST_COMPLETED_INIT,
    leadgeneratorBusinessListCompletedSuccess,
    LEADGENERATOR_BUSINESS_LIST_SCANNED_COMPLETED_INIT,
    leadgeneratorBusinessListSiteScannedCompletedSuccess,
    LEADGENERATOR_ADD_REQUESTING,
    LEADGENERATOR_ADD_SCRAPE_SITE_REQUESTING,
    LEADGENERATOR_REMOVE_DUPLICATES,
    LEADGENERATOR_BUSINESS_LIST_PROGRESS_INIT,
    LEADGENERATOR_BUSINESS_LIST_SCANNED_PROGRESS_INIT,
    leadgeneratorBusinessListProgressSuccess,
    leadgeneratorBusinessListSiteScannedProgressSuccess,
    LEADGENERATOR_COLLECTION_DETAIL,
    leadgeneratorCollectionDetailSuccess, LEADGENERATOR_COLLECTION_DETAIL_INIT,
} from "./actions";

//step 1 - Returns all States with their counties
function leadgeneratorStateListCall() {
  return request('get', urls.LEADGENERATOR + `/statelist`);
}

//step 2
function leadgeneratorBusinessListCall(payload) {
  return request('post', urls.LEADGENERATOR + `/businesslist`, payload);
}

//step 2 completed list
function leadgeneratorBusinessListCompletedCall() {
  return request('get', urls.LEADGENERATOR + `/businesslistcompleted`);
}

//step 2 Progress list
function leadgeneratorBusinessListProgressCall() {
  return request('get', urls.LEADGENERATOR + `/businesslistprogress`);
}

//step 3 completed list
function leadgeneratorBusinessListSiteScannedCompletedCall() {
  return request('get', urls.LEADGENERATOR + `/businesslistsitescannedcompleted`);
}

//step 3 completed list
function leadgeneratorBusinessListSiteScannedProgressCall() {
  return request('get', urls.LEADGENERATOR + `/businesslistsitescannedprogress`);
}

//step 3
function leadgeneratorBusinessListScannedWorkerCall(payload) {
  return request('post', urls.LEADGENERATOR + `/scanned_businesslist`, payload);
}


//step 2 add
function leadgeneratorAddRequestWorkerCall(payload) {
  return request('post', urls.LEADGENERATOR + `/addrequest`, payload);
}

//step 3 Add
function leadgeneratorAddRequestScrapeSiteWorkerCall(payload) {
  return request('post', urls.LEADGENERATOR + `/addrequestscrapesite`, payload);
}


// Remove duplicates
function leadgeneratorRemoveDuplicatesWorkerCall(payload) {
  return request('post', urls.ADMIN + `/removecollectionduplicates`, payload);
}


function leadgeneratorCollectionDetailInitWorkerCall(payload) {
    // console.log(`payload: ${payload}`)
  return request('post', urls.LEADGENERATOR + `/collectiondetailinit`, payload);
}


// Step 1 Worker
function* leadgeneratorCollectionDetailWorker({payload}) {
    // console.log(`leadgeneratorCollectionDetailWorker firing correctly`)
    // console.log(`payload: ${payload}`)
  try {
      let response = yield call(leadgeneratorCollectionDetailInitWorkerCall, payload);
      response = response.data.data;

      // console.log(`response :`)
      // console.log(response)

    yield put(leadgeneratorCollectionDetailSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorAddError:`, err);
  }
}



// Step 1 Worker
function* leadgeneratorRemoveDuplicatesWorker() {
  try {
      let response = yield call(leadgeneratorRemoveDuplicatesWorkerCall);
      response = response.data.data;
    yield put(leadgeneratorStateListSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorAddError:`, err);
  }
}



// Step 1 Worker
function* leadgeneratorStateListWorker() {
  try {
      let response = yield call(leadgeneratorStateListCall);
      response = response.data.data;
    yield put(leadgeneratorStateListSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorAddError:`, err);
  }
}


// Step 2 Worker
function* leadgeneratorBusinessListWorker({payload}) {
  try {
      let response = yield call(leadgeneratorBusinessListCall, payload);
      response = response.data.data;
    yield put(leadgeneratorBusinessListSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorBusinessList Error:`, err);
  }
}


// Step 2 Completed Worker
function* leadgeneratorBusinessListCompletedWorker() {
  try {
      let response = yield call(leadgeneratorBusinessListCompletedCall);
      response = response.data.data;
    yield put(leadgeneratorBusinessListCompletedSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorBusinessList Error:`, err);
  }
}

// Step 2 Progress Worker
function* leadgeneratorBusinessListProgressWorker() {
  try {
      let response = yield call(leadgeneratorBusinessListProgressCall);
      response = response.data.data;
    yield put(leadgeneratorBusinessListProgressSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorBusinessList Error:`, err);
  }
}


// Step 3 Progress Worker
function* leadgeneratorBusinessListSiteScannedProgressWorker() {
  try {
      let response = yield call(leadgeneratorBusinessListSiteScannedProgressCall);
      response = response.data.data;
      // console.log(`result: ${response}`)
    yield put(leadgeneratorBusinessListSiteScannedProgressSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorBusinessList Error:`, err);
  }
}

// Step 3 Completed Worker
function* leadgeneratorBusinessListSiteScannedCompletedWorker() {
  try {
      let response = yield call(leadgeneratorBusinessListSiteScannedCompletedCall);
      response = response.data.data;
      // console.log(`result: ${response}`)
    yield put(leadgeneratorBusinessListSiteScannedCompletedSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorBusinessList Error:`, err);
  }
}


// Step 3 Worker
function* leadgeneratorBusinessListScannedWorker({payload}) {
  try {
      let response = yield call(leadgeneratorBusinessListScannedWorkerCall, payload);
      response = response.data.data;
    yield put(leadgeneratorBusinessListScannedSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorBusinessList Error:`, err);
  }
}



// Step 3 Worker
function* leadgeneratorAddRequestWorker({payload}) {
  try {
      yield call(leadgeneratorAddRequestWorkerCall, payload);
      // response = response.data.data;
    // yield put(leadgeneratorBusinessListScannedSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorAddRequestWorkerCall Error:`, err);
  }
}

// Step 3 Worker
function* leadgeneratorAddRequestScrapeSiteWorker({payload}) {
  try {
      yield call(leadgeneratorAddRequestScrapeSiteWorkerCall, payload);
      // response = response.data.data;
    // yield put(leadgeneratorBusinessListScannedSuccess(response));
    // yield call(browserRedirect, '/leadgenerator');
  } catch (err) {
    // yield put(leadgeneratorAddError(err.response.data));
    console.log(`leadgeneratorAddRequestWorkerCall Error:`, err);
  }
}


// leadgeneratorAdd Watcher
export default function* leadgeneratorAddSaga() {
  yield all([
    takeLatest(LEADGENERATOR_STATE_LIST_INIT, leadgeneratorStateListWorker),
    takeLatest(LEADGENERATOR_ADD_REQUESTING, leadgeneratorAddRequestWorker),
    takeLatest(LEADGENERATOR_ADD_SCRAPE_SITE_REQUESTING, leadgeneratorAddRequestScrapeSiteWorker),
    takeLatest(LEADGENERATOR_REMOVE_DUPLICATES, leadgeneratorRemoveDuplicatesWorker),
    takeLatest(LEADGENERATOR_COLLECTION_DETAIL_INIT, leadgeneratorCollectionDetailWorker),
    takeLatest(LEADGENERATOR_BUSINESS_LIST_INIT, leadgeneratorBusinessListWorker),
    takeLatest(LEADGENERATOR_BUSINESS_LIST_PROGRESS_INIT, leadgeneratorBusinessListProgressWorker),
    takeLatest(LEADGENERATOR_BUSINESS_LIST_SCANNED_PROGRESS_INIT, leadgeneratorBusinessListSiteScannedProgressWorker),
    takeLatest(LEADGENERATOR_BUSINESS_LIST_COMPLETED_INIT, leadgeneratorBusinessListCompletedWorker),
    takeLatest(LEADGENERATOR_BUSINESS_LIST_SCANNED_COMPLETED_INIT, leadgeneratorBusinessListSiteScannedCompletedWorker),
    takeLatest(LEADGENERATOR_BUSINESS_LIST_SCANNED_INIT, leadgeneratorBusinessListScannedWorker),
  ]);
}
