import { createSlice } from "@reduxjs/toolkit";

let loadingSlice = createSlice({
    name: 'loading',
    initialState: true,
    reducers: ({
        setLoading: (state, { payload }) => {
            state = payload
            return state
        }
    })
})

export const { setLoading } = loadingSlice.actions

export default loadingSlice.reducer

// ----------------------------------------------------------
// import { combineReducers } from "redux";

// export const SET_LOADING = 'loading/setLoading';
//
// export const setLoading = payload => ({
//     type: SET_LOADING,
//     payload,
// });
//
// const initialState = true;
//
// // const loadingReducer = function(state = initialState, action){
// export default function(state = initialState, action){
//     switch(action.type){
//         case SET_LOADING:
//             return action.payload;
//         default:
//             return state;
//     }
// }

// export default combineReducers({
//     loading : loadingReducer,
// });
