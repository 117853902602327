import { all } from "redux-saga/effects";
import loginSaga from "../Login/saga";
import clientSaga from "../Client/saga";
import roomSaga from "../Room/saga";
import clientSiteSaga from "../ClientSite/saga";
import clientCasesSaga from "../ClientCases/saga";
import adminSaga from "../Admin/saga";
import userSaga from "../User/saga";
import salesforceSaga from "../Salesforce/saga";
import leadGeneratorSaga from "../LeadGenerator/saga";
import nationStateSaga from "../NationState/saga";
import launchSaga from "../Launch/saga";
import stagingSaga from "../Staging/saga";
import openAISaga from "../OpenAI/saga";
import territorySaga from "../Map/saga";

export function* mainSaga() {
  yield all([
    loginSaga(),
    clientSaga(),
    clientCasesSaga(),
    roomSaga(),
    clientSiteSaga(),
    adminSaga(),
    userSaga(),
    salesforceSaga(),
    leadGeneratorSaga(),
    nationStateSaga(),
    launchSaga(),
    stagingSaga(),
    openAISaga(),
    territorySaga()
  ]);
}
