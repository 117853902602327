import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
    stagingCheckEmailInit, stagingCheckSalesforceInit,
    stagingEmailRequestedInit,
    // stagingSiteUpdateInit,
    // stagingSiteStageInit,
} from "./actions";

import {Button, Container, Grid, Tooltip} from "@mui/material";
// import { Box } from "@mui/material";
// import SecondDrawer from "./SecondDrawer";
import DataTableMaterialStagingAutomation from "./DataTableMaterialStagingAutomation";
import CheckEmailButton from "./CheckEmailButton";
import StagingDialog from "./StagingDialog";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import InfoIcon from "@mui/icons-material/Info";
import {salesForceUserTokens} from "../../helpers/helpers";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import AutoPopulatedAutomationForm from "./AutoPopulatedAutomationForm";

function StagingAutomation() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const stagingCheckSalesforceInitiator = (payload) => dispatch(stagingCheckSalesforceInit(payload));

    const stagingEmailRequests = useSelector((state) => state.stagings.list_staging.stagingEmailRequests)
    // const stagingEmailRequested = useSelector((state) => state.stagings.list_staging.stagingEmailRequested);


  useEffect(() => {
      // COMMENTED OUT TEMPORARILY SO IT DOESNT RUN DURING DEVELOPMENT RE-RENDERS
      stagingCheckSalesforceInitiator({userTokens: salesForceUserTokens()});
  },[]);


    useEffect(() => {
        // stagingEmailRequestedInitiator();
    },[stagingEmailRequests]);


  const handleSubmit = () => {
    // stagingEmailRequestedInitiator();
    // stagingEmailInitiator();
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          // marginLeft: "300px",
          // marginRight: "5%",
          paddingBottom: "20px",
          width: "100%",
        }}
      >
        <Grid item width="100%" align="center">
            <Tooltip
                title={`Accounts that are in the content transfer stage`}
                arrow
                enterDelay={300}
                leaveDelay={200}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 300 }}
            >
                <InfoIcon sx={{ marginLeft: "160px", position: "absolute" }} fontSize={"small"} color="info" />
            </Tooltip>
            <Typography variant="h4">Staging Automation</Typography>
        </Grid>
        {/*<Grid item>*/}
        {/*  <CheckEmailButton*/}
        {/*    handleSubmit={handleSubmit}*/}
        {/*    stagingEmailRequested={stagingEmailRequested}*/}
        {/*  />*/}
        {/*</Grid>*/}
          <Grid item>
          <AutoPopulatedAutomationForm/>
        </Grid>
        <Grid container item>
          <DataTableMaterialStagingAutomation open={open} setOpen={setOpen} />
        </Grid>
        {/*<StagingDialog open={open} setOpen={setOpen} />*/}
        {/*  <Grid item width="100%">*/}
        {/*    <PrettyPrintJson data={stagingEmailRequests} />*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
}
// }

StagingAutomation.propTypes = {
  stagings: PropTypes.array,
};

export default StagingAutomation;
// export default memo(StagingAutomation);
