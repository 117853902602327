export const CASE_ADD_REQUESTING = 'CASE_ADD_REQUESTING';
export const CASE_ADD_SUCCESS = 'CASE_ADD_SUCCESS';
export const CASE_ADD_ERROR = 'CASE_ADD_ERROR';
export const CASE_ADD_PAGE_INIT = 'CASE_ADD_PAGE_INIT';

export const CASE_UPDATE_REQUESTING = 'CASE_UPDATE_REQUESTING';
export const CASE_UPDATE_SUCCESS = 'CASE_UPDATE_SUCCESS';
export const CASE_UPDATE_ERROR = 'CASE_UPDATE_ERROR';



export function caseAddPageInit() {
    return {
        type: CASE_ADD_PAGE_INIT,
    };
}

export function caseAddRequest(payload) {
    return {
        type: CASE_ADD_REQUESTING,
        payload
    };
}

export function caseAddError(error) {
    return {
        type: CASE_ADD_ERROR,
        error,
    };
}

export function caseAddSuccess() {
    return {
        type: CASE_ADD_SUCCESS,
    };
}

export function caseUpdateRequest(payload,id) {
    return {
        type: CASE_UPDATE_REQUESTING,
        payload,
        id
    };
}

export function caseUpdateError(error) {
    return {
        type: CASE_UPDATE_ERROR,
        error,
    };
}

export function caseUpdateSuccess() {
    return {
        type: CASE_UPDATE_SUCCESS,
    };
}