import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// import {
//     userUnlockablesAddPageInit,
//     // userUnlockablesAddRequest,
//     // userUnlockablesDetailClose,
//     // userUnlockablesDeleteInit,
//     // // releaseStateData,
//     // userUnlockablesStartInit
// } from './actions';


// import DataTableMaterial from "./DataTableMaterial";
import {Box, Button, Grid} from "@mui/material";
import UnlockableThemes from "./UnlockableThemes";
import UnlockableAvatar from "./UnlockableAvatar";
import SecondDrawer from "../SecondDrawer";


function UserUnlockables(props) {

    const [selectedTerritory, setSelectedTerritory] = React.useState('');
    const [selectedData, setSelectedData] = React.useState('');

    /* The variable after "list_userUnlockables" corresponds to what the reducer returns
    *    */
    // const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockables)
    // const userUnlockables = useSelector((state) => state.userUnlockables)
    // const selectedUserUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUserUnlockables)
    // const errors = useSelector((state) => state.userUnlockables.list_userUnlockables.errors)
    // const selectedUserUnlockablesError = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUserUnlockablesError)
    // const deleteUserUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.deleteUserUnlockables)

    const dispatch = useDispatch();
    // const getUserUnlockabless = () => dispatch(userUnlockablesAddPageInit());
    // const getUserUnlockablesDetail = (id) => dispatch(userUnlockablesAddRequest(id));
    // getUserUnlockablesStartDetail: (id) => dispatch(userUnlockablesAddRequest(id));
    // const detailModalClose = () => dispatch(userUnlockablesDetailClose());
    // const deleteUserUnlockablesRequest = (id) => dispatch(userUnlockablesDeleteInit(id));
    // const startUserUnlockablesRequest = (id) => dispatch(userUnlockablesStartInit(id));
    // const releaseData = () => dispatch(releaseStateData());


    // const [isForDelete, setIsForDelete] = useState(false)
    // const [isForStart, setIsForStart] = useState(false)

    // const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockabless)
    // const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockables)
    // const selectedUserUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUserUnlockables)
    // const errors = useSelector((state) => state.userUnlockables.list_userUnlockables.errors)
    // const selectedUserUnlockablesError = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUserUnlockablesError)
    // const deleteUserUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.deleteUserUnlockables)

    useEffect(() => {
        // getUserUnlockabless();
        // console.log(userUnlockables)
        // dispatch(userUnlockablesAddPageInit())
    }, [])

    // Runs on exit of component
    // useEffect(() => {

    // return function cleanup() {
    //     mapDispatchToProps.releaseData()
    // Side-effect cleanup...
    // };
    // }, []);

    // const handleChangeTerritory = (event) => {
    //     let id = event.target.value.toLowerCase()
    //     // console.log(`lowerCaseName = ${id}`)
    //     setSelectedTerritory(event.target.value);
    //     setSelectedData(id)
    // };
    //
    // const handleChangeMapSelection = (statename) => {
    //     let id = statename.toLowerCase()
    //     // console.log(`lowerCaseName = ${id}`)
    //     setSelectedTerritory(statename);
    //     // setSelectedData(id)
    // };


    return (
        <>
            <Box id="secondDrawer"
                 sx={{
                     marginTop: "100px",
                     zIndex: 1,
                     position: "absolute",
                 }}
            >
                <SecondDrawer/>
            </Box>

            <Grid
                container
                spacing={5}
                direction="row"
                justifyContent="center"
            >
                <Grid item width="100%" align="center">
            <Typography gutterBottom variant="h5" component="div">Unlockable Content</Typography>
                </Grid>
                <Grid
                    container
                    // spacing={5}
                    direction="row"
                    justifyContent="center"
                    id="unlockablesContainer"
                    sx={{
                        minWidth: "410px",
                        // maxWidth: "500px",
                        marginTop: "20px",
                        position: "relative",
                        width: "410px",
                        height: "144px"
                    }}
                >
            <UnlockableAvatar/>
            <UnlockableThemes/>
                </Grid>
                </Grid>
                </>
    );

}


UserUnlockables.propTypes = {
    userUnlockables: PropTypes.array
};

// const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockables)
// const selectedUserUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUserUnlockables)
// const errors = useSelector((state) => state.userUnlockables.list_userUnlockables.errors)
// const selectedUserUnlockablesError = useSelector((state) =>
// state.userUnlockables.list_userUnlockables.selectedUserUnlockablesError)
// const deleteUserUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.deleteUserUnlockables)

// function mapStateToProps(state) {
// 	return {
// 		userUnlockables: state.userUnlockables.list_userUnlockables.userUnlockables,
// 		selectedUserUnlockables: state.userUnlockables.list_userUnlockables.selectedUserUnlockables,
// 		errors: state.userUnlockables.list_userUnlockables.errors,
// 		selectedUserUnlockablesError: state.userUnlockables.list_userUnlockables.selectedUserUnlockablesError,
// 		deleteUserUnlockables: state.userUnlockables.list_userUnlockables.deleteUserUnlockables
// 	};
// }

// function mapDispatchToProps(dispatch) {
// 	return {
// 		getUserUnlockabless: () => dispatch(userUnlockablesAddPageInit()),
// 		getUserUnlockablesDetail: (id) => dispatch(userUnlockablesAddRequest(id)),
// 		// getUserUnlockablesStartDetail: (id) => dispatch(userUnlockablesAddRequest(id)),
// 		detailModalClose: () => dispatch(userUnlockablesDetailClose()),
// 		deleteUserUnlockablesRequest: (id) => dispatch(userUnlockablesDeleteInit(id)),
// 		startUserUnlockablesRequest: (id) => dispatch(userUnlockablesStartInit(id)),
// 		releaseData: () => dispatch(releaseStateData()),
// 	};
// }

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps,
// )(withRouter(UserUnlockables));

export default UserUnlockables