import {createTheme} from '@mui/material/styles';
// import { red } from '@material-ui/core/colors'

// Normal or default theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#cc4444',
        },
        error: {
            main: '#cc4444',
        },
        background: {
            default: '#f5f5f5',
        },
        titleBar: {
            main: '#eeeeee',
            contrastText: '#222222',
        },
    },
})

export default theme