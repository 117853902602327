import {intersection} from 'lodash';

// export function isLoggedIn() {
/*
    * Note:
    *  This assumes that if local storage have roles it means
    *  user is authenticated
*/
// 	return !!localStorage.getItem('roles')
// }

export function isArrayWithLength(arr) {
    return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes) {

    try {
        let currentUser = JSON.parse(localStorage.getItem("user"));

        if (!!currentUser) {

            return routes.filter(({permission}) => {
                if (!permission) return true;
                else if (!isArrayWithLength(permission)) return true;
                else return intersection(permission, currentUser.roles).length;
            })
        } else {
            let currentUser = {}
            return currentUser.roles = ['GUEST']
                && routes.filter(({permission}) => {
                    if (!permission) return true;
                    else if (!isArrayWithLength(permission)) return true;
                    else return intersection(permission, currentUser.roles).length;
                });
        }
    } catch (e) {
        let currentUser = {}
        return currentUser.roles = ['GUEST']
            && routes.filter(({permission}) => {
                if (!permission) return true;
                else if (!isArrayWithLength(permission)) return true;
                else return intersection(permission, currentUser.roles).length;
            });
    }
}