export const STAGING_PAD_ADD_REQUESTING = 'STAGING_PAD_ADD_REQUESTING';
export const STAGING_PAD_ADD_SUCCESS = 'STAGING_PAD_ADD_SUCCESS';
export const STAGING_PAD_ADD_ERROR = 'STAGING_PAD_ADD_ERROR';
export const STAGING_PAD_ADD_PAGE_INIT = 'STAGING_PAD_ADD_PAGE_INIT';
export const STAGING_PAD_UPDATE_REQUESTING = 'STAGING_PAD_UPDATE_REQUESTING';
export const STAGING_PAD_UPDATE_SUCCESS = 'STAGING_PAD_UPDATE_SUCCESS';
export const STAGING_PAD_UPDATE_ERROR = 'STAGING_PAD_UPDATE_ERROR';
export const STAGING_PAD_UPDATE_PAGE_INIT = 'STAGING_PAD_UPDATE_PAGE_INIT';

export function stagingPadAddPageInit() {
    return {
        type: STAGING_PAD_ADD_PAGE_INIT,
    };
}

export function stagingPadAddRequest(payload) {
    return {
        type: STAGING_PAD_ADD_REQUESTING,
        payload
    };
}

export function stagingPadAddError(error) {
    return {
        type: STAGING_PAD_ADD_ERROR,
        error,
    };
}

export function stagingPadAddSuccess() {
    return {
        type: STAGING_PAD_ADD_SUCCESS,
    };
}

export function stagingPadUpdatePageInit(id) {
    return {
        type: STAGING_PAD_UPDATE_PAGE_INIT,
        id
    };
}

export function stagingPadUpdateRequest(payload,id) {
    return {
        type: STAGING_PAD_UPDATE_REQUESTING,
        payload,
        id
    };
}

export function stagingPadUpdateError(error) {
    return {
        type: STAGING_PAD_UPDATE_ERROR,
        error,
    };
}

export function stagingPadUpdateSuccess() {
    return {
        type: STAGING_PAD_UPDATE_SUCCESS,
    };
}