export const ROOM_PAGE_INIT = 'ROOM_PAGE_INIT';
export const ROOM_ERROR = 'ROOM_ERROR';
export const ROOM_SUCCESS = 'ROOM_SUCCESS';
export const ROOM_DETAIL_INIT = 'ROOM_DETAIL_INIT';
export const ROOM_DETAIL_ERROR = 'ROOM_DETAIL_ERROR';
export const ROOM_DETAIL_SUCCESS = 'ROOM_DETAIL_SUCCESS';
export const ROOM_DETAIL_CLOSE = 'ROOM_DETAIL_CLOSE';
export const ROOM_DELETE_INIT = 'ROOM_DELETE_INIT';
export const ROOM_DELETE_ERROR = 'ROOM_DELETE_ERROR';
export const ROOM_DELETE_SUCCESS = 'ROOM_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const ROOM_START_INIT = 'ROOM_START_INIT';
export const ROOM_START_REQUESTING = 'ROOM_START_REQUESTING';
export const ROOM_START_SUCCESS = 'ROOM_START_SUCCESS';
export const ROOM_START_ERROR = 'ROOM_START_ERROR';
// export const ROOM_START_PAGE_INIT = 'ROOM_START_PAGE_INIT';
export const CREATE_ROOM_REQUEST = "CREATE_ROOM_REQUEST"
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS"
export const CREATE_ROOM_ERROR = "CREATE_ROOM_ERROR"

export const JOIN_ROOM_REQUEST = "JOIN_ROOM_REQUEST"
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS"
export const JOIN_ROOM_ERROR = "JOIN_ROOM_ERROR"
export const SET_USERNAME = "SET_USERNAME"

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST"
export const UPDATE_CHAT_LOG = "UPDATE_CHAT_LOG"

export function createRoomRequest(){
    return {
        type: CREATE_ROOM_REQUEST
    }
}

export function createRoomSuccess(payload){
    return {
        type: CREATE_ROOM_SUCCESS,
        payload
    }
}

export function createRoomError(error){
    return {
        type: CREATE_ROOM_ERROR,
        error
    }
}


export function joinRoomRequest(){
    return {
        type: JOIN_ROOM_REQUEST
    }
}

export function joinRoomSuccess(payload){
    return {
        type: JOIN_ROOM_SUCCESS,
        payload
    }
}

export function joinRoomError(error){
    return {
        type: JOIN_ROOM_ERROR,
        error
    }
}



export function updateChatLog(payload){
    return {
        type: UPDATE_CHAT_LOG,
        payload
    }
}



export function setUsername(payload){
    return {
        type: SET_USERNAME,
        payload
    }
}















export function roomPageInit() {
    return {
        type: ROOM_PAGE_INIT,
    };
}

export function roomError(error) {
    return {
        type: ROOM_ERROR,
        error,
    };
}

export function roomSuccess(payload) {
    return {
        type: ROOM_SUCCESS,
        payload
    };
}

export function roomDetailInit(id) {
    return {
        type: ROOM_DETAIL_INIT,
        id 
    };
}

export function roomDetailError(error) {
    return {
        type: ROOM_DETAIL_ERROR,
        error,
    };
}

export function roomDetailSuccess(payload) {
    return {
        type: ROOM_DETAIL_SUCCESS,
        payload
    };
}

export function roomDetailClose() {
    return {
        type: ROOM_DETAIL_CLOSE,
    };
}

export function roomDeleteInit(id) {
    return {
        type: ROOM_DELETE_INIT,
        id 
    };
}

export function roomStartInit(id) {
    return {
        type: ROOM_START_INIT,
        id
    };
}

export function roomStartError(error) {
    return {
        type: ROOM_START_ERROR,
        error,
    };
}

export function roomStartSuccess(payload) {
    return {
        type: ROOM_START_SUCCESS,
        payload
    };
}

// export function roomStartRequest(payload,id) {
//     return {
//         type: ROOM_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function roomDeleteError(error) {
    return {
        type: ROOM_DELETE_ERROR,
        error,
    };
}

export function roomDeleteSuccess(payload) {
    return {
        type: ROOM_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}