export const ADMIN_COLLECTION_LIST = 'ADMIN_COLLECTION_LIST';
export const ADMIN_COLLECTION_LIST_SUCCESS = 'ADMIN_COLLECTION_LIST_SUCCESS';
export const ADMIN_COLLECTION_LIST_ERROR = 'ADMIN_COLLECTION_LIST_ERROR';

export const ADMIN_PAGE_INIT = 'ADMIN_PAGE_INIT';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const ADMIN_SUCCESS = 'ADMIN_SUCCESS';
export const ADMIN_DETAIL_INIT = 'ADMIN_DETAIL_INIT';
export const ADMIN_DETAIL_ERROR = 'ADMIN_DETAIL_ERROR';
export const ADMIN_DETAIL_SUCCESS = 'ADMIN_DETAIL_SUCCESS';
export const ADMIN_DETAIL_CLOSE = 'ADMIN_DETAIL_CLOSE';
export const ADMIN_DELETE_INIT = 'ADMIN_DELETE_INIT';
export const ADMIN_DELETE_ERROR = 'ADMIN_DELETE_ERROR';
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const ADMIN_START_INIT = 'ADMIN_START_INIT';
export const ADMIN_START_REQUESTING = 'ADMIN_START_REQUESTING';
export const ADMIN_START_SUCCESS = 'ADMIN_START_SUCCESS';
export const ADMIN_START_ERROR = 'ADMIN_START_ERROR';
export const ADMIN_ADD_USER_INIT = 'ADMIN_ADD_USER_INIT';
export const ADMIN_ADD_USER_SUCCESS = 'ADMIN_ADD_USER_SUCCESS';
export const ADMIN_ADD_USER_ERROR = 'ADMIN_ADD_USER_ERROR';
// export const ADMIN_START_PAGE_INIT = 'ADMIN_START_PAGE_INIT';



export const IMPORT_COLLECTION_FILE_NAME_SAVE = 'IMPORT_COLLECTION_FILE_NAME_SAVE';
export const IMPORT_COLLECTION_FILE_DATA_SAVE = 'IMPORT_COLLECTION_FILE_DATA_SAVE';


export const IMPORT_STATE_CITIES_INIT = 'IMPORT_STATE_CITIES_INIT';
export const IMPORT_STATE_CITIES_SUCCESS = 'IMPORT_STATE_CITIES_SUCCESS';

export const IMPORT_COLLECTION_INIT = 'IMPORT_COLLECTION_INIT';
export const IMPORT_COLLECTION_SUCCESS = 'IMPORT_COLLECTION_SUCCESS';
export const IMPORT_COLLECTION_ERROR = 'IMPORT_COLLECTION_ERROR';

export const EXPORT_COLLECTION_INIT = 'EXPORT_COLLECTION_INIT';
export const EXPORT_COLLECTION_SUCCESS = 'EXPORT_COLLECTION_SUCCESS';
export const EXPORT_COLLECTION_ERROR = 'EXPORT_COLLECTION_ERROR';

export const DELETE_COLLECTION_INIT = 'DELETE_COLLECTION_INIT';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_ERROR = 'DELETE_COLLECTION_ERROR';

export const GET_CLIENT_SITE_KEY_CONFIGURATION_INIT = 'GET_CLIENT_SITE_KEY_CONFIGURATION_INIT';
export const GET_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS = 'GET_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS';
export const GET_CLIENT_SITE_KEY_CONFIGURATION_ERROR = 'GET_CLIENT_SITE_KEY_CONFIGURATION_ERROR';


export const SAVE_CLIENT_SITE_KEY_CONFIGURATION_INIT = 'SAVE_CLIENT_SITE_KEY_CONFIGURATION_INIT';
export const SAVE_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS = 'SAVE_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS';
export const SAVE_CLIENT_SITE_KEY_CONFIGURATION_ERROR = 'SAVE_CLIENT_SITE_KEY_CONFIGURATION_ERROR';




export function getClientSiteKeyConfigurationInit(payload) {
    return {
        type: GET_CLIENT_SITE_KEY_CONFIGURATION_INIT,
        payload
    };
}

export function getClientSiteKeyConfigurationSuccess(payload) {
    return {
        type: GET_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS,
        payload
    };
}

export function getClientSiteKeyConfigurationError(payload) {
    return {
        type: GET_CLIENT_SITE_KEY_CONFIGURATION_ERROR,
        payload
    };
}

/*
* Save Client Site Key Configuration
* */

export function saveClientSiteKeyConfigurationInit(payload) {
    return {
        type: SAVE_CLIENT_SITE_KEY_CONFIGURATION_INIT,
        payload
    };
}

export function saveClientSiteKeyConfigurationSuccess(payload) {
    return {
        type: SAVE_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS,
        payload
    };
}

export function saveClientSiteKeyConfigurationError(payload) {
    return {
        type: SAVE_CLIENT_SITE_KEY_CONFIGURATION_ERROR,
        payload
    };
}










/*
* Import State Cities
* */
export function importStateCitiesInit() {
    return {
        type: IMPORT_STATE_CITIES_INIT,
    };
}

export function importStateCitiesSuccess(payload) {
    return {
        type: IMPORT_STATE_CITIES_SUCCESS,
        payload
    };
}




/*
* Import Collection related
* */
export function importCollectionInit(payload) {
    return {
        type: IMPORT_COLLECTION_INIT,
        payload
        // fileData
    };
}

export function importCollectionFileNameSave(payload) {
    return {
        type: IMPORT_COLLECTION_FILE_NAME_SAVE,
        payload
    };
}

export function importCollectionFileDataSave(payload) {
    return {
        type: IMPORT_COLLECTION_FILE_DATA_SAVE,
        payload
    };
}




export function importCollectionSuccess(payload) {
    return {
        type: IMPORT_COLLECTION_SUCCESS,
        payload
    };
}

export function importCollectionError(payload) {
    return {
        type: IMPORT_COLLECTION_ERROR,
        payload
    };
}


/*
* Export Collection related
* */
export function exportCollectionInit(payload) {
    return {
        type: EXPORT_COLLECTION_INIT,
        payload
    };
}

export function exportCollectionSuccess(payload) {
    return {
        type: EXPORT_COLLECTION_SUCCESS,
        payload
    };
}

export function exportCollectionError(payload) {
    return {
        type: EXPORT_COLLECTION_ERROR,
        payload
    };
}


/*
* Delete Collection related
* */
export function deleteCollectionInit(payload) {
    return {
        type: DELETE_COLLECTION_INIT,
        payload
    };
}

export function deleteCollectionSuccess(payload) {
    return {
        type: DELETE_COLLECTION_SUCCESS,
        payload
    };
}

export function deleteCollectionError(payload) {
    return {
        type: DELETE_COLLECTION_ERROR,
        payload
    };
}






/*
* Admin User related
* */
export function adminAddUserInit(payload) {
    return {
        type: ADMIN_ADD_USER_INIT,
        payload
    };
}

export function adminAddUserSuccess(payload) {
    return {
        type: ADMIN_ADD_USER_SUCCESS,
        payload
    };
}

export function adminPageInit() {
    return {
        type: ADMIN_PAGE_INIT,
    };
}




export function adminCollectionList() {
    return {
        type: ADMIN_COLLECTION_LIST,
    };
}

export function adminCollectionListSuccess(payload) {
    return {
        type: ADMIN_COLLECTION_LIST_SUCCESS,
        payload
    };
}

export function adminCollectionListError(error) {
    return {
        type: ADMIN_COLLECTION_LIST_ERROR,
        error,
    };
}






export function adminError(error) {
    return {
        type: ADMIN_ERROR,
        error,
    };
}

export function adminAddUserError(error) {
    return {
        type: ADMIN_ADD_USER_ERROR,
        error,
    };
}

export function adminSuccess(payload) {
    return {
        type: ADMIN_SUCCESS,
        payload
    };
}

export function adminDetailInit(id) {
    return {
        type: ADMIN_DETAIL_INIT,
        id 
    };
}

export function adminDetailError(error) {
    return {
        type: ADMIN_DETAIL_ERROR,
        error,
    };
}

export function adminDetailSuccess(payload) {
    return {
        type: ADMIN_DETAIL_SUCCESS,
        payload
    };
}

export function adminDetailClose() {
    return {
        type: ADMIN_DETAIL_CLOSE,
    };
}

export function adminDeleteInit(payload) {
    return {
        type: ADMIN_DELETE_INIT,
        payload
    };
}

export function adminStartInit(id) {
    return {
        type: ADMIN_START_INIT,
        id
    };
}

export function adminStartError(error) {
    return {
        type: ADMIN_START_ERROR,
        error,
    };
}

export function adminStartSuccess(payload) {
    return {
        type: ADMIN_START_SUCCESS,
        payload
    };
}



// export function adminStartRequest(payload,id) {
//     return {
//         type: ADMIN_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function adminDeleteError(error) {
    return {
        type: ADMIN_DELETE_ERROR,
        error,
    };
}

export function adminDeleteSuccess(payload) {
    return {
        type: ADMIN_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}