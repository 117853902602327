import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import {Tooltip} from "@mui/material";
import Fade from "@mui/material/Fade";
import AutoPopulatedForm from "./AutoPopulatedForm";
import AutocompleteSelection from "./AutocompleteSelection";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2';

function ManualLaunching() {
    return (
        <Grid sx={{
            // backgroundColor: "red"
        }}>
            <Grid
                container
                align="center"
                justifyContent="center"
                sx={{
                    // marginLeft: "2vw",
                    marginRight: "45vw",
                    paddingBottom: "20px",
                    width: "100%",
                }}
            >
                <Grid container
                      align="center"
                      justifyContent="center"
                      sx={{marginBottom: "10px"}}>
                    <Typography variant="h4">Launch a Site</Typography>
                    <Tooltip
                        title={`Launch a Site based on a staging domain found on the server`}
                        arrow
                        enterDelay={300}
                        leaveDelay={200}
                        TransitionComponent={Fade}
                        TransitionProps={{timeout: 300}}
                    >
                        <InfoIcon
                            sx={{}}
                            fontSize={"small"}
                            color="info"
                        />
                    </Tooltip>
                </Grid>
                <Grid
                    container
                    align="center"
                    justifyContent="center"
                    sx={{
                        paddingBottom: "40px",
                        width:"100%"
                    }}
                >
                    <Grid sx={{p: 3, boxShadow: "2"}}>
                        <Grid align="center" sx={{m:4}}>
                            <AutocompleteSelection/>
                        </Grid>
                        <AutoPopulatedForm/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

ManualLaunching.propTypes = {
    stagings: PropTypes.array,
};

export default ManualLaunching;