import history from '../config/history';


function refreshPage() {
  window.location.reload(false);
}

export const browserRedirect = url => {
   history.push(url);
  refreshPage()
}

export const browserRedirectAndRefresh = url => {
   history.push(url);
  refreshPage()
}

export const browserRedirectSamePage = url => {
  history.push(url);
}

export const browserRedirectNewTab = url => {
  // history.push(url);
    var win = window.open(url, '_blank');
    win.focus();
}

export const parseJwt = token => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  return null;
};


export const salesforceUserSession = (passed) => {
  // console.log("saving salesforceUserSession tokens to local storage")
  localStorage.setItem('sf_session_accessToken', passed.session_accessToken)
  localStorage.setItem('sf_session_instanceUrl', passed.session_instanceUrl)
  localStorage.setItem('sf_session_refreshToken', passed.session_refreshToken)
}


export const salesForceUserTokens = () => {
  let sf_session_accessToken = '00'
  let sf_session_instanceUrl = '00'
  let sf_session_refreshToken = '00'
  
  return {
    sf_session_accessToken: sf_session_accessToken,
    sf_session_instanceUrl: sf_session_instanceUrl,
    sf_session_refreshToken: sf_session_refreshToken,
  };
}


// export const checkAuthorization = () => {
//   const storedGoogleToken = localStorage.getItem('g_tokenId');
//
//   const storedToken = localStorage.getItem('token');
//   if (storedGoogleToken && storedToken) {
//     const tokenPayload = parseJwt(storedToken);
//
//     const expiration = new Date(tokenPayload.exp * 1000).getTime();
//
//     const current = new Date().getTime();
//
//     if (current > expiration) return false;
//
//     return true;
//   }
//   return false;
// };


export const checkSalesforceAuthorization = () => {
  const storedToken = localStorage.getItem('sf_session_refreshToken');
  if (storedToken) {
    return true;
  }
    if (!storedToken) {
      return false;
    }
};


export const refreshTokenSetup = (res) => {
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    // console.log('newAuthRes:', newAuthRes);
    // saveUserToken(newAuthRes.access_token);  <-- save new token
    localStorage.setItem('authToken', newAuthRes.id_token);

    // Set up the other timer after the first one
    setTimeout(refreshToken, refreshTiming);
  };

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};


export const checkVersionAuthorization = () => {
  let userObject = JSON.parse(localStorage.getItem("user"));
  let userObjectVersion = userObject.hasVersion
    console.log(`userObjectVersion`)
    console.log(userObjectVersion)
  if (userObjectVersion === 2) {
    console.log(`User has up to date Version`)
    return true;
  }
  if (userObjectVersion !== 2) {
    return false;
  }
};
