import React, {useState, useEffect, useRef} from 'react';
import {
    DataGridPro,
    GridActionsCellItem, GridToolbarColumnsButton,
    // GridEvents,
    GridToolbar,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import {useDispatch, useSelector} from "react-redux";
// import {adminDetailInit} from "../ClientSite/actions";
import {Box, Select} from "@mui/material";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import {
    adminAddUserInit,
    adminDeleteInit,
    deleteCollectionInit,
    exportCollectionInit, importCollectionFileDataSave,
    importCollectionFileNameSave,
    importCollectionSave
} from "./actions";
import MenuItem from "@mui/material/MenuItem";
import Roles from '../../helpers/Roles'
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishIcon from '@mui/icons-material/Publish';
import LaunchIcon from '@mui/icons-material/Launch';
import LinearDeterminateProgressBar from "../Staging/LinearDeterminateProgressBar";



function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

// function EditToolbar(props) {
//     const {apiRef} = props;
//
//     const handleClick = () => {
//         function randomId() {
//             let result = "";
//             let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
//             let charactersLength = characters.length;
//             for (let i = 0; i < 24; i++) {
//                 result += characters.charAt(Math.floor(Math.random() * charactersLength));
//             }
//             return result;
//         }
//
//         const _id = randomId();
//
//         apiRef.current.updateRows([{_id, isNew: true}]);
//         apiRef.current.setRowMode(_id, 'edit');
//         // Wait for the grid to render with the new row
//         setTimeout(() => {
//             apiRef.current.scrollToIndexes({
//                 rowIndex: apiRef.current.getRowsCount() - 1,
//             });
//
//             apiRef.current.setCellFocus(_id, 'firstName');
//         });
//     };
//
//     return (
//         <GridToolbarContainer>
//             <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
//                 Add Collection
//             </Button>
//         </GridToolbarContainer>
//     );
// }
//
// EditToolbar.propTypes = {
//     apiRef: PropTypes.shape({
//         current: PropTypes.object.isRequired,
//     }).isRequired,
// };


// const FileUploader = ({onFileSelect}) => {
//     const fileInput = useRef(null)
//
//     const handleFileInput = (e) => {
//         // handle validations
//         const file = e.target.files[0];
//         if (file.size > 1024)
//             onFileSelectError({ error: "File size cannot exceed more than 1MB" });
//         else onFileSelectSuccess(file);
//     };
//
//     return (
//         <Button className="file-uploader">
//             <input type="file" onChange={handleFileInput}>
//                 <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary">
//         </Button>
// )
// }

export default function DataTableMaterialDatabase({selectedData}) {
    const dispatch = useDispatch();
    // const fileInput = useRef(null)

    // const [name, setName] = useState("");
    // const [selectedFile, setSelectedFile] = useState(null);

    // const importCollectionFileNameSaveInit = (payload) => dispatch(importCollectionFileNameSave(payload));
    // const importCollectionFileDataSaveInit = (payload) => dispatch(importCollectionFileDataSave(payload));
    // const exportCollectionInitiator = (payload) => dispatch(exportCollectionInit(payload));
    const deleteCollectionInitiator = (payload) => dispatch(deleteCollectionInit(payload));








    const apiRef = useGridApiRef();

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellFocusOut = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'edit');
    };

    // const handleOpenClick = (id) => (event) => {
    //     event.stopPropagation();
    //     apiRef.current.commitRowChange(id);
    //     apiRef.current.setRowMode(id, 'view');
    //
    //     const row = apiRef.current.getRow(id);
    //     // console.log('row')
    //     // console.log(row)
    //     // addUserInitiator({payload: row})
    //     apiRef.current.updateRows([{...row, isNew: false}]);
    // };







    // const handleImportClick = (id) => (event) => {
    //     event.stopPropagation();
    //     apiRef.current.commitRowChange(id);
    //     apiRef.current.setRowMode(id, 'view');
    //
    //     const row = apiRef.current.getRow(id);
    //     // console.log('row')
    //     // console.log(row)
    //
    //     // setSelectedFile(event.target.files)
    //     // setSelectedFile(apiRef.current.files)
    //
    //     // importCollectionFileNameSaveInit({collectionName: row.collectionName})
    //     apiRef.current.updateRows([{...row, isNew: false}]);
    // };

    // const handleFileDataUpload = (e) => {
    //     // handle validations
    //     // setSelectedFile(e.target.files[0])
    //     const uploadedFile = e.target.files[0]
    //     // importCollectionFileDataSaveInit({
    //     //     fileData: e.target.files[0]
    //     // });
    //     // importCollectionInitiator({selectedData: selectedFile})
    //     console.log(`uploadedFile`)
    //     console.log(uploadedFile)
    // }



    // const handleExportClick = (id) => (event) => {
    //     event.stopPropagation();
    //     apiRef.current.commitRowChange(id);
    //     apiRef.current.setRowMode(id, 'view');
    //
    //     const row = apiRef.current.getRow(id);
    //     // console.log('row')
    //     // console.log(row)
    //     // exportCollectionInitiator({rowData: row})
    //     apiRef.current.updateRows([{...row, isNew: false}]);
    // };

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        console.log(row)
        deleteCollectionInitiator({rowData: row})
        apiRef.current.updateRows([{...row, _action: 'delete'}]);
    };

    const handleCancelClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        if (row.isNew) {
            apiRef.current.updateRows([{...row, _action: 'delete'}]);
        }
    };



    // function getFullName(params) {
    //     // console.log("params")
    //     // console.log(params)
    //     return `${params.getValue(params.id, 'firstName') || ''} ${
    //         params.getValue(params.id, 'lastName') || ''
    //     }`;
    // }

    // let widthOfCol = 200;

    let col = [
        {field: 'name', flex: 0.5, headerName: 'Collection Name', editable: false},
        // {
        //     field: 'id' ,
        //     headerName: 'number',
        //     filterable: false,
            // renderCell:(index) => index.api.getRowIndex(index.row.name + '_' + index.row.type)
        // },

        // {field: 'fileUpload', minWidth: widthOfCol, flex: 0.25, headerName: 'Upload', editable: false,
        //     renderCell: (params) => {
        //         return (
        //             <Button
        //                 variant="contained"
        //                 component="label"
        //             >
        //                 Upload File
        //                 <input
        //                     type="file"
        //                     name='file'
        //                     hidden
        //                     onChange={handleFileDataUpload}
        //                 />
        //             </Button>
        //         );
        //     }},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 0.25,
            // cellClassName: classes.actions,
            getActions: ({id}) => {
                const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

                if (isInEditMode) {
                    return [
                        // <GridActionsCellItem
                        //     icon={<PublishIcon/>}
                        //     label="Import"
                        //     onClick={handleImportClick(id)}
                        //     color="primary"
                        //     component="label"
                        // >
                        // </GridActionsCellItem>,
                        // <GridActionsCellItem
                        //     icon={<FileDownloadIcon/>}
                        //     label="Export"
                        //     onClick={handleExportClick(id)}
                        //     color="primary"
                        // />,
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            // className={classes.textPrimary}
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    // <GridActionsCellItem
                    //     icon={<LaunchIcon/>}
                    //     label="Open"
                    //     // className={classes.textPrimary}
                    //     onClick={handleOpenClick(id)}
                    //     color="inherit"
                    // />,
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        // className={classes.textPrimary}
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ]
    // {/*{...props.list}*/}


    if (selectedData === '') {
        return (
            <div style={{height: 400, width: '100%'}}>
                {/*{`${selectedData}`}*/}
            </div>
        )
    } else {
        return (
            <Box style={{height: 800, width: '100%'}}>
                <Box style={{display: 'flex', height: '100%'}}>
                    <Box style={{flexGrow: 1}}>
                        <DataGridPro
                            apiRef={apiRef}
                            columns={col}
                            rows={selectedData}
                            getRowId={(row) => row.name}
                            editMode="row"
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            onCellFocusOut={handleCellFocusOut}
                            components={{
                                Toolbar: GridToolbar,
                                // Toolbar: CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar: {apiRef},
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }
}
