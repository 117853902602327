import React, {useState, useEffect, useRef} from 'react';
import {
    DataGridPro,
    GridActionsCellItem, GridToolbarColumnsButton,
    // GridEvents,
    // GridToolbar,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import {useDispatch, useSelector} from "react-redux";
// import {adminDetailInit} from "../ClientSite/actions";
import {Box, Select} from "@mui/material";
// import Alert from "@mui/material/Alert";
// import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
// import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
// import PropTypes from "prop-types";
// import {
//     adminAddUserInit,
//     adminDeleteInit,
//     deleteCollectionInit,
//     exportCollectionInit, importCollectionFileDataSave,
//     importCollectionFileNameSave,
//     importCollectionSave
// } from "./actions";
// import MenuItem from "@mui/material/MenuItem";
// import Roles from '../../helpers/Roles'
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import PublishIcon from '@mui/icons-material/Publish';
// import LaunchIcon from '@mui/icons-material/Launch';
import {leadgeneratorBusinessListCompletedInit, leadgeneratorBusinessListProgressInit} from "./actions";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import LinearDeterminateProgressBar from "../Staging/LinearDeterminateProgressBar";
import StateScrapeProgressBar from "./StateScrapeProgressBar";
import SiteScrapeProgressBar from "./SiteScrapeProgressBar";
// import LinearDeterminateProgressBar from "../Staging/LinearDeterminateProgressBar";



function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}



export default function DataTableBusinessListProgress() {
    const dispatch = useDispatch();
    const leadgeneratorBusinessListProgressInitiator = () => dispatch(leadgeneratorBusinessListProgressInit())

    const businessListProgress = useSelector((state) => state.leadgenerator.businessListProgress);

    useEffect(() => {
        leadgeneratorBusinessListProgressInitiator()
    }, []);


    useEffect(() => {
    }, [businessListProgress]);



    const apiRef = useGridApiRef();

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellFocusOut = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'edit');
    };

    const handleOpenClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.commitRowChange(id);
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        // console.log('row')
        // console.log(row)
        // addUserInitiator({payload: row})
        apiRef.current.updateRows([{...row, isNew: false}]);
    };

    const handleImportClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.commitRowChange(id);
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        // console.log('row')
        // console.log(row)

        // setSelectedFile(event.target.files)
        // setSelectedFile(apiRef.current.files)

        // importCollectionFileNameSaveInit({collectionName: row.collectionName})
        apiRef.current.updateRows([{...row, isNew: false}]);
    };

    const handleFileDataUpload = (e) => {
        // handle validations
        // setSelectedFile(e.target.files[0])
        const uploadedFile = e.target.files[0]
        // importCollectionFileDataSaveInit({
        //     fileData: e.target.files[0]
        // });
        // importCollectionInitiator({selectedData: selectedFile})
        console.log(`uploadedFile`)
        console.log(uploadedFile)
    }

    const handleExportClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.commitRowChange(id);
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        // console.log('row')
        // console.log(row)
        // exportCollectionInitiator({rowData: row})
        apiRef.current.updateRows([{...row, isNew: false}]);
    };

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        console.log(row)
        // deleteCollectionInitiator({rowData: row})
        apiRef.current.updateRows([{...row, _action: 'delete'}]);
    };

    const handleCancelClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        if (row.isNew) {
            apiRef.current.updateRows([{...row, _action: 'delete'}]);
        }
    };



    function getCountiesRatio(params) {
        // console.log("params")
        // console.log(params)
        return `${params.getValue(params.id, 'totalCountiesScanned') || ''} / ${
            params.getValue(params.id, 'totalStateCountiesCount') || ''
        }`;
    }

    function getWebsitesRatio(params) {
        // console.log("params")
        // console.log(params)
        return `${params.getValue(params.id, 'totalSitesScanned') || ''} / ${
            params.getValue(params.id, 'totalSitesCount') || ''
        }`;
    }


    let widthOfCol = 200;

    let col = [
        {field: 'simpleName', flex: 0.1, headerName: 'State', editable: false},
        {field: 'industryName', flex: 0.1, headerName: 'Industry', editable: false},
        {field: 'collectionName', flex: 0.5, headerName: 'DB Collection Name', editable: false, hide: true},
        // {field: 'started', flex: 0.5, headerName: 'Started', editable: false, hide: true},
        {
            field: 'totalCountiesScannedAndTotalCountiesInState',
            flex: 0.2,
            headerName: 'Counties Scanned / Total',
            editable: false,
            hide: false,
            valueGetter: getCountiesRatio,
            sortable: false,
        },
        // {field: 'totalCountiesScanned', flex: 0.2, headerName: 'Counties Scanned', editable: false, hide: false},
        // {field: 'totalStateCountiesCount', flex: 0.2, headerName: 'Counties in State', editable: false},
        {field: 'countiesScannedProgress', flex: 0.2, headerName: 'Percentage Scanned', editable: false,
            renderCell: (params) => {
                return (
                    <StateScrapeProgressBar progress={params.getValue(params.id, ('totalCountiesScanned'))} progressMax={params.getValue(params.id, ('totalStateCountiesCount'))} />
                );
            }
        },
        {field: 'totalBusinessesCount', flex: 0.05, headerName: 'Leads', editable: false},
        // {field: 'totalSitesScanned', flex: 0.2, headerName: 'Websites Scanned', editable: false, hide: false},
        // {field: 'totalSitesCount', flex: 0.2, headerName: 'Number of Websites', editable: false, hide: false},
        {
            field: 'totalSitesScannedAndSitesTotal',
            flex: 0.2,
            headerName: 'Websites Scanned / Total',
            editable: false,
            hide: false,
            valueGetter: getWebsitesRatio,
            sortable: false,
        },
        {field: 'other', flex: 0.2, headerName: 'Percentage Scanned', editable: false, hide: false,
            renderCell: (params) => {
                return (
                    <StateScrapeProgressBar progress={params.getValue(params.id, ('totalSitesScanned'))} progressMax={params.getValue(params.id, ('totalSitesCount'))} />
                );
            }
        },
        {field: 'finished', flex: 0.05, headerName: 'Finished', editable: false, hide: false},
        // {field: 'fileUpload', minWidth: widthOfCol, flex: 0.25, headerName: 'Upload', editable: false,
        //     renderCell: (params) => {
        //         return (
        //             <Button
        //                 variant="contained"
        //                 component="label"
        //             >
        //                 Upload File
        //                 <input
        //                     type="file"
        //                     name='file'
        //                     hidden
        //                     onChange={handleFileDataUpload}
        //                 />
        //             </Button>
        //         );
        //     }},
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     headerName: 'Actions',
        //     flex: 0.25,
        //     // cellClassName: classes.actions,
        //     getActions: ({id}) => {
        //         const isInEditMode = apiRef.current.getRowMode(id) === 'edit';
        //
        //         if (isInEditMode) {
        //             return [
        //                 // <GridActionsCellItem
        //                 //     icon={<PublishIcon/>}
        //                 //     label="Import"
        //                 //     onClick={handleImportClick(id)}
        //                 //     color="primary"
        //                 //     component="label"
        //                 // >
        //                 // </GridActionsCellItem>,
        //                 <GridActionsCellItem
        //                     icon={<FileDownloadIcon/>}
        //                     label="Export"
        //                     onClick={handleExportClick(id)}
        //                     color="primary"
        //                 />,
        //                 // <GridActionsCellItem
        //                 //     icon={<DeleteIcon/>}
        //                 //     label="Delete"
        //                 //     onClick={handleDeleteClick(id)}
        //                 //     color="inherit"
        //                 // />,
        //                 <GridActionsCellItem
        //                     icon={<CancelIcon/>}
        //                     label="Cancel"
        //                     // className={classes.textPrimary}
        //                     onClick={handleCancelClick(id)}
        //                     color="inherit"
        //                 />,
        //             ];
        //         }
        //
        //         return [
        //             // <GridActionsCellItem
        //             //     icon={<LaunchIcon/>}
        //             //     label="Open"
        //             //     // className={classes.textPrimary}
        //             //     onClick={handleOpenClick(id)}
        //             //     color="inherit"
        //             // />,
        //             <GridActionsCellItem
        //                 icon={<EditIcon/>}
        //                 label="Edit"
        //                 // className={classes.textPrimary}
        //                 onClick={handleEditClick(id)}
        //                 color="inherit"
        //             />,
        //         ];
        //     },
        // },
    ]
    // {/*{...props.list}*/}


    if (businessListProgress === '') {
        return (
            <div style={{height: 800, width: '100%'}}>
                {/*{`${selectedData}`}*/}
            </div>
        )
    } else {
        return (
            <Box style={{height: 800, width: '100%'}}>
                <Box style={{display: 'flex', height: '100%'}}>
                    <Box style={{flexGrow: 1}}>
                        <DataGridPro
                            apiRef={apiRef}
                            columns={col}
                            rows={businessListProgress}
                            // getRowId={(row) => row._id}
                            editMode="row"
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            onCellFocusOut={handleCellFocusOut}
                            components={{
                                // Toolbar: GridToolbar,
                                Toolbar: CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar: {apiRef},
                            }}
                        />
                    </Box>
                </Box>
                {/*<PrettyPrintJson data={businessListProgress} />*/}
            </Box>
        );
    }
}
