import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  SALESFORCE_PAGE_INIT,
  // SALESFORCE_NEW_CASES_PAGE_INIT,
  SALESFORCE_OPEN_CASES_PAGE_INIT,
  // SALESFORCE_DETAIL_INIT,
  // SALESFORCE_DELETE_INIT,
  // SALESFORCE_START_INIT,
  LOGGED_IN_INIT,
  LOGGED_IN,
  // loggedIn,
  salesforceError,
  // salesforceSuccess,
  // salesforceDetailError,
  // salesforceDetailSuccess,
  // salesforceDeleteError,
  // salesforceDeleteSuccess,
  // salesforcePageInit,
  // salesforceStartSuccess,
  // salesforceStartError,
  // FETCH_SALESFORCES_BY_ACCOUNT,
  // fetchSalesforcesByAccountSuccess,
  // loadNewCasesSuccess,
  loadOpenCasesSuccess,
  // loadDealsThisMonthSuccess,
  // loadLaunchesThisMonthSuccess,
  loadAccountsSuccess,
  loggedInInitSuccess,
  // loadAccountsError,
  loggedInInitError,
  INITIAL_STATE_LOAD_ACCOUNTS,
  // INITIAL_STATE_LOAD_NEW_CASES,
  INITIAL_STATE_LOAD_OPEN_CASES,
  SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT,
  SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT, salesforceDealsThisMonthSuccess, salesforceLaunchesThisMonthSuccess,
} from "./actions";

// import { browserRedirect } from '../../helpers/helpers';
import {browserRedirectSamePage, salesforceUserSession} from '../../helpers/helpers';
import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
import manageCaseSaga from './ManageCase/saga';
// import {browserRedirect} from "../../helpers/helpers";


//Salesforce API calls
function salesforceLoginCall() {
  return request('get', urls.SALESFORCE+`/salesforcelogin`); // sends back token url
}


function sendToken_GetSalesforcesCall(payload) {
  // console.log('Payload in token call')
  // console.log(payload)
  // sends token from frontend to back ... code: {payload},
  return request('post', urls.SALESFORCE+`/token`, payload);
}



function getAccountsCall(payload) {
  // console.log('Payload in accounts call')
  // console.log(payload)
  return request('post', urls.SALESFORCE+`/accounts`, payload);
}

// function getNewCasesCall(payload) {
//   console.log('Payload in accounts call')
//   console.log(payload)
//   return request('post', urls.SALESFORCE+`/newcases`, payload);
// }

function getOpenCasesCall(payload) {
  // console.log('Payload in open cases call')
  // console.log(payload)
  return request('post', urls.SALESFORCE+`/opencases`, payload);
}

function getDealsThisMonthCall(payload) {
  // console.log('Payload in getDealsThisMonthCall call')
  // console.log(payload)
  return request('post', urls.SALESFORCE+`/dealsthismonth`, payload);
}

function getLaunchesThisMonthCall(payload) {
  // console.log('Payload in getLaunchesThisMonthCall call')
  // console.log(payload)
  return request('post', urls.SALESFORCE+`/launchesthismonth`, payload);
}


function salesforceLoginRedirectAndGetTokenCall(payload) {
  return browserRedirectSamePage(`${payload}`)
}



//************************************* Salesforce Workers

/*
* Send User to Salesforce
* User Signs into Salesforce
* Salesforce sends User to callback URL '/app/salesforce/token'
* User takes Token and sends token to the backend
*  */
function* salesforce_LoginWorker() {
  try {
    let token_url = yield call(salesforceLoginCall);
    token_url = token_url.data.data

    let second = yield call(salesforceLoginRedirectAndGetTokenCall, token_url);
    // yield call(salesforceTokenCall, secondResponse)
    // console.log('salesforce_LoginWorker finished')
  } catch (err) {
    yield put(salesforceError(err.response.data.data));
  }
}

/* User received token or sends token data at this point, */
function* salesForce_SendTokenWorker(payload) {
  try {
    let response = yield call(sendToken_GetSalesforcesCall, payload.payload);
    response = response.data.data
    salesforceUserSession(response)
    yield put(loggedInInitSuccess(response));
  } catch (err) {
    yield put(loggedInInitError(err.response.data.data));
  }
}

// Fetch Salesforce account
function* getAccountsWorker(payload) {
  try {
    let response = yield call(getAccountsCall, payload.payload);
    response = response.data.data
    // console.log("getting accounts response")
    // console.log(response)
    yield put(loadAccountsSuccess(response));
  } catch (err) {
    console.log('err in getAccountsWorker in saleforce saga' + err)
  }
}

// Fetch Salesforce account
// function* getNewCasesWorker(payload) {
//   try {
//     let response = yield call(getNewCasesCall, payload.payload);
//     response = response.data.data
//     console.log("getting new cases response = ")
//     console.log(response)
//     yield put(loadNewCasesSuccess(response));
//   } catch (err) {
//     console.log('err in getNewCasesWorker in saleforce saga' + err)
//   }
// }

// Fetch Salesforce account
function* getOpenCasesWorker(payload) {
  try {
    let response = yield call(getOpenCasesCall, payload.payload);
    response = response.data.data
    // console.log("getting open cases response = ")
    // console.log(response)
    yield put(loadOpenCasesSuccess(response));
  } catch (err) {
    console.log('err in getOpenCasesWorker in saleforce saga' + err)
  }
}

// Fetch DealsThisMonth Report
function* getDealsThisMonthWorker(payload) {
  try {
    let response = yield call(getDealsThisMonthCall, payload.payload);
    response = response.data.data
    // console.log(" getDealsThisMonthWorker response = ")
    // console.log(response)
    yield put(salesforceDealsThisMonthSuccess(response));
  } catch (err) {
    console.log('err in getDealsThisMonthWorker in saleforce saga' + err)
  }
}
// Fetch Launches This Month
function* getLaunchesThisMonthWorker(payload) {
  try {
    let response = yield call(getLaunchesThisMonthCall, payload.payload);
    response = response.data.data
    // console.log("getLaunchesThisMonthWorker response = ")
    // console.log(response)
    yield put(salesforceLaunchesThisMonthSuccess(response));
  } catch (err) {
    console.log('err in getLaunchesThisMonthWorker in saleforce saga' + err)
  }
}



// Salesforce Workers
// function* salesforceWorker() {
//   try {
//     let response = yield call(salesforceCall);
//     response = response.data.data;
//     yield put(salesforceSuccess(response));
//   } catch (err) {
//     yield put(salesforceError(err.response.data.data));
//   }
// }





// Salesforce Watcher
export default function* salesforceSaga() {
  yield all([
    takeLatest(SALESFORCE_PAGE_INIT, getAccountsWorker),
    takeLatest(LOGGED_IN_INIT, salesforce_LoginWorker),
    takeLatest(LOGGED_IN, salesForce_SendTokenWorker),
    takeLatest(INITIAL_STATE_LOAD_ACCOUNTS, getAccountsWorker),
    takeLatest(SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT, getDealsThisMonthWorker),
    takeLatest(SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT, getLaunchesThisMonthWorker),
    takeLatest(SALESFORCE_OPEN_CASES_PAGE_INIT, getOpenCasesWorker),
    takeLatest(INITIAL_STATE_LOAD_OPEN_CASES, getOpenCasesWorker),
    // takeLatest(SALESFORCE_NEW_CASES_PAGE_INIT, getNewCasesWorker),
    // takeLatest(INITIAL_STATE_LOAD_NEW_CASES, getNewCasesWorker),
    manageCaseSaga()
  ]);
}