import Roles from '../../helpers/Roles'
// import React, { lazy } from 'react';


// import Launch from '../../modules/Launch';
// import ManageStaging from '../../modules/Staging/ManageStaging'
// import Staging from '../modules/Staging'
// import NationState from '../../modules/NationState'
// import ManageLaunch from '../modules/Launch/ManageLaunch'

/*
* Material Icons
* */
import MapIcon from '@mui/icons-material/Map';
// import ConstructionIcon from '@mui/icons-material/Construction';
// import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
// import LeaderboardIcon from '@mui/icons-material/Leaderboard';
// import HighlightIcon from '@mui/icons-material/Highlight';
// import MultilineChartIcon from '@mui/icons-material/MultilineChart';
// import Fingerprint from '@mui/icons-material/Fingerprint';
import StorageIcon from '@mui/icons-material/Storage';
import Users from "./Users";
import DatabaseSettings from "./DatabaseSettings";
// import ClientSiteConfiguration from "./ClientSiteConfiguration";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ClientSiteKeyConfiguration from "./ClientSiteKeyConfiguration";

/*
* Route config object supports all react-router's route element props with some additional props ie: (name, permission, children)
* you can add or remove props from config object it's means it is super customizable and support upto N nesting, child routes must follow the same parent shape,
* it means the config object is same for both there is no additional key for child nor for parent.
* you can find route props from here https://reactrouter.com/web/api/Route let's take a look at some additional props
* 1. permission: permission is an optional prop and it's type is an array of roles, permission is used for allowing certain users/roles
*  	to have access of that route and if you skip/omit the permission or it's an empty array that means every authenticated user/role have access to that route.
* 2. name: name is an optional prop and it's type is a string|node it is used for mapping route link into a navigation
* 3. children: children is also an optional prop and it's type is an array of route config objects, children are used for nested routes
* */

/*
* SUPER_ADMIN has access to all of the app
* ADMIN has access to all of the app except managing users
* ACCOUNT_MANAGER has access to service team related work
* SALES_MANAGER is not an IMC, has access to some of the Scraper
*/

// export default {
// 	SUPER_ADMIN: 'SUPER_ADMIN',
// 	ADMIN: 'ADMIN',
// 	ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
// 	SALES_MANAGER: 'SALES_MANAGER',
// 	GUEST: 'GUEST'
// };


const SecondaryDrawerNavigationRoutePermissions = [
    {
        element: Users,
        path: '/app/admin/users',
        name: 'Users',
        MenuIcon: MapIcon,
		MenuToolTip: "Users",
		permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.ACCOUNT_MANAGER
        ],
    },
    {
        element: DatabaseSettings,
        path: '/app/admin/mongodb',
        name: 'MongoDB Configuration',
        MenuIcon: StorageIcon,
        MenuToolTip: "MongoDB Configuration",
        permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.SALES_MANAGER
        ],
    },
    {
        element: ClientSiteKeyConfiguration,
        path: '/app/admin/clientsiteconfiguration',
        name: 'Client Site Key Configuration',
        MenuIcon: VpnKeyIcon,
        MenuToolTip: "Client Site Key Configuration",
        permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.SALES_MANAGER
        ],
    },
]

export default SecondaryDrawerNavigationRoutePermissions