import {createTheme} from '@mui/material/styles';
// import { red } from '@material-ui/core/colors'

// Normal or default theme
const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#bd0707',
        },
        secondary: {
            main: '#ffc510',
        },
        background: {
            default: '#4c69f6',
            paper: '#4c94f6',
        },
    },
    typography: {
        fontFamily: 'Bangers',
        color: '#ffffffde',
        body1: {
            fontFamily: 'Bangers',
        },
        h1: {
            fontFamily: 'Bangers',
            color: '#ffffffde',
        },
        caption: {
            fontFamily: 'Do Hyeon',
        },
        overline: {
            fontFamily: 'Do Hyeon',
        },
        body2: {
            fontFamily: 'Roboto',
        },
    },
    components: {
        // Name of the component
        MuiPaper: {
            styleOverrides: {
                root: {
                    // Some CSS
                    fontSize: '1rem',
                    borderRadius: '1px',
                    backgroundColor: '#F6576D',
                    color: '#000000'
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    fontSize: '1rem',
                    borderRadius: '1px',
                    backgroundColor: '#F6576D',
                    color: '#000000'
                },
            },
        },
    },
})

export default theme