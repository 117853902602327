import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from '../../../helpers/requests';
import { browserRedirect } from '../../../helpers/helpers';
import { urls } from '../../../helpers/urls';
import {
    USERAVATAR_ADD_REQUESTING,
    userAvatarAddSuccess,
    userAvatarAddError,
    USERAVATAR_UPDATE_REQUESTING,
    userAvatarUpdateSuccess,
    userAvatarUpdateError
} from "./actions";

//userAvatarAdd API call
function userAvatarAddCall(payload) {
  return request('post', urls.USERAVATAR, payload);
}

//userAvatarUpdate API call
function userAvatarUpdateCall(id, payload) {
  return request('put', urls.USERAVATAR+"/"+id, payload);
}





// userAvatarAdd Worker
function* userAvatarAddWorker({ payload }) {
  try {
    yield call(userAvatarAddCall, payload);
    yield put(userAvatarAddSuccess());
    yield call(browserRedirect, '/app/user/avatar');
  } catch (err) {
    yield put(userAvatarAddError(err.response.data));
  }
}

//userAvatarUpdate Worker
function* userAvatarUpdateWorker({id, payload}) {
  try {
    yield call(userAvatarUpdateCall, id, payload);
    yield put(userAvatarUpdateSuccess());
    yield call(browserRedirect, '/app/userAvatar');
  } catch (err) {
    yield put(userAvatarUpdateError(err.response.data));
  }
}



/* Watchers are like subscribers in pub-sub, they listen for the event name and trigger functions */
/*  */
// userAvatarAdd Watcher
export default function* userAvatarAddSaga() {
  yield all([
    takeLatest(USERAVATAR_ADD_REQUESTING, userAvatarAddWorker),
    takeLatest(USERAVATAR_UPDATE_REQUESTING, userAvatarUpdateWorker),
  ]);
}