import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Link, useRoutes} from "react-router-dom";

import {getAllowedRoutes} from "../../helpers/getAllowedRoutes";

import SecondaryDrawerNavigationRoutePermissions from "./SecondaryDrawerNavigationRoutePermissions";
import AppMenuItem from "../MenuDrawer/AppMenuItem";
import NavigationRoutePermissions from "../../helpers/NavigationRoutePermissions";
import MapAllowedRoutes from "../../helpers/MapAllowedRoutes";


const drawerWidth = 230;

export default function SecondDrawer({allowedRoutes}) {
// export default function SecondDrawer() {

    // const [allowedRoutes, setAllowedRoutes] = useState([])

    // const allowedRoutes = getAllowedRoutes(NavigationRoutePermissions)
    // console.log(`Permission Based Routes for menu: \n`, allowedRoutes)
    // const mappedAllowedRoutes = MapAllowedRoutes(allowedRoutes)
    // // const mappedAllowedRoutes = MapAllowedRoutesWithoutElementParents(allowedRoutes)
    // console.log(`Routes available to access for the router: \n`, mappedAllowedRoutes)
    // // const { role } = useRoleContext();
    // const router = useRoutes(mappedAllowedRoutes);



    useEffect(() => {
        // setAllowedRoutes(getAllowedRoutes(SecondaryDrawerNavigationRoutePermissions))
        //	empty array makes useEffect call only once and avoids infinite loop
    }, []);

    return (
        <Box
            // id="secondDrawer"
            sx={{
            display: 'block',
            // display: 'flex',
            position: "absolute",
            width:0,
            height:0,
            left: "100px",
        }}>
            {/*<CssBaseline />*/}
            <Drawer
                sx={{
                    display: 'block',
                    position: "absolute",
                    left: "100px",
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        zIndex: 1,
                        marginLeft: 7
                        // display: 'block',
                        // position: "absolute",
                        // left: "10px",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar />
                <List id="verticalList"
                      sx={{
                          // marginTop:"10px",
                          // marginRight: "0",
                          // marginLeft: "auto"
                          // marginLeft: "35px"
                      }}
                >
                    {allowedRoutes.map((item, index) => (
                        // {allowedRoutes.map(({path, title, MenuIcon}) => (
                        // <ListItem button key={title} component={Link} to={`${path}`}>
                        //     <ListItemIcon> <MenuIcon/> </ListItemIcon> <ListItemText primary={title}/>
                        <AppMenuItem {...item} key={index}/>
                        // </ListItem>
                    ))}
                </List>
            </Drawer>
            {/*<Box*/}
            {/*    component="main"*/}
            {/*    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1 }}*/}
            {/*>*/}
            {/*    <Toolbar />*/}

            {/*</Box>*/}
        </Box>
    );
}
