export const STAGING_AREA_LIST = 'STAGING_AREA_LIST';
export const STAGING_AREA_LIST_SUCCESS = 'STAGING_AREA_LIST_SUCCESS';

export const STAGING_UPDATE_LOG = 'STAGING_UPDATE_LOG';
export const STAGING_UPDATE_LOG_SUCCESS = 'STAGING_UPDATE_LOG_SUCCESS';


// Page that shows the progress of stagings
export const STAGING_STARTED_PAGE_INIT = 'STAGING_STARTED_PAGE_INIT';
export const STAGING_STARTED_PAGE_SUCCESS = 'STAGING_STARTED_PAGE_SUCCESS';
export const STAGING_STARTED_PAGE_ERROR = 'STAGING_STARTED_PAGE_ERROR';



export const STAGING_PAGE_INIT = 'STAGING_PAGE_INIT';
export const STAGING_PAGE_ERROR = 'STAGING_PAGE_ERROR';
export const STAGING_PAGE_SUCCESS = 'STAGING_PAGE_SUCCESS';


export const STAGING_LIST_INIT = 'STAGING_LIST_INIT';
export const STAGING_LIST_ERROR = 'STAGING_LIST_ERROR';
export const STAGING_LIST_SUCCESS = 'STAGING_LIST_SUCCESS';


export const STAGING_SITE_UPDATE_INIT = 'STAGING_SITE_UPDATE_INIT';
export const STAGING_SITE_UPDATE_ERROR = 'STAGING_SITE_UPDATE_ERROR';
export const STAGING_SITE_UPDATE_SUCCESS = 'STAGING_SITE_UPDATE_SUCCESS';


export const STAGING_SITE_STAGE_INIT = 'STAGING_SITE_STAGE_INIT';
export const STAGING_SITE_STAGE_SUCCESS = 'STAGING_SITE_STAGE_SUCCESS';
export const STAGING_SITE_STAGE_ERROR = 'STAGING_SITE_STAGE_ERROR';


export const STAGING_ACCOUNT_DETAIL_INIT = 'STAGING_ACCOUNT_DETAIL_INIT';
export const STAGING_ACCOUNT_DETAIL_SUCCESS = 'STAGING_ACCOUNT_DETAIL_SUCCESS';
export const STAGING_ACCOUNT_DETAIL_ERROR = 'STAGING_ACCOUNT_DETAIL_ERROR';


export const STAGING_ACCOUNT_DETAIL_AUTOMATION_INIT = 'STAGING_ACCOUNT_DETAIL_AUTOMATION_INIT';
export const STAGING_ACCOUNT_DETAIL_AUTOMATION_SUCCESS = 'STAGING_ACCOUNT_DETAIL_AUTOMATION_SUCCESS';
export const STAGING_ACCOUNT_DETAIL_AUTOMATION_ERROR = 'STAGING_ACCOUNT_DETAIL_AUTOMATION_ERROR';


export const STAGING_DETAIL_INIT = 'STAGING_DETAIL_INIT';
export const STAGING_DETAIL_ERROR = 'STAGING_DETAIL_ERROR';
export const STAGING_DETAIL_SUCCESS = 'STAGING_DETAIL_SUCCESS';
export const STAGING_DETAIL_CLOSE = 'STAGING_DETAIL_CLOSE';

export const STAGING_DELETE_INIT = 'STAGING_DELETE_INIT';
export const STAGING_DELETE_ERROR = 'STAGING_DELETE_ERROR';
export const STAGING_DELETE_SUCCESS = 'STAGING_DELETE_SUCCESS';


export const STAGING_DELETE_EMAIL_INIT = 'STAGING_DELETE_EMAIL_INIT';
export const STAGING_DELETE_EMAIL_ERROR = 'STAGING_DELETE_EMAIL_ERROR';
export const STAGING_DELETE_EMAIL_SUCCESS = 'STAGING_DELETE_EMAIL_SUCCESS';



export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const STAGING_START_INIT = 'STAGING_START_INIT';
export const STAGING_START_REQUESTING = 'STAGING_START_REQUESTING';
export const STAGING_START_SUCCESS = 'STAGING_START_SUCCESS';
export const STAGING_START_ERROR = 'STAGING_START_ERROR';
// export const STAGING_START_PAGE_INIT = 'STAGING_START_PAGE_INIT';

export const STAGING_AUTOSELECT_AUTOMATION_SAVE = 'STAGING_AUTOSELECT_AUTOMATION_SAVE';
export const STAGING_AUTOSELECT_AUTOMATION_SAVE_SUCCESS = 'STAGING_AUTOSELECT_AUTOMATION_SAVE_SUCCESS';


export const STAGING_AUTOSELECT_SAVE = 'STAGING_AUTOSELECT_SAVE';
export const STAGING_AUTOSELECT_SAVE_SUCCESS = 'STAGING_AUTOSELECT_SAVE_SUCCESS';

export const STAGING_AUTOSELECT_FOR_LAUNCH_SAVE = 'STAGING_AUTOSELECT_FOR_LAUNCH_SAVE';
export const STAGING_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS = 'STAGING_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS';

export const STAGING_EMAIL_REQUESTED_INIT = 'STAGING_EMAIL_REQUESTED_INIT';
// export const STAGING_EMAIL_REQUESTED_SUCCESSFUL = 'STAGING_EMAIL_REQUESTED_SUCCESSFUL';


export const STAGING_CHECK_SALESFORCE_INIT = 'STAGING_CHECK_SALESFORCE_INIT';
export const STAGING_CHECK_SALESFORCE_SUCCESS = 'STAGING_CHECK_SALESFORCE_SUCCESS';
export const STAGING_CHECK_EMAIL_ERROR = 'STAGING_CHECK_EMAIL_ERROR';

export const STAGING_CHECK_EMAIL_INIT = 'STAGING_CHECK_EMAIL_INIT';


export const STAGING_CHECK_SERVER_INIT = 'STAGING_CHECK_SERVER_INIT';
export const STAGING_CHECK_SERVER_SUCCESS = 'STAGING_CHECK_SERVER_SUCCESS';
export const STAGING_CHECK_SERVER_ERROR = 'STAGING_CHECK_SERVER_ERROR';

//

/* Get status of automation being on or off */
export const STAGING_AUTOMATION_STATUS_INIT = 'STAGING_AUTOMATION_STATUS_INIT';
export const STAGING_AUTOMATION_STATUS_SUCCESS = 'STAGING_AUTOMATION_STATUS_SUCCESS';
export const STAGING_AUTOMATION_STATUS_ERROR = 'STAGING_AUTOMATION_STATUS_ERROR';


/* Change status of automation being on or off */
export const STAGING_AUTOMATION_STATUS_CHANGE_INIT = 'STAGING_AUTOMATION_STATUS_CHANGE_INIT';
export const STAGING_AUTOMATION_STATUS_CHANGE_SUCCESS = 'STAGING_AUTOMATION_STATUS_CHANGE_SUCCESS';
export const STAGING_AUTOMATION_STATUS_CHANGE_ERROR = 'STAGING_AUTOMATION_STATUS_CHANGE_ERROR';



export function stagingEmailRequestedInit() {
    return {
        type: STAGING_EMAIL_REQUESTED_INIT
    };
}

export function stagingCheckSalesforceInit(payload) {
    return {
        type: STAGING_CHECK_SALESFORCE_INIT,
        payload
    };
}

export function stagingCheckEmailInit() {
    return {
        type: STAGING_CHECK_EMAIL_INIT
    };
}


export function stagingCheckServerInit() {
    return {
        type: STAGING_CHECK_SERVER_INIT
    };
}


export function stagingCheckSalesforceSuccess(payload) {
    return {
        type: STAGING_CHECK_SALESFORCE_SUCCESS,
        payload
    };
}


export function stagingCheckServerSuccess(payload) {
    return {
        type: STAGING_CHECK_SERVER_SUCCESS,
        payload
    };
}


export function stagingCheckServerError(payload) {
    return {
        type: STAGING_CHECK_SERVER_ERROR,
        payload
    };
}




export function stagingAutoSelectAutomationSave(payload) {
    return {
        type: STAGING_AUTOSELECT_AUTOMATION_SAVE,
        payload
    };
}


export function stagingAutoSelectAutomationSaveSuccess(payload) {
    return {
        type: STAGING_AUTOSELECT_AUTOMATION_SAVE_SUCCESS,
        payload
    };
}




export function stagingAutoSelectSave(payload) {
    return {
        type: STAGING_AUTOSELECT_SAVE,
        payload
    };
}


export function stagingAutoSelectSaveSuccess(payload) {
    return {
        type: STAGING_AUTOSELECT_SAVE_SUCCESS,
        payload
    };
}






export function stagingAutoSelectForLaunchSave(payload) {
    return {
        type: STAGING_AUTOSELECT_FOR_LAUNCH_SAVE,
        payload
    };
}


export function stagingAutoSelectForLaunchSaveSuccess(payload) {
    return {
        type: STAGING_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS,
        payload
    };
}







export function stagingStartedPageInit(payload) {
    return {
        type: STAGING_STARTED_PAGE_INIT,
        payload
    };
}

export function stagingPageInit(payload) {
    return {
        type: STAGING_PAGE_INIT,
        payload
    };
}

export function stagingAreaList() {
    return {
        type: STAGING_AREA_LIST
    };
}

export function stagingAreaListSuccess(payload) {
    return {
        type: STAGING_AREA_LIST_SUCCESS,
        payload
    };
}



export function stagingUpdateLog(payload) {
    return {
        type: STAGING_UPDATE_LOG,
        payload
    };
}

export function stagingUpdateLogSuccess(payload) {
    return {
        type: STAGING_UPDATE_LOG_SUCCESS,
        payload
    };
}


// Stage a site account list for autocomplete
export function stagingListInit(payload) {
    return {
        type: STAGING_LIST_INIT,
        payload
    };
}

export function stagingListError(error) {
    return {
        type: STAGING_LIST_ERROR,
        error,
    };
}

export function stagingListSuccess(payload) {
    return {
        type: STAGING_LIST_SUCCESS,
        payload
    };
}



export function stagingSiteUpdateInit(payload) {
    return {
        type: STAGING_SITE_UPDATE_INIT,
        payload
    };
}

export function stagingSiteUpdateError(error) {
    return {
        type: STAGING_SITE_UPDATE_ERROR,
        error,
    };
}

export function stagingSiteUpdateSuccess(payload) {
    return {
        type: STAGING_SITE_UPDATE_SUCCESS,
        payload
    };
}



/* Get status of automation being on or off */
export function stagingAutomationStatusInit() {
    return {
        type: STAGING_AUTOMATION_STATUS_INIT,
    };
}

export function stagingAutomationStatusSuccess(payload) {
    return {
        type: STAGING_AUTOMATION_STATUS_SUCCESS,
        payload
    };
}




/* Change status of automation being on or off */
export function stagingAutomationStatusChangeInit(payload) {
    return {
        type: STAGING_AUTOMATION_STATUS_CHANGE_INIT,
        payload
    };
}


export function stagingAutomationStatusChangeSuccess(payload) {
    return {
        type: STAGING_AUTOMATION_STATUS_CHANGE_SUCCESS,
        payload
    };
}






export function stagingAccountDetailAutomationInit(payload) {
    return {
        type: STAGING_ACCOUNT_DETAIL_AUTOMATION_INIT,
        payload
    };
}





export function stagingAccountDetailAutomationSuccess(payload) {
    return {
        type: STAGING_ACCOUNT_DETAIL_AUTOMATION_SUCCESS,
        payload
    };
}

export function stagingAccountDetailAutomationError(error) {
    return {
        type: STAGING_ACCOUNT_DETAIL_AUTOMATION_ERROR,
        error,
    };
}







export function stagingAccountDetailInit(payload) {
    return {
        type: STAGING_ACCOUNT_DETAIL_INIT,
        payload
    };
}

export function stagingAccountDetailSuccess(payload) {
    return {
        type: STAGING_ACCOUNT_DETAIL_SUCCESS,
        payload
    };
}

export function stagingAccountDetailError(error) {
    return {
        type: STAGING_ACCOUNT_DETAIL_ERROR,
        error,
    };
}







export function stagingSiteStageInit(payload) {
    return {
        type: STAGING_SITE_STAGE_INIT,
        payload
    };
}

export function stagingSiteStageError(error) {
    return {
        type: STAGING_SITE_STAGE_ERROR,
        error,
    };
}

export function stagingSiteStageSuccess(payload) {
    return {
        type: STAGING_SITE_STAGE_SUCCESS,
        payload
    };
}









export function stagingDetailInit(id) {
    return {
        type: STAGING_DETAIL_INIT,
        id 
    };
}

export function stagingDetailError(error) {
    return {
        type: STAGING_DETAIL_ERROR,
        error,
    };
}

export function stagingDetailSuccess(payload) {
    return {
        type: STAGING_DETAIL_SUCCESS,
        payload
    };
}

export function stagingDetailClose() {
    return {
        type: STAGING_DETAIL_CLOSE,
    };
}

export function stagingDeleteInit(payload) {
    return {
        type: STAGING_DELETE_INIT,
        payload
    };
}

export function stagingDeleteEmailInit(payload) {
    return {
        type: STAGING_DELETE_EMAIL_INIT,
        payload
    };
}

// export function stagingStartInit(id) {
//     return {
//         type: STAGING_START_INIT,
//         id
//     };
// }

export function stagingStartError(error) {
    return {
        type: STAGING_START_ERROR,
        error,
    };
}

export function stagingStartSuccess(payload) {
    return {
        type: STAGING_START_SUCCESS,
        payload
    };
}

// export function stagingStartRequest(payload,id) {
//     return {
//         type: STAGING_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function stagingDeleteEmailError(error) {
    return {
        type: STAGING_DELETE_EMAIL_ERROR,
        error,
    };
}

export function stagingDeleteEmailSuccess(payload) {
    return {
        type: STAGING_DELETE_EMAIL_SUCCESS,
        payload
    };
}

export function stagingDeleteError(error) {
    return {
        type: STAGING_DELETE_ERROR,
        error,
    };
}

export function stagingDeleteSuccess(payload) {
    return {
        type: STAGING_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}