import normal from './themes/normal'
import typewriter from './themes/typewriter'
import nightowl from "./themes/nightowl";
import batman from "./themes/batman"
import blackpanther from "./themes/blackpanther"
import chicagoblackhawks from "./themes/chicagoblackhawks"
import ironman from "./themes/ironman"
import miamiheat from "./themes/miamiheat"
import newenglandpatriots from "./themes/newenglandpatriots"
import seattlekraken from "./themes/seattlekraken"
import superman from "./themes/superman"
import venom from "./themes/venom"
import wonderwoman from "./themes/wonderwoman"





const themes = {
    normal,
    typewriter,
    nightowl,
    batman,
    blackpanther,
    chicagoblackhawks,
    ironman,
    miamiheat,
    newenglandpatriots,
    seattlekraken,
    superman,
    venom,
    wonderwoman
}

export default function getTheme(theme) {
    return themes[theme]
}
