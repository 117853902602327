import React, {useContext, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {
    adminPageInit,
} from './actions';



import DataTableMaterial from "./DataTableMaterial";
import {Box, Button, Container} from "@mui/material";
import {WebSocketContext} from "../../socketHandlers/WebSocket";
// import Skeleton from "@mui/material/Skeleton";
// import SecondDrawer from "./SecondDrawer";
import Grid from '@mui/material/Unstable_Grid2';


function Users(props) {
    const ws = useContext(WebSocketContext);


    const dispatch = useDispatch();
    const getUsers = () => dispatch(adminPageInit());

    const admin = useSelector((state) => state.admin.list_admin.admins)


    useEffect(() => {
        getUsers();
        // console.log(admin)
        // dispatch(adminPageInit())
    }, [])


    if (admin.length === 0) {
        return (
            <Box>
                <Box id="secondDrawer"
                     sx={{
                         marginTop: "100px",
                         zIndex: 1,
                         position: "absolute",
                     }}
                >
                    {/*<SecondDrawer/>*/}
                </Box>
            {/*<Skeleton*/}
            {/*    sx={{*/}
            {/*        height: 800,*/}
            {/*        width: '100%',*/}
            {/*        marginTop: "40px",*/}
            {/*        maxWidth: "1400px",*/}
            {/*        // width: "1400px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*</Skeleton>*/}
                    </Box>

        )
    } else {

        return (
            <>
                <Grid
                    spacing={1}
                    container
                    sx={{
                        // marginLeft: "15%",
                        // marginLeft: "300px",
                        // marginRight: "5%",
                        paddingBottom: "20px",
                        width: "100%",
                    }}
                >
                    <Grid container>
                    <DataTableMaterial selectedData={admin}/>
                    </Grid>
                </Grid>
            </>
        );

    }
}



/* Receives an Array */
// const UserRow = (props) => {
// function UserRow(props) {
// console.log(props)
// const [isForDelete, setIsForDelete] = useState(false)
// const [isForStart, setIsForStart] = useState(false)
// const [admin, setUsers] = useState([])
// useEffect(() => {
// }, [handleModalShow])

// const dispatch = useDispatch();

// const handleModalStartShowClick = modalType => {
//     return event => {
//         // this is taking the elements attribute : data-id
//         // dispatch(adminDetailInit(event.currentTarget.dataset.id))
//         // dispatch(getUserDetail(event.currentTarget.dataset.id));
//
//         if (modalType === "view") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForStart(true)
// 			// return (
// 			// 	<UserModal />
// 			// )
//         }
//         if (modalType === "admin") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForStart(true)
//         }
//         if (modalType === "delete") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForDelete(true)
//
//
//         }
//     }
// }

//     if (props.list.length === 0) {
//     	return (<tr>
//     		<td colSpan="4">No admin present</td>
//     	</tr>)
//     } else {
//     	return props.list.map(function (data, id) {
//     		return (
//     			<tr key={id}>
//     				<th scope="row">{id + 1}</th>
//     				<td>{data.adminAddress}</td>
//     				<td>{data.liveAddress}</td>
//     				<td style={{"padding": "5px"}}>
//     					<button type="button"
//     							className="btn btn-outline-info mr-3"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#exampleModal"
//     							onClick={<UserModal modalType={"view"} data-id={data._id}/>}>View
//     					</button>
//     					<button type="button"
//     							className="btn btn-outline-info mr-3"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#adminModal"
//     							data-actiontype="admin"
//     							onClick={<UserModal prop={"admin"}/>}>User Pad
//     					</button>
//     					<Link to={"/manage-admin/" + data._id}
//     						  className="btn btn-outline-warning mr-3">Update</Link>
//     					<button type="button"
//     							className="btn btn-outline-danger"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#exampleModal"
//     							data-actiontype="delete"
//     							onClick={<UserModal prop={"delete"}/>}>Delete
//     					</button>
//     				</td>
//     			</tr>
//     		);
//     	});
//     }
// }


Users.propTypes = {
    admin: PropTypes.array
};


export default Users