import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  CLIENTSITE_PAGE_INIT,
  CLIENTSITE_LIST_INIT,
  CLIENTSITE_UPDATE_INIT,
  CLIENTSITE_LAUNCH_INIT,
  // CLIENTSITE_DETAIL_INIT,
  // CLIENTSITE_DELETE_INIT,
  CLIENTSITE_START_INIT,
  clientSiteError,
  clientSiteSuccess,
  // clientSiteDetailError,
  // clientSiteDetailSuccess,
  // clientSiteDeleteError,
  // clientSiteDeleteSuccess,
  clientSitePageInit,
  // CLIENTSITE_START_REQUESTING,
  clientSiteListSuccess,
  clientSiteUpdateSuccess,
  clientSiteLaunchSuccess,
  clientSiteStartSuccess,
  clientSiteStartError, CLIENTSITE_LIST_REFRESH_INIT, clientSiteListRefreshSuccess,
} from "./actions";


import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
// import manageClientSiteSaga from './ManageClientSite/saga';
import {browserRedirect} from "../../helpers/helpers";
// import {browserRedirect} from "../../helpers/helpers";
// import {clientSitePadAddError, clientSitePadAddSuccess} from "./ClientSitePad/actions";

//ClientSite API calls
function clientSiteCall() {
  return request('get', urls.CLIENTSITE);
}



//clientSiteStart API call
function clientSiteStartCall(id) {
  return request('post', urls.CLIENTSITE+'/'+id);
}

//clientSiteListCall API call
function clientSiteListCall() {
  // console.log('Payload in clientSiteListCall call')
  // console.log(payload)
  return request('get', urls.CLIENTSITE+'/clientsitelist');
}

//clientSiteListCall API call
function clientSiteListRefreshInitCall() {
  // console.log('Payload in clientSiteListCall call')
  // console.log(payload)
  return request('get', urls.CLIENTSITE+'/clientsitelistrefresh');
}


//clientSiteUpdate API call
function clientSiteUpdateCall(payload) {
  console.log('Payload in clientSiteUpdateCall call')
  console.log(payload)
  return request('post', urls.CLIENTSITE+'/clientsiteupdate', payload);
}


//clientSiteUpdate API call
function clientSiteLaunchCall(payload) {
  console.log('Payload in clientSiteLaunchCall call')
  console.log(payload)
  return request('post', urls.CLIENTSITE+'/clientsitelaunch', payload);
}


// ClientSite Workers
function* clientSiteWorker() {
  try {
    let response = yield call(clientSiteCall);
    response = response.data.data;
    yield put(clientSiteSuccess(response));
  } catch (err) {
    yield put(clientSiteError(err.response.data.data));
  }
}

function* clientSiteListWorker() {
  try {
    let response = yield call(clientSiteListCall);
    response = response.data.data;
    console.log(" clientSiteListWorker response = ")
    console.log(response)
    yield put(clientSiteListSuccess(response));
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(clientSiteDetailError(err.response.data.data));
  }
}

function* clientSiteListRefreshInitWorker() {
  try {
    let response = yield call(clientSiteListRefreshInitCall);
    response = response.data.data;
    console.log(" clientSiteListWorker response = ")
    console.log(response)
    yield put(clientSiteListRefreshSuccess(response));
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(clientSiteDetailError(err.response.data.data));
  }
}

function* clientSiteUpdateWorker(payload) {
  try {
    let response = yield call(clientSiteUpdateCall, payload.payload);
    response = response.data.data;
    console.log(" clientSiteUpdateWorker response = ")
    console.log(response)
    yield put(clientSiteUpdateSuccess(response));
  } catch (err) {
    console.log('err in clientSiteUpdateWorker in clientSite saga' + err)
    // yield put(clientSiteDetailError(err.response.data.data));
  }
}

function* clientSiteLaunchWorker(payload) {
  try {
    let response = yield call(clientSiteLaunchCall, payload.payload);
    response = response.data.data;
    console.log(" clientSiteLaunchWorker response = ")
    console.log(response)
    // yield put(clientSiteLaunchSuccess(response));
    yield call(browserRedirect,'/clients/launch');
  } catch (err) {
    console.log('err in clientSiteLaunchSuccess in clientSite saga' + err)
    // yield put(clientSiteDetailError(err.response.data.data));
  }
}

// function* clientSiteDetailWorker(payload) {
//   try {
//     let response = yield call(clientSiteDetailCall, payload.id);
//     response = response.data.data;
//     yield put(clientSiteDetailSuccess(response));
//   } catch (err) {
//     yield put(clientSiteDetailError(err.response.data.data));
//   }
// }

// function* clientSiteDeleteWorker(payload) {
//   try {
//     let response = yield call(clientSiteDeleteCall, payload.id);
//     response = response.data;
//     yield put(clientSiteDeleteSuccess(response));
//     yield put(clientSitePageInit());
//   } catch (err) {
//     yield put(clientSiteDeleteError(err.response.data));
//   }
// }


function* clientSiteStartWorker(payload) {
  try {
    let response = yield call(clientSiteStartCall, payload.id);
    response = response.data;
    yield put(clientSiteStartSuccess(response));
    /* success will have the clientSite page reload with the new results */
    yield put(clientSitePageInit());
  } catch (err) {
    yield put(clientSiteStartError(err.response.data));
  }
}


// ClientSite Watcher
export default function* clientSiteSaga() {
  yield all([
    takeLatest(CLIENTSITE_LIST_INIT, clientSiteListWorker),
    takeLatest(CLIENTSITE_LIST_REFRESH_INIT, clientSiteListRefreshInitWorker),
    takeLatest(CLIENTSITE_UPDATE_INIT, clientSiteUpdateWorker),
    takeLatest(CLIENTSITE_LAUNCH_INIT, clientSiteLaunchWorker),
    takeLatest(CLIENTSITE_PAGE_INIT, clientSiteWorker),
    // takeLatest(CLIENTSITE_DETAIL_INIT, clientSiteDetailWorker),
    // takeLatest(CLIENTSITE_DELETE_INIT, clientSiteDeleteWorker),
    takeLatest(CLIENTSITE_START_INIT, clientSiteStartWorker),
    // takeEvery(CLIENTSITE_START_REQUESTING, clientSiteStartWorker),
    // manageClientSiteSaga()
  ]);
}