import {Avatar, Card, CardHeader, Chip} from "@mui/material";
import React, {memo, useEffect} from "react";
// import {useStyles} from "../Dashboard/LeaderboardStyles";
import Grid from '@mui/material/Unstable_Grid2';

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import {createTheme, darken, lighten} from "@mui/material/styles";
import Box from "@mui/material/Box";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
// import './usercard.css'
// import styled from 'styled-components'
// import {useDispatch, useSelector} from "react-redux";
// import {getUserCardImageInit} from "./actions";
// import {useEffect} from "@types/react";

// const defaultTheme = createTheme();
// const useStyles = makeStyles(
//     (theme) => {
//         const getBackgroundColor = (color) =>
//             theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
//
//         const getHoverBackgroundColor = (color) =>
//             theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
//
//         return {
//             root: {
//                 '& div.MuiDataGrid-root': {
//                     border: "none",
//                     // backgroundColor: "blue",
//                     // fontSize: 14,
//                     // color: "blue",
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 '& div.MuiDataGrid-columnsContainer': {
//                     border: "none",
//                     // backgroundColor: "blue",
//                     // fontSize: 14,
//                     // color: "blue",
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//
//                 '& .leaderboard-rank_0': {
//                     // backgroundColor: "#ff1010",
//                     // fontSize: 30,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     // height: "144px",
//                     marginTop: "5px",
//                     marginBottom: "34px",
//                     minHeight: "225px",
//                     // '&:after': {
//                     //     content: '',
//                     //     position: "absolute",
//                     //     top: "-5px",
//                     //     left: "-5px",
//                     //     right: "-5px",
//                     //     bottom: "-5px",
//                     //     background: "linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2)",
//                     //     zIndex: "-1",
//                     // },
//                     // '&:before': {
//                     //     content: '',
//                     //     position: "absolute",
//                     //     top: "0",
//                     //     left: "0",
//                     //     right: "0",
//                     //     bottom: "0",
//                     //     background: "linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2)",
//                     //     zIndex: "-2",
//                     //     filter: "blur(40px)",
//                     // },
//                 },
//                 '& .leaderboard-rank_1': {
//                     // backgroundColor: "grey",
//                     // fontSize: 18,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "21px",
//                 },
//                 '& .leaderboard-rank_2': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "13px",
//                     // WebkitMaskImage: "linear-gradient(180deg, #000 0%, transparent)",
//                 },
//                 '& .leaderboard-rank_3': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "8px",
//                     WebkitMaskImage: "linear-gradient(180deg, #000 80%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 '& .leaderboard-rank_4': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "8px",
//                     WebkitMaskImage: "linear-gradient(180deg, #000 -30%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 '& .leaderboard-rank_5': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "8px",
//                     WebkitMaskImage: "linear-gradient(180deg, #000 -30%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 '& .leaderboard-rank_6': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "8px",
//                     WebkitMaskImage: "linear-gradient(180deg, #000 -30%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 '& .leaderboard-rank_7': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "8px",
//                     WebkitMaskImage: "linear-gradient(180deg, #000 -30%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//
//                 // No deals or launches
//                 '& .occurrence_0': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     minHeight: "225px",
//                     marginBottom: "8px",
//                     WebkitMaskImage: "linear-gradient(180deg, #000 -30%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 // No deals or launches
//                 '& .leaderboard-avatar': {
//                     // backgroundColor: "grey",
//                     // fontSize: 14,
//                     color: "white",
//                     // borderRadius: "5px",
//                     // border: "none",
//                     // minHeight: "225px",
//                     // marginBottom: "8px",
//                     // WebkitMaskImage: "linear-gradient(180deg, #000 -30%, transparent)"
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//
//
//                 // Other
//                 '& .leaderboard-theme': {
//                     backgroundColor: getBackgroundColor(theme.palette.info.main),
//                     '&:hover': {
//                         backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
//                     },
//                 },
//                 '& .leaderboard-theme--PartiallyFilled': {
//                     backgroundColor: getBackgroundColor(theme.palette.warning.main),
//                     '&:hover': {
//                         backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
//                     },
//                 },
//                 '& .leaderboard-theme--Rejected': {
//                     backgroundColor: getBackgroundColor(theme.palette.error.main),
//                     '&:hover': {
//                         backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
//                     },
//                 },
//             },
//         };
//     },
//     {defaultTheme},
// );


function UserCardGeneric({userAccount, index, imageData}) {
    const nameWithSpaceRemoved = `${userAccount["firstName"]}${userAccount["lastName"]}`
    // const classes = useStyles();


    return (
        <Grid key={index} sx={{
            minWidth: "410px",
            // maxWidth: "500px",
            position: "relative",
            // width: "512px", // 64 128 256 512
            // height: "225px", // 27 56 112 224
            // maxHeight: "144px"
        }}>
            <Card className={`box-contents occurrence_${userAccount.occurrence} leaderboard-rank_${index}`}
                  sx={{
                      backgroundImage: `url(${userAccount.cardImage})`,
                      // backgroundImage: `url(${imageData})`,
                      width: "525px",
                      height: "225px",
                      maxWidth: "525px",
                      maxHeight: "225px",
                      backgroundSize: "100%"
                      // '&:hover': {
                      //     transform: "translateY(-5px) scale(1.005) translateZ(0)",
                      //     boxShadow: "0 24px 36px rgba(0,0,0,0.11) 0 24px 46px"
                      // },
                  }}
            >
                <CardHeader
                    sx={{
                        backdropFilter: "blur(20px)",
                        backgroundColor: "rgba( 0, 0, 0, 0.5 )",
                        maxWidth: "350px",
                        borderRadius: "5px",
                        height: "80px",
                    }}
                    avatar={
                        <Avatar
                            className={'leaderboard-avatar ' + nameWithSpaceRemoved}
                            aria-label="user"
                            sx={{height: "50px", width: "50px"}}
                        >
                        </Avatar>
                    }
                    title={<Typography
                        sx={{
                            color: "white",
                            fontSize: 20
                        }}>{userAccount["IMC__c"] === null ? 'Andrew Boomhower' : userAccount.displayName || userAccount["IMC__c"]}</Typography>}
                    subheader={
                        <Typography sx={{color: "white", fontSize: 20}}>
                            {userAccount.occurrenceType || 'Deals'}
                            {`: ${userAccount.occurrence}`}
                        </Typography>
                    }
                />
                <CardContent>
                    {userAccount["IMC__c"] ?
                        <Box sx={{
                            alignItems: 'flex-start', display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <Chip
                                sx={{
                                    backdropFilter: "blur(20px)",
                                    backgroundColor: "rgba( 0, 0, 0, 0.5 )",
                                }}
                                label={<Typography
                                    sx={{color: "white"}}>
                                    MRR: {userAccount["TotalMRR__c"]}
                                </Typography>}></Chip>
                            <Chip
                                sx={{
                                    backdropFilter: "blur(20px)",
                                    backgroundColor: "rgba( 0, 0, 0, 0.5 )",
                                    mx: 2
                                }}
                                label={<Typography
                                    sx={{color: "white",}}>
                                    {userAccount["IMC__c"] ? `I.I: ${userAccount["Amount"]}` : null}
                                </Typography>}></Chip>
                        </Box> : null}
                    {/*{userAccount[]}*/}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default UserCardGeneric
// export default memo(UserCardGeneric)
