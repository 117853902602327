import React, {memo, useEffect, useState} from 'react';
import {
    DataGridPro,
    GridActionsCellItem,
    GridToolbar, GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import {Box, Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {clientSiteUpdateInit, clientSiteLaunchInit, clientSiteListInit, clientSiteListRefreshInit} from "./actions";
// import SaveIcon from "@mui/icons-material/Save";
// import CancelIcon from "@mui/icons-material/Close";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import {AwesomeButton} from "react-awesome-button";
// import LoginIcon from '@mui/icons-material/Login';
// import {browserRedirectNewTab} from "../../helpers/helpers";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import axios from "axios";

// import Typography from "@mui/material/Typography";


function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small"/>,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{visibility: props.value ? 'visible' : 'hidden'}}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};


function RefreshListButton() {
    const dispatch = useDispatch();
    const clientSiteListRefreshInitiation = () => dispatch(clientSiteListRefreshInit());
    let currentUser = JSON.parse(localStorage.getItem("user"));
    let currentRoleOfUser = currentUser.roles

    return (
        <>
            {currentRoleOfUser == 'SUPER_ADMIN' ? (
                <Box
                    sx={{
                        p: 0.5,
                        pb: 0,
                    }}
                >
                    <Button size="small" onClick={clientSiteListRefreshInitiation}>
                        {/*<Typography>*/}
                        Purge & Refresh List
                        {/*</Typography>*/}
                    </Button>
                </Box>
            ) : (<></>)
            }
        </>
    )
}


function CustomToolbar(props) {
    return (
        <GridToolbarContainer>
            <QuickSearchToolbar clearSearch={props.clearSearch} onChange={props.onChange} value={props.value}/>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <RefreshListButton/>
            {/*<GridToolbarDensitySelector />*/}
        </GridToolbarContainer>
    );
}


function DataTableMaterial() {
    const [key, setKey] = useState(null);
    const dispatch = useDispatch();
    const getClientSiteList = () => dispatch(clientSiteListInit());
    const clientSiteListRefreshInitiation = () => dispatch(clientSiteListRefreshInit());
    let currentUser = JSON.parse(localStorage.getItem("user"));
    let currentRoleOfUser = currentUser.roles

    const clientSites = useSelector((state) => state.clientSite.list_clientSite.clientSites)


    // const siteConfiguration = useSelector((state) => state.admin.list_admin.siteConfiguration);


    const siteConfiguration = {
        VIZI_USERNAME_QUERY: 't5rwz2yg4hh9whdz',
        VIZI_PASSWORD_QUERY: 'racxu6xg4y488kbq',
        VIZI_UNIQUE_QUERY: 'actnjn2fsttxwf5w',
        VIZI_UNIQUE_VALUE: 'gzu34drqje67hgs7',
    }


    const apiRef = useGridApiRef();

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellFocusOut = (params, event) => {
        event.defaultMuiPrevented = true;
    };


    const handleLoginClick = (id) => async (event) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        console.log('row for logging in:')
        console.log(row)

        // let siteLogin = `https://${row.domain}wp-login.php?${siteConfiguration.VIZI_USERNAME_QUERY}=${row.wordpress_username}&${siteConfiguration.VIZI_UNIQUE_QUERY}=${siteConfiguration.VIZI_UNIQUE_VALUE}`
        try {

            const handleLogin = async () => {
                try {
                    const response = await axios.post('https://vizisitestest.com/wp-json/vizilogin/v1/login/', {
                        username: 'viziadmin',
                        password: 'Noj9*K3s$8tu$afvh3vW0$LD',
                    });

                    if (response.data.success) {
                        console.log("Logged in successfully!");
                        // Open a new browser tab with the WordPress admin URL
                        window.open('https://vizisitestest.com/wp-admin', '_blank');
                    } else {
                        console.log("Login failed.");
                    }
                } catch (error) {
                    console.log(error.response.data.message);
                }
            }
            handleLogin()
            // const AutoLogin = () => {


                // useEffect(() => {
            //     const api_url = "http://vizisitestest.com/specific-url/";
            //
            //     // Assuming you have a way to get the currently logged in user in your React app
            //     const user_login = "viziadmin";
            //
            //     const params = {
            //         action: 'get_login_key',
            //         key: '54321',
            //         user_login: user_login
            //     };
            //
            //     axios.post(api_url, params)
            //         .then(response => {
            //             setKey(response.data.key);
            //         })
            //         .catch(error => {
            //             console.error('Error:', error);
            //         });
            // }
            //     // }, []);
            //
            // await AutoLogin();

//             async function performLogin(username, password) {
//                 const params = new URLSearchParams();
//                 params.append('log', username);
//                 params.append('pwd', password);
//
//                 const response = await fetch('https://vizisitestest.com/wp-login.php', {
//                     method: 'POST',
//                     body: params,
//                     credentials: 'include', // needed to include cookies
//                 });
//
//                 if (response.url.includes('wp-admin')) {
//                     console.log('Login successful ', response.url);
//                 } else {
//                     console.log('Login failed');
//                 }
//             }
//
// // usage
//             const username = 'viziadmin';
//             const password = 'Noj9*K3s$8tu$afvh3vW0$LD';
//             const response = await performLogin(username, password);
//             async function getNonce() {
//                 const response = await fetch(`https://${row.domain}/wp-json/vizilogin/v1/nonce/`);
//                 const data = await response.json();
//                 return data.nonce;
//             }
//
// // Later in your authentication function:
//             const nonce = await getNonce();
//
//             const response = await axios.post(`https://${row.domain}/wp-json/vizilogin/v1/login/`,
//                 {
//                 username: 'viziadmin',
//                 password: 'Noj9*K3s$8tu$afvh3vW0$LD',
//                 nonce: nonce
//             },
//                  {
//                 'Content-Type': 'application/json',
//             },
//             );

            // fetch('https://vizisitestest.com/wp-json/vizilogin/v1/login/', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         username: 'your-username',
            //         password: 'your-password',
            //         nonce: 'your-nonce',
            //     }),
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         if (data.success) {
            //             window.location.href = data.redirect_url;
            //         } else {
            //             console.error('Login failed:', data.message);
            //         }
                // })
                // .catch((error) => {
                //     console.error('Error:', error);
                // });


            // const responseForLogin = await axios.post(`https://${row.domain}/wp-json/nopassauth/v1/login/`, {
            //     username: 'ARyxGW44e'
            //     // username: row.wordpress_username
            // });

            //     console.log(`response `, response)
            // if (response.url.includes('wp-admin')) {
            //     // setMessage("Logged in successfully!");
            //     // Open a new browser tab with the WordPress admin URL
            //     window.open(response.url, '_blank');
            // } else {
            //     // setMessage("Login failed.");
            // }

            // browserRedirectNewTab('')
            // console.log(`siteLogin :`)
            // console.log(siteLogin)
        } catch (error) {
            // setMessage(error.response.data.message);
        }
        ;
    }

        let widthOfCol = 150;

    const col = React.useMemo(
        () => [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Login',
                maxWidth: 100,
                getActions: ({id}) => {
                    return [
                        <GridActionsCellItem
                            icon={<OpenInNewIcon/>}
                            label="Open"
                            // className={classes.textPrimary}
                            onClick={handleLoginClick(id)}
                            color="inherit"
                            // disabled
                        />,
                    ];
                },
            },
            {
                field: 'domain',
                minWidth: widthOfCol,
                width: 100,
                headerName: 'Domain'
            },
            {
                field: 'diskused',
                editable: false,
                minWidth: widthOfCol,
                width: 100,
                headerName: 'Disk Used',
                hide: true
            },
            ],
            [handleLoginClick],
            );


        useEffect(() => {

            //TODO UNCOMMENT THIS, IT WAS JUST FOR TESTING TO PREVENT CONSTANT CALLS TO THE SERVER
            getClientSiteList();
        }, [])

        useEffect(() => {

        }, [clientSites])


        const data = {
            rows: clientSites,
            columns: col
        }

        const [searchText, setSearchText] = useState('');
        const [rows, setRows] = useState(data.rows);

        const requestSearch = (searchValue) => {
            setSearchText(searchValue);
            // console.log(`searchValue: ${searchValue}`)
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = data.rows.filter((row) => {
                return Object.keys(row).some((field) => {
                    return searchRegex.test(row[field].toString());
                });
            });
            setRows(filteredRows);
        };

        useEffect(() => {
            setRows(data.rows);
        }, [data.rows]);


        return (
            <Box sx={{height: "80vh", width: '90vw'}}>
                <Box sx={{display: 'flex', height: '100%'}}>
                    <Box sx={{height: "80vh", width: '90vw'}}>
                    {/*<Box style={{flexGrow: 1}}>*/}
                        <DataGridPro
                            apiRef={apiRef}
                            columns={col}
                            rows={rows}
                            getRowId={(row) => row._id}
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            onCellFocusOut={handleCellFocusOut}
                            slots={{
                                // toolbar: GridToolbar
                                toolbar: CustomToolbar
                                // Toolbar: QuickSearchToolbar
                                // Toolbar: EditToolbar,
                            }}
                            slotProps={{
                                // toolbar: { apiRef },
                                toolbar: {
                                    value: searchText,
                                    onChange: (event) => requestSearch(event.target.value),
                                    clearSearch: () => requestSearch(''),
                                    apiRef
                                }
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        {
                                            field: 'domain',
                                            sort: 'asc',
                                        },
                                    ],
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        );

    }


// export default memo(DataTableMaterial)
export default DataTableMaterial