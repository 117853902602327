import {
    USERAVATAR_ADD_PAGE_INIT,
    USERAVATAR_ADD_ERROR,
    USERAVATAR_ADD_REQUESTING,
    USERAVATAR_ADD_SUCCESS,
    USERAVATAR_UPDATE_ERROR,
    USERAVATAR_UPDATE_REQUESTING,
    USERAVATAR_UPDATE_SUCCESS,
} from './actions';

// The initial state of the Reducer
export const initialState = {
    requesting: false,
    successful: false,
    messages: [],
    errors: {},
    addedUserAvatar: {},
    updatedUserAvatar: {},
  };

export default function(state = initialState,actions){
    switch(actions.type){
        case USERAVATAR_ADD_PAGE_INIT:
            return {...state, errors:{}};
        case USERAVATAR_ADD_REQUESTING:
            return {...state, requesting: true, errors:{}};
        case USERAVATAR_ADD_SUCCESS:
            return {...state, successful: true, errors:{}, addedUserAvatar:{...actions.payload}};
        case USERAVATAR_ADD_ERROR:
            return {...state, successful: false, errors:{...actions.error}};
        case USERAVATAR_UPDATE_REQUESTING:
            return {...state, requesting: true, errors:{}};
        case USERAVATAR_UPDATE_SUCCESS:
            return {...state, successful: true, errors:{}, updatedUserAvatar:{...actions.payload}};
        case USERAVATAR_UPDATE_ERROR:
            return {...state, successful: false, errors:{...actions.error}};
        default:        
            return state;
    }
}