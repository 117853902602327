import {Avatar, Card, CardHeader} from "@mui/material";
import React, {memo, useEffect} from "react";
// import {useStyles} from "../Dashboard/LeaderboardStyles";
// import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2';

// import {createTheme, darken, lighten} from "@mui/material/styles";
// import {makeStyles} from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
// import './usercard.css'
// import styled from 'styled-components'
// import {useDispatch, useSelector} from "react-redux";
// import {getUserCardImageInit} from "./actions";
// import {useEffect} from "@types/react";



function UserCardZeroOccurrences({userAccount, index, imageData}) {
    const nameWithSpaceRemoved = `${userAccount["firstName"]}${userAccount["lastName"]}`

    // const classes = useStyles();


    return (
        <Grid key={index} sx={{
            my: 1,
            minWidth: "215px",
            // maxWidth: "500px",
            position: "relative",
            // width: "512px", // 64 128 256 512
            // height: "224px", // 27 56 112 224
            // maxHeight: "144px"
        }}>
            <Card className={`box-contents`}
                  sx={{
                      backgroundImage: `url(${imageData})`,
                      // backgroundImage: "url(https://ca.slack-edge.com/T9KMM6Z8V-UA68QBP7A-ab91f81e203c-512)",
                      // maxHeight: "144px",
                      // width:"512px",
                      maxWidth: "225px",
                      // width: "525px",
                      // height: "225px",
                      // width:"100%",
                      // paddingBottom: "42.86%",
                      // height: "224px",
                      maxHeight: "224px",
                      // backgroundPositionX: `${temporaryBackgroundXYValue.backgroundX}px`,
                      // backgroundPositionY: `${temporaryBackgroundXYValue.backgroundY}px`,
                      // maxWidth: "100%",
                      backgroundSize: "100%"
                      // '&:hover': {
                      //     transform: "translateY(-5px) scale(1.005) translateZ(0)",
                      //     boxShadow: "0 24px 36px rgba(0,0,0,0.11) 0 24px 46px"
                      // },
                  }}
            >
                <CardHeader
                    sx={{
                        backdropFilter: "blur(20px)",
                        backgroundColor: "rgba( 0, 0, 0, 0.5 )",
                        maxWidth: "225px",
                        borderRadius: "15px",
                        height: "60px",
                    }}
                    avatar={
                        <Avatar
                            className={'leaderboard-avatar ' + nameWithSpaceRemoved}
                            aria-label="user"
                            sx={{height: "30px", width: "30px"}}
                        >
                            {/*{nameWithSpaceRemoved.charAt(0)}*/}
                        </Avatar>
                    }
                    title={<Typography sx={{color: "white"}}>
                        {userAccount.displayName || userAccount["IMC__c"]}
                    </Typography>}
                    subheader={
                        <Typography sx={{color: "white"}}>
                            {`${userAccount.occurrenceType}`}
                            {`: ${userAccount.occurrence}`}
                        </Typography>
                    }
                />
                {/*<CardContent>*/}

                {/*</CardContent>*/}
            </Card>
        </Grid>
    )
}

export default UserCardZeroOccurrences
// export default memo(UserCardGeneric)
