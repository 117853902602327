import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Switch from '@mui/material/Switch';
import {
    Box,
    // CircularProgress,
    // Container,
    FormControlLabel,
    FormGroup,
    // InputLabel,
    // Paper,
    // Select,
    // TextField,
    Tooltip
} from "@mui/material";
import {
    stagingAccountDetailAutomationInit,
    stagingAccountDetailInit, stagingAutomationStatusChangeInit, stagingAutomationStatusInit,
    stagingSiteStageInit,
    stagingSiteUpdateInit,
} from "./actions";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import SplitButton from "./SplitButton";
// import {salesForceUserTokens} from "../../helpers/helpers";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import Fade from "@mui/material/Fade";


function AutoPopulatedAutomationForm() {

    const dispatch = useDispatch();
    const stagingAutomationStatusInitiated = () => dispatch(stagingAutomationStatusInit());


    const stagingAutomationStatus = useSelector((state) => state.stagings.list_staging.stagingAutomationStatus.staging_automation);


    useEffect(() => {
        stagingAutomationStatusInitiated()
    }, []);

    useEffect(() => {
    }, [stagingAutomationStatus]);


    return (
        <AutopopulatedFields stagingAutomationStatus={stagingAutomationStatus}/>
    );
}


function AutopopulatedFields({stagingAutomationStatus}) {
    const dispatch = useDispatch();

    const stagingAutomationStatusChangeInitiator = (payload) => dispatch(stagingAutomationStatusChangeInit(payload));
    const [automationStatus, setAutomationStatus] = useState(stagingAutomationStatus)


    useEffect(() => {
    }, [stagingAutomationStatus]);

    
    const handleStagingOverrideSwitch = event => {
        setAutomationStatus(event.target.checked)
        stagingAutomationStatusChangeInitiator({
            automationStatus: event.target.checked,
        })
    }

console.log(``)
console.log(`stagingAutomationStatus.staging_automation: `, stagingAutomationStatus)
console.log(stagingAutomationStatus)
console.log(``)

    return (
                <Box sx={{}}>
                    <Tooltip
                        title={`Toggle Automation Status from ${automationStatus ? `on` : `off`} to ${automationStatus ? `off` : `on`}`}
                        arrow
                        enterDelay={100}
                        leaveDelay={300}
                        TransitionComponent={Fade}
                        TransitionProps={{timeout: 300}}
                    >

                        <FormGroup>
                            <FormControlLabel
                            control={<Switch checked={automationStatus} onChange={handleStagingOverrideSwitch}/>}
                            label=""/>
                        </FormGroup>
                    </Tooltip>
                </Box>
    )
}


export default AutoPopulatedAutomationForm;
