import React, { memo, useEffect } from "react";

import PropTypes from "prop-types";

import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/material";
// import SecondDrawer from "./SecondDrawer";
import DataTableMaterial from "./DataTableMaterial";
import Typography from "@mui/material/Typography";

function Staging() {
  return (
    <>
      <Grid
        spacing={1}
        container
        sx={{
          paddingBottom: "20px",
          width: "100%",
        }}
      >
          <Grid width="100%" align="center">
              <Typography variant="h4">Staging Progress</Typography>
          </Grid>
        <Grid container>
          <DataTableMaterial />
        </Grid>
      </Grid>
    </>
  );
}

Staging.propTypes = {
  stagings: PropTypes.array,
};

export default Staging;
// export default memo(Staging);
