import {createTheme} from '@mui/material/styles';
// import { red } from '@material-ui/core/colors'

// Normal or default theme
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
            light: '#484848',
        },
        secondary: {
            main: '#0060A8',
        },
        info: {
            main: '#0097a7',
        },
        background: {
            default: '#282f48',
        },
    },
})

export default theme