import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {
    userDetailInit,
} from './actions';

import Grid from '@mui/material/Unstable_Grid2';
import {Box, Paper} from "@mui/material";
import SecondDrawer from "../User/SecondDrawer";
import Typography from "@mui/material/Typography";
import Example from "./PinturaImageEditor";
import UserCardProfile from "./UserCardProfile";
import ProfileCardImageForm from "./ProfileCardForm";
// import ProfileAvatarForm from "./ProfileAvatarForm";
import PinturaImageEditor from "./PinturaImageEditor";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import {getAllowedRoutes} from "../../helpers/getAllowedRoutes";
import NavigationRoutePermissions from "../../helpers/NavigationRoutePermissions";
import MapAllowedRoutes from "../../helpers/MapAllowedRoutes";
import {useRoutes} from "react-router-dom";
import secondaryDrawerNavigationRoutePermissions from "./SecondaryDrawerNavigationRoutePermissions";


function User() {
    const dispatch = useDispatch();
    const userDetailInitiated = (payload) => dispatch(userDetailInit(payload))
    const currentLocalUser = useSelector((state) => state.user.list_user.currentLocalUser[0])
    const userCardImageBackground = useSelector((state) => state.user.list_user.userCardImageBackground[0])
    const userCardImageData = userCardImageBackground.img
    let localUserObject = JSON.parse(localStorage.getItem("user"))


    // const allowedRoutes = getAllowedRoutes(secondaryDrawerNavigationRoutePermissions)
    // console.log(`Permission Based Routes for menu: \n`, allowedRoutes)
    // const mappedAllowedRoutes = MapAllowedRoutes(allowedRoutes)
    // const mappedAllowedRoutes = MapAllowedRoutesWithoutElementParents(allowedRoutes)
    // console.log(`Routes available to access for the router: \n`, mappedAllowedRoutes)
    // const { role } = useRoleContext();
    // const router = useRoutes(mappedAllowedRoutes);



    useEffect(() => {
        userDetailInitiated({"userName": localUserObject.condensedName})
    }, []);


    // useEffect(() => {
    // }, [currentLocalUser]);

    useEffect(() => {
    }, [userCardImageData]);


    return (
        <>
            <Box id="secondDrawer"
                 sx={{
                     marginTop: "100px",
                     zIndex: 1,
                     position: "absolute",
                 }}
            >
                {/*<SecondDrawer allowedRoutes={allowedRoutes}/>*/}
            </Box>
            <Grid
                container
                spacing={5}
                direction="row"
                justifyContent="center"
            >
                <Grid item width="100%" align="center">
                    <Typography variant="h4">Profile</Typography>
                    {/*<PrettyPrintJson data={currentLocalUser}/>*/}
                </Grid>
                <Grid
                    container
                    item width="100%"
                    direction="row"
                    justifyContent="center"
                    id="ProfileContainer"
                    sx={{
                        marginTop: "100px",
                        marginBottom: "100px",
                        width: "100%",
                    }}
                >
                    {/*<ProfileAvatarForm userAccount={currentLocalUser}/>*/}
                    {/*<UserCardProfile userAccount={currentLocalUser} imageData={currentLocalUser.cardImage}/>*/}
                    <UserCardProfile userAccount={currentLocalUser} imageData={currentLocalUser.cardImage}/>
                    <ProfileCardImageForm userAccount={currentLocalUser}/>
                </Grid>
                <Grid item sx={{
                    height: "800px",
                    paddingTop: "20px",
                    paddingBottom: "2000px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    minWidth: "200px",
                }}>
                    <Paper
                        elevation={10}
                        sx={{
                            padding: 3,
                            minWidth: "910px",
                            marginTop: "20px",
                            marginLeft: "200px",
                            position: "relative",
                            width: "70vw",
                            height: "80vh"
                        }}
                    >
                        {/*<Example userAccount={currentLocalUser} imageData={currentLocalUser.cardImage}/>*/}
                        <PinturaImageEditor userAccount={currentLocalUser} imageData={currentLocalUser.cardImage}/>
                    </Paper>
                    {/*{router}*/}
                </Grid>
            </Grid>
        </>
    );
}

User.propTypes = {
    user: PropTypes.array
};


export default User