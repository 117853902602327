import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";




// Example component that utilizes the `normalise` function at the point of render.
// function Progress(props) {
//     return (
//         <React.Fragment>
//             <CircularProgress variant="determinate" value={normalise(props.value)} />
//             <LinearProgress variant="determinate" value={normalise(props.value)} />
//         </React.Fragment>
//     );
// }





function LinearProgressWithLabel({progress, buffer}) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    progress,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function StateScrapeProgressBar({progress, progressMax}) {
    const [progress2, setProgress] = useState(0);
    const [buffer, setBuffer] = React.useState(10);
    const MAX = progressMax || 100
    const MIN = 1
    const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

    const normalisedProgress = normalise(progress)
    const progressRef = React.useRef(() => {});
    // console.log(`normalisedProgress`)
    // console.log(normalisedProgress)
    useEffect(() => {
        progressRef.current = () => {
            if (normalisedProgress <= 100) {
                // console.log(`progress is NOT greater than 100 : ${progress}`)
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(normalisedProgress + diff < 100 ? normalisedProgress + diff : normalisedProgress );
                setBuffer(buffer + diff + diff2 < 100 ? buffer + diff + diff2 : buffer);
            } else {
                setProgress(100 );
                setBuffer(100);
            }
        };
        progressRef.current();
    }, [progress]);





    return (
        <Box sx={{ width: "90%" }}>
            <LinearProgressWithLabel progress={progress2} buffer={buffer} />
        </Box>
    );
}
