import {
    SALESFORCE_PAGE_INIT,
    SALESFORCE_ERROR,
    SALESFORCE_SUCCESS,
    SALESFORCE_DETAIL_INIT,
    SALESFORCE_DETAIL_ERROR,
    SALESFORCE_DETAIL_SUCCESS,
    SALESFORCE_DETAIL_CLOSE,
    SALESFORCE_DELETE_ERROR,
    SALESFORCE_DELETE_SUCCESS,
    SALESFORCE_START_ERROR,
    SALESFORCE_START_SUCCESS,
    RELEASE_STATE_DATA,


    LOGGED_IN_INIT,
    LOGGED_IN_INIT_ERROR,
    LOGGED_IN_INIT_SUCCESS,

    // Send Token on this action
    LOGGED_IN,

    INITIAL_STATE_LOAD_ACCOUNTS,
    INITIAL_STATE_LOAD_ACCOUNTS_ERROR,
    INITIAL_STATE_LOAD_ACCOUNTS_SUCCESS,

    INITIAL_STATE_LOAD_NEW_CASES,
    INITIAL_STATE_LOAD_NEW_CASES_ERROR,
    INITIAL_STATE_LOAD_NEW_CASES_SUCCESS,

    INITIAL_STATE_LOAD_OPEN_CASES,
    INITIAL_STATE_LOAD_OPEN_CASES_ERROR,
    INITIAL_STATE_LOAD_OPEN_CASES_SUCCESS,

    SALESFORCE_OPEN_CASES_PAGE_INIT,
    SALESFORCE_OPEN_CASES_ERROR,
    SALESFORCE_OPEN_CASES_SUCCESS,

    SALESFORCE_NEW_CASES_PAGE_INIT,
    SALESFORCE_NEW_CASES_ERROR,
    SALESFORCE_NEW_CASES_SUCCESS,

    //Dashboard Reports
    SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT,
    SALESFORCE_DEALS_THIS_MONTH_SUCCESS,
    SALESFORCE_DEALS_THIS_MONTH_ERROR,

    //Dashboard Reports
    SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT,
    SALESFORCE_LAUNCHES_THIS_MONTH_SUCCESS,
    SALESFORCE_LAUNCHES_THIS_MONTH_ERROR,


    SEND_SALESFORCE_INFO,
    CREATE_SALESFORCE,


    FETCH_SALESFORCES_BY_ACCOUNT,
    FETCH_SALESFORCES_BY_ACCOUNT_ERROR,
    FETCH_SALESFORCES_BY_ACCOUNT_SUCCESS,
    SELECTED_ACCOUNT_INFO,
    SELECTED_ACCOUNT_INFO_ERROR,
    SELECTED_ACCOUNT_INFO_SUCCESS,
    SELECTED_ACCOUNT,
    SELECTED_ACCOUNT_ERROR,
    SELECTED_ACCOUNT_SUCCESS,
    SELECTED_SALESFORCE,
    SELECTED_SALESFORCE_SUCCESS,
    SELECTED_SALESFORCE_ERROR,
    LOAD_SALESFORCE_INFO_TO_FORM,
    LOAD_SALESFORCE_INFO_TO_FORM_ERROR,
    LOAD_SALESFORCE_INFO_TO_FORM_SUCCESS,
    SALESFORCE_TO_UPDATE,
    SALESFORCE_TO_UPDATE_ERROR,
    SALESFORCE_TO_UPDATE_SUCCESS,
} from './actions';
import { combineReducers } from "redux";
import  manageCaseReducer  from './ManageCase/reducer';
// import  casePadReducer  from './CasePad/reducer';

// This is what's under redux state.case.list_case.HERE
// aka , state.list_case.[a reducer at the bottom of this page that's combined]
// aka , state.[key in mainReducer].[a reducer at the bottom of this page that's combined]
export const initialState = {
    loggedIn: false,
    loggedIn_user_sf_information: {},
    loggedIn_accessToken: '',
    loggedIn_refreshToken: '',
    loggedIn_userID: '',
    loggedIn_orgID: '',
    accounts: [],
    opencases: [],
    newcases: [],
    dealsThisMonth: [],
    launchesThisMonth: [],
    caseNum: '',
    selectedAccountInfo: '',
    selectedAccount:'',
    salesforceByAccount: [],
    currentCaseInfo: '',
    // selectedCase - already on list
    caseToUpdate: {},
    initialValues:{},
    confirmId:{},
    successful: false,
    messages: [],
    errors: [],
    salesforce: [],
    selectedCase: {},
    selectedCaseError: {},
    deleteCase: {}
  };

const salesforceReducer = function(state = initialState,actions){
    switch(actions.type){
        // case LOGGED_IN:
        //     return {...state, loggedIn: state.loggedIn += 1 }



        case LOGGED_IN:
            return {...state, successful: false, loggedIn: state.loggedIn }



        case LOGGED_IN_INIT:
            return {...state, successful: false, loggedIn: state.loggedIn }
        case LOGGED_IN_INIT_SUCCESS:
            return {...state, successful: true, loggedIn: true, loggedIn_user_sf_information:{...actions.payload}};
        case LOGGED_IN_INIT_ERROR:
            return {...state, successful: false, loggedIn: false, errors:[...actions.error]};


        case INITIAL_STATE_LOAD_NEW_CASES:
            return {...state}
        case INITIAL_STATE_LOAD_NEW_CASES_SUCCESS:
            return {...state, successful: true, newcases:[...actions.payload]};
        case INITIAL_STATE_LOAD_NEW_CASES_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case INITIAL_STATE_LOAD_OPEN_CASES:
            return {...state}
        case INITIAL_STATE_LOAD_OPEN_CASES_SUCCESS:
            return {...state, successful: true, opencases:[...actions.payload]};
        case INITIAL_STATE_LOAD_OPEN_CASES_ERROR:
            return {...state, successful: false, errors:[...actions.error]};



        case INITIAL_STATE_LOAD_ACCOUNTS:
            return {...state}
        case INITIAL_STATE_LOAD_ACCOUNTS_SUCCESS:
            return {...state, successful: true, accounts:[...actions.payload]};
        case INITIAL_STATE_LOAD_ACCOUNTS_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case SALESFORCE_NEW_CASES_PAGE_INIT:
            return {...state, errors:[], newcases: [...state.newcases]};
        case SALESFORCE_NEW_CASES_SUCCESS:
            return {...state, successful: true, newcases:[...actions.payload]};
        case SALESFORCE_NEW_CASES_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case SALESFORCE_OPEN_CASES_PAGE_INIT:
            return {...state, errors:[], opencases: [...state.opencases]};
        case SALESFORCE_OPEN_CASES_SUCCESS:
            return {...state, successful: true, opencases:[...actions.payload]};
        case SALESFORCE_OPEN_CASES_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        // Dashboard Reports
        case SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT:
            return {...state, errors:[], dealsThisMonth: [...state.dealsThisMonth]};
        case SALESFORCE_DEALS_THIS_MONTH_SUCCESS:
            return {...state, successful: true, dealsThisMonth:[...actions.payload]};
        case SALESFORCE_DEALS_THIS_MONTH_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        // Dashboard Reports
        case SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT:
            return {...state, errors:[], launchesThisMonth: [...state.launchesThisMonth]};
        case SALESFORCE_LAUNCHES_THIS_MONTH_SUCCESS:
            return {...state, successful: true, launchesThisMonth:[...actions.payload]};
        case SALESFORCE_LAUNCHES_THIS_MONTH_ERROR:
            return {...state, successful: false, errors:[...actions.error]};






        case SALESFORCE_PAGE_INIT:
            return {...state, errors:[], salesforce: []};
        case SALESFORCE_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case SALESFORCE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};



        case CREATE_SALESFORCE:
            return {...state, caseNum: actions.payload}

        case FETCH_SALESFORCES_BY_ACCOUNT:
            return {...state, errors:[], salesforce: []};
        case FETCH_SALESFORCES_BY_ACCOUNT_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case FETCH_SALESFORCES_BY_ACCOUNT_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case SELECTED_ACCOUNT_INFO:
            return {...state, errors:[], salesforce: []};
        case SELECTED_ACCOUNT_INFO_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case SELECTED_ACCOUNT_INFO_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case SELECTED_ACCOUNT:
            return {...state, errors:[], salesforce: []};
        case SELECTED_ACCOUNT_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case SELECTED_ACCOUNT_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case SELECTED_SALESFORCE:
            return {...state, errors:[], salesforce: []};
        case SELECTED_SALESFORCE_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case SELECTED_SALESFORCE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case LOAD_SALESFORCE_INFO_TO_FORM:
            return {...state, errors:[], salesforce: []};
        case LOAD_SALESFORCE_INFO_TO_FORM_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case LOAD_SALESFORCE_INFO_TO_FORM_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case SALESFORCE_TO_UPDATE:
            return {...state, errors:[], salesforce: []};
        case SALESFORCE_TO_UPDATE_SUCCESS:
            return {...state, successful: true, salesforce:[...actions.payload]};
        case SALESFORCE_TO_UPDATE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case SALESFORCE_DETAIL_INIT:
            return {...state, selectedCaseError:{}, selectedCase: {}};
        case SALESFORCE_DETAIL_SUCCESS:
            return {...state, selectedCase: {...actions.payload}};
        case SALESFORCE_DETAIL_ERROR:
            return {...state, selectedCaseError:{...actions.error}};
        case SALESFORCE_DETAIL_CLOSE:
            return {...state, selectedCaseError:{}, selectedCase: {}};
        case SALESFORCE_DELETE_SUCCESS:
            return {...state, deleteCase: {...actions.payload}};
        case SALESFORCE_DELETE_ERROR:
            return {...state, selectedCaseError:{...actions.error}};
        case SALESFORCE_START_SUCCESS:
            return {...state, startCase: {...actions.payload}};
        case SALESFORCE_START_ERROR:
            return {...state, selectedCaseError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], salesforce: [], selectedCase: {},selectedCaseError: {},deleteCase: {}}
        default:        
            return state;
    }
}

// This export gets named in the main reducer
export default combineReducers({
    signed_in : salesforceReducer,
    manage_case: manageCaseReducer,
    // case_pad: casePadReducer
});