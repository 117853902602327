export const CLIENT_PAGE_INIT = 'CLIENT_PAGE_INIT';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const CLIENT_SUCCESS = 'CLIENT_SUCCESS';
export const CLIENT_DETAIL_INIT = 'CLIENT_DETAIL_INIT';
export const CLIENT_DETAIL_ERROR = 'CLIENT_DETAIL_ERROR';
export const CLIENT_DETAIL_SUCCESS = 'CLIENT_DETAIL_SUCCESS';
export const CLIENT_DETAIL_CLOSE = 'CLIENT_DETAIL_CLOSE';
export const CLIENT_DELETE_INIT = 'CLIENT_DELETE_INIT';
export const CLIENT_DELETE_ERROR = 'CLIENT_DELETE_ERROR';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const CLIENT_START_INIT = 'CLIENT_START_INIT';
export const CLIENT_START_REQUESTING = 'CLIENT_START_REQUESTING';
export const CLIENT_START_SUCCESS = 'CLIENT_START_SUCCESS';
export const CLIENT_START_ERROR = 'CLIENT_START_ERROR';
// export const CLIENT_START_PAGE_INIT = 'CLIENT_START_PAGE_INIT';

export function clientPageInit() {
    return {
        type: CLIENT_PAGE_INIT,
    };
}

export function clientError(error) {
    return {
        type: CLIENT_ERROR,
        error,
    };
}

export function clientSuccess(payload) {
    return {
        type: CLIENT_SUCCESS,
        payload
    };
}

export function clientDetailInit(id) {
    return {
        type: CLIENT_DETAIL_INIT,
        id 
    };
}

export function clientDetailError(error) {
    return {
        type: CLIENT_DETAIL_ERROR,
        error,
    };
}

export function clientDetailSuccess(payload) {
    return {
        type: CLIENT_DETAIL_SUCCESS,
        payload
    };
}

export function clientDetailClose() {
    return {
        type: CLIENT_DETAIL_CLOSE,
    };
}

export function clientDeleteInit(id) {
    return {
        type: CLIENT_DELETE_INIT,
        id 
    };
}

export function clientStartInit(id) {
    return {
        type: CLIENT_START_INIT,
        id
    };
}

export function clientStartError(error) {
    return {
        type: CLIENT_START_ERROR,
        error,
    };
}

export function clientStartSuccess(payload) {
    return {
        type: CLIENT_START_SUCCESS,
        payload
    };
}

// export function clientStartRequest(payload,id) {
//     return {
//         type: CLIENT_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function clientDeleteError(error) {
    return {
        type: CLIENT_DELETE_ERROR,
        error,
    };
}

export function clientDeleteSuccess(payload) {
    return {
        type: CLIENT_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}