import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  LAUNCH_PAGE_INIT,
  // LAUNCH_DETAIL_INIT,
  // LAUNCH_DELETE_INIT,
  // LAUNCH_START_INIT,
  launchError,
  launchSuccess,
  // launchDetailError,
  // launchDetailSuccess,
  // launchDeleteError,
  // launchDeleteSuccess,
  // launchPageInit,
  // LAUNCH_START_REQUESTING,
  // launchStartSuccess,
  // launchStartError,
  LAUNCH_UPDATE_LOG,
  launchUpdateLogSuccess, LAUNCH_DELETE_INIT, launchDeleteSuccess,
} from "./actions";


import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';


//Launch API calls
function launchCall() {
  return request('get', urls.LAUNCH + '/launchlist');
}


function launchUpdateLogCall() {
  return request('get', urls.LAUNCH + '/updatelog');
}


function launchDeleteInitCall(payload) {
  return request('delete', urls.LAUNCH + '/deleteinit', payload);
}


// Launch Workers
function* launchWorker() {
  try {
    let response = yield call(launchCall);
    response = response.data.data;
    yield put(launchSuccess(response));
  } catch (err) {
    yield put(launchError(err.response.data.data));
  }
}


function* launchUpdateLogWorker() {
  try {
    let response = yield call(launchUpdateLogCall);
    response = response.data.data;
    console.log(`launchUpdateLogWorker response:`)
    console.log(response)
    yield put(launchUpdateLogSuccess(response));
    /* success will have the launch page reload with the new results */
    // yield put(launchPageInit());
  } catch (err) {
    console.log(`error: ${err.message}`);
    // yield put(launchStartError(err.response.data));
  }
}


function* launchDeleteInitWorker({payload}) {
  try {
    console.log(`payload in delete saga`)
    console.log(payload)
    let response = yield call(launchDeleteInitCall, payload);
    response = response.data;
    console.log(`launchDeleteInitCall response:`)
    console.log(response)
    yield put(launchDeleteSuccess(response));
    /* success will have the launch page reload with the new results */
    // yield put(launchPageInit());
  } catch (err) {
    console.log(`error: ${err.message}`);
    // yield put(launchStartError(err.response.data));
  }
}


// Launch Watcher
export default function* launchSaga() {
  yield all([
    takeLatest(LAUNCH_PAGE_INIT, launchWorker),
    takeLatest(LAUNCH_UPDATE_LOG, launchUpdateLogWorker),
    takeLatest(LAUNCH_DELETE_INIT, launchDeleteInitWorker),
  ]);
}