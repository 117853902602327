import {createTheme} from '@mui/material/styles';
// import { red } from '@material-ui/core/colors'

// Normal or default theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#2d348d',
        },
        secondary: {
            main: '#cc4444',
        },
        error: {
            main: '#cc4444',
        },
        // background: {
        //     default: '#eeeeee',
        // },
        // titleBar: {
        //     main: '#eeeeee',
        //     contrastText: '#222222',
        // },
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true,
            },
        },
        MuiPaper: {
            defaultProps: {},
            styleOverrides: {
                root: {},
            },
        },
        MuiButton: {
            defaultProps: {},
            styleOverrides: {
                outlined: { // Targeting the "contained" variant
                    backgroundColor: '#ffffff!important', // blue color in hexadecimal
                    "&:hover": {
                        backgroundColor: 'rgb(81, 91, 95)' // darker blue for hover effect
                    }
                },
            },
        },
        MuiFormControl: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff!important', // blue color in hexadecimal
                    "&:hover": {
                        backgroundColor: 'rgb(81, 91, 95)'
                    }
                },
            },
        }
    }
})

export default theme