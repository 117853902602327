/*
* SUPER_ADMIN has access to all of the app
* ADMIN has access to all of the app except managing users
* ACCOUNT_MANAGER has access to service team related work
* SALES_MANAGER is not an IMC, has access to some of the Scraper
*/
const Roles = {
	SUPER_ADMIN: 'SUPER_ADMIN',
	ADMIN: 'ADMIN',
	ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
	SALES_MANAGER: 'SALES_MANAGER',
	SALES_IMC: 'SALES_IMC',
	GUEST: 'GUEST'
};

export default Roles