import React, {useContext, useEffect} from 'react';
import {Box, Button, Container, Grid} from "@mui/material";
import SecondDrawer from "./SecondDrawer";
import ClientSiteConfiguration from "./ClientSiteConfiguration";



function ClientSiteKeyConfiguration(props) {
        return (
            <>
                <Box
                    id="secondDrawer"
                    sx={{
                        marginTop: "100px",
                        zIndex: 1,
                        position: "absolute",
                    }}
                >
                    <SecondDrawer/>
                </Box>
                <Grid
                    container
                    // align="center"
                    spacing={3}
                    sx={{
                        // position: "inherit",
                        // display: "inline-block",
                        // marginLeft: "15%",
                        marginLeft: "300px",
                        marginRight: "5%",
                        paddingBottom: "20px",
                        width: "100%",
                    }}
                >
                    <Grid container item sx={{ marginBottom: "100px" }}>
                    <ClientSiteConfiguration/>
                    </Grid>
                </Grid>
            </>
        );

    }
// }




export default ClientSiteKeyConfiguration