import {createTheme} from '@mui/material/styles';
// import { red } from '@material-ui/core/colors'

// Normal or default theme
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffc510',
        },
        background: {
            default: '#0f0f17',
            paper: '#1f242f',
        },
    },
    typography: {
        fontFamily: 'Bangers',
        color: '#ffffffde',
        body1: {
            fontFamily: 'Bangers',
        },
        h1: {
            fontFamily: 'Bangers',
            fontColor: '#ffffffde',
        },
        caption: {
            fontFamily: 'Do Hyeon',
        },
        overline: {
            fontFamily: 'Do Hyeon',
        },
        body2: {
            fontFamily: 'Roboto',
        },
    },
    components: {
        // Name of the component
        MuiToolbar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    // fontSize: '1rem',
                    // borderRadius: '1px',
                    backgroundColor: '#000000',
                    // color: '#000000'
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    // fontSize: '1rem',
                    // borderRadius: '1px',
                    backgroundColor: '#484848',
                    // color: '#ffffffde'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'primary' && {
                            backgroundColor: '#ffc510',
                            color: '#fff',
                        }),
                }),
            },
        },
    },
})

export default theme