import {
    // STAGING_UPDATE_LOG,
    STAGING_AREA_LIST_SUCCESS,
    STAGING_UPDATE_LOG_SUCCESS,

    // STAGING_STARTED_PAGE_INIT,
    STAGING_STARTED_PAGE_SUCCESS,
    STAGING_STARTED_PAGE_ERROR,

    STAGING_LIST_INIT,
    STAGING_LIST_ERROR,
    STAGING_LIST_SUCCESS,

    STAGING_ACCOUNT_DETAIL_SUCCESS,
    STAGING_ACCOUNT_DETAIL_ERROR,

    // STAGING_SITE_UPDATE_INIT,
    STAGING_SITE_UPDATE_ERROR,
    STAGING_SITE_UPDATE_SUCCESS,

    // STAGING_SITE_STAGE_INIT,
    STAGING_SITE_STAGE_ERROR,
    STAGING_SITE_STAGE_SUCCESS,

    STAGING_DELETE_ERROR,
    STAGING_DELETE_SUCCESS,
    STAGING_START_ERROR,
    STAGING_START_SUCCESS,
    RELEASE_STATE_DATA,
    STAGING_AUTOSELECT_SAVE,
    STAGING_AUTOSELECT_SAVE_SUCCESS,
    STAGING_CHECK_SALESFORCE_SUCCESS,
    STAGING_CHECK_EMAIL_INIT,
    STAGING_EMAIL_REQUESTED_INIT,
    STAGING_AUTOSELECT_AUTOMATION_SAVE_SUCCESS,
    STAGING_ACCOUNT_DETAIL_AUTOMATION_SUCCESS,
    STAGING_ACCOUNT_DETAIL_AUTOMATION_ERROR,
    STAGING_DELETE_EMAIL_SUCCESS,
    STAGING_DELETE_EMAIL_ERROR,
    STAGING_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS,
    STAGING_AUTOSELECT_FOR_LAUNCH_SAVE,
    STAGING_CHECK_SERVER_SUCCESS,
    STAGING_AUTOMATION_STATUS_SUCCESS,
    STAGING_AUTOMATION_STATUS_CHANGE_SUCCESS
} from './actions';
import { combineReducers } from "redux";
import  manageStagingReducer  from './ManageStaging/reducer';
import  stagingPadReducer  from './StagingPad/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    stagings: [],
    stagingSites: [],
    stagingSitesOnServer: [],
    stagingAutoSelect: {},
    stagingAutoSelectForLaunch: {},
    stagingAutoSelectAutomation: {},
    selectedStagingForLaunch: [{
        Staging_Site_URL__c: '',
    }],
    selectedStaging: [{
        Id: '',
        Name: '',
        Staging_Site_URL__c: '',
        Business_Email__c: '',
        ShippingStreet: '',
        ShippingCity: '',
        ShippingState: '',
        ShippingPostalCode: '',
        Business_Phone__c: '',
        Hours_Line_1__c: '',
        Hours_Line_2__c: '',
        Hours_Line_3__c: '',
        Hours_Line_4__c: '',
        Industry: '',
        Initial_Investment__c: '',
        coreSelect: ''
    }],
    selectedStagingAutomation: [{
        Id: '',
        Name: '',
        Staging_Site_URL__c: '',
        Business_Email__c: '',
        ShippingStreet: '',
        ShippingCity: '',
        ShippingState: '',
        ShippingPostalCode: '',
        Business_Phone__c: '',
        Hours_Line_1__c: '',
        Hours_Line_2__c: '',
        Hours_Line_3__c: '',
        Hours_Line_4__c: '',
        Industry: '',
        Initial_Investment__c: '',
        coreSelect: ''
    }],
    selectedStagingDetailRetrieved: false,
    selectedStagingDetailRetrievedForLaunch: false,
    stagingEmailRequests: [],
    stagingEmailRequested: false,
    selectedStagingError: {},
    deleteStaging: {},
    stagingAutomationStatus: {staging_automation:false}
  };

const stagingReducers = function(state = initialState,actions){
    switch(actions.type){
        case STAGING_AUTOSELECT_SAVE_SUCCESS:
            return {...state, selectedStagingDetailRetrieved: false, stagingAutoSelect: {...actions.payload}}

        case STAGING_AUTOSELECT_AUTOMATION_SAVE_SUCCESS:
            return {...state, selectedStagingDetailRetrieved: false, stagingAutoSelectAutomation: {...actions.payload}}




        case STAGING_AUTOMATION_STATUS_SUCCESS:
            return {...state, selectedStagingDetailRetrieved: false, stagingAutomationStatus: {...actions.payload}}

        case STAGING_AUTOMATION_STATUS_CHANGE_SUCCESS:
            return {...state, selectedStagingDetailRetrieved: false, stagingAutomationStatus: {...actions.payload}}



        // case STAGING_AUTOSELECT_SAVE:
        //     return {...state, selectedStagingDetailRetrieved: false, stagingAutoSelect: [...actions.payload]}


        case STAGING_AUTOSELECT_FOR_LAUNCH_SAVE:
            return {...state, selectedStagingDetailRetrievedForLaunch: true, stagingAutoSelectForLaunch: {...actions.payload}}

        case STAGING_UPDATE_LOG_SUCCESS:
            return {...state, errors:[], stagingSites: [...actions.payload]};

        case STAGING_STARTED_PAGE_SUCCESS:
            return {...state, successful: true, stagingSites:[...actions.payload]};
        case STAGING_STARTED_PAGE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case STAGING_EMAIL_REQUESTED_INIT:
            return {...state, errors:[], stagingEmailRequested:true};

        case STAGING_CHECK_SALESFORCE_SUCCESS:
            return {...state, errors:[], stagingEmailRequested:false , stagingEmailRequests: [...actions.payload]};


        case STAGING_CHECK_SERVER_SUCCESS:
            return {...state, errors:[], stagingEmailRequested:false , stagingSitesOnServer: [...actions.payload]};



        case STAGING_AREA_LIST_SUCCESS:
            return {...state, errors:[], stagingSites: [...actions.payload]};

        case STAGING_LIST_INIT:
            return {...state, errors:[], stagings: []};

        case STAGING_LIST_SUCCESS:
            return {...state, successful: true, stagings:[...actions.payload]};
        case STAGING_LIST_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

            // case STAGING_ACCOUNT_DETAIL_INIT:
        //     return {...state, errors:[], stagings: []};
        case STAGING_ACCOUNT_DETAIL_SUCCESS:
            return {...state, selectedStagingDetailRetrieved: true, selectedStaging:[...actions.payload]};
        case STAGING_ACCOUNT_DETAIL_ERROR:
            return {...state, selectedStagingDetailRetrieved: false, errors:[...actions.error]};

        case STAGING_ACCOUNT_DETAIL_AUTOMATION_SUCCESS:
            return {...state, selectedStagingDetailRetrieved: true, selectedStagingAutomation:[...actions.payload]};
        case STAGING_ACCOUNT_DETAIL_AUTOMATION_ERROR:
            return {...state, selectedStagingDetailRetrieved: false, errors:[...actions.error]};

        // case STAGING_SITE_UPDATE_INIT:
        //     return {...state, errors:[], stagings: []};
        case STAGING_SITE_UPDATE_SUCCESS:
            return {...state, successful: true, selectedStaging:[...actions.payload]};
        case STAGING_SITE_UPDATE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        //    User selected actions do not receive a case but is handled by saga
        // case STAGING_SITE_STAGE_INIT:
        //     return {...state, errors:[], stagings: []};
        case STAGING_SITE_STAGE_SUCCESS:
            return {...state, successful: true, stagings:[...actions.payload]};
        case STAGING_SITE_STAGE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case STAGING_DELETE_EMAIL_SUCCESS:
            return {...state, deleteStaging: {...actions.payload}};
        case STAGING_DELETE_EMAIL_ERROR:
            return {...state, selectedStagingError:{...actions.error}};

        case STAGING_DELETE_SUCCESS:
            return {...state, deleteStaging: {...actions.payload}};
        case STAGING_DELETE_ERROR:
            return {...state, selectedStagingError:{...actions.error}};

        case STAGING_START_SUCCESS:
            return {...state, startStaging: {...actions.payload}};
        case STAGING_START_ERROR:
            return {...state, selectedStagingError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], stagings: [], selectedStaging: {},selectedStagingError: {},deleteStaging: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_staging : stagingReducers,
    manage_staging: manageStagingReducer,
    staging_pad: stagingPadReducer
});