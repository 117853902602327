import {
    LEADGENERATOR_ADD_PAGE_INIT,
    LEADGENERATOR_ADD_ERROR,
    // LEADGENERATOR_ADD_REQUESTING,
    LEADGENERATOR_ADD_SUCCESS,
    LEADGENERATOR_UPDATE_ERROR,
    LEADGENERATOR_UPDATE_REQUESTING,
    LEADGENERATOR_UPDATE_SUCCESS,
    // LEADGENERATOR_STATE_LIST_INIT,
    LEADGENERATOR_STATE_LIST_SUCCESS,
    LEADGENERATOR_BUSINESS_LIST_SUCCESS,
    LEADGENERATOR_BUSINESS_LIST_SCANNED_SUCCESS,
    LEADGENERATOR_SAVE_SELECTED_STATE,
    LEADGENERATOR_SAVE_SELECTED_INDUSTRY,
    LEADGENERATOR_BUSINESS_LIST_COMPLETED_SUCCESS,
    LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_COMPLETED_SUCCESS,
    LEADGENERATOR_SAVE_SELECTED_STATE_LIST_TO_SCRAPE,
    LEADGENERATOR_BUSINESS_LIST_PROGRESS_SUCCESS,
    LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_PROGRESS_SUCCESS, LEADGENERATOR_SAVE_SELECTED_COLLECTION,
    LEADGENERATOR_COLLECTION_DETAIL_SUCCESS
} from './actions';

export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    scrapes: [],
    stateList: [],
    exportCollection: [],
    collectionSelected: {collectionName: ''},
    collectionSelectedData: [],
    businessListCompleted: [],
    businessListProgress: [],
    businessListSiteScannedProgress: [],
    businessListSiteScannedCompleted: [],
    businessList: [],
    businessScannedList: [],
    stateSelected: {collectionName: ''},
    industrySelected: {industryName: ''},
    stateListToScrapeSelected: {collectionName: ''},
    selectedScrapes: {},
    selectedScrapesError: {},
    deleteScrapes: {}
};

export default function(state = initialState,actions){
    switch(actions.type){

        case LEADGENERATOR_COLLECTION_DETAIL_SUCCESS:
            return {...state, successful: true, errors:{}, collectionSelectedData:[...actions.payload]};

        case LEADGENERATOR_STATE_LIST_SUCCESS:
            return {...state, successful: true, errors:{}, stateList:[...actions.payload]};

        case LEADGENERATOR_BUSINESS_LIST_SUCCESS:
            return {...state, successful: true, errors:{}, businessList:[...actions.payload]};


        case LEADGENERATOR_BUSINESS_LIST_SCANNED_SUCCESS:
            return {...state, successful: true, errors:{}, businessScannedList:[...actions.payload]};



        case LEADGENERATOR_BUSINESS_LIST_COMPLETED_SUCCESS:
            return {...state, successful: true, errors:{}, businessListCompleted:[...actions.payload]};


        case LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_COMPLETED_SUCCESS:
            return {...state, successful: true, errors:{}, businessListSiteScannedCompleted:[...actions.payload]};




        case LEADGENERATOR_BUSINESS_LIST_PROGRESS_SUCCESS:
            return {...state, successful: true, errors:{}, businessListProgress:[...actions.payload]};


        case LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_PROGRESS_SUCCESS:
            return {...state, successful: true, errors:{}, businessListSiteScannedProgress:[...actions.payload]};






        case LEADGENERATOR_SAVE_SELECTED_COLLECTION:
            return {...state, successful: true, errors:{}, collectionSelected:{...actions.payload}};



        case LEADGENERATOR_SAVE_SELECTED_STATE:
            return {...state, successful: true, errors:{}, stateSelected:{...actions.payload}};




        case LEADGENERATOR_SAVE_SELECTED_INDUSTRY:
            return {...state, successful: true, errors:{}, industrySelected:{...actions.payload}};


        case LEADGENERATOR_SAVE_SELECTED_STATE_LIST_TO_SCRAPE:
            return {...state, successful: true, errors:{}, stateListToScrapeSelected:{...actions.payload}};




        case LEADGENERATOR_ADD_PAGE_INIT:
            return {...state, errors:{} };
        // case LEADGENERATOR_ADD_REQUESTING:
        //     return {...state, requesting: true, errors:{}};
        case LEADGENERATOR_ADD_SUCCESS:
            return {...state, successful: true, errors:{}, addedScrapes:{...actions.payload}};
        case LEADGENERATOR_ADD_ERROR:
            return {...state, successful: false, errors:{...actions.error}};
        case LEADGENERATOR_UPDATE_REQUESTING:
            return {...state, requesting: true, errors:{}};
        case LEADGENERATOR_UPDATE_SUCCESS:
            return {...state, successful: true, errors:{}, updatedScrapes:{...actions.payload}};
        case LEADGENERATOR_UPDATE_ERROR:
            return {...state, successful: false, errors:{...actions.error}};
        default:
            return state;
    }
}
