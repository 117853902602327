export const SALESFORCE_PAGE_INIT = 'SALESFORCE_PAGE_INIT';
export const SALESFORCE_ERROR = 'SALESFORCE_ERROR';
export const SALESFORCE_SUCCESS = 'SALESFORCE_SUCCESS';
export const SALESFORCE_DETAIL_INIT = 'SALESFORCE_DETAIL_INIT';
export const SALESFORCE_DETAIL_ERROR = 'SALESFORCE_DETAIL_ERROR';
export const SALESFORCE_DETAIL_SUCCESS = 'SALESFORCE_DETAIL_SUCCESS';
export const SALESFORCE_DETAIL_CLOSE = 'SALESFORCE_DETAIL_CLOSE';
export const SALESFORCE_DELETE_INIT = 'SALESFORCE_DELETE_INIT';
export const SALESFORCE_DELETE_ERROR = 'SALESFORCE_DELETE_ERROR';
export const SALESFORCE_DELETE_SUCCESS = 'SALESFORCE_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const SALESFORCE_START_INIT = 'SALESFORCE_START_INIT';
export const SALESFORCE_START_REQUESTING = 'SALESFORCE_START_REQUESTING';
export const SALESFORCE_START_SUCCESS = 'SALESFORCE_START_SUCCESS';
export const SALESFORCE_START_ERROR = 'SALESFORCE_START_ERROR';
// export const SALESFORCE_START_PAGE_INIT = 'SALESFORCE_START_PAGE_INIT';


//----------------- START OF SALESFORCE RELATED ACTIONS
export const LOGGED_IN_INIT = 'LOGGED_IN_INIT';
export const LOGGED_IN_INIT_ERROR = 'LOGGED_IN_INIT_ERROR';
export const LOGGED_IN_INIT_SUCCESS = 'LOGGED_IN_INIT_SUCCESS';

export const LOGGED_IN = 'LOGGED_IN';



export const INITIAL_STATE_LOAD_ACCOUNTS = 'INITIAL_STATE_LOAD_ACCOUNTS';
export const INITIAL_STATE_LOAD_ACCOUNTS_ERROR = 'INITIAL_STATE_LOAD_ACCOUNTS_ERROR';
export const INITIAL_STATE_LOAD_ACCOUNTS_SUCCESS = 'INITIAL_STATE_LOAD_ACCOUNTS_SUCCESS';


export const INITIAL_STATE_LOAD_NEW_CASES = 'INITIAL_STATE_LOAD_NEW_CASES';
export const INITIAL_STATE_LOAD_NEW_CASES_SUCCESS = 'INITIAL_STATE_LOAD_NEW_CASES_SUCCESS';
export const INITIAL_STATE_LOAD_NEW_CASES_ERROR = 'INITIAL_STATE_LOAD_NEW_CASES_ERROR';

export const INITIAL_STATE_LOAD_OPEN_CASES = 'INITIAL_STATE_LOAD_OPEN_CASES';
export const INITIAL_STATE_LOAD_OPEN_CASES_SUCCESS = 'INITIAL_STATE_LOAD_OPEN_CASES_SUCCESS';
export const INITIAL_STATE_LOAD_OPEN_CASES_ERROR = 'INITIAL_STATE_LOAD_OPEN_CASES_ERROR';

//Dashboard Reports
export const INITIAL_STATE_LOAD_DEALS_THIS_MONTH = 'INITIAL_STATE_LOAD_DEALS_THIS_MONTH';
export const INITIAL_STATE_LOAD_DEALS_THIS_MONTH_SUCCESS = 'INITIAL_STATE_LOAD_DEALS_THIS_MONTH_SUCCESS';
export const INITIAL_STATE_LOAD_DEALS_THIS_MONTH_ERROR = 'INITIAL_STATE_LOAD_DEALS_THIS_MONTH_ERROR';

export const SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT = 'SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT';
export const SALESFORCE_DEALS_THIS_MONTH_SUCCESS = 'SALESFORCE_DEALS_THIS_MONTH_SUCCESS';
export const SALESFORCE_DEALS_THIS_MONTH_ERROR = 'SALESFORCE_DEALS_THIS_MONTH_ERROR';

//Dashboard Reports
export const INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH = 'INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH';
export const INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH_SUCCESS = 'INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH_SUCCESS';
export const INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH_ERROR = 'INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH_ERROR';

export const SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT = 'SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT';
export const SALESFORCE_LAUNCHES_THIS_MONTH_SUCCESS = 'SALESFORCE_LAUNCHES_THIS_MONTH_SUCCESS';
export const SALESFORCE_LAUNCHES_THIS_MONTH_ERROR = 'SALESFORCE_LAUNCHES_THIS_MONTH_ERROR';




export const SALESFORCE_OPEN_CASES_PAGE_INIT = 'SALESFORCE_OPEN_CASES_PAGE_INIT';
export const SALESFORCE_OPEN_CASES_SUCCESS = 'SALESFORCE_OPEN_CASES_SUCCESS';
export const SALESFORCE_OPEN_CASES_ERROR = 'SALESFORCE_OPEN_CASES_ERROR';

export const SALESFORCE_NEW_CASES_PAGE_INIT = 'SALESFORCE_NEW_CASES_PAGE_INIT';
export const SALESFORCE_NEW_CASES_SUCCESS = 'SALESFORCE_NEW_CASES_SUCCESS';
export const SALESFORCE_NEW_CASES_ERROR = 'SALESFORCE_NEW_CASES_ERROR';


export const SEND_SALESFORCE_INFO = 'SEND_SALESFORCE_INFO';

export const CREATE_SALESFORCE = 'CREATE_SALESFORCE';



export const FETCH_SALESFORCES_BY_ACCOUNT = 'FETCH_SALESFORCES_BY_ACCOUNT';
export const FETCH_SALESFORCES_BY_ACCOUNT_ERROR = 'FETCH_SALESFORCES_BY_ACCOUNT_ERROR';
export const FETCH_SALESFORCES_BY_ACCOUNT_SUCCESS = 'FETCH_SALESFORCES_BY_ACCOUNT_SUCCESS';

export const SELECTED_ACCOUNT_INFO = 'SELECTED_ACCOUNT_INFO';
export const SELECTED_ACCOUNT_INFO_ERROR = 'SELECTED_ACCOUNT_INFO_ERROR';
export const SELECTED_ACCOUNT_INFO_SUCCESS = 'SELECTED_ACCOUNT_INFO_SUCCESS';


export const SELECTED_ACCOUNT = 'SELECTED_ACCOUNT';
export const SELECTED_ACCOUNT_ERROR = 'SELECTED_ACCOUNT_ERROR';
export const SELECTED_ACCOUNT_SUCCESS = 'SELECTED_ACCOUNT_SUCCESS';



export const SELECTED_SALESFORCE = 'SELECTED_SALESFORCE';
export const SELECTED_SALESFORCE_SUCCESS = 'SELECTED_SALESFORCE_SUCCESS';
export const SELECTED_SALESFORCE_ERROR = 'SELECTED_SALESFORCE_ERROR';


export const LOAD_SALESFORCE_INFO_TO_FORM = 'LOAD_SALESFORCE_INFO_TO_FORM';
export const LOAD_SALESFORCE_INFO_TO_FORM_ERROR = 'LOAD_SALESFORCE_INFO_TO_FORM_ERROR';
export const LOAD_SALESFORCE_INFO_TO_FORM_SUCCESS = 'LOAD_SALESFORCE_INFO_TO_FORM_SUCCESS';


export const SALESFORCE_TO_UPDATE = 'SALESFORCE_TO_UPDATE';
export const SALESFORCE_TO_UPDATE_ERROR = 'SALESFORCE_TO_UPDATE_ERROR';
export const SALESFORCE_TO_UPDATE_SUCCESS = 'SALESFORCE_TO_UPDATE_SUCCESS';


// -- SALESFORCE START
export function loggedInInit() {
    return {
        type: LOGGED_IN_INIT,
    };
}
export function loggedInInitError(error) {
    return {
        type: LOGGED_IN_INIT_ERROR,
        error
    };
}

export function loggedInInitSuccess(payload) {
    return {
        type: LOGGED_IN_INIT_SUCCESS,
        payload
    };
}

// Sending token
export function loggedIn(payload) {
    return {
        type: LOGGED_IN,
        payload
    };
}




// NEW CASES
export function salesforceNewCasesPageInit(payload) {
    return {
        type: SALESFORCE_NEW_CASES_PAGE_INIT,
        payload
    };
}


export function salesforceNewCasesError(error) {
    return {
        type: SALESFORCE_NEW_CASES_ERROR,
        error,
    };
}

export function salesforceNewCasesSuccess(payload) {
    return {
        type: SALESFORCE_NEW_CASES_SUCCESS,
        payload
    };
}


// NEW CASES
export function salesforceOpenCasesPageInit(payload) {
    return {
        type: SALESFORCE_OPEN_CASES_PAGE_INIT,
        payload
    };
}


export function salesforceOpenCasesError(error) {
    return {
        type: SALESFORCE_OPEN_CASES_ERROR,
        error,
    };
}

export function salesforceOpenCasesSuccess(payload) {
    return {
        type: SALESFORCE_OPEN_CASES_SUCCESS,
        payload
    };
}


// Dashboard Report - Deals
// export function getDealsThisMonthOnPageInit(payload) {
//     return {
//         type: SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT,
//         payload
//     };
// }

export function salesforceDealsThisMonthPageInit(payload) {
    return {
        type: SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT,
        payload
    };
}


export function salesforceDealsThisMonthError(error) {
    return {
        type: SALESFORCE_DEALS_THIS_MONTH_ERROR,
        error,
    };
}

export function salesforceDealsThisMonthSuccess(payload) {
    return {
        type: SALESFORCE_DEALS_THIS_MONTH_SUCCESS,
        payload
    };
}

// Dashboard Reports - Launches
// export function getLaunchesThisMonthOnPageInit(payload) {
//     return {
//         type: SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT,
//         payload
//     };
// }


export function salesforceLaunchesThisMonthPageInit(payload) {
    return {
        type: SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT,
        payload
    };
}


export function salesforceLaunchesThisMonthError(error) {
    return {
        type: SALESFORCE_LAUNCHES_THIS_MONTH_ERROR,
        error,
    };
}

export function salesforceLaunchesThisMonthSuccess(payload) {
    return {
        type: SALESFORCE_LAUNCHES_THIS_MONTH_SUCCESS,
        payload
    };
}



// export function loadDealsThisMonth(payload) {
//     return {
//         type: SALESFORCE_DEALS_THIS_MONTH_PAGE_INIT,
//         payload
//     };
// }

// export function loadDealsThisMonthError(error) {
//     return {
//         type: INITIAL_STATE_LOAD_DEALS_THIS_MONTH_ERROR,
//         error
//     };
// }
// export function loadDealsThisMonthSuccess(payload) {
//     return {
//         type: INITIAL_STATE_LOAD_DEALS_THIS_MONTH_SUCCESS,
//         payload
//     }
// }



// export function loadLaunchesThisMonth(payload) {
//     return {
//         type: SALESFORCE_LAUNCHES_THIS_MONTH_PAGE_INIT,
//         payload
//     };
// }
//
// export function loadLaunchesThisMonthError(error) {
//     return {
//         type: INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH_ERROR,
//         error
//     };
// }
// export function loadLaunchesThisMonthSuccess(payload) {
//     return {
//         type: INITIAL_STATE_LOAD_LAUNCHES_THIS_MONTH_SUCCESS,
//         payload
//     }
// }




export function loadNewCases(payload) {
    return {
        type: SALESFORCE_NEW_CASES_PAGE_INIT,
        payload
    };
}

export function loadNewCasesError(error) {
    return {
        type: INITIAL_STATE_LOAD_NEW_CASES_ERROR,
        error
    };
}
export function loadNewCasesSuccess(payload) {
    return {
        type: INITIAL_STATE_LOAD_NEW_CASES_SUCCESS,
        payload
    }
}




export function loadOpenCases(payload) {
    return {
        type: SALESFORCE_OPEN_CASES_PAGE_INIT,
        payload
    };
}

export function loadOpenCasesError(error) {
    return {
        type: INITIAL_STATE_LOAD_OPEN_CASES_ERROR,
        error
    };
}
export function loadOpenCasesSuccess(payload) {
    return {
        type: INITIAL_STATE_LOAD_OPEN_CASES_SUCCESS,
        payload
    }
}








export function loadAccounts(payload) {
    return {
        type: INITIAL_STATE_LOAD_ACCOUNTS,
        payload
    };
}

export function loadAccountsError(error) {
    return {
        type: INITIAL_STATE_LOAD_ACCOUNTS_ERROR,
        error
    };
}
export function loadAccountsSuccess(payload) {
    return {
        type: INITIAL_STATE_LOAD_ACCOUNTS_SUCCESS,
        payload
    }
}



export function sendCaseInfo(payload) {
    return {
        type: SEND_SALESFORCE_INFO,
        payload
    };
}






export function fetchCasesByAccount() {
    return {
        type: FETCH_SALESFORCES_BY_ACCOUNT,
    };
}
export function fetchCasesByAccountError(error) {
    return {
        type: FETCH_SALESFORCES_BY_ACCOUNT_ERROR,
        error,
    };
}
export function fetchCasesByAccountSuccess(payload) {
    return {
        type: FETCH_SALESFORCES_BY_ACCOUNT_SUCCESS,
        payload
    };
}


export function selectedAccountInfo() {
    return {
        type: SELECTED_ACCOUNT_INFO,
    };
}
export function selectedAccountInfoError(error) {
    return {
        type: SELECTED_ACCOUNT_INFO_ERROR,
        error,
    };
}
export function selectedAccountInfoSuccess(payload) {
    return {
        type: SELECTED_ACCOUNT_INFO_SUCCESS,
        payload
    };
}



export function selectedAccount() {
    return {
        type: SELECTED_ACCOUNT,
    };
}
export function selectedAccountError(error) {
    return {
        type: SELECTED_ACCOUNT_ERROR,
        error,
    };
}
export function selectedAccountSuccess(payload) {
    return {
        type: SELECTED_ACCOUNT_SUCCESS,
        payload
    };
}



export function selectedCase() {
    return {
        type: SELECTED_SALESFORCE,
    };
}
export function selectedCaseError(error) {
    return {
        type: SELECTED_SALESFORCE_ERROR,
        error,
    };
}
export function selectedCaseSuccess(payload) {
    return {
        type: SELECTED_SALESFORCE_SUCCESS,
        payload
    };
}



export function loadCaseInfoToForm() {
    return {
        type: LOAD_SALESFORCE_INFO_TO_FORM,
    };
}
export function loadCaseInfoToFormError(error) {
    return {
        type: LOAD_SALESFORCE_INFO_TO_FORM_ERROR,
        error,
    };
}
export function loadCaseInfoToFormSuccess(payload) {
    return {
        type: LOAD_SALESFORCE_INFO_TO_FORM_SUCCESS,
        payload
    };
}



export function salesforceToUpdate() {
    return {
        type: SALESFORCE_TO_UPDATE,
    };
}
export function salesforceToUpdateError(error) {
    return {
        type: SALESFORCE_TO_UPDATE_ERROR,
        error,
    };
}
export function salesforceToUpdateSuccess(payload) {
    return {
        type: SALESFORCE_TO_UPDATE_SUCCESS,
        payload
    };
}





//-- Basic actions
export function salesforcePageInit(payload) {
    return {
        type: SALESFORCE_PAGE_INIT,
        payload
    };
}


export function salesforceError(error) {
    return {
        type: SALESFORCE_ERROR,
        error,
    };
}

export function salesforceSuccess(payload) {
    return {
        type: SALESFORCE_SUCCESS,
        payload
    };
}

export function salesforceDetailInit(id) {
    return {
        type: SALESFORCE_DETAIL_INIT,
        id 
    };
}

export function salesforceDetailError(error) {
    return {
        type: SALESFORCE_DETAIL_ERROR,
        error,
    };
}

export function salesforceDetailSuccess(payload) {
    return {
        type: SALESFORCE_DETAIL_SUCCESS,
        payload
    };
}

export function salesforceDetailClose() {
    return {
        type: SALESFORCE_DETAIL_CLOSE,
    };
}

export function salesforceDeleteInit(id) {
    return {
        type: SALESFORCE_DELETE_INIT,
        id 
    };
}

export function salesforceStartInit(id) {
    return {
        type: SALESFORCE_START_INIT,
        id
    };
}

export function salesforceStartError(error) {
    return {
        type: SALESFORCE_START_ERROR,
        error,
    };
}

export function salesforceStartSuccess(payload) {
    return {
        type: SALESFORCE_START_SUCCESS,
        payload
    };
}

// export function salesforceStartRequest(payload,id) {
//     return {
//         type: SALESFORCE_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function salesforceDeleteError(error) {
    return {
        type: SALESFORCE_DELETE_ERROR,
        error,
    };
}

export function salesforceDeleteSuccess(payload) {
    return {
        type: SALESFORCE_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}