import React, {Suspense, useEffect, useState} from 'react';
// import SecondDrawer from "../Domain/SecondDrawer";
// import './Domain.css';
import Grid from '@mui/material/Unstable_Grid2';

import DataTableMaterial from "./DataTableMaterial";
import {Box, Tooltip, TextField, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";


function Domain() {
    const [searchDomainName, setsearchDomainName] = useState("");

    const updateNewCollectionInput = (e) => {
        let userInput = e.target.value
        // let userInputValidated = userInput.toLowerCase()
        let userInputValidated = userInput
            .replace(/\s/g, "")
            // .replace(/-/g, "")
            // .replace(/\./g, "")
            .replace(/[&\\#,+()$~%'"*?<>{}]/g, "");
        setsearchDomainName(userInputValidated)
  

    }

    const handleClick = () => {
      
    };

    return (
        <>
            <Grid
                spacing={1}
                container
                sx={{
                    paddingBottom: "20px",
                    width: "100%",
                }}
            >
                <Grid width="100%" align="center">
                    <Tooltip
                        title={`Buy a domain on behalf of a client. The charge will go to vizisites directly`}
                        arrow
                        enterDelay={300}
                        leaveDelay={200}
                        TransitionComponent={Fade}
                        TransitionProps={{timeout: 300}}
                    >
                        <InfoIcon
                            sx={{marginLeft: "160px", position: "absolute"}}
                            fontSize={"small"}
                            color="info"
                        />
                    </Tooltip>
                    <Typography variant="h6">Domain Registration</Typography>
                </Grid>

                <Grid container>
                <FormControl sx={{ width: "500px", marginTop: "20px" }}>
              <TextField
                sx={{ marginLeft: "50px" }}
                size="small"
                id="searchDomainName"
                name="searchDomainName"
                label="Search for a domain"
                variant="outlined"
                onChange={(e) => {
                  updateNewCollectionInput(e);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      // checking if enter was pressed and shift wasn't held down
                      e.preventDefault(); // prevent the newline character from being entered
                    //   FormHandle(); // call the FormHandle function
                    }
                  }}
                // value={collectionNameSelected}
                value={searchDomainName}
              />
              <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Search
            </Button>
              </FormControl>
                    {/* <DataTableMaterial /> */}
                </Grid>
            </Grid>
        </>
    );
}





export default Domain