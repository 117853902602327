import Roles from '../../helpers/Roles'
// import React, { lazy } from 'react';



/*
* Material Icons
* */
import LockOpenIcon from '@mui/icons-material/LockOpen';
import InsightsIcon from '@mui/icons-material/Insights';
import ContactPageIcon from '@mui/icons-material/ContactPage';
// import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
// import MapIcon from '@mui/icons-material/Map';
// import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import ConstructionIcon from '@mui/icons-material/Construction';
// import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
// import LeaderboardIcon from '@mui/icons-material/Leaderboard';
// import HighlightIcon from '@mui/icons-material/Highlight';
// import MultilineChartIcon from '@mui/icons-material/MultilineChart';
// import Fingerprint from '@mui/icons-material/Fingerprint';
// import CallMergeIcon from '@mui/icons-material/CallMerge';
import UserUnlockables from "./UserUnlockables";
import Goals from "./Goals";
import TeamGoals from "./TeamGoals";
import CommissionCalculator from "./CommissionCalculator";
// import StorageIcon from '@mui/icons-material/Storage';
// import StagingList from "./StagingList";
// import StagingForm from "./StagingForm";



/*
* Route config object supports all react-router's route element props with some additional props ie: (name, permission, children)
* you can add or remove props from config object it's means it is super customizable and support upto N nesting, child routes must follow the same parent shape,
* it means the config object is same for both there is no additional key for child nor for parent.
* you can find route props from here https://reactrouter.com/web/api/Route let's take a look at some additional props
* 1. permission: permission is an optional prop and it's type is an array of roles, permission is used for allowing certain users/roles
*  	to have access of that route and if you skip/omit the permission or it's an empty array that means every authenticated user/role have access to that route.
* 2. name: name is an optional prop and it's type is a string|node it is used for mapping route link into a navigation
* 3. children: children is also an optional prop and it's type is an array of route config objects, children are used for nested routes
* */

/*
* SUPER_ADMIN has access to all of the app
* ADMIN has access to all of the app except managing users
* ACCOUNT_MANAGER has access to service team related work
* SALES_MANAGER is not an IMC, has access to some of the Scraper
*/

// export default {
// 	SUPER_ADMIN: 'SUPER_ADMIN',
// 	ADMIN: 'ADMIN',
// 	ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
// 	SALES_MANAGER: 'SALES_MANAGER',
// 	GUEST: 'GUEST'
// };


const SecondaryDrawerNavigationRoutePermissions = [
    {
        element: UserUnlockables,
        path: '/app/user',
        name: 'Profile',
        MenuIcon: ContactPageIcon,
		MenuToolTip: "Profile",
		permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.ACCOUNT_MANAGER,
            Roles.SALES_MANAGER,
            Roles.SALES_IMC,
        ],
    },
    // {
    //     element: Goals,
    //     path: '/app/user/goals',
    //     name: 'Goals',
    //     MenuIcon: InsightsIcon,
	// 	MenuToolTip: "Goals",
	// 	permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         Roles.ACCOUNT_MANAGER,
    //         Roles.SALES_MANAGER,
    //         Roles.SALES_IMC,
    //     ],
    // },
    // {
    //     element: TeamGoals,
    //     path: '/app/user/teamgoals',
    //     name: 'Team Goals',
    //     MenuIcon: InsightsIcon,
	// 	MenuToolTip: "Team Goals",
	// 	permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         // Roles.ACCOUNT_MANAGER,
    //         // Roles.SALES_MANAGER,
    //         // Roles.SALES_IMC,
    //     ],
    // },
]

export default SecondaryDrawerNavigationRoutePermissions