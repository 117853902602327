import { createSlice } from '@reduxjs/toolkit'

let userSlice = createSlice({
    name: 'user',
    initialState: {name: ''},
    // initialState: null,
    reducers: {
        insertUser: (state, action) => {
            return action.payload
        },
        emptyUser: (state, action) => {
            return null
        }
    }
})

export const { insertUser, emptyUser } = userSlice.actions

export default userSlice.reducer

// -----------------------------------------------------------------
// import {combineReducers} from "redux";
// export const INSERT_USER = 'user/insertUser';
// export const EMPTY_USER = 'user/emptyUser';
//
// export const insertUser = payload => ({
//     type: INSERT_USER,
//     payload,
// });
//
// export const emptyUser = () => ({
//     type: EMPTY_USER,
// });
//
// const initialState = {
//     name: 'user'
// };
//
// // const userGPTReducer = function(state = initialState, action){
// export default function(state = initialState, action){
//     switch(action.type){
//         case INSERT_USER:
//             return action.payload;
//         case EMPTY_USER:
//             return null;
//         default:
//             return state;
//     }
// }

// export default combineReducers({
//     user : userGPTReducer,
// });