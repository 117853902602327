import {
    LOGIN_PAGE_INIT,
    LOGIN_ERROR,
    LOGIN_REQUESTING,
    GOOGLE_LOGIN_REQUESTING,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_ERROR,
    LOGIN_SUCCESS,
} from './actions';

// The initial state of the Login Reducer
export const initialState = {
    id: '',
    password: '',
    user:'',
    requesting: false,
    successful: false,
    messages: [],
    errors: {},
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GOOGLE_LOGIN_REQUESTING:
            return {...state, requesting: true};
        case GOOGLE_LOGIN_SUCCESS:
            return {...state, successful: true, user: {...actions.payload}};
        case GOOGLE_LOGIN_ERROR:
            return {...state, successful: false, errors: {...actions.error}};
        case LOGIN_PAGE_INIT:
            return {...state, errors: {}};
        case LOGIN_REQUESTING:
            return {...state, requesting: true};
        case LOGIN_SUCCESS:
            return {...state, successful: true, user: {...actions.payload}};
        case LOGIN_ERROR:
            return {...state, successful: false, errors: {...actions.error}};
        default:
            return state;
    }
}