import {createContext, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {parseJwt, refreshTokenSetup} from "./helpers";
import {useDispatch} from "react-redux";
import {googleloginRequest} from "../modules/Login/actions";
import axios from "axios";

export const AuthContext = createContext(false);



const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [authorized, setAuthorized] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const getGoogleLoginRequest = (payload) => dispatch(googleloginRequest(payload));





    const checkAuthorization = () => {
        // const storedGoogleToken = localStorage.getItem('g_tokenId');
        const storedToken = localStorage.getItem('token');

        // console.log(`checkAuthorization storedToken: `, storedToken)
        // console.log(`checkAuthorization storedGoogleToken: `, storedGoogleToken)

        if (storedToken) {
        // if (storedGoogleToken && storedToken) {
            const tokenPayload = parseJwt(storedToken);
            const expiration = new Date(tokenPayload.exp * 1000).getTime();
            const current = new Date().getTime();
            if (current > expiration) return false;
            return true;
        }
        return false;
    };


    //  const refreshTokenSetup = (res) => {
    //     // Timing to renew access token
    //     let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
    //
    //     const refreshToken = async () => {
    //         const newAuthRes = await res.reloadAuthResponse();
    //         refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    //         // console.log('newAuthRes:', newAuthRes);
    //         // saveUserToken(newAuthRes.access_token);  <-- save new token
    //         localStorage.setItem('authToken', newAuthRes.id_token);
    //
    //         // Set up the other timer after the first one
    //         setTimeout(refreshToken, refreshTiming);
    //     };
    //
    //     // Setup first refresh timer
    //     setTimeout(refreshToken, refreshTiming);
    // };
    //


    const onSuccess = async ({ access_token }) => {

            // const userInfo = await axios.get(
            //     'https://www.googleapis.com/oauth2/v3/userinfo',
            //     { headers: { Authorization: `Bearer ${access_token}` } },
            // );
            //
            // console.log(userInfo);

        // refreshTokenSetup(res);
        // BACK UP PLAN FOR INCONSISTENCY OF USER DATA IN LOCAL STORAGE
        // localStorage.removeItem('user');
        // localStorage.removeItem('roles');
        // localStorage.removeItem('token');
        // localStorage.removeItem('g_googleId');
        // localStorage.removeItem('g_v_email');
        // localStorage.removeItem('g_tokenId');
        // localStorage.setItem('g_googleId', res.profileObj.googleId);
        // localStorage.setItem('g_v_email', res.profileObj.email);
        // localStorage.setItem('g_tokenId', res.tokenId);
        //This just authenticates with the backend, sending the information of the user back there and then receives the database user information to then set in the localstorage of the browser
        // getGoogleLoginRequest({
        //     googlecode: {
        //         token: res.credential
        //     },
        //     // profileObj: res.profileObj
        // })
    };




    const handleLogin = async () => {
        // const token = await fakeAuth();
        setToken(token);
        navigate('/');
    };

    const handleLogout = () => {
        setToken(null);
    };



    const value = {
        token,
        authorized,
        checkAuthorization: checkAuthorization,
        onSuccess: onSuccess,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
        {/*<AuthContext.Provider value={value}>*/}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const ctx = useContext(AuthContext)

    if (!ctx) {
        console.log("need auth provider");
        // throw new Error("need role provider");
    }
    return ctx
}

export default AuthProvider