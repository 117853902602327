import React, {memo, useEffect, useState} from 'react';
import {
    DataGridPro,
    GridActionsCellItem,
    GridToolbar, GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import {Box, Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { clientCaseListInit, clientCaseListRefreshInit} from "./actions";
import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";

// import Typography from "@mui/material/Typography";


function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small"/>,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{visibility: props.value ? 'visible' : 'hidden'}}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};


function RefreshListButton() {
    const dispatch = useDispatch();
    const clientCaseListRefreshInitiation = () => dispatch(clientCaseListRefreshInit());
    let currentUser = JSON.parse(localStorage.getItem("user"));
    let currentRoleOfUser = currentUser.roles

    return (
        <>
            {currentRoleOfUser == 'SUPER_ADMIN' ? (
                <Box
                    sx={{
                        p: 0.5,
                        pb: 0,
                    }}
                >
                    <Button size="small" onClick={clientCaseListRefreshInitiation}>
                        {/*<Typography>*/}
                        Purge & Refresh List
                        {/*</Typography>*/}
                    </Button>
                </Box>
            ) : (<></>)
            }
        </>
    )
}


function CustomToolbar(props) {
    return (
        <GridToolbarContainer>
            <QuickSearchToolbar clearSearch={props.clearSearch} onChange={props.onChange} value={props.value}/>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <RefreshListButton/>
            {/*<GridToolbarDensitySelector />*/}
        </GridToolbarContainer>
    );
}


function DataTableMaterial() {
    const dispatch = useDispatch();
    const getClientCases = () => dispatch(clientCaseListInit());
    const clientCaseListRefreshInitiation = () => dispatch(clientCaseListRefreshInit());
    let currentUser = JSON.parse(localStorage.getItem("user"));

    const clientCases = useSelector((state) => state.clientCase.list_clientCases.clientCases)




    const apiRef = useGridApiRef();

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellFocusOut = (params, event) => {
        event.defaultMuiPrevented = true;
    };


    const handleLoginClick = (id) => async (event) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        console.log('row for logging in:')
        console.log(row)

        try {


            const response = await axios.post(`https://${row.domain}/wp-json/vizilogin/v1/login/`, {
                username: 'viziadmin',
                password: 'Noj9*K3s$8tu$afvh3vW0$LD',
            });




            // const responseForLogin = await axios.post(`https://${row.domain}/wp-json/nopassauth/v1/login/`, {
            //     username: 'ARyxGW44e'
            //     // username: row.wordpress_username
            // });

            if (response.data.success) {
                // setMessage("Logged in successfully!");
                // Open a new browser tab with the WordPress admin URL
                window.open(`https://www.${row.domain}/wp-admin`, '_blank');
            } else {
                // setMessage("Login failed.");
            }

            // browserRedirectNewTab('')
            // console.log(`siteLogin :`)
            // console.log(siteLogin)
        } catch (error) {
            // setMessage(error.response.data.message);
        }
        ;
    }

        let widthOfCol = 150;

    const col = React.useMemo(
        () => [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Login',
                maxWidth: 100,
                getActions: ({id}) => {
                    return [
                        <GridActionsCellItem
                            icon={<OpenInNewIcon/>}
                            label="Open"
                            // className={classes.textPrimary}
                            onClick={handleLoginClick(id)}
                            color="inherit"
                            // disabled
                        />,
                    ];
                },
            },
            // {
            //     field: 'domain',
            //     minWidth: widthOfCol,
            //     width: 100,
            //     headerName: 'Domain'
            // },
            // {
            //     field: 'diskused',
            //     editable: false,
            //     minWidth: widthOfCol,
            //     width: 100,
            //     headerName: 'Disk Used',
            //     hide: true
            // },
            ],
            [handleLoginClick],
            );


        useEffect(() => {

            //TODO UNCOMMENT THIS, IT WAS JUST FOR TESTING TO PREVENT CONSTANT CALLS TO THE SERVER
            getClientCases();
        }, [])

        useEffect(() => {

        }, [clientCases])


        const data = {
            rows: clientCases,
            columns: col
        }

        const [searchText, setSearchText] = useState('');
        const [rows, setRows] = useState(data.rows);

        const requestSearch = (searchValue) => {
            setSearchText(searchValue);
            // console.log(`searchValue: ${searchValue}`)
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = data.rows.filter((row) => {
                return Object.keys(row).some((field) => {
                    return searchRegex.test(row[field].toString());
                });
            });
            setRows(filteredRows);
        };

        useEffect(() => {
            setRows(data.rows);
        }, [data.rows]);


        return (
            <Box sx={{height: "80vh", width: '90vw'}}>
                <Box sx={{display: 'flex', height: '100%'}}>
                    <Box sx={{height: "80vh", width: '90vw'}}>
                    {/*<Box style={{flexGrow: 1}}>*/}

                        <PrettyPrintJson data={rows} />

                        {/*<DataGridPro*/}
                        {/*    apiRef={apiRef}*/}
                        {/*    columns={col}*/}
                        {/*    rows={rows}*/}
                        {/*    getRowId={(row) => row._id}*/}
                        {/*    onRowEditStart={handleRowEditStart}*/}
                        {/*    onRowEditStop={handleRowEditStop}*/}
                        {/*    onCellFocusOut={handleCellFocusOut}*/}
                        {/*    slots={{*/}
                        {/*        // toolbar: GridToolbar*/}
                        {/*        toolbar: CustomToolbar*/}
                        {/*        // Toolbar: QuickSearchToolbar*/}
                        {/*        // Toolbar: EditToolbar,*/}
                        {/*    }}*/}
                        {/*    slotProps={{*/}
                        {/*        // toolbar: { apiRef },*/}
                        {/*        toolbar: {*/}
                        {/*            value: searchText,*/}
                        {/*            onChange: (event) => requestSearch(event.target.value),*/}
                        {/*            clearSearch: () => requestSearch(''),*/}
                        {/*            apiRef*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    initialState={{*/}
                        {/*        sorting: {*/}
                        {/*            sortModel: [*/}
                        {/*                {*/}
                        {/*                    field: 'domain',*/}
                        {/*                    sort: 'asc',*/}
                        {/*                },*/}
                        {/*            ],*/}
                        {/*        },*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Box>
                </Box>
            </Box>
        );

    }


// export default memo(DataTableMaterial)
export default DataTableMaterial