import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import {withRouter} from '../../helpers/withRouter'
// import {Link} from 'react-router-dom';
// import './userUnlockables.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
    userUnlockablesAddPageInit,
    // userUnlockablesAddRequest,
    // userUnlockablesDetailClose,
    // userUnlockablesDeleteInit,
    // // releaseStateData,
    // userUnlockablesStartInit
} from './actions';


// import DataTableMaterial from "./DataTableMaterial";
import {Box, Button} from "@mui/material";
// import SecondDrawer from "../Client/SecondDrawer";
// import Map from "../Map";
// import TableSelect from "../NationState/TableSelect";


function UnlockableThemes(props) {

    const [selectedTerritory, setSelectedTerritory] = React.useState('');
    const [selectedData, setSelectedData] = React.useState('');

    /* The variable after "list_userUnlockables" corresponds to what the reducer returns
    *    */
    // const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockables)
    // const userUnlockables = useSelector((state) => state.userUnlockables)
    // const selectedUnlockableThemes = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUnlockableThemes)
    // const errors = useSelector((state) => state.userUnlockables.list_userUnlockables.errors)
    // const selectedUnlockableThemesError = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUnlockableThemesError)
    // const deleteUnlockableThemes = useSelector((state) => state.userUnlockables.list_userUnlockables.deleteUnlockableThemes)

    const dispatch = useDispatch();
    // const getUnlockableThemess = () => dispatch(userUnlockablesAddPageInit());
    // const getUnlockableThemesDetail = (id) => dispatch(userUnlockablesAddRequest(id));
    // getUnlockableThemesStartDetail: (id) => dispatch(userUnlockablesAddRequest(id));
    // const detailModalClose = () => dispatch(userUnlockablesDetailClose());
    // const deleteUnlockableThemesRequest = (id) => dispatch(userUnlockablesDeleteInit(id));
    // const startUnlockableThemesRequest = (id) => dispatch(userUnlockablesStartInit(id));
    // const releaseData = () => dispatch(releaseStateData());


    // const [isForDelete, setIsForDelete] = useState(false)
    // const [isForStart, setIsForStart] = useState(false)

    // const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockabless)
    // const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockables)
    // const selectedUnlockableThemes = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUnlockableThemes)
    // const errors = useSelector((state) => state.userUnlockables.list_userUnlockables.errors)
    // const selectedUnlockableThemesError = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUnlockableThemesError)
    // const deleteUnlockableThemes = useSelector((state) => state.userUnlockables.list_userUnlockables.deleteUnlockableThemes)

    useEffect(() => {
        // getUnlockableThemess();
        // console.log(userUnlockables)
        // dispatch(userUnlockablesAddPageInit())
    }, [])

    // Runs on exit of component
    // useEffect(() => {

    // return function cleanup() {
    //     mapDispatchToProps.releaseData()
    // Side-effect cleanup...
    // };
    // }, []);

    const handleChangeTerritory = (event) => {
        let id = event.target.value.toLowerCase()
        // console.log(`lowerCaseName = ${id}`)
        setSelectedTerritory(event.target.value);
        setSelectedData(id)
    };

    const handleChangeMapSelection = (statename) => {
        let id = statename.toLowerCase()
        // console.log(`lowerCaseName = ${id}`)
        setSelectedTerritory(statename);
        // setSelectedData(id)
    };

    const themeMinWidth = 325
    const themeMaxWidth = 345

    return (
            <Box id="themeUnlockablesBox"
                 sx={{
                paddingTop: "20px",
                marginTop: "100px",
                marginBottom: "100px",
                paddingBottom: "20px",
                maxWidth: "100%",


                // maxWidth: "82vw"
            }}>
                {/*<Map selectedTerritory={selectedTerritory} handleChangeMapSelection={handleChangeMapSelection}/>*/}

                <Typography gutterBottom variant="h5" component="div">Themes</Typography>
                <Box id="themesBox" sx={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: 'row',
                    alignContent: "flex-start",
                    flexWrap: "wrap",
                    height: "auto",
                }}>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingBottom: "20px",
                            maxWidth: "82vw",
                            // display: "flex",
                        }}>
                        <Card sx={{maxWidth: 345, minWidth: 325}}>
                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    height="140"*/}
                            {/*    image=""*/}
                            {/*    alt="theme example"*/}
                            {/*/>*/}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    default
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    This is the default theme
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button disabled size="small">switch</Button>
                            </CardActions>
                        </Card>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingBottom: "20px",
                            maxWidth: "82vw"
                        }}>
                        <Card sx={{maxWidth: themeMaxWidth, minWidth: themeMinWidth}}>
                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    height="140"*/}
                            {/*    image=""*/}
                            {/*    alt="theme example"*/}
                            {/*/>*/}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    nite owl
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    unlock this theme by logging in after 5pm
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button disabled size="small">switch</Button>
                            </CardActions>
                        </Card>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingBottom: "20px",
                            maxWidth: "82vw"
                        }}>
                        <Card sx={{maxWidth: themeMaxWidth, minWidth: themeMinWidth}}>
                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    height="140"*/}
                            {/*    image=""*/}
                            {/*    alt="theme example"*/}
                            {/*/>*/}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    <em>typewriter</em>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    unlock this theme by tabbing to menu items
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button disabled size="small">switch</Button>
                            </CardActions>
                        </Card>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingBottom: "20px",
                            maxWidth: "82vw"
                        }}>
                        <Card sx={{maxWidth: themeMaxWidth, minWidth: themeMinWidth}}>
                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    height="140"*/}
                            {/*    image=""*/}
                            {/*    alt="theme example"*/}
                            {/*/>*/}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Custom
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    unlock this theme by being top producer on your team one month
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button disabled size="small">switch</Button>
                                <Button disabled size="small">create</Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Box>
    );

    // }
}


/* Receives an Array */
// const UnlockableThemesRow = (props) => {
// function UnlockableThemesRow(props) {
// console.log(props)
// const [isForDelete, setIsForDelete] = useState(false)
// const [isForStart, setIsForStart] = useState(false)
// const [userUnlockables, setUnlockableThemess] = useState([])
// useEffect(() => {
// }, [handleModalShow])

// const dispatch = useDispatch();

// const handleModalStartShowClick = modalType => {
//     return event => {
//         // this is taking the elements attribute : data-id
//         // dispatch(userUnlockablesAddRequest(event.currentTarget.dataset.id))
//         // dispatch(getUnlockableThemesDetail(event.currentTarget.dataset.id));
//
//         if (modalType === "view") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForStart(true)
// 			// return (
// 			// 	<UnlockableThemesModal />
// 			// )
//         }
//         if (modalType === "userUnlockables") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForStart(true)
//         }
//         if (modalType === "delete") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForDelete(true)
//
//
//         }
//     }
// }

//     if (props.list.length === 0) {
//     	return (<tr>
//     		<td colSpan="4">No userUnlockables present</td>
//     	</tr>)
//     } else {
//     	return props.list.map(function (data, id) {
//     		return (
//     			<tr key={id}>
//     				<th scope="row">{id + 1}</th>
//     				<td>{data.userUnlockablesAddress}</td>
//     				<td>{data.liveAddress}</td>
//     				<td style={{"padding": "5px"}}>
//     					<button type="button"
//     							className="btn btn-outline-info mr-3"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#exampleModal"
//     							onClick={<UnlockableThemesModal modalType={"view"} data-id={data._id}/>}>View
//     					</button>
//     					<button type="button"
//     							className="btn btn-outline-info mr-3"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#userUnlockablesModal"
//     							data-actiontype="userUnlockables"
//     							onClick={<UnlockableThemesModal prop={"userUnlockables"}/>}>UnlockableThemes Pad
//     					</button>
//     					<Link to={"/manage-userUnlockables/" + data._id}
//     						  className="btn btn-outline-warning mr-3">Update</Link>
//     					<button type="button"
//     							className="btn btn-outline-danger"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#exampleModal"
//     							data-actiontype="delete"
//     							onClick={<UnlockableThemesModal prop={"delete"}/>}>Delete
//     					</button>
//     				</td>
//     			</tr>
//     		);
//     	});
//     }
// }


UnlockableThemes.propTypes = {
    userUnlockables: PropTypes.array
};

// const userUnlockables = useSelector((state) => state.userUnlockables.list_userUnlockables.userUnlockables)
// const selectedUnlockableThemes = useSelector((state) => state.userUnlockables.list_userUnlockables.selectedUnlockableThemes)
// const errors = useSelector((state) => state.userUnlockables.list_userUnlockables.errors)
// const selectedUnlockableThemesError = useSelector((state) =>
// state.userUnlockables.list_userUnlockables.selectedUnlockableThemesError)
// const deleteUnlockableThemes = useSelector((state) => state.userUnlockables.list_userUnlockables.deleteUnlockableThemes)

// function mapStateToProps(state) {
// 	return {
// 		userUnlockables: state.userUnlockables.list_userUnlockables.userUnlockables,
// 		selectedUnlockableThemes: state.userUnlockables.list_userUnlockables.selectedUnlockableThemes,
// 		errors: state.userUnlockables.list_userUnlockables.errors,
// 		selectedUnlockableThemesError: state.userUnlockables.list_userUnlockables.selectedUnlockableThemesError,
// 		deleteUnlockableThemes: state.userUnlockables.list_userUnlockables.deleteUnlockableThemes
// 	};
// }

// function mapDispatchToProps(dispatch) {
// 	return {
// 		getUnlockableThemess: () => dispatch(userUnlockablesAddPageInit()),
// 		getUnlockableThemesDetail: (id) => dispatch(userUnlockablesAddRequest(id)),
// 		// getUnlockableThemesStartDetail: (id) => dispatch(userUnlockablesAddRequest(id)),
// 		detailModalClose: () => dispatch(userUnlockablesDetailClose()),
// 		deleteUnlockableThemesRequest: (id) => dispatch(userUnlockablesDeleteInit(id)),
// 		startUnlockableThemesRequest: (id) => dispatch(userUnlockablesStartInit(id)),
// 		releaseData: () => dispatch(releaseStateData()),
// 	};
// }

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps,
// )(withRouter(UnlockableThemes));

export default UnlockableThemes