export const CLIENTCASE_PAGE_INIT = 'CLIENTCASE_PAGE_INIT';
export const CLIENTCASE_ERROR = 'CLIENTCASE_ERROR';
export const CLIENTCASE_SUCCESS = 'CLIENTCASE_SUCCESS';
// export const CLIENTCASE_DETAIL_INIT = 'CLIENTCASE_DETAIL_INIT';
// export const CLIENTCASE_DETAIL_ERROR = 'CLIENTCASE_DETAIL_ERROR';
// export const CLIENTCASE_DETAIL_SUCCESS = 'CLIENTCASE_DETAIL_SUCCESS';
// export const CLIENTCASE_DETAIL_CLOSE = 'CLIENTCASE_DETAIL_CLOSE';
// export const CLIENTCASE_DELETE_INIT = 'CLIENTCASE_DELETE_INIT';
// export const CLIENTCASE_DELETE_ERROR = 'CLIENTCASE_DELETE_ERROR';
// export const CLIENTCASE_DELETE_SUCCESS = 'CLIENTCASE_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const CLIENTCASE_LIST_INIT = 'CLIENTCASE_LIST_INIT';
export const CLIENTCASE_LIST_SUCCESS = 'CLIENTCASE_LIST_SUCCESS';
export const CLIENTCASE_LIST_ERROR = 'CLIENTCASE_LIST_ERROR';

export const CLIENTCASE_UPDATE_INIT = 'CLIENTCASE_UPDATE_INIT';
export const CLIENTCASE_UPDATE_SUCCESS = 'CLIENTCASE_UPDATE_SUCCESS';
export const CLIENTCASE_UPDATE_ERROR = 'CLIENTCASE_UPDATE_ERROR';

export const CLIENTCASE_LAUNCH_INIT = 'CLIENTCASE_LAUNCH_INIT';
export const CLIENTCASE_LAUNCH_SUCCESS = 'CLIENTCASE_LAUNCH_SUCCESS';
export const CLIENTCASE_LAUNCH_ERROR = 'CLIENTCASE_LAUNCH_ERROR';

export const CLIENTCASE_START_INIT = 'CLIENTCASE_START_INIT';
export const CLIENTCASE_START_REQUESTING = 'CLIENTCASE_START_REQUESTING';
export const CLIENTCASE_START_SUCCESS = 'CLIENTCASE_START_SUCCESS';
export const CLIENTCASE_START_ERROR = 'CLIENTCASE_START_ERROR';
export const CLIENTCASE_LIST_REFRESH_INIT = 'CLIENTCASE_LIST_REFRESH_INIT';
export const CLIENTCASE_LIST_REFRESH_SUCCESS = 'CLIENTCASE_LIST_REFRESH_SUCCESS';
// export const CLIENTCASE_START_PAGE_INIT = 'CLIENTCASE_START_PAGE_INIT';



export function clientCaseListRefreshInit() {
    return {
        type: CLIENTCASE_LIST_REFRESH_INIT
    };
}

export function clientCaseListRefreshSuccess(payload) {
    return {
        type: CLIENTCASE_LIST_REFRESH_SUCCESS,
        payload
    };
}

export function clientCaseListInit() {
    return {
        type: CLIENTCASE_LIST_INIT
    };
}


export function clientCaseListSuccess(payload) {
    return {
        type: CLIENTCASE_LIST_SUCCESS,
        payload
    };
}

export function clientCaseListError(payload) {
    return {
        type: CLIENTCASE_LIST_ERROR,
        payload
    };
}



export function clientCaseUpdateInit(payload) {
    return {
        type: CLIENTCASE_UPDATE_INIT,
        payload
    };
}

export function clientCaseUpdateSuccess(payload) {
    return {
        type: CLIENTCASE_UPDATE_SUCCESS,
        payload
    };
}

export function clientCaseUpdatetError(payload) {
    return {
        type: CLIENTCASE_UPDATE_ERROR,
        payload
    };
}

// Currently the Launch action
export function clientCaseLaunchInit(payload) {
    return {
        type: CLIENTCASE_LAUNCH_INIT,
        payload
    };
}

export function clientCaseLaunchSuccess(payload) {
    return {
        type: CLIENTCASE_LAUNCH_SUCCESS,
        payload
    };
}

export function clientCaseLaunchtError(payload) {
    return {
        type: CLIENTCASE_LAUNCH_ERROR,
        payload
    };
}








export function clientCasePageInit() {
    return {
        type: CLIENTCASE_PAGE_INIT,
    };
}

export function clientCaseError(error) {
    return {
        type: CLIENTCASE_ERROR,
        error,
    };
}

export function clientCaseSuccess(payload) {
    return {
        type: CLIENTCASE_SUCCESS,
        payload
    };
}


export function clientCaseStartError(error) {
    return {
        type: CLIENTCASE_START_ERROR,
        error,
    };
}

export function clientCaseStartSuccess(payload) {
    return {
        type: CLIENTCASE_START_SUCCESS,
        payload
    };
}
