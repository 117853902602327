export const TERRITORY_PAGE_INIT = 'TERRITORY_PAGE_INIT';
export const TERRITORY_ERROR = 'TERRITORY_ERROR';
export const TERRITORY_SUCCESS = 'TERRITORY_SUCCESS';
export const TERRITORY_DETAIL_INIT = 'TERRITORY_DETAIL_INIT';
export const TERRITORY_DETAIL_ERROR = 'TERRITORY_DETAIL_ERROR';
export const TERRITORY_DETAIL_SUCCESS = 'TERRITORY_DETAIL_SUCCESS';
export const TERRITORY_DETAIL_CLOSE = 'TERRITORY_DETAIL_CLOSE';
export const TERRITORY_DELETE_INIT = 'TERRITORY_DELETE_INIT';
export const TERRITORY_DELETE_ERROR = 'TERRITORY_DELETE_ERROR';
export const TERRITORY_DELETE_SUCCESS = 'TERRITORY_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export function territoryPageInit() {
    return {
        type: TERRITORY_PAGE_INIT,
    };
}

export function territoryError(error) {
    return {
        type: TERRITORY_ERROR,
        error,
    };
}

export function territorySuccess(payload) {
    return {
        type: TERRITORY_SUCCESS,
        payload
    };
}

export function territoryDetailInit(id) {
    return {
        type: TERRITORY_DETAIL_INIT,
        id
    };
}

export function territoryDetailError(error) {
    return {
        type: TERRITORY_DETAIL_ERROR,
        error,
    };
}

export function territoryDetailSuccess(payload) {
    return {
        type: TERRITORY_DETAIL_SUCCESS,
        payload
    };
}

export function territoryDetailClose() {
    return {
        type: TERRITORY_DETAIL_CLOSE,
    };
}

export function territoryDeleteInit(id) {
    return {
        type: TERRITORY_DELETE_INIT,
        id
    };
}

export function territoryDeleteError(error) {
    return {
        type: TERRITORY_DELETE_ERROR,
        error,
    };
}

export function territoryDeleteSuccess(payload) {
    return {
        type: TERRITORY_DELETE_SUCCESS,
        payload
    };
}

export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}
