// import {put, all, call, takeLatest} from "redux-saga/effects";
// import {
//   CALL_CHAT_INIT,
//   callChatInitSuccess
// } from "./actions";


// import { request } from '../../helpers/requests';
// import { urls } from '../../helpers/urls';
// import {INSERT_NEW} from "./redux/messages";


//Client API calls
// function callChatInitChat(payload) {
//   console.log(`payload from  `);
//   console.log(payload);
//   return request('put', urls.OPENAI + '/', payload);
// }



// Chat Workers
// function* callChatInitWorker(payload) {
//   try {
//     let response = yield call(callChatInitChat, payload);
//     response = response.data;
//     // response = response.data.data;
//     console.log(`response from server `);
//     console.log(response);
//
//     // yield put(callChatInitSuccess(response));
//   } catch (err) {
//     // yield put(roomError(err.response.data.data));
//   }
// }


// Chat Watcher
export default function* openAISaga() {
  // yield all([
  //   // takeLatest(INSERT_NEW, callChatInitWorker),
  // ]);
}