import {
    CLIENTSITE_PAGE_INIT,
    CLIENTSITE_ERROR,
    CLIENTSITE_SUCCESS,
    CLIENTSITE_LIST_INIT,
    CLIENTSITE_LIST_SUCCESS,
    CLIENTSITE_LIST_ERROR,
    CLIENTSITE_UPDATE_SUCCESS,
    CLIENTSITE_UPDATE_ERROR,
    CLIENTSITE_LAUNCH_SUCCESS,
    CLIENTSITE_LAUNCH_ERROR,
    CLIENTSITE_START_ERROR,
    CLIENTSITE_START_SUCCESS,
    RELEASE_STATE_DATA, CLIENTSITE_LIST_REFRESH_SUCCESS
} from './actions';
import { combineReducers } from "redux";


// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    clientSites: [],
    selectedClientSite: {},
    selectedClientSiteError: {},
    deleteClientSite: {}
  };

const clientSiteReducers = function(state = initialState,actions){
    switch(actions.type){
        case CLIENTSITE_PAGE_INIT:
            return {...state, errors:[], clientSites: []};
        case CLIENTSITE_SUCCESS:
            return {...state, successful: true, clientSites:[...actions.payload]};
        case CLIENTSITE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};





        case CLIENTSITE_LIST_INIT:
            return {...state, errors:[], clientSites: []};

        case CLIENTSITE_LIST_SUCCESS:
            return {...state, successful: true, clientSites:[...actions.payload]};

        case CLIENTSITE_LIST_REFRESH_SUCCESS:
            return {...state, successful: true, clientSites:[...actions.payload]};

        case CLIENTSITE_LIST_ERROR:
            return {...state, successful: false, errors:[...actions.error]};








        // case CLIENTSITE_UPDATE_INIT:
        //     return {...state, errors:[], clientSites: []};

        case CLIENTSITE_UPDATE_SUCCESS:
            return {...state, successful: true, clientSites:[...actions.payload]};

        case CLIENTSITE_UPDATE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};



        case CLIENTSITE_LAUNCH_SUCCESS:
            return {...state, successful: true, clientSites:[...actions.payload]};

        case CLIENTSITE_LAUNCH_ERROR:
            return {...state, successful: false, errors:[...actions.error]};



        // case CLIENTSITE_DETAIL_INIT:
        //     return {...state, selectedClientSiteError:{}, selectedClientSite: {}};
        // case CLIENTSITE_DETAIL_SUCCESS:
        //     return {...state, selectedClientSite: {...actions.payload}};
        // case CLIENTSITE_DETAIL_ERROR:
        //     return {...state, selectedClientSiteError:{...actions.error}};
        // case CLIENTSITE_DETAIL_CLOSE:
        //     return {...state, selectedClientSiteError:{}, selectedClientSite: {}};
        // case CLIENTSITE_DELETE_SUCCESS:
        //     return {...state, deleteClientSite: {...actions.payload}};
        // case CLIENTSITE_DELETE_ERROR:
        //     return {...state, selectedClientSiteError:{...actions.error}};
        case CLIENTSITE_START_SUCCESS:
            return {...state, startClientSite: {...actions.payload}};
        case CLIENTSITE_START_ERROR:
            return {...state, selectedClientSiteError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], clientSites: [], selectedClientSite: {},selectedClientSiteError: {},deleteClientSite: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_clientSite : clientSiteReducers
});