import {Avatar, Button, ButtonBase, Card, CardHeader, Grid} from "@mui/material";
import React, {memo, useEffect} from "react";
// import {useStyles} from "../Dashboard/LeaderboardStyles";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import './usercard.css'
// import styled from 'styled-components'
import {useDispatch, useSelector} from "react-redux";
// import {getUserCardImageInit, userDetailInit} from "./actions";
// import {useEffect} from "@types/react";

// const defaultTheme = createTheme();
// const useStyles = makeStyles(
//     (theme) => {
//         const getBackgroundColor = (color) =>
//             theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
//
//         const getHoverBackgroundColor = (color) =>
//             theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
//
//         return {
//             root: {
//                 '& div.MuiDataGrid-root': {
//                     border: "none",
//                     backgroundColor: "grey",
//                     // fontSize: 18,
//                     // color: "white",
//                     // borderRadius: "5px",
//                     // border: "none",
//                     width: "1000px",
//                     height: "1005px!important",
//                     marginBottom: "21px",
//                     minHeight: "90px!important"
//                     // backgroundColor: "blue",
//                     // fontSize: 14,
//                     // color: "blue",
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//                 '& div.MuiDataGrid-columnsContainer': {
//                     border: "none",
//                     // backgroundColor: "blue",
//                     // fontSize: 14,
//                     // color: "blue",
//                     // boxShadow:"inset 0px -20px 10px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
//                 },
//
//                 '& .leaderboard-rank_0': {
//                     // backgroundColor: "#ff1010",
//                     fontSize: 20,
//                     // color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     height: "144px",
//                     marginTop: "5px",
//                     marginBottom: "34px",
//                     minHeight: "100px!important",
//                     // '&:after': {
//                     //     content: '',
//                     //     position: "absolute",
//                     //     top: "-5px",
//                     //     left: "-5px",
//                     //     right: "-5px",
//                     //     bottom: "-5px",
//                     //     background: "linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2)",
//                     //     zIndex: "-1",
//                     // },
//                     // '&:before': {
//                     //     content: '',
//                     //     position: "absolute",
//                     //     top: "0",
//                     //     left: "0",
//                     //     right: "0",
//                     //     bottom: "0",
//                     //     background: "linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2)",
//                     //     zIndex: "-2",
//                     //     filter: "blur(40px)",
//                     // },
//                 },
//                 '& .leaderboard-rank_1': {
//                     backgroundColor: "grey",
//                     fontSize: 18,
//                     color: "white",
//                     borderRadius: "5px",
//                     border: "none",
//                     height: "95px",
//                     marginBottom: "21px",
//                     minHeight: "90px!important"
//                 },
//
//                 // '& .box-contents': {
//                 // backgroundColor: "grey",
//                 // fontSize: 18,
//                 // color: "white",
//                 // borderRadius: "5px",
//                 // border: "none",
//                 // width: "1000px",
//                 // height: "1005px!important",
//                 // marginBottom: "21px",
//                 // minHeight: "90px!important"
//                 // },
//
//
//                 // Other
//                 '& .leaderboard-theme': {
//                     backgroundColor: getBackgroundColor(theme.palette.info.main),
//                     '&:hover': {
//                         backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
//                     },
//                 },
//                 '& .leaderboard-theme--PartiallyFilled': {
//                     backgroundColor: getBackgroundColor(theme.palette.warning.main),
//                     '&:hover': {
//                         backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
//                     },
//                 },
//                 '& .leaderboard-theme--Rejected': {
//                     backgroundColor: getBackgroundColor(theme.palette.error.main),
//                     '&:hover': {
//                         backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
//                     },
//                 },
//             },
//         };
//     },
//     {defaultTheme},
// );


function UserCardProfile({userAccount, imageData}) {
    // const classes = useStyles();

    // const dispatch = useDispatch();
    // const getUserCardImageInitiated = (payload) => dispatch(getUserCardImageInit(payload))
    // const userDetailInitiated = (payload) => dispatch(userDetailInit(payload))

    const userCardImageBackground = useSelector((state) => state.user.list_user.userCardImageBackground[0])
    const userCardImageData = userCardImageBackground.img
    const userCardImageContentType = userCardImageBackground.img.mimetype


    let localUserObject = JSON.parse(localStorage.getItem("user"))


    useEffect(() => {
        }, [imageData]);
    // }, [userCardImageData]);


    // const defaultOrCustomCardImage = () => {
    //     if (userCardImageData.data === "defaultCardBackground.png"){
    //         return `${userCardImageData.data}`
    //     } else {
    //
    // const imageDataStringPrefix = `data:${userCardImageContentType};base64,`;
    // const imageData = arrayBufferToBase64(userCardImageData.data.data);
    //         return imageDataStringPrefix + imageData
    //     }
    // }


    return (
        <Grid item sx={{
            minWidth: "410px",
            // maxWidth: "500px",
            position: "relative",
            width: "525px", // 64 128 256 525
            height: "225px", // 27 56 112 225
            // minHeight: "225px", // 27 56 112 225
            // maxHeight: "144px"
        }}>

            <Card className={'box-contents leaderboard-rank_' + ' ' + 'leaderboard-currentUser'}
                  sx={{
                      backgroundImage: `url(${imageData})`,
                      width: "525px",
                      height: "225px",
                      backgroundSize: "100%"
                      // '&:hover': {
                      //     transform: "translateY(-5px) scale(1.005) translateZ(0)",
                      //     boxShadow: "0 24px 36px rgba(0,0,0,0.11) 0 24px 46px"
                      // },
                  }}
            >
                <CardHeader
                    sx={{
                        backdropFilter: "blur(20px)",
                        backgroundColor: "rgba( 0, 0, 0, 0.5 )",
                        maxWidth: "350px",
                        borderRadius: "5px",
                        height: "80px",
                    }}
                    avatar={
                        <Avatar
                            className={'leaderboard-avatar-'}
                            aria-label="user"
                            sx={{height: "50px", width: "50px"}}
                        >
                            {/*{nameWithSpaceRemoved.charAt(0)}*/}
                        </Avatar>
                    }
                    title={<Typography
                        sx={{color: "white", fontSize: 20}}>{
                        userAccount.name
                    }</Typography>}
                    subheader={<Typography sx={{color: "white", fontSize: 20}}>
                        {/*{userAccount.userDashboardType}:*/}
                        {/*{userAccount.occurrence}*/}
                    </Typography>}
                />
                <CardContent>
                    {/*{ !!userCardImageData ?*/}

                    {/*    <img width="100%" height="auto" alt={`string`} src={defaultOrCustomCardImage()}/>*/}

                    {/*    :*/}
                    {/*    <></>*/}
                    {/*}*/}
                </CardContent>
            </Card>

            {/*</ButtonBase>*/}
        </Grid>
    )
}

export default UserCardProfile
// export default memo(UserCardGeneric)
