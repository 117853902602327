import {createStore, applyMiddleware} from "redux";
// import {legacy_createStore as createStore, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import {composeWithDevToolsDevelopmentOnly, composeWithDevTools, devToolsEnhancer} from '@redux-devtools/extension';
import {mainReducer} from "../modules/app/mainReducer";
import {mainSaga} from "../modules/app/mainSaga";


const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsDenylist, actionsCreators and other options if needed
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(
    mainReducer,
    /* preloadedState, */ composeEnhancers(
        applyMiddleware(...middleware)
        // other store enhancers if any
    )
    // devToolsEnhancer()
    // composeEnhancers(applyMiddleware(...middleware),
    //
    // )
);


// const store = createStore(
//     mainReducer,
//     composeEnhancers(applyMiddleware(...middleware)
//     )
// );

// const store = createStore(
//     mainReducer,
//     composeWithDevToolsDevelopmentOnly(
//         applyMiddleware(...middleware)
//     )
// );

sagaMiddleware.run(mainSaga);

export default store;