export const STAGING_ADD_REQUESTING = 'STAGING_ADD_REQUESTING';
export const STAGING_ADD_SUCCESS = 'STAGING_ADD_SUCCESS';
export const STAGING_ADD_ERROR = 'STAGING_ADD_ERROR';
export const STAGING_ADD_PAGE_INIT = 'STAGING_ADD_PAGE_INIT';

export const STAGING_UPDATE_REQUESTING = 'STAGING_UPDATE_REQUESTING';
export const STAGING_UPDATE_SUCCESS = 'STAGING_UPDATE_SUCCESS';
export const STAGING_UPDATE_ERROR = 'STAGING_UPDATE_ERROR';



export function stagingAddPageInit() {
    return {
        type: STAGING_ADD_PAGE_INIT,
    };
}

export function stagingAddRequest(payload) {
    return {
        type: STAGING_ADD_REQUESTING,
        payload
    };
}

export function stagingAddError(error) {
    return {
        type: STAGING_ADD_ERROR,
        error,
    };
}

export function stagingAddSuccess() {
    return {
        type: STAGING_ADD_SUCCESS,
    };
}

export function stagingUpdateRequest(payload,id) {
    return {
        type: STAGING_UPDATE_REQUESTING,
        payload,
        id
    };
}

export function stagingUpdateError(error) {
    return {
        type: STAGING_UPDATE_ERROR,
        error,
    };
}

export function stagingUpdateSuccess() {
    return {
        type: STAGING_UPDATE_SUCCESS,
    };
}