export const urls = {
    'LOGIN_URL': '/appauth/login',
    'GOOGLE_LOGIN_URL': '/appauth/googlelogin',
    'REGISTER_URL': '/auth/register',
    'ROOM': '/room',
    'OPENAI': '/openai',
    'CLIENT': '/client',
    'CLIENTSITE': '/clientsite',
    'CLIENTCASE': '/clientcase',
    'ADMIN': '/admin',
    'USER': '/user',
    'SALESFORCE': '/salesforce',
    'CASE': '/case',
    'LAUNCH': '/launch',
    'STAGING': '/staging',
    'LAUNCHPAD': '/launchpad',
    'NATIONSTATE': '/nationState',
    'LEADGENERATOR': '/leadgenerator',
    'MARKETING': '/marketing',
    'TERRITORY': '/territory',
    'VERIFY_CONFIRM_OTP': '/auth/verify-otp',
    'RESEND_CONFIRM_OTP': '/auth/resend-verify-otp'
}
