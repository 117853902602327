import { combineReducers } from "redux";
import loginReducer from "../Login/reducer";
import salesforceReducer from "../Salesforce/reducer";
import clientReducer from "../Client/reducer";
import clientCaseReducer from "../ClientCases/reducer";
import roomReducer from "../Room/reducer";
import OpenAIReducer from "../OpenAI/reducer";
import clientSiteReducer from "../ClientSite/reducer";
import adminReducer from "../Admin/reducer";
import menuDrawerReducer from "../MenuDrawer/reducer";
import userReducer from "../User/reducer";
import nationStateReducer from "../NationState/reducer";
import leadgeneratorReducer from "../LeadGenerator/reducer";
import launchReducer from "../Launch/reducer";
import stagingReducer from "../Staging/reducer";
import territoryReducer from "../Map/reducer";

/*
* everything on the left side is what comes after state
* aka - state.scrappers
* */
export const mainReducer = combineReducers({
  login: loginReducer,
  clients: clientReducer,
  rooms: roomReducer,
  openAI: OpenAIReducer,
  clientSite: clientSiteReducer,
  clientCase: clientCaseReducer,
  admin: adminReducer,
  menuDrawer: menuDrawerReducer,
  user: userReducer,
  salesforce: salesforceReducer,
  nationStates: nationStateReducer,
  leadgenerator: leadgeneratorReducer,
  launches: launchReducer,
  stagings: stagingReducer,
  territorys: territoryReducer
});
