import React, {useContext, useEffect} from 'react';
// import {useRoleContext} from "./helpers/roleContext";
import MapAllowedRoutes from "./helpers/MapAllowedRoutes";
import {getAllowedRoutes} from "./helpers/getAllowedRoutes";
import NavigationRoutePermissions from "./helpers/NavigationRoutePermissions";
import {useRoutes} from "react-router-dom";
// import Loader from "./components/Loader/Loader";
import {AuthContext} from "./helpers/AuthProvider";
import Login from "./modules/Login";
import MenuDrawer from "./modules/MenuDrawer";
// import {checkAuthorization} from "./helpers/helpers";

/*
* Any possible traversable routes are here, permissions are set in NavigationRoutePermissions
*  */
function App() {
    const authContextObject = useContext(AuthContext)

    /* Items directly for the menu */

    const mappedAllowedRoutes = MapAllowedRoutes(getAllowedRoutes(NavigationRoutePermissions))
    // console.log(`Routes available to access for the router: \n`, mappedAllowedRoutes)
    const router = useRoutes(mappedAllowedRoutes);

    useEffect(() => {
    }, []);


    return (
        <>
            {
                authContextObject.checkAuthorization()
                    ?
                    <MenuDrawer router={router} />
                    :
                    <><Login/></>
            }
        </>
    )
}


export default App;
