import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";

function LinearProgressWithLabel({progress, buffer}) {


    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="buffer" value={progress} valueBuffer={buffer}/>
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    progress,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function LinearDeterminateProgressBar({progress}) {
    const [progress2, setProgress] = useState(0);
    const [buffer, setBuffer] = React.useState(10);


    const progressRef = React.useRef(() => {
    });

    useEffect(() => {
        progressRef.current = () => {
            if (progress <= 100 && progress > 0) {
                // if (progress <= 100) {
                // console.log(`progress is NOT greater than 100 : ${progress}`)
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff < 100 ? progress + diff : progress);
                setBuffer(buffer + diff + diff2 < 100 ? buffer + diff + diff2 : buffer);
            }
           else if (progress === 0){
                setProgress(0);
                setBuffer(0);

            } else {
                setProgress(100 );
                setBuffer(100);
            }
        };
        progressRef.current();
    }, [progress]);


    return (
        <Box sx={{width: "90%"}}>
            <LinearProgressWithLabel progress={progress2} buffer={buffer}/>
        </Box>
    );
}