import {
    LAUNCH_PAGE_INIT,
    LAUNCH_ERROR,
    LAUNCH_SUCCESS,
    LAUNCH_DETAIL_INIT,
    LAUNCH_DETAIL_ERROR,
    LAUNCH_DETAIL_SUCCESS,
    LAUNCH_DETAIL_CLOSE,
    LAUNCH_DELETE_ERROR,
    LAUNCH_DELETE_SUCCESS,
    LAUNCH_START_ERROR,
    LAUNCH_START_SUCCESS,
    RELEASE_STATE_DATA,
    // LAUNCH_UPDATE_LOG,
    LAUNCH_UPDATE_LOG_SUCCESS
} from './actions';
import { combineReducers } from "redux";


// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    launches: [],
    selectedLaunch: {},
    selectedLaunchError: {},
    deleteLaunch: {}
  };

const launchReducers = function(state = initialState,actions){
    switch(actions.type){
        // case LAUNCH_UPDATE_LOG:
        //     return {...state, errors:[], launches: [...state.launches,...actions.payload]};
        case LAUNCH_UPDATE_LOG_SUCCESS:
            return {...state, errors:[], launches: [...actions.payload]};
        case LAUNCH_PAGE_INIT:
            return {...state, errors:[], launches: []};
        case LAUNCH_SUCCESS:
            return {...state, successful: true, launches:[...actions.payload]};
        case LAUNCH_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        case LAUNCH_DETAIL_INIT:
            return {...state, selectedLaunchError:{}, selectedLaunch: {}};
        case LAUNCH_DETAIL_SUCCESS:
            return {...state, selectedLaunch: {...actions.payload}};
        case LAUNCH_DETAIL_ERROR:
            return {...state, selectedLaunchError:{...actions.error}};
        case LAUNCH_DETAIL_CLOSE:
            return {...state, selectedLaunchError:{}, selectedLaunch: {}};

        case LAUNCH_DELETE_SUCCESS:
            return {...state, deleteLaunch: {...actions.payload}};
        case LAUNCH_DELETE_ERROR:
            return {...state, selectedLaunchError:{...actions.error}};

        case LAUNCH_START_SUCCESS:
            return {...state, startLaunch: {...actions.payload}};
        case LAUNCH_START_ERROR:
            return {...state, selectedLaunchError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], launches: [], selectedLaunch: {},selectedLaunchError: {},deleteLaunch: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_launch : launchReducers
});