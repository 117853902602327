import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import Roles from '../helpers/Roles'


// const quickAndDirtyStyle = {
//   // width: "200px",
//   height: "400px",
//   background: "#008a6b",
//   color: "#FFFFFF",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   borderRadius: "5px",
// };

const PrettyPrintJson = ({ data }) => {
  // const [pressed, setPressed] = useState(false);
  // const [position, setBoxPosition] = useState({ x: 0, y: 0 });
  const ref = useRef("");

    let currentUser = JSON.parse(localStorage.getItem("user"));
  let currentRoleOfUser = currentUser.roles;
  let superAdmin = Roles.SUPER_ADMIN





  const renderDebuggingInformation = ({ data }) => {
    if (currentRoleOfUser != superAdmin) {
      return <></>;
    } else if (currentRoleOfUser == superAdmin) {
      return (
        <Box
          ref={ref}
          // style={quickAndDirtyStyle}
          // onMouseMove={onMouseMove}
          // onMouseDown={onMouseDown}
          // onMouseUp={onMouseUp}
          // onMouseDown={() => setPressed(true)}
          // onMouseUp={() => setPressed(false)}
          id="PrettyPrintJson_Debugger"
          sx={{
            // marginTop: "500px",
            position: "absolute",
            overflowY: "auto",
            height: "100%",
            // width: "100%",
            fontSize: "small",
            // zIndex: 9999999
          }}
        >
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Box>
      );
    }
  };

  return <>{renderDebuggingInformation({ data })}</>;
};

export default PrettyPrintJson;
