export const NATIONSTATE_LIST_INIT = 'NATIONSTATE_LIST_INIT';
export const NATIONSTATE_LIST_ERROR = 'NATIONSTATE_LIST_ERROR';
export const NATIONSTATE_LIST_SUCCESS = 'NATIONSTATE_LIST_SUCCESS';

export const NATIONSTATE_PAGE_INIT = 'NATIONSTATE_PAGE_INIT';
export const NATIONSTATE_ERROR = 'NATIONSTATE_ERROR';
export const NATIONSTATE_SUCCESS = 'NATIONSTATE_SUCCESS';
export const NATIONSTATE_DETAIL_INIT = 'NATIONSTATE_DETAIL_INIT';
export const NATIONSTATE_DETAIL_ERROR = 'NATIONSTATE_DETAIL_ERROR';
export const NATIONSTATE_DETAIL_SUCCESS = 'NATIONSTATE_DETAIL_SUCCESS';
export const NATIONSTATE_DETAIL_CLOSE = 'NATIONSTATE_DETAIL_CLOSE';
export const NATIONSTATE_DELETE_INIT = 'NATIONSTATE_DELETE_INIT';
export const NATIONSTATE_DELETE_ERROR = 'NATIONSTATE_DELETE_ERROR';
export const NATIONSTATE_DELETE_SUCCESS = 'NATIONSTATE_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

//Get all states with any industry with or without scrapped
export function nationStateListInit() {
    return {
        type: NATIONSTATE_LIST_INIT,
    };
}

export function nationStateListSuccess(payload) {
    return {
        type: NATIONSTATE_LIST_SUCCESS,
        payload
    };
}

export function nationStateListError(error) {
    return {
        type: NATIONSTATE_LIST_ERROR,
        error,
    };
}



export function nationStatePageInit() {
    return {
        type: NATIONSTATE_PAGE_INIT,
    };
}

export function nationStateError(error) {
    return {
        type: NATIONSTATE_ERROR,
        error,
    };
}

export function nationStateSuccess(payload) {
    return {
        type: NATIONSTATE_SUCCESS,
        payload
    };
}





export function nationStateDetailInit(id) {
    return {
        type: NATIONSTATE_DETAIL_INIT,
        id
    };
}

export function nationStateDetailError(error) {
    return {
        type: NATIONSTATE_DETAIL_ERROR,
        error,
    };
}

export function nationStateDetailSuccess(payload) {
    return {
        type: NATIONSTATE_DETAIL_SUCCESS,
        payload
    };
}

export function nationStateDetailClose() {
    return {
        type: NATIONSTATE_DETAIL_CLOSE,
    };
}

export function nationStateDeleteInit(id) {
    return {
        type: NATIONSTATE_DELETE_INIT,
        id
    };
}

export function nationStateDeleteError(error) {
    return {
        type: NATIONSTATE_DELETE_ERROR,
        error,
    };
}

export function nationStateDeleteSuccess(payload) {
    return {
        type: NATIONSTATE_DELETE_SUCCESS,
        payload
    };
}

export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}
