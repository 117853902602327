import {
    NATIONSTATE_PAGE_INIT,
    NATIONSTATE_ERROR,
    NATIONSTATE_SUCCESS,
    NATIONSTATE_LIST_ERROR,
    NATIONSTATE_LIST_SUCCESS,
    NATIONSTATE_DETAIL_INIT,
    NATIONSTATE_DETAIL_ERROR,
    NATIONSTATE_DETAIL_SUCCESS,
    NATIONSTATE_DETAIL_CLOSE,
    NATIONSTATE_DELETE_ERROR,
    NATIONSTATE_DELETE_SUCCESS,
    RELEASE_STATE_DATA
} from './actions';
import { combineReducers } from "redux";
// import  scrapperReducer  from './Scrapper/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    nationStates: [],
    nationStatesList: [],
    selectedNationState: {},
    selectedNationStateError: {},
    deleteNationState: {}
  };

const nationStateReducers = function(state = initialState,actions){
    switch(actions.type){
        case NATIONSTATE_PAGE_INIT:
            return {...state, errors:[], nationStates: []};
        case NATIONSTATE_SUCCESS:
            return {...state, successful: true, nationStates:[...actions.payload]};

        case NATIONSTATE_LIST_SUCCESS:
            return {...state, successful: true, nationStatesList:[...actions.payload]};
        case NATIONSTATE_LIST_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

        case NATIONSTATE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        case NATIONSTATE_DETAIL_INIT:
            return {...state, selectedNationStateError:{}, selectedNationState: {}};
        case NATIONSTATE_DETAIL_SUCCESS:
            return {...state, successful: true, nationStates:[...actions.payload]};
            // return {...state, errors:[], nationStates: []};
            // return {...state, selectedNationState: {...actions.payload}};
        case NATIONSTATE_DETAIL_ERROR:
            return {...state, selectedNationStateError:{...actions.error}};
        case NATIONSTATE_DETAIL_CLOSE:
            return {...state, selectedNationStateError:{}, selectedNationState: {}};
        case NATIONSTATE_DELETE_SUCCESS:
            return {...state, deleteNationState: {...actions.payload}};
        case NATIONSTATE_DELETE_ERROR:
            return {...state, selectedNationStateError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], nationStates: [], selectedNationState: {},selectedNationStateError: {},deleteNationState: {}}
        default:
            return state;
    }
}

export default combineReducers({
    list_nationState : nationStateReducers,
    // manage_scrapper: scrapperReducer
});
