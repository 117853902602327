export const USER_PAGE_INIT = 'USER_PAGE_INIT';
export const USER_ERROR = 'USER_ERROR';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_DETAIL_INIT = 'USER_DETAIL_INIT';
export const USER_DETAIL_ERROR = 'USER_DETAIL_ERROR';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_CLOSE = 'USER_DETAIL_CLOSE';
export const USER_DELETE_INIT = 'USER_DELETE_INIT';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const USER_START_INIT = 'USER_START_INIT';
export const USER_START_REQUESTING = 'USER_START_REQUESTING';
export const USER_START_SUCCESS = 'USER_START_SUCCESS';
export const USER_START_ERROR = 'USER_START_ERROR';


export const IMPORT_USER_IMAGE_INIT = 'IMPORT_USER_IMAGE_INIT';
export const IMPORT_USER_IMAGE_SUCCESS = 'IMPORT_USER_IMAGE_SUCCESS';
export const IMPORT_USER_IMAGE_ERROR = 'IMPORT_USER_IMAGE_ERROR';
// export const USER_START_PAGE_INIT = 'USER_START_PAGE_INIT';





// Temporarily saves Month Amount
export const USER_GOAL_MONTH_SELECT_SAVE = 'USER_GOAL_MONTH_SELECT_SAVE';
export const USER_GOAL_MONTH_SELECT_SAVE_SUCCESS = 'USER_GOAL_MONTH_SELECT_SAVE_SUCCESS';

// Temporarily saves Goal Amount
export const USER_GOAL_AMOUNT_SAVE = 'USER_GOAL_AMOUNT_SAVE';
export const USER_GOAL_AMOUNT_SAVE_SUCCESS = 'USER_GOAL_AMOUNT_SAVE_SUCCESS';


// Updates the Month and Month's goal for user in Database
export const USER_GOAL_MONTH_AMOUNT_SAVE = 'USER_GOAL_MONTH_AMOUNT_SAVE';
export const USER_GOAL_MONTH_AMOUNT_SAVE_SUCCESS = 'USER_GOAL_MONTH_AMOUNT_SAVE_SUCCESS';


export const GET_USER_CARD_IMAGE_INIT = 'GET_USER_CARD_IMAGE_INIT';
export const GET_USER_CARD_IMAGE_SUCCESS = 'GET_USER_CARD_IMAGE_SUCCESS';
export const GET_USER_CARD_IMAGE_ERROR = 'GET_USER_CARD_IMAGE_ERROR';


export const GET_USER_GOAL_MONTH_INIT = 'GET_USER_GOAL_MONTH_INIT';
export const GET_USER_GOAL_MONTH_INIT_SUCCESS = 'GET_USER_GOAL_MONTH_INIT_SUCCESS';
export const GET_USER_GOAL_MONTH_INIT_ERROR = 'GET_USER_GOAL_MONTH_INIT_ERROR';
// export const USER_START_PAGE_INIT = 'USER_START_PAGE_INIT';


export const MONTH_GOAL_AUTOSELECT_SAVE = 'MONTH_GOAL_AUTOSELECT_SAVE';
export const MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS = 'MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS';
export const SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED = 'SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED';


export const GET_MONTH_GOAL_DETAILS_INIT = 'GET_MONTH_GOAL_DETAILS_INIT';
export const GET_MONTH_GOAL_DETAILS_INIT_SUCCESS = 'GET_MONTH_GOAL_DETAILS_INIT_SUCCESS';


export const GOAL_MONTH_UPDATE_INIT = 'GOAL_MONTH_UPDATE_INIT';
export const GOAL_MONTH_UPDATE_INIT_SUCCESS = 'GOAL_MONTH_UPDATE_INIT_SUCCESS';

export const USER_TEAM_INIT = 'USER_TEAM_INIT';
export const USER_TEAM_ERROR = 'USER_TEAM_ERROR';
export const USER_TEAM_SUCCESS = 'USER_TEAM_SUCCESS';


export const TEAM_MONTH_GOAL_AUTOSELECT_SAVE = 'TEAM_MONTH_GOAL_AUTOSELECT_SAVE';
export const TEAM_MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS = 'TEAM_MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS';
export const TEAM_SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED = 'TEAM_SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED';



export const TEAM_GET_MONTH_GOAL_DETAILS_INIT = 'TEAM_GET_MONTH_GOAL_DETAILS_INIT';
export const TEAM_GET_MONTH_GOAL_DETAILS_INIT_SUCCESS = 'TEAM_GET_MONTH_GOAL_DETAILS_INIT_SUCCESS';
export const TEAM_GOAL_MONTH_UPDATE_INIT = 'TEAM_GOAL_MONTH_UPDATE_INIT';
export const TEAM_GOAL_MONTH_UPDATE_INIT_SUCCESS = 'TEAM_GOAL_MONTH_UPDATE_INIT_SUCCESS';




export function userTeamDetailInit(payload) {
    return {
        type: USER_TEAM_INIT,
        payload
    };
}

export function userTeamDetailError(error) {
    return {
        type: USER_TEAM_ERROR,
        error,
    };
}

export function userTeamDetailSuccess(payload) {
    return {
        type: USER_TEAM_SUCCESS,
        payload
    };
}










// Save temporary selection of month for other components to see the change
export function monthGoalAutoSelectSave(payload) {
    return {
        type: MONTH_GOAL_AUTOSELECT_SAVE,
        payload
    };
}

// Save temporary selection of month for other components to see the change
export function monthGoalAutoSelectSaveSuccess(payload) {
    return {
        type: MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS,
        payload
    };
}




// Acknowledge and rerender
export function saveMonthSelectionInitSuccessAcknowledged() {
    return {
        type: SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED
    };
}





// Retrieve the month selected and the goal count if set
export function getMonthGoalDetailsInit(payload) {
    return {
        type: GET_MONTH_GOAL_DETAILS_INIT,
        payload
    };
}


export function getMonthGoalDetailsInitSuccess(payload) {
    return {
        type: GET_MONTH_GOAL_DETAILS_INIT_SUCCESS,
        payload
    };
}








export function goalMonthUpdateInit(payload) {
    return {
        type: GOAL_MONTH_UPDATE_INIT,
        payload
    };
}

export function goalMonthUpdateInitSuccess(payload) {
    return {
        type: GOAL_MONTH_UPDATE_INIT_SUCCESS,
        payload
    };
}


/***************************** Team Goal Related */

export function teamMonthGoalAutoSelectSave(payload) {
    return {
        type: TEAM_MONTH_GOAL_AUTOSELECT_SAVE,
        payload
    };
}

// Save temporary selection of month for other components to see the change
export function teamMonthGoalAutoSelectSaveSuccess(payload) {
    return {
        type: TEAM_MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS,
        payload
    };
}




// Acknowledge and rerender
export function teamSaveMonthSelectionInitSuccessAcknowledged() {
    return {
        type: TEAM_SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED
    };
}





// Retrieve the month selected and the goal count if set
export function teamGetMonthGoalDetailsInit(payload) {
    return {
        type: TEAM_GET_MONTH_GOAL_DETAILS_INIT,
        payload
    };
}


export function teamGetMonthGoalDetailsInitSuccess(payload) {
    return {
        type: TEAM_GET_MONTH_GOAL_DETAILS_INIT_SUCCESS,
        payload
    };
}




export function teamGoalMonthUpdateInit(payload) {
    return {
        type: TEAM_GOAL_MONTH_UPDATE_INIT,
        payload
    };
}

export function teamGoalMonthUpdateInitSuccess(payload) {
    return {
        type: TEAM_GOAL_MONTH_UPDATE_INIT_SUCCESS,
        payload
    };
}

/*************************** / Team Goal Related */









export function importUserImageInit(payload) {
    return {
        type: IMPORT_USER_IMAGE_INIT,
        payload
    };
}

export function importUserImageSuccess(payload) {
    return {
        type: IMPORT_USER_IMAGE_SUCCESS,
        payload
    };
}


export function importUserImageError(payload) {
    return {
        type: IMPORT_USER_IMAGE_ERROR,
        payload
    };
}


// Get Users profile card image
export function getUserCardImageInit(payload) {
    return {
        type: GET_USER_CARD_IMAGE_INIT,
        payload
    };
}

export function getUserCardImageSuccess(payload) {
    return {
        type: GET_USER_CARD_IMAGE_SUCCESS,
        payload
    };
}


export function getUserCardImageError(payload) {
    return {
        type: GET_USER_CARD_IMAGE_ERROR,
        payload
    };
}











export function userPageInit(payload) {
    return {
        type: USER_PAGE_INIT,
        payload
    };
}

export function userError(error) {
    return {
        type: USER_ERROR,
        error,
    };
}

export function userSuccess(payload) {
    return {
        type: USER_SUCCESS,
        payload
    };
}

export function userDetailInit(payload) {
    return {
        type: USER_DETAIL_INIT,
        payload
    };
}

export function userDetailError(error) {
    return {
        type: USER_DETAIL_ERROR,
        error,
    };
}

export function userDetailSuccess(payload) {
    return {
        type: USER_DETAIL_SUCCESS,
        payload
    };
}

export function userDetailClose() {
    return {
        type: USER_DETAIL_CLOSE,
    };
}

export function userDeleteInit(id) {
    return {
        type: USER_DELETE_INIT,
        id 
    };
}

export function userStartInit(id) {
    return {
        type: USER_START_INIT,
        id
    };
}

export function userStartError(error) {
    return {
        type: USER_START_ERROR,
        error,
    };
}

export function userStartSuccess(payload) {
    return {
        type: USER_START_SUCCESS,
        payload
    };
}

// export function userStartRequest(payload,id) {
//     return {
//         type: USER_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function userDeleteError(error) {
    return {
        type: USER_DELETE_ERROR,
        error,
    };
}

export function userDeleteSuccess(payload) {
    return {
        type: USER_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}