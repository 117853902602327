
// Step 1 - Just States with County's
export const LEADGENERATOR_STATE_LIST_INIT = 'LEADGENERATOR_STATE_LIST_INIT';
export const LEADGENERATOR_STATE_LIST_SUCCESS = 'LEADGENERATOR_STATE_LIST_SUCCESS';
export const LEADGENERATOR_STATE_LIST_ERROR = 'LEADGENERATOR_STATE_LIST_ERROR';

// Step 2 - States that have contact data in a specified industry
export const LEADGENERATOR_BUSINESS_LIST_INIT = 'LEADGENERATOR_BUSINESS_LIST_INIT';
export const LEADGENERATOR_BUSINESS_LIST_SUCCESS = 'LEADGENERATOR_BUSINESS_LIST_SUCCESS';
export const LEADGENERATOR_BUSINESS_LIST_ERROR = 'LEADGENERATOR_CONTACT_LIST_INIT_ERROR';



// Step 2 Completed - States that have contact data in a specified industry
export const LEADGENERATOR_BUSINESS_LIST_COMPLETED_INIT = 'LEADGENERATOR_BUSINESS_LIST_COMPLETED_INIT';
export const LEADGENERATOR_BUSINESS_LIST_COMPLETED_SUCCESS = 'LEADGENERATOR_BUSINESS_LIST_COMPLETED_SUCCESS';

// Step 2 Progress - States that have contact data in a specified industry
export const LEADGENERATOR_BUSINESS_LIST_PROGRESS_INIT = 'LEADGENERATOR_BUSINESS_LIST_PROGRESS_INIT';
export const LEADGENERATOR_BUSINESS_LIST_PROGRESS_SUCCESS = 'LEADGENERATOR_BUSINESS_LIST_PROGRESS_SUCCESS';


// Step 3 - States that have contact data in a specified industry that have their site scanned
export const LEADGENERATOR_BUSINESS_LIST_SCANNED_PROGRESS_INIT = 'LEADGENERATOR_BUSINESS_LIST_SCANNED_PROGRESS_INIT';
export const LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_PROGRESS_SUCCESS = 'LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_PROGRESS_SUCCESS';



// Step 3 - States that have contact data in a specified industry that have their site scanned
export const LEADGENERATOR_BUSINESS_LIST_SCANNED_COMPLETED_INIT = 'LEADGENERATOR_BUSINESS_LIST_SCANNED_COMPLETED_INIT';
export const LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_COMPLETED_SUCCESS = 'LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_COMPLETED_SUCCESS';




// Step 3 - States that have contact data in a specified industry that have their site scanned
export const LEADGENERATOR_BUSINESS_LIST_SCANNED_INIT = 'LEADGENERATOR_BUSINESS_LIST_SCANNED_INIT';
export const LEADGENERATOR_BUSINESS_LIST_SCANNED_SUCCESS = 'LEADGENERATOR_BUSINESS_LIST_SCANNED_SUCCESS';
export const LEADGENERATOR_BUSINESS_LIST_SCANNED_ERROR = 'LEADGENERATOR_BUSINESS_LIST_SCANNED_ERROR';


export const LEADGENERATOR_SAVE_SELECTED_STATE = 'LEADGENERATOR_SAVE_SELECTED_STATE';
export const LEADGENERATOR_SAVE_SELECTED_INDUSTRY = 'LEADGENERATOR_SAVE_SELECTED_INDUSTRY';
export const LEADGENERATOR_SAVE_SELECTED_STATE_LIST_TO_SCRAPE = 'LEADGENERATOR_SAVE_SELECTED_STATE_LIST_TO_SCRAPE';

export const LEADGENERATOR_SAVE_SELECTED_COLLECTION = 'LEADGENERATOR_SAVE_SELECTED_COLLECTION';

/* Adds scrapping of business list from Google*/
export const LEADGENERATOR_ADD_REQUESTING = 'LEADGENERATOR_ADD_REQUESTING';
export const LEADGENERATOR_ADD_SUCCESS = 'LEADGENERATOR_ADD_SUCCESS';
export const LEADGENERATOR_ADD_ERROR = 'LEADGENERATOR_ADD_ERROR';




export const LEADGENERATOR_ADD_SCRAPE_SITE_REQUESTING = 'LEADGENERATOR_ADD_SCRAPE_SITE_REQUESTING';
// export const LEADGENERATOR_ADD_SUCCESS = 'LEADGENERATOR_ADD_SUCCESS';
// export const LEADGENERATOR_ADD_ERROR = 'LEADGENERATOR_ADD_ERROR';


export const LEADGENERATOR_COLLECTION_DETAIL_INIT = 'LEADGENERATOR_COLLECTION_DETAIL_INIT';
export const LEADGENERATOR_COLLECTION_DETAIL_SUCCESS = 'LEADGENERATOR_COLLECTION_DETAIL_SUCCESS';
export const LEADGENERATOR_REMOVE_DUPLICATES = 'LEADGENERATOR_REMOVE_DUPLICATES';

export const LEADGENERATOR_ADD_PAGE_INIT = 'LEADGENERATOR_ADD_PAGE_INIT';
export const LEADGENERATOR_UPDATE_REQUESTING = 'LEADGENERATOR_UPDATE_REQUESTING';
export const LEADGENERATOR_UPDATE_SUCCESS = 'LEADGENERATOR_UPDATE_SUCCESS';
export const LEADGENERATOR_UPDATE_ERROR = 'LEADGENERATOR_UPDATE_ERROR';
// export const LEADGENERATOR_UPDATE_PAGE_INIT = 'LEADGENERATOR_UPDATE_PAGE_INIT';


// detail init
export function leadgeneratorCollectionDetailInit(payload) {
    return {
        type: LEADGENERATOR_COLLECTION_DETAIL_INIT,
        payload
    };
}
// detail success
export function leadgeneratorCollectionDetailSuccess(payload) {
    return {
        type: LEADGENERATOR_COLLECTION_DETAIL_SUCCESS,
        payload
    };
}

// Remove duplicates from collection
export function leadgeneratorRemoveDuplicates(payload) {
    return {
        type: LEADGENERATOR_REMOVE_DUPLICATES,
        payload
    };
}

// Step 1 - Just States with County's
export function leadgeneratorStateListInit() {
    return {
        type: LEADGENERATOR_STATE_LIST_INIT,
    };
}

export function leadgeneratorStateListSuccess(payload) {
    return {
        type: LEADGENERATOR_STATE_LIST_SUCCESS,
        payload
    };
}

export function leadgeneratorStateListError(error) {
    return {
        type: LEADGENERATOR_STATE_LIST_ERROR,
        error,
    };
}
//-------------------------------------------------

// Step 2 - States that have contact data in a specified industry
export function leadgeneratorBusinessListInit(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_INIT,
        payload
    };
}


export function leadgeneratorBusinessListSuccess(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SUCCESS,
        payload
    };
}

export function leadgeneratorBusinessListError(error) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_ERROR,
        error,
    };
}
//-------------------------------------------------
// Step 2 Completed - States that have contact data in a specified industry
export function leadgeneratorBusinessListCompletedInit() {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_COMPLETED_INIT
    };
}


export function leadgeneratorBusinessListCompletedSuccess(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_COMPLETED_SUCCESS,
        payload
    };
}



// Step 2 Progress
export function leadgeneratorBusinessListProgressInit() {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_PROGRESS_INIT
    };
}


export function leadgeneratorBusinessListProgressSuccess(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_PROGRESS_SUCCESS,
        payload
    };
}


// Step 3 Progress
export function leadgeneratorBusinessListSiteScannedProgressInit() {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SCANNED_PROGRESS_INIT
    };
}

export function leadgeneratorBusinessListSiteScannedProgressSuccess(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_PROGRESS_SUCCESS,
        payload
    };
}




// Step 3 COMPLETED - States that have contact data in a specified industry that have their site scanned
export function leadgeneratorBusinessListSiteScannedCompletedInit() {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SCANNED_COMPLETED_INIT
    };
}

export function leadgeneratorBusinessListSiteScannedCompletedSuccess(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SITE_SCANNED_COMPLETED_SUCCESS,
        payload
    };
}






// Step 3 - States that have contact data in a specified industry that have their site scanned
export function leadgeneratorBusinessListScannedInit(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SCANNED_INIT,
        payload
    };
}

export function  leadgeneratorBusinessListScannedSuccess(payload) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SCANNED_SUCCESS,
        payload
    };
}

export function  leadgeneratorBusinessListScannedError(error) {
    return {
        type: LEADGENERATOR_BUSINESS_LIST_SCANNED_ERROR,
        error,
    };
}
//-------------------------------------------------


export function leadgeneratorSaveSelectedState(payload) {
    return {
        type: LEADGENERATOR_SAVE_SELECTED_STATE,
        payload
    };
}

export function leadgeneratorSaveSelectedCollection(payload) {
    return {
        type: LEADGENERATOR_SAVE_SELECTED_COLLECTION,
        payload
    };
}


export function leadgeneratorSaveSelectedStateList(payload) {
    return {
        type: LEADGENERATOR_SAVE_SELECTED_STATE_LIST_TO_SCRAPE,
        payload
    };
}





export function leadgeneratorSaveSelectedIndustry(payload) {
    return {
        type: LEADGENERATOR_SAVE_SELECTED_INDUSTRY,
        payload
    };
}







export function leadgeneratorAddPageInit() {
    return {
        type: LEADGENERATOR_ADD_PAGE_INIT,
    };
}


export function leadgeneratorAddRequest(payload) {
    return {
        type: LEADGENERATOR_ADD_REQUESTING,
        payload
    };
}


export function leadgeneratorAddScrapeSiteRequest(payload) {
    return {
        type: LEADGENERATOR_ADD_SCRAPE_SITE_REQUESTING,
        payload
    };
}

export function leadgeneratorAddError(error) {
    return {
        type: LEADGENERATOR_ADD_ERROR,
        error,
    };
}


export function leadgeneratorAddSuccess(payload) {
    return {
        type: LEADGENERATOR_ADD_SUCCESS,
        payload
    };
}



// export function leadgeneratorUpdatePageInit(id) {
//     return {
//         type: LEADGENERATOR_UPDATE_PAGE_INIT,
//         id
//     };
// }
//
// export function leadgeneratorUpdateRequest(payload,id) {
//     return {
//         type: LEADGENERATOR_UPDATE_REQUESTING,
//         payload,
//         id
//     };
// }
//
// export function leadgeneratorUpdateError(error) {
//     return {
//         type: LEADGENERATOR_UPDATE_ERROR,
//         error,
//     };
// }
//
// export function leadgeneratorUpdateSuccess() {
//     return {
//         type: LEADGENERATOR_UPDATE_SUCCESS,
//     };
// }
