import React, {createContext} from 'react'
import {WEBSOCKET_BASE} from './config';
import {useDispatch} from 'react-redux';
import {io} from "socket.io-client";
import {launchUpdateLog} from "../modules/Launch/actions";
import {stagingUpdateLog} from "../modules/Staging/actions";
import {
    leadgeneratorBusinessListProgressInit,
    leadgeneratorBusinessListSiteScannedProgressInit
} from "../modules/LeadGenerator/actions";

export const WebSocketContext = createContext(null)

const WebSocketProvider = (props) => {
    const dispatch = useDispatch();
    const {children} = props

    var socket = io(
        `${WEBSOCKET_BASE}`,
        // "https://vizisaga.com:443",
        // "http://localhost:8001",
        {
            path: "/api/room",
            withCredentials: true,
            // autoConnect: false
            // reconnection: true,
            reconnectionDelay: 3000,
            reconnectionAttempts: 10
            // path: "/api/room",
            // auth: {token: localStorage.getItem('g_v_email')}
        });

    // const currentUser = JSON.parse(localStorage.getItem("user"));
    const storedGoogleEmail = localStorage.getItem('g_v_email');
    const sessionID = localStorage.getItem("sessionID");

    if (typeof sessionID !== 'undefined' && sessionID !== null) {
        // console.log(`sessionID has a value: ${sessionID}`);
        // console.log(sessionID);
        socket.auth = {sessionID};
        // socket.auth.username = username;
        socket.connect();
    } else if (typeof sessionID === 'undefined' || typeof sessionID == null) {
        // console.log(`sessionID has NO value, removing localStorage item`);
        localStorage.removeItem("sessionID");
    }


    socket.on("session", ({sessionID, userID, socketID}) => {
        // attach the session ID to the next reconnection attempts
        // console.log(`session received : sessionID`, sessionID)
        // console.log(`session received : userID`, userID)
        // console.log(`session received : socketID`, socketID)

        socket.auth = {sessionID};
        // console.log(`socket`)
        // console.log(socket)
        // store it in the localStorage
        localStorage.setItem("sessionID", sessionID);
        localStorage.setItem("userID", userID);
        // save the ID of the user
        socket.userID = userID;
    });


    // socket.on("connect_error", (err) => {
    //     if (err.message === "invalid username") {
    //         // this.usernameAlreadySelected = false;
    //         console.log(`connect error with invalid username`);
    //     }
    // });


    socket.on("connect", () => {
        // console.log()
        if (!!storedGoogleEmail) {
            // console.log(`Your user ${storedGoogleEmail} is connected with socket.id ${socket.id}`);
            // console.log(`socket`);
            // console.log(socket);
            if (localStorage.getItem("user")) {
                const currentUser = JSON.parse(localStorage.getItem("user"));


                if (Object.hasOwn(currentUser, 'displayName')) {

                    let payload = {
                        displayName: currentUser.displayName,
                        userEmail: storedGoogleEmail,
                        socketID: socket.id
                    }
                    socket.emit("user:online", payload);
                } else {
                    let payload = {
                        displayName: storedGoogleEmail,
                        userEmail: storedGoogleEmail,
                        socketID: socket.id
                    }
                    socket.emit("user:online", payload);
                }
            } else {
                let payload = {userEmail: 'no email detected yet'}
                socket.emit("user:online", payload);
            }
        }
    });


    socket.on("connect_error", (err) => {
        console.log(`connection error = ${err.message}`);  // the error message, for example "Session ID unknown"
        socket.io.opts.transports = ["polling", "websocket"];
    });


    /* Staging Updates
    * */
    socket.on("staging:update", (payload) => {
        // console.log(`staging:update payload =`)
        // console.log(JSON.stringify(payload))

        if (payload.progress === true) {
            // console.log(`progress was made in a staging!!!!!`)
            dispatch(stagingUpdateLog())
        } else if (payload.progress === false) {
            // console.log(`progress was NOT made in a staging and needs attention`)
        }
        // console.log(`connect_error due to ${err.message}`);
    });


    /* Tracking Updates
    * */
    // socket.on("tracking:update", (payload) => {
    //     // console.log(`launch:update payload =`)
    //     // console.log(JSON.stringify(payload))
    //
    //     if (payload.progress === true) {
    //         // console.log(`progress was made in a launch!!!!!`)
    //         dispatch(launchUpdateLog())
    //     } else if (payload.progress === false) {
    //         console.log(`progress was NOT made in a launch and needs attention`)
    //     }
    //     // console.log(`connect_error due to ${err.message}`);
    // });


    /* Launch Updates
    * */
    socket.on("launch:update", (payload) => {
        // console.log(`launch:update payload =`)
        // console.log(JSON.stringify(payload))

        if (payload.progress === true) {
            // console.log(`progress was made in a launch!!!!!`)
            dispatch(launchUpdateLog())
        } else if (payload.progress === false) {
            console.log(`progress was NOT made in a launch and needs attention`)
        }
        // console.log(`connect_error due to ${err.message}`);
    });


    /* Launch Updates
    * */
    socket.on("scrapper:update", (payload) => {
        // console.log(`scrapper:update payload =`)
        // console.log(JSON.stringify(payload))

        if (payload.progress === true) {
            // console.log(`progress was made in a scrapper!!!!!`)
            // dispatch(launchUpdateLog())
            dispatch(leadgeneratorBusinessListProgressInit())
            dispatch(leadgeneratorBusinessListSiteScannedProgressInit())
        } else if (payload.progress === false) {
            console.log(`progress was NOT made in a launch and needs attention`)
        }
        // console.log(`connect_error due to ${err.message}`);
    });



    /* Sales Updates
    * */
    socket.on("leaderboard:sales", (payload) => {
        // console.log(`scrapper:update payload =`)
        console.log(JSON.stringify(payload))

        if (payload.progress === true) {
            // console.log(`progress was made in a scrapper!!!!!`)
            // dispatch(launchUpdateLog())
            // dispatch(leadgeneratorBusinessListProgressInit())
            // dispatch(leadgeneratorBusinessListSiteScannedProgressInit())
        } else if (payload.progress === false) {
            // console.log(`progress was NOT made in a launch and needs attention`)
        }
        // console.log(`connect_error due to ${err.message}`);
    });

    if (!socket) {
        console.log(`no socket, connecting... `)
        socket = io.connect(WEBSOCKET_BASE)
    }


    /*
    * Component triggered events
    * Sending Client payloads to the server
    * */
    const sendWords = (payload) => {
        console.log(`emitting user:message`, payload)
        socket.emit("user:message", JSON.stringify(payload));
        // dispatch(updateChatLog(payload));
    }


    const sendMouseTracking = (payload) => {
        console.log(`emitting mousetracking:update`, payload)
        socket.emit("mousetracking:update", JSON.stringify(payload));
        // dispatch(updateChatLog(payload));
    }


    // This object can be passed to whatever component to give event handlers by left side key
    const ws = {
        socket: socket,
        sendWords: sendWords,
        sendMouseTracking: sendMouseTracking,
        // userHandler: onlineUser
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocketProvider