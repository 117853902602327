import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  USER_PAGE_INIT,
  USER_DETAIL_INIT,
  USER_DELETE_INIT,
  USER_START_INIT,
  userError,
  userSuccess,
  userDetailError,
  userDetailSuccess,
  userDeleteError,
  userDeleteSuccess,
  userPageInit,
  // USER_START_REQUESTING,
  userStartSuccess,
  userStartError,
  IMPORT_USER_IMAGE_INIT,
  importUserImageSuccess,
  getUserCardImageSuccess,
  GET_USER_CARD_IMAGE_INIT,
  SAVE_MONTH_SELECTION_INIT,
  saveMonthSelectionInitSuccess,
  GET_MONTH_GOAL_DETAILS_INIT,
  getMonthGoalDetailsInitSuccess,
  GOAL_MONTH_UPDATE_INIT,
  goalMonthUpdateInitSuccess,
  monthGoalAutoSelectSaveSuccess,
  MONTH_GOAL_AUTOSELECT_SAVE,
  USER_TEAM_INIT, userTeamDetailSuccess, userTeamDetailError,
} from "./actions";


import {request, requestWithFile} from '../../helpers/requests';
import { urls } from '../../helpers/urls';
import manageUserSaga from './UserAvatar/saga';
// import {browserRedirect} from "../../helpers/helpers";
// import {userPadAddError, userPadAddSuccess} from "./UserPad/actions";

//User API calls
function userCall(payload) {
  return request('post', urls.USER, payload);
}

//User API calls
function getUserCardImageCall(payload) {
  return request('post', urls.USER + '/usercardimage', payload);
}

function userDeleteCall(id) {
  return request('delete', urls.USER+'/'+id);
}

function userDetailCall(payload) {
  return request('post', urls.USER + '/getuserdetail', payload);
}


function userTeamDetailCall(payload) {
  return request('post', urls.USER + '/getuserteamdetail', payload);
}



function getMonthGoalDetailsInitCall(payload) {
  return request('post', urls.USER + '/getmonthgoaldetails', payload);
}

function goalMonthUpdateCall(payload) {
  return request('post', urls.USER + '/goalmonthupdate', payload);
}





function* userTeamDetailWorker(payload) {
  try {
    let response = yield call(userTeamDetailCall, payload);
    response = response.data.data;
    yield put(userTeamDetailSuccess(response));
  } catch (err) {
    yield put(userTeamDetailError(err.response.data.data));
  }
}




function* goalMonthUpdateWorker(payload) {
  try {
    console.log(`payload`)
    console.log(payload)
    let response = yield call(goalMonthUpdateCall, payload);
    response = response.data.data;
    console.log(`goalMonthUpdateCall response`)
    console.log(response)
    yield put(goalMonthUpdateInitSuccess(response));
  } catch (err) {
    console.log(`error: ${err}`);
    // yield put(userDetailError(err.response.data.data));
  }
}


function* getMonthGoalDetailsInitWorker(payload) {
  try {
    let response = yield call(getMonthGoalDetailsInitCall, payload);
    response = response.data.data;
    yield put(getMonthGoalDetailsInitSuccess(response));
  } catch (err) {
    yield put(userDetailError(err.response.data.data));
  }
}


//user image API call
function importUserImageInitCall(payload) {
  // console.log(`payload`)
  // console.log(payload)
  // console.log(`payload.files`)
  // console.log(payload.files)
  return requestWithFile('post', urls.USER + '/importuserimage', payload);
}



function* importUserImageWorker({payload}) {
  try {
    let response = yield call(importUserImageInitCall, payload);
    console.log(`response from import image:`)
    // console.log(response)
    // response = response.data.data;
    // yield put(importUserImageSuccess(response));
    /* success will have the user page reload with the new results */
    // yield put(userPageInit());
    window.location.reload(false);
  } catch (err) {
    console.log(`Error importing image`);
    console.log(err);
    // yield put(userStartError(err.response.data));
  }
}



// User Worker for profile page
function* getUserCardImageWorker(payload) {
  try {
    let response = yield call(getUserCardImageCall, payload);
    response = response.data.data;
    // console.log(`response`)
    // console.log(response)
    yield put(getUserCardImageSuccess(response));
  } catch (err) {
    console.log(`error getting user card image`)
    // yield put(userError(err.response.data.data));
  }
}


// User Worker for profile page
function* userWorker(payload) {
  try {
    let response = yield call(userCall, payload);
    response = response.data.data;
    yield put(userSuccess(response));
  } catch (err) {
    yield put(userError(err.response.data.data));
  }
}


function* userDetailWorker(payload) {
  try {
    let response = yield call(userDetailCall, payload);
    response = response.data.data;
    yield put(userDetailSuccess(response));
  } catch (err) {
    yield put(userDetailError(err.response.data.data));
  }
}

function* userDeleteWorker(payload) {
  try {
    let response = yield call(userDeleteCall, payload.id);
    response = response.data;
    yield put(userDeleteSuccess(response));
    yield put(userPageInit());
  } catch (err) {
    yield put(userDeleteError(err.response.data));
  }
}


function* saveMonthSelectionWorker(payload) {
  try {
    console.log("saveMonthSelectionWorker response = ")
    console.log(payload.payload)
    yield put(monthGoalAutoSelectSaveSuccess(payload.payload));
    /* success will have the user page reload with the new results */
    // yield put(userPageInit());
  } catch (err) {
    console.log(err);
    // yield put(userStartError(err.response.data));
  }
}



// User Watcher
export default function* userSaga() {
  yield all([
    takeLatest(USER_TEAM_INIT, userTeamDetailWorker),
    takeLatest(USER_DETAIL_INIT, userDetailWorker),
    takeLatest(USER_PAGE_INIT, userWorker),
    takeLatest(USER_DELETE_INIT, userDeleteWorker),
    takeLatest(MONTH_GOAL_AUTOSELECT_SAVE, saveMonthSelectionWorker),
    takeLatest(GET_MONTH_GOAL_DETAILS_INIT, getMonthGoalDetailsInitWorker),
    takeLatest(GOAL_MONTH_UPDATE_INIT, goalMonthUpdateWorker),

    takeLatest(GET_USER_CARD_IMAGE_INIT, getUserCardImageWorker),
    takeLatest(IMPORT_USER_IMAGE_INIT, importUserImageWorker),
    manageUserSaga()
  ]);
}
