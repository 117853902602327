import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';


import {Box, Button, Container} from "@mui/material";
import SecondDrawer from "./SecondDrawer";


function Admin(props) {
        return (
            <Box>
                <Box id="secondDrawer"
                     sx={{
                         marginTop: "100px",
                         zIndex: 1,
                         position: "absolute",
                     }}
                >
                    {/*<SecondDrawer/>*/}
                </Box>
            </Box>
        );


}



Admin.propTypes = {
    admin: PropTypes.array
};


export default Admin