export const LAUNCH_UPDATE_LOG = 'LAUNCH_UPDATE_LOG';
export const LAUNCH_UPDATE_LOG_SUCCESS = 'LAUNCH_UPDATE_LOG_SUCCESS';
export const LAUNCH_PAGE_INIT = 'LAUNCH_PAGE_INIT';
export const LAUNCH_ERROR = 'LAUNCH_ERROR';
export const LAUNCH_SUCCESS = 'LAUNCH_SUCCESS';
export const LAUNCH_DETAIL_INIT = 'LAUNCH_DETAIL_INIT';
export const LAUNCH_DETAIL_ERROR = 'LAUNCH_DETAIL_ERROR';
export const LAUNCH_DETAIL_SUCCESS = 'LAUNCH_DETAIL_SUCCESS';
export const LAUNCH_DETAIL_CLOSE = 'LAUNCH_DETAIL_CLOSE';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const LAUNCH_START_INIT = 'LAUNCH_START_INIT';
export const LAUNCH_START_REQUESTING = 'LAUNCH_START_REQUESTING';
export const LAUNCH_START_SUCCESS = 'LAUNCH_START_SUCCESS';
export const LAUNCH_START_ERROR = 'LAUNCH_START_ERROR';
// export const LAUNCH_START_PAGE_INIT = 'LAUNCH_START_PAGE_INIT';
export const LAUNCH_DELETE_INIT = 'LAUNCH_DELETE_INIT';
export const LAUNCH_DELETE_ERROR = 'LAUNCH_DELETE_ERROR';
export const LAUNCH_DELETE_SUCCESS = 'LAUNCH_DELETE_SUCCESS';

export function launchUpdateLog(payload) {
    return {
        type: LAUNCH_UPDATE_LOG,
        payload
    };
}

export function launchUpdateLogSuccess(payload) {
    return {
        type: LAUNCH_UPDATE_LOG_SUCCESS,
        payload
    };
}

export function launchPageInit(payload) {
    return {
        type: LAUNCH_PAGE_INIT,
        payload
    };
}

export function launchError(error) {
    return {
        type: LAUNCH_ERROR,
        error,
    };
}

export function launchSuccess(payload) {
    return {
        type: LAUNCH_SUCCESS,
        payload
    };
}

export function launchDetailInit(id) {
    return {
        type: LAUNCH_DETAIL_INIT,
        id 
    };
}

export function launchDetailError(error) {
    return {
        type: LAUNCH_DETAIL_ERROR,
        error,
    };
}

export function launchDetailSuccess(payload) {
    return {
        type: LAUNCH_DETAIL_SUCCESS,
        payload
    };
}

export function launchDetailClose() {
    return {
        type: LAUNCH_DETAIL_CLOSE,
    };
}


export function launchStartInit(id) {
    return {
        type: LAUNCH_START_INIT,
        id
    };
}

export function launchStartError(error) {
    return {
        type: LAUNCH_START_ERROR,
        error,
    };
}

export function launchStartSuccess(payload) {
    return {
        type: LAUNCH_START_SUCCESS,
        payload
    };
}

export function launchDeleteInit(payload) {
    return {
        type: LAUNCH_DELETE_INIT,
        payload
    };
}
// export function launchStartRequest(payload,id) {
//     return {
//         type: LAUNCH_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function launchDeleteError(error) {
    return {
        type: LAUNCH_DELETE_ERROR,
        error,
    };
}

export function launchDeleteSuccess(payload) {
    return {
        type: LAUNCH_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}