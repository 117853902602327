import React, {useEffect, useState, useContext, memo,} from 'react';
import {styled, useTheme} from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import {Link, Outlet} from "react-router-dom";
import {getAllowedRoutes} from "../../helpers/getAllowedRoutes";
import NavigationRoutePermissions from "../../helpers/NavigationRoutePermissions";
// import Badge from '@mui/material/Badge';
import AppMenuItem from './AppMenuItem'
import {Tooltip} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

// import RedeemIcon from '@mui/icons-material/Redeem';
import ThemeSelector from "../CustomThemeProvider/ThemeSelector";
import {CustomThemeContext} from "../CustomThemeProvider/CustomThemeProvider";
// import Logout from "../Logout";

// import {useDispatch} from 'react-redux';
// import {menuOpenState, menuClosedState} from "./actions";
import {browserRedirectAndRefresh} from "../../helpers/helpers";
// import MapAllowedRoutes from "../../helpers/MapAllowedRoutes";

// import AppMenuAsReference from "./AppMenu";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth, transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
    }), overflowX: 'hidden', zIndex: 10000
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), overflowX: 'hidden', width: `calc(${theme.spacing(7)} + 1px)`, [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1), // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap', boxSizing: 'border-box', ...(open && {
        ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme),
    }), ...(!open && {
        ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme),
    }),
}),);





// function MenuDrawer({router, allowedRoutes}) {
function MenuDrawer({router}) {
// function MenuDrawer() {
    const theme = useTheme();
    const {currentTheme, setTheme} = useContext(CustomThemeContext)
    const [open, setMainDrawerOpen] = useState(false);
    const [allowedRoutes, setAllowedRoutes] = useState([])
    const [selectedTheme, setSelectedTheme] = useState(currentTheme);
    const [anchorEl, setAnchorEl] = useState(null);


    useEffect(() => {
        setAllowedRoutes(getAllowedRoutes(NavigationRoutePermissions))
    }, []);


    useEffect(() => {
        setTheme(selectedTheme)
    }, [setSelectedTheme])


    const handleMainDrawerOpen = () => {
        setMainDrawerOpen(true);
    };

    const handleMainDrawerClose = () => {
        setMainDrawerOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeTheme = (event) => {
        let name = event.target.value
        setTheme(name)
        setSelectedTheme(name)
    };

    const handleLogOut = () => {
        console.log(`log out`)
        localStorage.removeItem('user');
        localStorage.removeItem('roles');
        localStorage.removeItem('token');
        localStorage.removeItem('g_tokenId');
        localStorage.removeItem('g_v_email');
        localStorage.removeItem('g_googleId');
        browserRedirectAndRefresh('/')
    }

    return (
        <Box id="drawer"
             sx={{
                 display: 'flex', height: "55px"
             }}>
            <CssBaseline/>
            <AppBar position="fixed" open={open} id="appBar"
                    sx={{
                        height: "55px", minHeight: "55px"
                    }}>
                <Toolbar id="drawerToolbar"
                         sx={{
                             height: "55px", minHeight: "60px", marginTop: "-5px",
                         }}>
                    <Tooltip title="expand main menu" arrow>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => handleMainDrawerOpen()}
                            edge="start"
                            sx={{
                                // minHeight:"60px",
                                // height:"60px",
                                marginRight: '36px', ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuOpenIcon/>
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h6" noWrap component="div">
                        ViziSaga
                    </Typography>

                    <Box id="accountMenu"
                         sx={{
                             // width: "100px",
                             // height: "auto",
                             marginRight: "0", marginLeft: "auto"
                         }}
                    >

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => handleMenu(e)}
                            // onClick={() => handleAccountOpen()}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}

                            sx={{
                                marginTop: "40px", // width: "50px",
                            }}
                        >

                            <MenuItem component={Link} to='/user'>Profile</MenuItem>
                            <ThemeSelector selectedTheme={selectedTheme} handleChangeTheme={handleChangeTheme}/>
                            <MenuItem onClick={handleLogOut}>Log out</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open} id="drawer-main-container"
                    sx={{
                        // backdropFilter: "blur(10px)",
                        // filter: "blur(4px)",

                        // NOTICE !!!
                        // Turning on position absolute will make main content slide under drawer when drawer is
                        // fully open , which is a design choice and theme choice
                        // position: "fixed",
                        position: "absolute",


                        // width: "100%",
                        // backgroundColor: "rgb(130 68 68 / 34%)",
                        height: "100%",
                    }}
            >
                <DrawerHeader id="drawer-first-header"
                              sx={{
                                  zIndex: "100000",
                              }}
                >
                    <IconButton onClick={() => handleMainDrawerClose()}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                {/*<Divider />*/}
                <List id="verticalList"
                      onMouseEnter={() => handleMainDrawerOpen()}
                      onMouseLeave={() => handleMainDrawerClose()}
                      sx={{
                          marginLeft: "-20px", // filter: "blur(4px)",
                          height: "100%", // backdropFilter: "blur(10px)",
                      }}
                >
                    {allowedRoutes.map((item, index) => (<AppMenuItem {...item} key={index}/>))}
                </List>
            </Drawer>
            <Grid container
                  id="currentRoutesContainer"
                  sx={{
                      marginTop: "100px",
                      marginLeft: "100px",
                      marginRight: "100px",
                  }}
            >
                {router}
            </Grid>
        </Box>);
}

export default MenuDrawer
// export default memo(MenuDrawer)