import {
    ADMIN_PAGE_INIT,
    ADMIN_ERROR,
    ADMIN_SUCCESS,
    ADMIN_DETAIL_INIT,
    ADMIN_COLLECTION_LIST,
    ADMIN_COLLECTION_LIST_SUCCESS,
    ADMIN_COLLECTION_LIST_ERROR,
    ADMIN_DETAIL_ERROR,
    ADMIN_DETAIL_SUCCESS,
    ADMIN_DETAIL_CLOSE,
    ADMIN_DELETE_ERROR,
    ADMIN_DELETE_SUCCESS,
    ADMIN_START_ERROR,
    ADMIN_START_SUCCESS,
    // ADMIN_ADD_USER_INIT,
    ADMIN_ADD_USER_SUCCESS,
    ADMIN_ADD_USER_ERROR,
    RELEASE_STATE_DATA,
    IMPORT_COLLECTION_SUCCESS,
    IMPORT_COLLECTION_ERROR,
    EXPORT_COLLECTION_SUCCESS,
    EXPORT_STATE_CITIES_SUCCESS,
    EXPORT_COLLECTION_ERROR,
    DELETE_COLLECTION_SUCCESS,
    DELETE_COLLECTION_ERROR,
    IMPORT_COLLECTION_FILE_NAME_SAVE,
    IMPORT_COLLECTION_FILE_DATA_SAVE,
    IMPORT_STATE_CITIES_SUCCESS,
    GET_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS,
    SAVE_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS
} from './actions';
import { combineReducers } from "redux";
// import  manageAdminReducer  from './ManageAdmin/reducer';
// import  adminPadReducer  from './AdminPad/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    admins: [],
    collections: [],
    needToImportStateCities: {hasBeenImported: false},
    importFileName: {},
    importFileData: [],
    exportCollection: [],
    selectedAdmin: {},
    siteConfigurationKeys: [{
        viziUsernameQueryParam:'',
        viziPasswordQueryParam:'',
        viziUniqueQueryParam:'',
        viziUniqueValueParam:''
    }],
    selectedAdminError: {},
    deleteAdmin: {}
  };

const adminReducers = function(state = initialState,actions){
    switch(actions.type){
        case ADMIN_PAGE_INIT:
            return {...state, errors:[], admins: []};

            case ADMIN_SUCCESS:
            return {...state, successful: true, admins:[...actions.payload]};


        case GET_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS:
            return {...state, successful: true, siteConfigurationKeys:[...actions.payload]};

        case SAVE_CLIENT_SITE_KEY_CONFIGURATION_SUCCESS:
            return {...state, successful: true, siteConfigurationKeys:[...actions.payload]};



        case ADMIN_COLLECTION_LIST:
            return {...state, errors:[], collections: []};

        case ADMIN_COLLECTION_LIST_SUCCESS:
            return {...state, successful: true, collections:[...actions.payload]};

        case ADMIN_COLLECTION_LIST_ERROR:
            return {...state, successful: false, errors:[...actions.error]};




        case IMPORT_COLLECTION_FILE_NAME_SAVE:
            return {...state, successful: true, importFileName:{...actions.payload}};

        case IMPORT_COLLECTION_FILE_DATA_SAVE:
            return {...state, successful: true, importFileData:[]};




        case IMPORT_STATE_CITIES_SUCCESS:
            return {...state, successful: true, needToImportStateCities:{...state.collections}};
            // return {...state, successful: true, collections:[...actions.payload]};


        case IMPORT_COLLECTION_SUCCESS:
            return {...state, successful: true, collections:[...state.collections]};
            // return {...state, successful: true, collections:[...actions.payload]};

        case IMPORT_COLLECTION_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case EXPORT_COLLECTION_SUCCESS:
            return {...state, successful: true, exportCollection:[...actions.payload]};

        case EXPORT_COLLECTION_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case DELETE_COLLECTION_SUCCESS:
            return {...state, successful: true, collections:[...actions.payload]};

        case DELETE_COLLECTION_ERROR:
            return {...state, successful: false, errors:[...actions.error]};









        // case ADMIN_ADD_USER_INIT:
        //     return {...state, admins:[]};
        case ADMIN_ADD_USER_SUCCESS:
            return {...state, successful: true, admins:[...actions.payload]};
        case ADMIN_ADD_USER_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case ADMIN_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        case ADMIN_DETAIL_INIT:
            return {...state, selectedAdminError:{}, selectedAdmin: {}};
        case ADMIN_DETAIL_SUCCESS:
            return {...state, selectedAdmin: {...actions.payload}};
        case ADMIN_DETAIL_ERROR:
            return {...state, selectedAdminError:{...actions.error}};
        case ADMIN_DETAIL_CLOSE:
            return {...state, selectedAdminError:{}, selectedAdmin: {}};

        case ADMIN_DELETE_SUCCESS:
            return {...state, deleteAdmin: {...actions.payload}};
        case ADMIN_DELETE_ERROR:
            return {...state, selectedAdminError:{...actions.error}};

        case ADMIN_START_SUCCESS:
            return {...state, startAdmin: {...actions.payload}};
        case ADMIN_START_ERROR:
            return {...state, selectedAdminError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], admins: [], selectedAdmin: {},selectedAdminError: {},deleteAdmin: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_admin : adminReducers,
    // manage_admin: manageAdminReducer,
    // admin_pad: adminPadReducer
});