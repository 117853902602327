import {
    ROOM_PAGE_INIT,
    ROOM_ERROR,
    ROOM_SUCCESS,
    ROOM_DETAIL_INIT,
    ROOM_DETAIL_ERROR,
    ROOM_DETAIL_SUCCESS,
    ROOM_DETAIL_CLOSE,
    ROOM_DELETE_ERROR,
    ROOM_DELETE_SUCCESS,
    ROOM_START_ERROR,
    ROOM_START_SUCCESS,
    RELEASE_STATE_DATA
} from './actions';
import { CREATE_ROOM_SUCCESS, JOIN_ROOM_SUCCESS, SET_USERNAME, UPDATE_CHAT_LOG} from './actions';

import { combineReducers } from "redux";
import  manageRoomReducer  from './ManageRoom/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    rooms: [],
    selectedRoom: {},
    selectedRoomError: {},
    deleteRoom: {},
    room: null,
    chatLog: [],
    username: ''
  };

const roomReducer = function(state = initialState,actions){
    switch(actions.type){
        case CREATE_ROOM_SUCCESS:
            return {...state, room: {...actions.payload}};
        case JOIN_ROOM_SUCCESS:
            return {...state, room: {...actions.payload}};
        case SET_USERNAME:
            return {...state, username: {...actions.payload}}
        case UPDATE_CHAT_LOG:
            // return if(state.room !== null && actions.update.roomId === state.room.id){
            return {...state, chatLog : [...state.chatLog, actions.update.data]};
        case ROOM_PAGE_INIT:
            return {...state, errors:[], rooms: []};
        case ROOM_SUCCESS:
            return {...state, successful: true, rooms:[...actions.payload]};
        case ROOM_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        case ROOM_DETAIL_INIT:
            return {...state, selectedRoomError:{}, selectedRoom: {}};
        case ROOM_DETAIL_SUCCESS:
            return {...state, selectedRoom: {...actions.payload}};
        case ROOM_DETAIL_ERROR:
            return {...state, selectedRoomError:{...actions.error}};
        case ROOM_DETAIL_CLOSE:
            return {...state, selectedRoomError:{}, selectedRoom: {}};
        case ROOM_DELETE_SUCCESS:
            return {...state, deleteRoom: {...actions.payload}};
        case ROOM_DELETE_ERROR:
            return {...state, selectedRoomError:{...actions.error}};
        case ROOM_START_SUCCESS:
            return {...state, startRoom: {...actions.payload}};
        case ROOM_START_ERROR:
            return {...state, selectedRoomError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], rooms: [], selectedRoom: {},selectedRoomError: {},deleteRoom: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_room : roomReducer,
    manage_room: manageRoomReducer,
});