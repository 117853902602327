import React, {Suspense, useEffect} from 'react';
// import SecondDrawer from "../ClientCases/SecondDrawer";
import './clientSite.css';
import Grid from '@mui/material/Unstable_Grid2';

import DataTableMaterial from "./DataTableMaterial";
import {Box, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import InfoIcon from "@mui/icons-material/Info";
// import {getAllowedRoutes} from "../../helpers/getAllowedRoutes";
// import MapAllowedRoutes from "../../helpers/MapAllowedRoutes";
// import {useRoutes} from "react-router-dom";
// import SecondaryDrawerNavigationRoutePermissions from "./SecondaryDrawerNavigationRoutePermissions";
// import Loader from "../../components/Loader/Loader";



function ClientCases() {


    return (
        <>
            <Grid
                spacing={1}
                container
                sx={{
                    // marginLeft: "15%",
                    // marginLeft: "300px",
                    // marginRight: "5%",
                    paddingBottom: "20px",
                    width: "100%",
                }}
            >
                <Grid width="100%" align="center">
                    <Tooltip
                        title={`Domains located directly on our server`}
                        arrow
                        enterDelay={300}
                        leaveDelay={200}
                        TransitionComponent={Fade}
                        TransitionProps={{timeout: 300}}
                    >
                        <InfoIcon
                            sx={{marginLeft: "160px", position: "absolute"}}
                            fontSize={"small"}
                            color="info"
                        />
                    </Tooltip>
                    <Typography variant="h6">Client Cases</Typography>
                </Grid>

                <Grid container>
                    <DataTableMaterial />
                </Grid>
            </Grid>
        </>
    );
}





export default ClientCases