import { createTheme } from '@mui/material/styles';

// Dark theme
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#00e676',
        },
        secondary: {
            main: '#40c4ff',
        },
        background: {
            paper: '#101418',
            default: '#101418',
        },
        info: {
            main: '#0097a7',
        },
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true,
            },
        },
        MuiPaper: {
            defaultProps: {},
            styleOverrides: {
                root: {},
            },
        },
        MuiButton: {
            defaultProps: {},
            styleOverrides: {
                outlined: { // Targeting the "contained" variant
                    backgroundColor: '#101418!important', // blue color in hexadecimal
                    "&:hover": {
                        backgroundColor: '#00a757' // darker blue for hover effect
                    }
                },
            },
        },
        MuiFormControl: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    backgroundColor: '#101418!important', // blue color in hexadecimal
                    "&:hover": {
                        backgroundColor: '#00a757'
                    }
                },
            },
        }
    }
})

export default theme