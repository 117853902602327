import React, {useContext, useEffect} from 'react';
// import {useSelector, useDispatch} from 'react-redux';
// import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
// import {
//     launchPageInit,
//     launchDetailInit,
//     launchDetailClose,
//     launchDeleteInit,
//     releaseStateData,
//     launchStartInit
// } from './actions';

import Grid from '@mui/material/Unstable_Grid2';
import DataTableMaterial from "../Launch/DataTableMaterial";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
// import {WebSocketContext} from "../../socketHandlers/WebSocket";
import Typography from "@mui/material/Typography";
// import SecondDrawer from "./SecondDrawer";


function Launch(props) {

    return (
        <>
            <Grid
                spacing={1}
                container
                sx={{
                    paddingBottom: "20px",
                    width: "100%",
                }}
            >
                <Grid width="100%" align="center">
                    <Typography variant="h4">Launch Progress</Typography>
                </Grid>
                <Grid container>
                    <DataTableMaterial />
                </Grid>
            </Grid>
        </>
    );
}



Launch.propTypes = {
    launches: PropTypes.array
};



export default Launch