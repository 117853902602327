import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from '../../../helpers/requests';
import { browserRedirect } from '../../../helpers/helpers';
import { urls } from '../../../helpers/urls';
import {
    CASE_ADD_REQUESTING,
    caseAddSuccess,
    caseAddError,
    CASE_UPDATE_REQUESTING,
    caseUpdateSuccess,
    caseUpdateError
} from "./actions";

//caseAdd API call
function caseAddCall(payload) {
  return request('post', urls.CASE, payload);
}

//caseUpdate API call
function caseUpdateCall(id, payload) {
  return request('put', urls.CASE+"/"+id, payload);
}





// caseAdd Worker
function* caseAddWorker({ payload }) {
  try {
    yield call(caseAddCall, payload);
    yield put(caseAddSuccess());
    yield call(browserRedirect, '/app/case');
  } catch (err) {
    yield put(caseAddError(err.response.data));
  }
}

//caseUpdate Worker
function* caseUpdateWorker({id, payload}) {
  try {
    yield call(caseUpdateCall, id, payload);
    yield put(caseUpdateSuccess());
    yield call(browserRedirect, '/app/case');
  } catch (err) {
    yield put(caseUpdateError(err.response.data));
  }
}



/* Watchers are like subscribers in pub-sub, they listen for the event name and trigger functions */
/*  */
// caseAdd Watcher
export default function* caseAddSaga() {
  yield all([
    takeLatest(CASE_ADD_REQUESTING, caseAddWorker),
    takeLatest(CASE_UPDATE_REQUESTING, caseUpdateWorker),
  ]);
}