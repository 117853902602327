import { createSlice } from "@reduxjs/toolkit";

let historySlice = createSlice({
    name: 'history',
    initialState: [],
    reducers: {
        addHistory: (state, { payload }) => {
            return payload
        },
        activePage: (state, { payload = null }) => {
            let pos = null
            state.forEach((obj, index) => {
                if (obj.chatId === payload) {
                    obj.active = true
                    pos = index
                } else {
                    obj.active = false
                }
            })

            if (pos) {
                let obj = state[pos]
                state.splice(pos,1)
                state.unshift(obj)
            }

            return state
        }
    }
})

export const { addHistory, activePage } = historySlice.actions

export default historySlice.reducer

// ----------------------------------------------------------

// import { combineReducers } from "redux";

// export const ADD_HISTORY = 'history/addHistory';
// export const ACTIVE_PAGE = 'history/activePage';
//
// export const addHistory = payload => ({
//     type: ADD_HISTORY,
//     payload,
// });
//
// export const activePage = payload => ({
//     type: ACTIVE_PAGE,
//     payload,
// });
//
// const initialState = [];
//
// // const historyReducer = function(state = initialState, action){
// export default function(state = initialState, action){
//     switch(action.type){
//         case ADD_HISTORY:
//             return action.payload;
//         case ACTIVE_PAGE:
//             let pos = null;
//             state.forEach((obj, index) => {
//                 if (obj.chatId === action.payload) {
//                     obj.active = true;
//                     pos = index;
//                 } else {
//                     obj.active = false;
//                 }
//             });
//
//             if (pos !== null) {
//                 let obj = state[pos];
//                 state.splice(pos, 1);
//                 state.unshift(obj);
//             }
//
//             return state;
//         default:
//             return state;
//     }
// }

// export default combineReducers({
//     history : historyReducer,
// });
