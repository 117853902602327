import React, { useState, useEffect } from 'react';
import {
    DataGridPro,
    GridToolbarContainer,
    useGridApiRef,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridOverlay, GridActionsCellItem
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import { launchDeleteInit, launchDetailInit, launchPageInit } from "./actions";
import { Box, Select } from "@mui/material";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { LaunchAddLaunchInit } from "./actions";
import MenuItem from "@mui/material/MenuItem";
// import { useDemoData } from '@mui/x-data-grid-generator';
import Roles from '../../helpers/Roles'
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import LinearDeterminateProgressBar from "./LinearDeterminateProgressBar";


import { styled } from '@mui/material/styles';
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
    flexDirection: 'column',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
        </StyledGridOverlay>
    );
}

function CustomEmptyOverlayGrid() {
    let widthOfCol = 100;
    let defaultCol = [
        // {field: 'Name', minWidth: widthOfCol, flex: 0.25, headerName: 'Account Name', editable: false},
        { field: 'Website', minWidth: widthOfCol, flex: 0.10, headerName: 'Launch Address', editable: false },
        {
            field: 'Staging_Site_URL__c',
            minWidth: widthOfCol,
            flex: 0.25,
            headerName: 'Staging Address',
            editable: false,
            hide: true
        },
        {
            field: 'progressPercentage', minWidth: widthOfCol, flex: 0.5, headerName: 'Progress', editable: false,
            renderCell: (params) => {
                return (
                    <LinearDeterminateProgressBar progress={params.row.progressPercentage} />
                );
            }
        },
        { field: 'currentStep', minWidth: widthOfCol, flex: 0.25, headerName: 'Current Step', editable: false },
        { field: 'dateLaunched', minWidth: widthOfCol, flex: 0.25, headerName: 'Date Launched', editable: false },
    ]

    return (
        <Box style={{ height: "80vh", width: '100%' }}>
            <Box style={{ display: 'flex', height: '100%' }}>
                <DataGridPro
                    slots={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={[]}
                    columns={defaultCol}
                />
            </Box>
        </Box>
    );
}


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

function EditToolbar(props) {
    const { apiRef } = props;

    const handleClick = () => {
        function randomId() {
            let result = "";
            let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
            let charactersLength = characters.length;
            for (let i = 0; i < 24; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        const _id = randomId();

        apiRef.current.updateRows([{ _id, isNew: true }]);
        apiRef.current.setRowMode(_id, 'edit');
        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });

            apiRef.current.setCellFocus(_id, 'firstName');
        });
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Launch
            </Button>
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    apiRef: PropTypes.shape({
        current: PropTypes.object.isRequired,
    }).isRequired,
};


export default function DataTableMaterial() {
    const dispatch = useDispatch();

    const getLaunches = () => dispatch(launchPageInit());
    // const getTerritorys = () => dispatch(territoryPageInit());
    const launches = useSelector((state) => state.launches.list_launch.launches)

    const launchDeleteInitiator = (payload) => dispatch(launchDeleteInit(payload));
    // const addUserInitiator = (payload) => dispatch(launchAddUserInit(payload));
    // // const admin = useSelector((state) => state)
    // const admin = useSelector((state) => state.admin.list_admin.admin)


    useEffect(() => {
        getLaunches()
    }, [])

    useEffect(() => {

    }, [launches])

    const apiRef = useGridApiRef();
    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleCellFocusOut = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // const handleEditClick = (id) => (event) => {
    //     event.stopPropagation();
    //     apiRef.current.setRowMode(id, 'edit');
    // };

    const handleSaveClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.commitRowChange(id);
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        // console.log('row')
        // console.log(row)
        // addUserInitiator(row)
        apiRef.current.updateRows([{ ...row, isNew: false }]);
    };

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        console.info(`row to delete: `)
        console.info(row)
        launchDeleteInitiator({ payload: row });
        apiRef.current.updateRows([{ ...row, _action: 'delete' }]);
    };

    const handleCancelClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.setRowMode(id, 'view');

        const row = apiRef.current.getRow(id);
        if (row.isNew) {
            apiRef.current.updateRows([{ ...row, _action: 'delete' }]);
        }
    };

    let widthOfCol = 100;

    const col = React.useMemo(
        () => [
            {
                field: 'Website',
                minWidth: widthOfCol,
                flex: 0.15,
                headerName: 'Launch Address',
                editable: false,
                hide: false
            },
            {
                field: 'Staging_Site_URL__c',
                minWidth: widthOfCol,
                flex: 0.25,
                headerName: 'Staging Address',
                editable: false,
                hide: true
            },
            {
                field: 'progressPercentage', minWidth: widthOfCol, flex: 0.5, headerName: 'Progress', editable: false,
                renderCell: (params) => {
                    return (
                        <LinearDeterminateProgressBar progress={params.row.progressPercentage} />
                    )
                }
            },
            {
                field: 'currentStep',
                minWidth: widthOfCol,
                flex: 0.25,
                headerName: 'Current Step',
                editable: false,
                hide: false
            },
            { field: 'dateLaunched', minWidth: widthOfCol, flex: 0.25, headerName: 'Date Launched', editable: false },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                // cellClassName: classes.actions,
                getActions: ({ id }) => {
                    const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(id)}
                                color="primary"
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                // className={classes.textPrimary}
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ];
                    }

                    return [

                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                },
            },
        ],
        [handleDeleteClick, handleCancelClick, handleSaveClick, apiRef],
    );


    return (
        <Box sx={{ height: "80vh", width: '90vw' }}>
            <Box sx={{ height: "80vh", width: '90vw' }}>
                <DataGridPro
                    apiRef={apiRef}
                    columns={col}
                    rows={launches}
                    getRowId={(row) => row._id}
                    editMode="row"
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    onCellFocusOut={handleCellFocusOut}
                    slots={{
                        Toolbar: CustomToolbar,
                        // Toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: { apiRef },
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'progressPercentage',
                                    sort: 'asc',
                                },
                            ],
                        },
                    }}
                />
            </Box>
            {/*</Box>*/}
        </Box>
    );
    // }
}
