import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
    adminCollectionList,
    exportCollectionInit,
    importCollectionFileNameSave,
    importCollectionInit, importStateCitiesInit,
} from "./actions";

import {styled} from '@mui/material/styles';
import DataTableMaterial from "./DataTableMaterial";
// import {Box, Button, Container} from "@mui/material";
// import {WebSocketContext} from "../../socketHandlers/WebSocket";
import Skeleton from "@mui/material/Skeleton";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import {Box, Button, Container, Grid, InputLabel, Paper, Select, Stack, TextField, Typography} from "@mui/material";
import SecondDrawer from "./SecondDrawer";
import DataTableMaterialDatabase from "./DataTableMaterialDatabase";
import FileUploader from "./FileUploader";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";


function DatabaseSettings(props) {
  // const ws = useContext(WebSocketContext);
  const [addingFileExistingCollection, setAddingFileExistingCollection] = useState(false);
  const [addCollectionChooseFile, setAddCollectionChooseFile] = useState(false);
  const [addingFile, setAddingFile] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [collectionNameSelected, setCollectionNameSelected] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();
  const getCollectionList = () => dispatch(adminCollectionList());

  const collections = useSelector(
    (state) => state.admin.list_admin.collections
  );

  const export_file_data = useSelector((state) => state.admin.list_admin.exportCollection);

  const importFileName = useSelector(
    (state) => state.admin.list_admin.importFileName
  );



  // const importFileDataFileData = importFileData.fileData
  const importCollectionFileNameSaveInit = (payload) =>
    dispatch(importCollectionFileNameSave(payload));

  const exportCollectionInitiator = (payload) =>
    dispatch(exportCollectionInit(payload));

  const importStateCitiesInitiator = () =>
    dispatch(importStateCitiesInit());

  const importCollectionInitiator = (payload) =>
    dispatch(importCollectionInit(payload));



  useEffect(() => {
    getCollectionList();
  }, []);

  const handleNameChange = (event) => {
    importCollectionFileNameSaveInit({ collectionName: event.target.value });
    setCollectionNameSelected(event.target.value);
  };

  const handleFileDataUploadExistingCollection = (e) => {
      setAddingFileExistingCollection(true)
      console.log(`e.target.files[0]`)
      console.log(e.target.files[0])
    setSelectedFile(e.target.files[0]);
  };

  const handleFileDataUploadAddCollection = (e) => {
      console.log(`newCollectionName = ${newCollectionName}`)
      setAddingFile(true)
    setSelectedFile(e.target.files[0]);
  };

  // const handleCreateCollection = (e) => {
  //   setNewCollectionName(e.target.value);
  // };


        const updateNewCollectionInput = (e) => {
      let userInput = e.target.value
      // let userInputValidated = userInput.toLowerCase()
      let userInputValidated = userInput
          .replace(/\s/g, "")
          .replace(/-/g, "")
          .replace(/\./g, "")
          .replace(/[&\\#,+()$~%'"*?<>{}]/g, "");
      setNewCollectionName(userInputValidated)

      if (userInputValidated.length > 3 ){
          setAddCollectionChooseFile(true)
      }

      if (userInputValidated.length < 3 ){
          setAddCollectionChooseFile(false)
      }
  }



  const handleExportClick = () => {
    exportCollectionInitiator({collectionName: collectionNameSelected});
  };


  const handleImportClick = () => {
    const formData = new FormData();
    formData.append(`${newCollectionName}`, selectedFile);
    importCollectionInitiator({
      formData,
    });
  };


  const handleImportStateCitiesClick = () => {
    // const formData = new FormData();
    // formData.append(`${collectionNameSelected}`, selectedFile);
    importStateCitiesInitiator();
  };



    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


  if (collections.length === 0) {
    return (
      <Skeleton
        sx={
          {
            // height: 800,
            // width: "100%",
            // marginTop: "40px",
            // maxWidth: "1400px",
            // width: "1400px",
          }
        }
      />
    );
  } else {
    return (
      <>
        <Box
          id="secondDrawer"
          sx={{
            marginTop: "100px",
            zIndex: 1,
            position: "absolute",
          }}
        >
          <SecondDrawer />
        </Box>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          // flexGrow
          // width="100%"
        >
          <Grid item align="center" sx={{height: 200}}>
              <FormControl sx={{ width: "300px", marginTop: "20px" }}>
                <InputLabel id="demo-multiple-name-label">
                  {`Select Collection`}
                </InputLabel>
                <Select
                  value={collectionNameSelected}
                  onChange={handleNameChange}
                  autoWidth
                  labelId="Select Collection"
                  name="selectCollection"
                  variant="standard"
                >
                  {collections.map((collection,index) => (
                    <MenuItem key={index} value={collection.name}>
                      {collection.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!!importFileName.collectionName ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "20px" }}
                  >
                    {`Choose File`}
                    <input
                      type="file"
                      name="collectionFile"
                      hidden
                      onChange={handleFileDataUploadExistingCollection}
                    />
                  </Button>

                  {selectedFile === null &&
                  addingFileExistingCollection === false ? (
                    <></>
                  ) : (
                    <Button
                      sx={{ marginTop: "20px" }}
                      variant="contained"
                      onClick={handleImportClick}
                    >
                      {`Start Import`}
                    </Button>
                  )}

                  <Button
                    sx={{ marginTop: "20px", marginLeft: "20px" }}
                    variant="outlined"
                    onClick={handleExportClick}
                  >
                      <Typography>Prepare to Export</Typography>
                  </Button>

                </Box>
              ) : (
                <></>
              )}
              {export_file_data.length === 0 ? (
                  <></>
              ) : (
                  <Button
                      sx={{ marginTop: "20px" }}
                      variant="contained"
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(export_file_data)
                      )}`}
                      download={`${collectionNameSelected}.json`}
                      endIcon={<FileDownloadIcon />}
                  >
                      <Typography>Download {collectionNameSelected}</Typography>
                  </Button>
              )}
            {/*</FormControl>*/}
          </Grid>

            <Grid item align="center" sx={{height: 200}}>
                <FormControl sx={{ width: "300px", marginTop: "20px" }}>
              <TextField
                sx={{ marginLeft: "50px" }}
                size="small"
                id="newCollectionName"
                name="newCollectionName"
                label="New Collection"
                variant="standard"
                onChange={(e) => {
                  updateNewCollectionInput(e);
                }}
                // value={collectionNameSelected}
                value={newCollectionName}
              />
              {addCollectionChooseFile === false ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ marginTop: "20px" }}
                >
                  {`Choose File`}
                  <input
                    type="file"
                    name="collectionFile"
                    hidden
                    onChange={handleFileDataUploadAddCollection}
                  />
                </Button>
              )}

              {selectedFile === null && addingFile === false ? (
                <></>
              ) : (
                <Button
                  sx={{ marginTop: "20px" }}
                  variant="contained"
                  onClick={handleImportClick}
                >
                  {`Start Import`}
                </Button>
              )}
            </FormControl>
          </Grid>

            {collections.length === 0 ? (
                <></>
            ) : (<>

                <Grid container item sx={{
                    width: "70%",
                    // position: "absolute",
                    marginTop: "300px",
                    marginLeft: "300px",
                    marginRight: "15%",
                }}>
                    <DataTableMaterialDatabase selectedData={collections}/>
                </Grid>
                <Grid
                    container
                    item
                    justifyContent="center"
                    align="center"
                    // width="100%"
                    sx={{
                        // width: "70%",
                        // position: "absolute",
                        marginTop: "300px",
                        marginBottom: "300px",
                        marginLeft: "300px",
                        marginRight: "5%",
                    }}
                    spacing={3}
                >
                    <Grid item align="center" sx={{ width: "100%" }}>
                        <Typography variant="subtitle" color="text.secondary">Available Collections</Typography>
                    </Grid>
                    {/*<Stack sx={{ position: "absolute", display: "flex" }} spacing={1}>*/}
                    {collections.map((collection, index) => (
                        <Grid item key={index} spacing={4} sx={{ display: "flex" }}>
                            <Item key={index} value={collection.name}>
                                {collection.name}
                            </Item>
                        </Grid>
                    ))}
                    {/*</Stack>*/}
                </Grid>
                </>
            )}
        </Grid>
      </>
    );
  }
}

DatabaseSettings.propTypes = {
  collections: PropTypes.array,
};

export default DatabaseSettings;
