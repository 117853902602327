import io from 'socket.io-client';
import {put, all, call, takeLatest, take, fork} from "redux-saga/effects";
import { eventChannel, END } from 'redux-saga'
import {
  ADMIN_PAGE_INIT,
  ADMIN_COLLECTION_LIST,
  ADMIN_DELETE_INIT,
  ADMIN_START_INIT,
  ADMIN_ADD_USER_INIT,
  adminError,
  adminSuccess,
  adminCollectionListSuccess,
  adminCollectionListError,
  // adminDetailError,
  // adminDetailSuccess,
  adminDeleteError,
  adminDeleteSuccess,
  adminPageInit,
  // ADMIN_START_REQUESTING,
  adminStartSuccess,
  adminAddUserSuccess,
  adminStartError,
  IMPORT_STATE_CITIES_INIT,
  IMPORT_COLLECTION_INIT,
  EXPORT_COLLECTION_INIT,
  DELETE_COLLECTION_INIT,
  importCollectionSuccess,
  exportCollectionSuccess,
  deleteCollectionSuccess,
  importStateCitiesSuccess,
  saveClientSiteKeyConfigurationInit,
  SAVE_CLIENT_SITE_KEY_CONFIGURATION_INIT,
  GET_CLIENT_SITE_KEY_CONFIGURATION_INIT,
  saveClientSiteKeyConfigurationSuccess, getClientSiteKeyConfigurationSuccess,
} from "./actions";


import {request, requestWithFile} from '../../helpers/requests';
import { urls } from '../../helpers/urls';
// import manageAdminSaga from './ManageAdmin/saga';
// import {browserRedirect} from "../../helpers/helpers";
// import {adminPadAddError, adminPadAddSuccess} from "./AdminPad/actions";



//Admin API calls
function adminCall() {
  return request('get', urls.ADMIN);
}

//Admin API calls
function adminCallCollectionsList() {
  // console.log(`calling get url on /collectionslist`)
  return request('get', urls.ADMIN + `/collectionslist`);
}

//Admin API calls
function getClientSiteKeyConfigurationInitCall(payload) {
  // console.log(`calling get url on /clientsitekeyconfiguration`)
  return request('post', urls.ADMIN + `/clientsitekeyconfiguration`, payload);
}

function saveClientSiteKeyConfigurationInitCall(payload) {

  return request('post', urls.ADMIN +'/saveclientsitekeyconfiguration', payload);
}

function adminDeleteUserCall(payload) {
  return request('delete', urls.ADMIN+'/deleteuser', payload);
}

function adminAddUserCall(payload) {
  console.log('Payload in adminAddUserCall')
  console.log(payload)
  return request('post', urls.ADMIN+'/adduser', payload);
}

function importStateCitiesInitCall() {
  return requestWithFile('get', urls.ADMIN+'/importallstatecitiescollection');
}




function importCollectionInitCall(payload) {
  console.log(`payload of import`)
  console.log(payload)
  return requestWithFile('post', urls.ADMIN+'/importcollection', payload);
}




function exportCollectionInitCall(payload) {
  console.log('Payload in exportCollectionInitCall')
  console.log(payload)
  return request('post', urls.ADMIN+'/exportcollection', payload);
}

function deleteCollectionInitCall(payload) {
  console.log('Payload in deleteCollectionInitCall')
  console.log(payload)
  return request('post', urls.ADMIN+'/deletecollection', payload);
}


// Admin Workers
function* adminWorker() {
  try {
    let response = yield call(adminCall);
    response = response.data.data;
    yield put(adminSuccess(response));
  } catch (err) {
    yield put(adminError(err.response.data.data));
  }
}


// Admin Workers
function* adminCollectionListWorker() {
  try {
    let response = yield call(adminCallCollectionsList);
    response = response.data.data;
    console.log(`adminCollectionListWorker response:`)
    console.log(response)
    yield put(adminCollectionListSuccess(response));
  } catch (err) {
    // yield put(adminCollectionListError(err.response.data.data));
    console.log(`adminCollectionListError `, err)
  }
}


function* adminDeleteUserWorker({payload}) {
  try {
    let response = yield call(adminDeleteUserCall, payload);
    response = response.data;
    yield put(adminDeleteSuccess(response));
    yield put(adminPageInit());
  } catch (err) {
    yield put(adminDeleteError(err.response.data));
  }
}



function* adminAddUserWorker({payload}) {
  try {
    let response = yield call(adminAddUserCall, payload);
    response = response.data;
    yield put(adminAddUserSuccess(response));
    /* success will have the admin page reload with the new results */
    // yield put(adminPageInit());
  } catch (err) {
    console.log(`err`);
    console.log(err);
    // yield put(adminStartError(err.response.data));
  }
}



function* importCollectionInitWorker({payload}) {
// function* importCollectionInitWorker({payload, fileData}) {
  try {
    let response = yield call(importCollectionInitCall, payload);
    response = response.data.data;
    yield put(importCollectionSuccess(response));
  } catch (err) {
    console.log(`err`);
    console.log(err);
  }
}



function* importStateCitiesInitWorker() {
  try {
    let response = yield call(importStateCitiesInitCall);
    response = response.data.data;
    yield put(importStateCitiesSuccess(response));
  } catch (err) {
    console.log(`err`);
    console.log(err);
  }
}


function* exportCollectionInitWorker({payload}) {
  try {
    let response = yield call(exportCollectionInitCall, payload);
    response = response.data.data;
    yield put(exportCollectionSuccess(response));
    /* success will have the admin page reload with the new results */
    // yield put(adminPageInit());
  } catch (err) {
    console.log(`err`);
    console.log(err);
    // yield put(adminStartError(err.response.data));
  }
}


function* deleteCollectionInitWorker({payload}) {
  try {
    let response = yield call(deleteCollectionInitCall, payload);
    response = response.data.data;
    yield put(deleteCollectionSuccess(response));
    /* success will have the admin page reload with the new results */
    // yield put(adminPageInit());
  } catch (err) {
    console.log(`err`);
    console.log(err);
    // yield put(adminStartError(err.response.data));
  }
}

function* saveClientSiteKeyConfigurationInitWorker({payload}) {
  try {
    let response = yield call(saveClientSiteKeyConfigurationInitCall, payload);
    response = response.data;
    yield put(saveClientSiteKeyConfigurationSuccess(response));
    /* success will have the admin page reload with the new results */
    // yield put(adminPageInit());
  } catch (err) {
    console.log(`err`);
    console.log(err);
    // yield put(adminStartError(err.response.data));
  }
}


// Admin Workers
function* getClientSiteKeyConfigurationInitWorker({payload}) {
  try {
    let response = yield call(getClientSiteKeyConfigurationInitCall, payload);
    response = response.data.data;
    console.log(`getClientSiteKeyConfigurationSuccess response:`)
    console.log(response)
    yield put(getClientSiteKeyConfigurationSuccess(response));
  } catch (err) {
    // console.log(`err = ${err}`)
    // yield put(adminError(err.response.data.data));
  }
}

// Admin Watcher
export default function* adminSaga() {
  yield all([
    takeLatest(ADMIN_PAGE_INIT, adminWorker),
    takeLatest(ADMIN_COLLECTION_LIST, adminCollectionListWorker),
    takeLatest(ADMIN_DELETE_INIT, adminDeleteUserWorker),
    takeLatest(ADMIN_ADD_USER_INIT, adminAddUserWorker),

    takeLatest(IMPORT_STATE_CITIES_INIT, importStateCitiesInitWorker),
    takeLatest(IMPORT_COLLECTION_INIT, importCollectionInitWorker),
    takeLatest(EXPORT_COLLECTION_INIT, exportCollectionInitWorker),
    takeLatest(DELETE_COLLECTION_INIT, deleteCollectionInitWorker),
    takeLatest(GET_CLIENT_SITE_KEY_CONFIGURATION_INIT, getClientSiteKeyConfigurationInitWorker),
    takeLatest(SAVE_CLIENT_SITE_KEY_CONFIGURATION_INIT, saveClientSiteKeyConfigurationInitWorker),
    // takeLatest(ADMIN_START_INIT, adminStartWorker),
    // take(ADMIN_PAGE_INIT, flow),
    // manageAdminSaga()
  ]);
}