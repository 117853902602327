import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Container, FormControlLabel, FormGroup,
    // FormControlLabel,
    // FormGroup,
    // InputLabel,
    // Paper,
    // Select,
    TextField, Tooltip,
    // Tooltip
} from "@mui/material";
// import {
//     stagingAccountDetailInit,
//     stagingSiteStageInit,
//     stagingSiteUpdateInit,
// } from "../Staging/actions";
// import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import SplitButton from "./SplitButton";
// import {salesForceUserTokens} from "../../helpers/helpers";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
// import Fade from "@mui/material/Fade";
import {clientSiteLaunchInit} from "../ClientSite/actions";
import {AwesomeButton} from "react-awesome-button";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import {createTheme, darken, lighten} from "@mui/material/styles";
// import {makeStyles} from "@mui/material/styles";
import {keyframes} from '@mui/system';
import Fade from "@mui/material/Fade";
import Switch from "@mui/material/Switch";
const currentUser = JSON.parse(localStorage.getItem("user"));

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


// const defaultTheme = createTheme();
// const useStyles = makeStyles(
//     (theme) => {
//         const getBackgroundColor = (color) =>
//             theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
//
//         const getHoverBackgroundColor = (color) =>
//             theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
//
//         return {
//             root: {
//                 '& div.MuiDataGrid-root': {
//                     border: "none",
//                 },
//                 '& div.MuiDataGrid-columnsContainer': {
//                     border: "none",
//                 },
//
//                 '& .rocket': {
//                     position: "absolute",
//                     bottom: "-600px",
//                     left: "35%",
//                     animation: "fly 10s .5s ease-in"
//                 },
//
//                 '& @keyframes fly': {
//                     '0%': {
//                         bottom: "-600px",
//                     },
//
//                     '100%': {
//                         bottom: "100%",
//                     }
//                 },
//             },
//             otherRoot: {
//                 '& .box-contents': {
//                     boxShadow: "0 0 50px rgba(0, 0, 0, .3)",
//                     transition: ".5s ease",
//                 },
//             }
//         };
//     },
//     {defaultTheme},
// );

const fly = keyframes`
  0% {
    bottom: -600px;
  }
  100% {
    bottom: 100%;
  }
`;


function AutoPopulatedForm() {

    const stagingAutoSelectForLaunch = useSelector((state) => state.stagings.list_staging.stagingAutoSelectForLaunch);
    const selectedStagingDetailRetrievedForLaunch = useSelector((state) => state.stagings.list_staging.selectedStagingDetailRetrievedForLaunch);


    useEffect(() => {
        // renderRefreshForm();
    }, [selectedStagingDetailRetrievedForLaunch]);


    return selectedStagingDetailRetrievedForLaunch === false ? (
        <></>
    ) : (
        <AutopopulatedFields selectedStagingDetail={stagingAutoSelectForLaunch}/>
    );
}


function AutopopulatedFields({selectedStagingDetail}) {
    const dispatch = useDispatch();
    const clientSiteLaunchInitiator = (payload) => dispatch(clientSiteLaunchInit(payload))


    const [stagingUrlOverride, setStagingUrlOverride] = useState(false)
    const [flyingRocket, setFlyingRocket] = useState(false)

    const [accountData, setAccountData] = useState({
        Staging_Site_URL__c: selectedStagingDetail.Staging_Site_URL__c == null ? '' : selectedStagingDetail.Staging_Site_URL__c,
        Website: selectedStagingDetail.Website == null ? '' : selectedStagingDetail.Website.toLowerCase(),
        // user: currentUser
        // Staging_Site_URL__c: selectedStagingDetail.Staging_Site_URL__c == null ? '' : selectedStagingDetail.Staging_Site_URL__c,
        // Staging_Site_URL__c: selectedStagingDetail.Staging_Site_URL__c == null ? "https://" + selectedStagingDetail.Name.toLowerCase()
        //     .replace(/\s/g, "")
        //     .replace(/-/g, "")
        //     .replace(/\./g, "")
        //     .replace(/[&\\#,+()$~%'"*?<>{}]/g, "") + ".viziglobal.com" : selectedStagingDetail.Staging_Site_URL__c,
    });


    useEffect(() => {

    }, [selectedStagingDetail]);


    const handleSubmit = () => {
        setFlyingRocket(true)

        // console.info(`Staging -> Launch information:`);
        // console.info();
        // console.info({
        //     accountData: accountData,
        // });

        // setTimeout(() => {
        //     clientSiteLaunchInitiator({
        //         accountData: accountData
        //     })
        // }, 6000);

        clientSiteLaunchInitiator({
                    accountData: accountData
                })

    }

    const updateFormData = event => {
        // console.log(`[event.target.name]: event.target.value`)
        // console.log(`${[event.target.name]}: ${event.target.value}`);

        setAccountData({
                ...accountData,
                [event.target.name]: event.target.value
            }
        );
    }


    // const handleLaunchOverrideSwitch = event => {
    //     setStagingUrlOverride(event.target.checked)
    // };




    const {Staging_Site_URL__c, Website} = accountData


    return (
        <>
            {selectedStagingDetail.Name == "" ? (
                <></>
            ) : (
                <Container sx={{mt: '-50px', minWidth: "320px", width: "100%"}}>
                    <FormControl sx={{marginTop:"80px", minWidth: "320px", width: "100%"}}>
                        <TextField
                            id="Staging_Site_URL__c"
                            name="Staging_Site_URL__c"
                            label="Staging Site URL"
                            variant="standard"
                            disabled={!stagingUrlOverride}
                            // defaultValue={inputValueStaging_Site_URL__c}
                            value={Staging_Site_URL__c} // configure based on name function in old vision-client
                            onChange={(event) => {
                                updateFormData(event);
                            }}
                            sx={{minWidth: "350px",}}
                            // helperText=""
                        />
                        {/*</Box>*/}
                        <TextField
                            sx={{mt: "10px", mb: "40px"}}
                            size="small"
                            id="Website"
                            name="Website"
                            label="Live Address"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Website}
                            helperText="no https or www"
                        />

                        <AwesomeButton type="primary" onPress={handleSubmit}>
                            Launch
                            <RocketLaunchIcon
                                sx={{
                                    // position: "absolute",
                                    marginLeft: '10px',
                                    // animation: `${fly} 10s .5s ease-in`
                                }}/>
                        </AwesomeButton>

                    </FormControl>
                    <Box>
                        {flyingRocket === false ? null :
                            (
                                <Box sx={{
                                    zIndex: 1000,
                                    position: "absolute",
                                    bottom: "-600px",
                                    left: "35%",
                                    animation: `${fly} 5s .5s ease-in`
                                }}>
                                    <img className="rocket launch" alt="rocket"
                                         src="https://cdn4.iconfinder.com/data/icons/whsr-january-flaticon-set/512/rocket.png"/>
                                </Box>
                            )}
                    </Box>
                </Container>
            )}
        </>
    );
}

// AutoPopulatedForm.propTypes = {
//     accountInformation: PropTypes.object,
// };

export default AutoPopulatedForm;
