// import { CALL_CHAT_INIT_SUCCESS, CURRENT_CHAT_LOG, CALL_CHAT_INIT, CHAT_LOGS} from './actions';
// import userGPTReducer from "./redux/user";
// import loadingReducer from "./redux/loading";
// import historyReducer from "./redux/history";
// import messagesReducer from "./redux/messages";

import user from "./redux/user";
import loading from "./redux/loading";
import history from "./redux/history";
import messages from "./redux/messages";

import { combineReducers } from "redux";


// The initial state of the Login Reducer
// export const initialState = {
    // successful: false,
    // currentChatLog: [],
    // chatLogs: [],
    // botResponse:{}
  // };


// const openAIReducer = function(state = initialState,actions){
//     switch(actions.type){
//         // case CALL_CHAT_INIT:
//         //     return {...state, botResponse: {...actions.payload}};
//         // case CALL_CHAT_INIT_SUCCESS:
//         //     return {...state, botResponse: {...actions.payload}};
//         case CURRENT_CHAT_LOG:
//             return {...state, botResponse: {...actions.payload}};
//         case CHAT_LOGS:
//             return {...state, botResponse: {...actions.payload}};
//
//         default:
//             return state;
//     }
// }

// export default combineReducers({
//     // list_chat : openAIReducer,
//     user: userGPTReducer,
//     loading: loadingReducer,
//     history: historyReducer,
//     messages: messagesReducer
// });

export default combineReducers({
    user: user,
    loading: loading,
    history: history,
    messages: messages
});