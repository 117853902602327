export const USERAVATAR_ADD_REQUESTING = 'USERAVATAR_ADD_REQUESTING';
export const USERAVATAR_ADD_SUCCESS = 'USERAVATAR_ADD_SUCCESS';
export const USERAVATAR_ADD_ERROR = 'USERAVATAR_ADD_ERROR';
export const USERAVATAR_ADD_PAGE_INIT = 'USERAVATAR_ADD_PAGE_INIT';

export const USERAVATAR_UPDATE_REQUESTING = 'USERAVATAR_UPDATE_REQUESTING';
export const USERAVATAR_UPDATE_SUCCESS = 'USERAVATAR_UPDATE_SUCCESS';
export const USERAVATAR_UPDATE_ERROR = 'USERAVATAR_UPDATE_ERROR';



export function userAvatarAddPageInit() {
    return {
        type: USERAVATAR_ADD_PAGE_INIT,
    };
}

export function userAvatarAddRequest(payload) {
    return {
        type: USERAVATAR_ADD_REQUESTING,
        payload
    };
}

export function userAvatarAddError(error) {
    return {
        type: USERAVATAR_ADD_ERROR,
        error,
    };
}

export function userAvatarAddSuccess() {
    return {
        type: USERAVATAR_ADD_SUCCESS,
    };
}

export function userAvatarUpdateRequest(payload,id) {
    return {
        type: USERAVATAR_UPDATE_REQUESTING,
        payload,
        id
    };
}

export function userAvatarUpdateError(error) {
    return {
        type: USERAVATAR_UPDATE_ERROR,
        error,
    };
}

export function userAvatarUpdateSuccess() {
    return {
        type: USERAVATAR_UPDATE_SUCCESS,
    };
}