import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  CLIENTCASE_PAGE_INIT,
  CLIENTCASE_LIST_INIT,
  CLIENTCASE_UPDATE_INIT,
  CLIENTCASE_LAUNCH_INIT,
  // CLIENTCASE_DETAIL_INIT,
  // CLIENTCASE_DELETE_INIT,
  CLIENTCASE_START_INIT,
  clientCaseError,
  clientCaseSuccess,
  // clientCaseDetailError,
  // clientCaseDetailSuccess,
  // clientCaseDeleteError,
  // clientCaseDeleteSuccess,
  clientCasePageInit,
  // CLIENTCASE_START_REQUESTING,
  clientCaseListSuccess,
  clientCaseUpdateSuccess,
  clientCaseLaunchSuccess,
  clientCaseStartSuccess,
  clientCaseStartError, CLIENTCASE_LIST_REFRESH_INIT, clientCaseListRefreshSuccess,
} from "./actions";


import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
// import manageClientCaseSaga from './ManageClientCase/saga';
import {browserRedirect} from "../../helpers/helpers";
// import {browserRedirect} from "../../helpers/helpers";
// import {clientCasePadAddError, clientCasePadAddSuccess} from "./ClientCasePad/actions";

//ClientCase API calls
function clientCaseCall() {
  return request('get', urls.CLIENTCASE);
}



//clientCaseStart API call
function clientCaseStartCall(id) {
  return request('post', urls.CLIENTCASE+'/'+id);
}

//clientCaseListCall API call
function clientCaseListCall() {
  // console.log('Payload in clientCaseListCall call')
  // console.log(payload)
  return request('get', urls.CLIENTCASE+'/clientcaselist');
}

//clientCaseListCall API call
// function clientCaseListRefreshInitCall() {
//   // console.log('Payload in clientCaseListCall call')
//   // console.log(payload)
//   return request('get', urls.CLIENTCASE+'/clientsitelistrefresh');
// }
//
//
// //clientCaseUpdate API call
// function clientCaseUpdateCall(payload) {
//   console.log('Payload in clientCaseUpdateCall call')
//   console.log(payload)
//   return request('post', urls.CLIENTCASE+'/clientsiteupdate', payload);
// }
//
//
// //clientCaseUpdate API call
// function clientCaseLaunchCall(payload) {
//   console.log('Payload in clientCaseLaunchCall call')
//   console.log(payload)
//   return request('post', urls.CLIENTCASE+'/clientsitelaunch', payload);
// }


// ClientCase Workers
// function* clientCaseWorker() {
//   try {
//     let response = yield call(clientCaseCall);
//     response = response.data.data;
//     yield put(clientCaseSuccess(response));
//   } catch (err) {
//     yield put(clientCaseError(err.response.data.data));
//   }
// }

function* clientCaseListWorker() {
  try {
    let response = yield call(clientCaseListCall);
    response = response.data.data;
    console.log(" clientCaseListWorker response = ")
    console.log(response)
    yield put(clientCaseListSuccess(response));
  } catch (err) {
    console.log('err in clientCaseListWorker in clientCase saga' + err)
    // yield put(clientCaseDetailError(err.response.data.data));
  }
}

// function* clientCaseListRefreshInitWorker() {
//   try {
//     let response = yield call(clientCaseListRefreshInitCall);
//     response = response.data.data;
//     console.log(" clientCaseListWorker response = ")
//     console.log(response)
//     yield put(clientCaseListRefreshSuccess(response));
//   } catch (err) {
//     console.log('err in clientCaseListWorker in clientCase saga' + err)
//     // yield put(clientCaseDetailError(err.response.data.data));
//   }
// }
//
// function* clientCaseUpdateWorker(payload) {
//   try {
//     let response = yield call(clientCaseUpdateCall, payload.payload);
//     response = response.data.data;
//     console.log(" clientCaseUpdateWorker response = ")
//     console.log(response)
//     yield put(clientCaseUpdateSuccess(response));
//   } catch (err) {
//     console.log('err in clientCaseUpdateWorker in clientCase saga' + err)
//     // yield put(clientCaseDetailError(err.response.data.data));
//   }
// }
//
// function* clientCaseLaunchWorker(payload) {
//   try {
//     let response = yield call(clientCaseLaunchCall, payload.payload);
//     response = response.data.data;
//     console.log(" clientCaseLaunchWorker response = ")
//     console.log(response)
//     // yield put(clientCaseLaunchSuccess(response));
//     yield call(browserRedirect,'/clients/launch');
//   } catch (err) {
//     console.log('err in clientCaseLaunchSuccess in clientCase saga' + err)
//     // yield put(clientCaseDetailError(err.response.data.data));
//   }
// }

// function* clientCaseDetailWorker(payload) {
//   try {
//     let response = yield call(clientCaseDetailCall, payload.id);
//     response = response.data.data;
//     yield put(clientCaseDetailSuccess(response));
//   } catch (err) {
//     yield put(clientCaseDetailError(err.response.data.data));
//   }
// }

// function* clientCaseDeleteWorker(payload) {
//   try {
//     let response = yield call(clientCaseDeleteCall, payload.id);
//     response = response.data;
//     yield put(clientCaseDeleteSuccess(response));
//     yield put(clientCasePageInit());
//   } catch (err) {
//     yield put(clientCaseDeleteError(err.response.data));
//   }
// }


// function* clientCaseStartWorker(payload) {
//   try {
//     let response = yield call(clientCaseStartCall, payload.id);
//     response = response.data;
//     yield put(clientCaseStartSuccess(response));
//     /* success will have the clientCase page reload with the new results */
//     yield put(clientCasePageInit());
//   } catch (err) {
//     yield put(clientCaseStartError(err.response.data));
//   }
// }


// ClientCase Watcher
export default function* clientCasesSaga() {
  yield all([
    takeLatest(CLIENTCASE_LIST_INIT, clientCaseListWorker),
    // takeLatest(CLIENTCASE_LIST_REFRESH_INIT, clientCaseListRefreshInitWorker),
    // takeLatest(CLIENTCASE_UPDATE_INIT, clientCaseUpdateWorker),
    // takeLatest(CLIENTCASE_LAUNCH_INIT, clientCaseLaunchWorker),
    // takeLatest(CLIENTCASE_PAGE_INIT, clientCaseWorker),
    // takeLatest(CLIENTCASE_DETAIL_INIT, clientCaseDetailWorker),
    // takeLatest(CLIENTCASE_DELETE_INIT, clientCaseDeleteWorker),
    // takeLatest(CLIENTCASE_START_INIT, clientCaseStartWorker),
    // takeEvery(CLIENTCASE_START_REQUESTING, clientCaseStartWorker),
    // manageClientCaseSaga()
  ]);
}