import React, { useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
     getClientSiteKeyConfigurationInit, saveClientSiteKeyConfigurationInit,
} from "./actions";
import {Box, Button, Grid, TextField} from "@mui/material";
import SecondDrawer from "./SecondDrawer";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import RoleBasedComponent from "../../helpers/RoleBasedComponent";

export default function ClientSiteConfiguration() {
    const dispatch = useDispatch();
    const getClientSiteKeyConfigurationInitiated = (payload) => dispatch(getClientSiteKeyConfigurationInit(payload));

    const siteConfigurationKeys = useSelector((state) => state.admin.list_admin.siteConfigurationKeys[0]);



    useEffect(() => {
        getClientSiteKeyConfigurationInitiated({});
    }, []);

    useEffect(() => {
    }, [siteConfigurationKeys]);


    return siteConfigurationKeys.viziUniqueValueParam == '' ? (
        <></>
        ) : (
                <ClientSiteConfigurationForm siteConfigurationKeys={siteConfigurationKeys}/>
    )
}







function ClientSiteConfigurationForm({siteConfigurationKeys}) {
    const dispatch = useDispatch();
    const saveClientSiteKeyConfigurationInitiated = (payload) => dispatch(saveClientSiteKeyConfigurationInit(payload));


    const handleSubmitNewKeys = (e) => {
        saveClientSiteKeyConfigurationInitiated({vizikeys: formData})
    }

    const [formData, setFormData] = useState({
        viziUsernameQueryParam: siteConfigurationKeys.viziUsernameQueryParam == null ? '' : siteConfigurationKeys.viziUsernameQueryParam,
        viziPasswordQueryParam: siteConfigurationKeys.viziPasswordQueryParam == null ? '' : siteConfigurationKeys.viziPasswordQueryParam,
        viziUniqueQueryParam: siteConfigurationKeys.viziUniqueQueryParam == null ? '' : siteConfigurationKeys.viziUniqueQueryParam,
        viziUniqueValueParam: siteConfigurationKeys.viziUniqueValueParam == null ? '' : siteConfigurationKeys.viziUniqueValueParam
    })


    useEffect(() => {

    }, [siteConfigurationKeys])




    const updateFormData = event =>
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
            // .replace(/\s/g, "")
            //     .replace(/-/g, "")
            //     .replace(/\./g, "")
            //     .replace(/[&\\#,+()$~%'"*?<>{}]/g, "")
        });




    const {viziUsernameQueryParam, viziPasswordQueryParam, viziUniqueQueryParam, viziUniqueValueParam} = formData

    return (
        <>
        {siteConfigurationKeys.viziUniqueValueParam == "" ? (
            <></>
        ) : (
            <>
        <Box
            id="secondDrawer"
            sx={{
                marginTop: "100px", zIndex: 1, position: "absolute",
            }}
        >
            <SecondDrawer/>
        </Box>

        <Grid
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid item align="center" sx={{height: 200}}>
                <FormControl sx={{maxWidth: "600px", marginTop: "20px"}}>
                    <Grid item align="center" sx={{height: 100, marginBottom:"100px"}}>
                        <PrettyPrintJson data={siteConfigurationKeys}/>
                    </Grid>
                    <Grid item align="center" sx={{height: 100}}>
                        <TextField
                            sx={{marginLeft: "50px"}}
                            size="small"
                            id="newViziUsernameQueryParam"
                            name="viziUsernameQueryParam"
                            label="ViziKey"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            // value={collectionNameSelected}
                            value={viziUsernameQueryParam}
                        />
                    </Grid>
                    <Grid item align="center" sx={{height: 100}}>
                        <TextField
                            sx={{marginLeft: "50px"}}
                            size="small"
                            id="newViziPasswordQueryParam"
                            name="viziPasswordQueryParam"
                            label="ViziPassword"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            // value={collectionNameSelected}
                            value={viziPasswordQueryParam}
                        />
                    </Grid>
                    <Grid item align="center" sx={{height: 100}}>
                        <TextField
                            sx={{marginLeft: "50px"}}
                            size="small"
                            id="newViziUniqueQueryParam"
                            name="viziUniqueQueryParam"
                            label="ViziUnique Query Key"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            // value={collectionNameSelected}
                            value={viziUniqueQueryParam}
                        />
                    </Grid>
                    <Grid item align="center" sx={{height: 100}}>
                        <TextField
                            sx={{marginLeft: "50px"}}
                            size="small"
                            id="newViziUniqueQueryKeyValueParam"
                            name="viziUniqueValueParam"
                            label="ViziUnique Query Key Value"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            // value={collectionNameSelected}
                            value={viziUniqueValueParam}
                        />
                    </Grid>
                <Grid item align="center" sx={{height: 100, marginTop: "20px"}}>
                    <Typography variant="subtitle" sx={{marginTop: "50px"}}>
                        https://domain.com/wp-login.php?{viziUsernameQueryParam}={'adminName'}?{viziPasswordQueryParam}={''}?{viziUniqueQueryParam}?={viziUniqueValueParam}
                    </Typography>
                </Grid>
                </FormControl>
                <Grid item align="center" sx={{height: 100, marginTop: "20px"}}>
                    <Button
                        sx={{}}
                        variant="contained"
                        onClick={handleSubmitNewKeys}
                        disabled={RoleBasedComponent({ roleNeeded: "SUPER_ADMIN" })}
                    >
                        {`Save`}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
            </>
            )}
        </>
    );
}
