import React, { useRef, } from "react";





const RoleBasedComponent = ({roleNeeded}) => {

    const ref = useRef("");

    let currentRoleOfUser = JSON.parse(localStorage.getItem("roles"));

    // Get index of current user role
    // Receive a passed role and get index of that role
    // if current user role is within index range
    /***********

     current user = accountManager

     0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,

    *************/

    // let superAdmin = Roles.SUPER_ADMIN
    // let admin = Roles.ADMIN
    // let accountManager = Roles.ACCOUNT_MANAGER
    // let salesManager = Roles.SALES_MANAGER
    // let salesImc = Roles.SALES_IMC

console.log(`currentRoleOfUser = ${currentRoleOfUser}`)
console.log(`roleNeeded = ${roleNeeded}`)

    // const renderDebuggingInformation = ({ roleNeeded }) => {
        if (currentRoleOfUser != roleNeeded) {
            return true;
        } else if (currentRoleOfUser == roleNeeded) {
            return false;
        }
    // };



    // return <>{renderDebuggingInformation({ roleNeeded })}</>;


}


export default RoleBasedComponent