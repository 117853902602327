import React from 'react';



function MapAllowedRoutes(routes) {
    return routes.map((route) => {

        const {path, element: Component, children, ...rest} = route;

        // if element, return normal route
        if (!children){
        return (
            {
                path: path,
                element: <Component />,
                ...rest
            }
        )
        } else {
            return (
                {
                    path: path,
                    children,
                    ...rest
                }
            )
        }
    })
}



export default MapAllowedRoutes;
