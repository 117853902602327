import React, {useEffect, memo} from 'react';
import {Collapse} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {salesforceDealsThisMonthPageInit} from "../Salesforce/actions";
import {TransitionGroup} from "react-transition-group";
import UserCardGeneric from "../User/UserCardGeneric";
import UserCardZeroOccurrences from "../User/UserCardZeroOccurrences";


function LeaderboardSales() {
    const dispatch = useDispatch();
    const dealsThisMonth = useSelector((state) => state.salesforce.signed_in.dealsThisMonth);

    const dealsThisMonthOnPageInit = (payload) => dispatch(salesforceDealsThisMonthPageInit(payload));

    let sf_session_accessToken = localStorage.getItem("sf_session_accessToken");
    let sf_session_instanceUrl = localStorage.getItem("sf_session_instanceUrl");
    let sf_session_refreshToken = localStorage.getItem("sf_session_refreshToken");

    useEffect(() => {
        dealsThisMonthOnPageInit({
            code: {
                sf_session_accessToken: sf_session_accessToken,
                sf_session_instanceUrl: sf_session_instanceUrl,
                sf_session_refreshToken: sf_session_refreshToken,
            },
        });
    }, []);




    return(

            <Grid
                id="salesMainBox"
                sx={{
                    minHeight: "800px",
                    // boxShadow: 2,
                    // paddingTop: "20px",
                    // paddingLeft: "20px",
                    // paddingRight: "20px",
                    minWidth: "200px",
                    maxWidth: "525px",
                    width: "525px",
                    marginTop: "-30px",
                }}
            >
                <Typography align="center" gutterBottom sx={{fontSize: 30}} >
                    Top Closer
                </Typography>

                <Grid>

                    <TransitionGroup>
                        {dealsThisMonth.map((user, index) => (
                            <Collapse key={index}
                                      timeout={{ appear: index * 1000, enter: 10000, exit: 250 }}
                                      style={{ transitionDelay: `${index * 1000}ms` }}
                            >
                            {user.occurrence > 0 ?
                                <UserCardGeneric userAccount={user} index={index} imageData={user.cardImage}/>
                            :
                                <UserCardZeroOccurrences userAccount={user} index={index} imageData={user.cardImage}/>
                            }
                            </Collapse>
                        ))}
                    </TransitionGroup>
                    {/*<PrettyPrintJson data={dealsThisMonth}/>*/}
                </Grid>
            </Grid>

    )

}

export default memo(LeaderboardSales)