import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PetsIcon from "@mui/icons-material/Pets";
import PublicIcon from "@mui/icons-material/Public";
import Switch from '@mui/material/Switch';
import {
    Box,
    Container,
    FormControlLabel,
    FormGroup,
    InputLabel,
    Select,
    TextField, Tooltip
} from "@mui/material";
import {
    stagingAccountDetailInit,
    stagingSiteStageInit,
    stagingSiteUpdateInit,
} from "./actions";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SplitButton from "./SplitButton";
import {salesForceUserTokens} from "../../helpers/helpers";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import Fade from "@mui/material/Fade";


function AutoPopulatedForm() {
    const dispatch = useDispatch();
    const getAccountStagingDetails = (payload) => dispatch(stagingAccountDetailInit(payload));

    const stagingAutoSelect = useSelector((state) => state.stagings.list_staging.stagingAutoSelect);

    const stagingAutoSelectName = stagingAutoSelect.Name;

    const selectedStagingDetail = useSelector((state) => state.stagings.list_staging.selectedStaging[0]);

    const selectedStagingDetailRetrieved = useSelector((state) => state.stagings.list_staging.selectedStagingDetailRetrieved);

    let sf_session_accessToken = localStorage.getItem("sf_session_accessToken");
    let sf_session_instanceUrl = localStorage.getItem("sf_session_instanceUrl");
    let sf_session_refreshToken = localStorage.getItem("sf_session_refreshToken");


    const handleSelectorRefresh = () => {
        if (!stagingAutoSelectName) {
        } else if (stagingAutoSelectName) {
            getAccountStagingDetails({
                accountData: stagingAutoSelect,
                userTokens: {
                    sf_session_accessToken: sf_session_accessToken,
                    sf_session_instanceUrl: sf_session_instanceUrl,
                    sf_session_refreshToken: sf_session_refreshToken,
                },
            });
        }
    };


    useEffect(() => {
        handleSelectorRefresh();
    }, [stagingAutoSelectName]);


    useEffect(() => {
        // renderRefreshForm();
    }, [selectedStagingDetailRetrieved]);


    return selectedStagingDetailRetrieved === false ? (
        <></>
    ) : (
        <AutopopulatedFields selectedStagingDetail={selectedStagingDetail}/>
    );
}


function AutopopulatedFields({selectedStagingDetail}) {
    const dispatch = useDispatch();
    const stagingSiteUpdateInitiator = (payload) => dispatch(stagingSiteUpdateInit(payload));
    const stagingSiteStageInitiator = (payload) => dispatch(stagingSiteStageInit(payload));


    const [stagingUrlOverride, setStagingUrlOverride] = useState(false)

    const [accountData, setAccountData] = useState({
            Id: selectedStagingDetail.Id == null ? '' : selectedStagingDetail.Id,
            Name: selectedStagingDetail.Name == null ? '' : selectedStagingDetail.Name,
            Staging_Site_URL__c: selectedStagingDetail.Staging_Site_URL__c == null ? "https://" + selectedStagingDetail.Name.toLowerCase()
                .replace(/\s/g, "")
                .replace(/-/g, "")
                .replace(/\./g, "")
                .replace(/[&\\#,+()$~%'"*?<>{}]/g, "") + ".viziglobal.com" : selectedStagingDetail.Staging_Site_URL__c,
            Business_Email__c: selectedStagingDetail.Business_Email__c == null ? '' : selectedStagingDetail.Business_Email__c,
            ShippingStreet: selectedStagingDetail.ShippingStreet == null ? '' : selectedStagingDetail.ShippingStreet,
            ShippingCity: selectedStagingDetail.ShippingCity == null ? '' : selectedStagingDetail.ShippingCity,
            ShippingState: selectedStagingDetail.ShippingState == null ? '' : selectedStagingDetail.ShippingState,
            ShippingPostalCode: selectedStagingDetail.ShippingPostalCode == null ? '' : selectedStagingDetail.ShippingPostalCode,
            Business_Phone__c: selectedStagingDetail.Business_Phone__c == null ? '' : selectedStagingDetail.Business_Phone__c.replace(/[&\\#,+()$~%'"*?<>{}]/g, "").replace(/\s/g, "-"),
            Hours_Line_1__c: selectedStagingDetail.Hours_Line_1__c == null ? '' : selectedStagingDetail.Hours_Line_1__c,
            Hours_Line_2__c: selectedStagingDetail.Hours_Line_2__c == null ? '' : selectedStagingDetail.Hours_Line_2__c,
            Hours_Line_3__c: selectedStagingDetail.Hours_Line_3__c == null ? '' : selectedStagingDetail.Hours_Line_3__c,
            Hours_Line_4__c: selectedStagingDetail.Hours_Line_4__c == null ? '' : selectedStagingDetail.Hours_Line_4__c,
            Industry: selectedStagingDetail.Industry == null ? '' : selectedStagingDetail.Industry,
            Initial_Investment__c: selectedStagingDetail.Initial_Investment__c == null ? '' : selectedStagingDetail.Initial_Investment__c,
            coreSelect: 'vet_core'
        }
    );


    useEffect(() => {

    }, [selectedStagingDetail]);
    // }, [selectedStagingDetail, inputValueName]);

    const siteCores = [
        {value: 'vet_core', label: 'ViziCore', coreIcon: <PublicIcon/>},
        // {value: 'vet_core_1', label: 'Vet Free', coreIcon: <PetsIcon/> },
        // {value: 'global_core', label: 'Global', coreIcon: <PublicIcon/>},
    ]


    const handleSubmit = (buttonValue) => {
        // console.info(`You clicked ${buttonValue}`);
        switch (buttonValue) {
            case 'Stage Site':
                stagingSiteStageInitiator({
                    accountData: accountData,
                    userTokens: salesForceUserTokens()
                })
                break;
            case 'Update Data & Stage Site':
                stagingSiteUpdateInitiator({
                    accountData: accountData,
                    userTokens: salesForceUserTokens()
                });
                stagingSiteStageInitiator({
                    accountData: accountData,
                    userTokens: salesForceUserTokens()
                })
                break;
            default:
                console.log('Empty action received.');
        }
    }

    const updateFormData = event => {
        // console.log(`[event.target.name]: event.target.value`)
        // console.log(`${[event.target.name]}: ${event.target.value}`);

        setAccountData({
                ...accountData,
                [event.target.name]: event.target.value
            }
        );
    }


    const handleStagingOverrideSwitch = event => {
        setStagingUrlOverride(event.target.checked)
    };


    const makeStagingUrl = (businessName) => {
        if (stagingUrlOverride === true) {
            return Staging_Site_URL__c
        } else {
            let newUrl = businessName.toLowerCase()
                .replace(/\s/g, "")
                .replace(/-/g, "")
                .replace(/\./g, "")
                .replace(/[&\\#,+()$~%'"*?<>{}]/g, "")
                .substring(0,40) + ".viziglobal.com";
            return "https://" + newUrl
        }
    }

    const {
        Id,
        Name,
        Staging_Site_URL__c,
        Business_Email__c,
        ShippingStreet,
        ShippingCity,
        ShippingState,
        ShippingPostalCode,
        Business_Phone__c,
        Hours_Line_1__c,
        Hours_Line_2__c,
        Hours_Line_3__c,
        Hours_Line_4__c,
        Initial_Investment__c,
        Industry,
        coreSelect
    } = accountData


    return (
        // <Container>
        <>
            {selectedStagingDetail.Name == "" ? (
                <></>
            ) : (
                <Container sx={{mt: '-50px', minWidth: "320px", width: "100%"}}>
                    <FormControl sx={{marginTop:"80px", minWidth: "320px", width: "100%"}}>
                            <Tooltip
                                title={`Override standardized staging address formatting`}
                                arrow
                                enterDelay={200}
                                leaveDelay={200}
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 300}}
                            >
                                <FormGroup><FormControlLabel control={<Switch checked={stagingUrlOverride} onChange={handleStagingOverrideSwitch}/>} label=""/></FormGroup>
                            </Tooltip>
                            <TextField
                                id="Staging_Site_URL__c"
                                name="Staging_Site_URL__c"
                                label="Staging Site URL"
                                variant="standard"
                                disabled={!stagingUrlOverride}
                                value={makeStagingUrl(Name)} // configure based on name function in old vision-client
                                onChange={(event) => {
                                    updateFormData(event);
                                }}
                                sx={{minWidth: "350px", width: "100%"}}
                                // sx={{minWidth: "350px", width: "100%", marginLeft: "-15px"}}
                                // helperText=""
                            />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Business_Email__c"
                            name="Business_Email__c"
                            label="Business Email"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Business_Email__c}
                        />

                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="ShippingStreet"
                            name="ShippingStreet"
                            label="Shipping Street"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={ShippingStreet}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="ShippingCity"
                            name="ShippingCity"
                            label="Shipping City"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={ShippingCity}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="ShippingState"
                            name="ShippingState"
                            label="Shipping State"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={ShippingState}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="ShippingPostalCode"
                            name="ShippingPostalCode"
                            label="Shipping Postal Code"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={ShippingPostalCode}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Business_Phone__c"
                            name="Business_Phone__c"
                            label="Business Phone"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Business_Phone__c}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Hours_Line_1__c"
                            name="Hours_Line_1__c"
                            label="Hours Line 1"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Hours_Line_1__c}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Hours_Line_2__c"
                            name="Hours_Line_2__c"
                            label="Hours Line 2"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Hours_Line_2__c}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Hours_Line_3__c"
                            name="Hours_Line_3__c"
                            label="Hours Line 3"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Hours_Line_3__c}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Hours_Line_4__c"
                            name="Hours_Line_4__c"
                            label="Hours Line 4"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Hours_Line_4__c}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Industry"
                            name="Industry"
                            label="Industry"
                            variant="standard"
                            onChange={(e) => {
                                updateFormData(e);
                            }}
                            value={Industry}
                        />
                        <TextField
                            sx={{mt: "3px"}}
                            size="small"
                            id="Initial_Investment__c"
                            name="Initial_Investment__c"
                            label="Initial Investment"
                            variant="standard"
                            onChange={(event) => {
                                updateFormData(event);
                            }}
                            value={Initial_Investment__c}
                        />

                        <FormControl
                            sx={{mt: 3, mb:3}}
                        >
                            <InputLabel id="demo-multiple-name-label">
                                Core Selection
                            </InputLabel>
                            <Select
                                value={coreSelect}
                                onChange={updateFormData}
                                // autoWidth
                                labelId="Core Selection"
                                name="coreSelect"
                                variant="standard"
                            >
                                {siteCores.map((core) => (
                                    <MenuItem key={core.value} value={core.value}>
                                        {core.label} {core.coreIcon}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormControl>
                    <SplitButton handleSubmit={handleSubmit}/>
                </Container>
            )}
            {/*<PrettyPrintJson data={accountData}/>*/}
        </>
    );
}

// AutoPopulatedForm.propTypes = {
//     accountInformation: PropTypes.object,
// };

export default AutoPopulatedForm;
