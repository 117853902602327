import {
    CLIENT_PAGE_INIT,
    CLIENT_ERROR,
    CLIENT_SUCCESS,
    CLIENT_DETAIL_INIT,
    CLIENT_DETAIL_ERROR,
    CLIENT_DETAIL_SUCCESS,
    CLIENT_DETAIL_CLOSE,
    CLIENT_DELETE_ERROR,
    CLIENT_DELETE_SUCCESS,
    CLIENT_START_ERROR,
    CLIENT_START_SUCCESS,
    RELEASE_STATE_DATA
} from './actions';
import { combineReducers } from "redux";
import  manageClientReducer  from './ManageClient/reducer';
import  clientPadReducer  from './ClientPad/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    clients: [],
    selectedClient: {},
    selectedClientError: {},
    deleteClient: {}
  };

const clientReducer = function(state = initialState,actions){
    switch(actions.type){
        case CLIENT_PAGE_INIT:
            return {...state, errors:[], clients: []};
        case CLIENT_SUCCESS:
            return {...state, successful: true, clients:[...actions.payload]};
        case CLIENT_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        case CLIENT_DETAIL_INIT:
            return {...state, selectedClientError:{}, selectedClient: {}};
        case CLIENT_DETAIL_SUCCESS:
            return {...state, selectedClient: {...actions.payload}};
        case CLIENT_DETAIL_ERROR:
            return {...state, selectedClientError:{...actions.error}};
        case CLIENT_DETAIL_CLOSE:
            return {...state, selectedClientError:{}, selectedClient: {}};
        case CLIENT_DELETE_SUCCESS:
            return {...state, deleteClient: {...actions.payload}};
        case CLIENT_DELETE_ERROR:
            return {...state, selectedClientError:{...actions.error}};
        case CLIENT_START_SUCCESS:
            return {...state, startClient: {...actions.payload}};
        case CLIENT_START_ERROR:
            return {...state, selectedClientError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], clients: [], selectedClient: {},selectedClientError: {},deleteClient: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_client : clientReducer,
    manage_client: manageClientReducer,
    client_pad: clientPadReducer
});