import {Avatar, Button, Card, CardHeader, Grid, Slider, Stack, Tooltip} from "@mui/material";
import React, {memo, useState} from "react";
// import {useStyles} from "../Dashboard/LeaderboardStyles";
// import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import './usercard.css'
// import styled from 'styled-components'
// import {useState} from "@types/react";
import {useDispatch} from "react-redux";
import {importUserImageInit} from "./actions";
import FormControl from "@mui/material/FormControl";
import Fade from "@mui/material/Fade";
import LockIcon from '@mui/icons-material/Lock';

function ProfileCardImageForm({userAccount}) {
    const [selectedFile, setSelectedFile] = useState(null);

    const dispatch = useDispatch();

    const importUserImageInitiator = (payload) =>
        dispatch(importUserImageInit(payload));

    // console.log(`userAccount.customCardImageUnlocked = ` + userAccount.customCardImageUnlocked)


    const handleFileDataUploadAddCollection = (e) => {
        // console.log(`e.target.files[0]`)
        // console.log(e.target.files[0])
        setSelectedFile(e.target.files[0]);
    };


    const handleImportClick = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append(`${selectedFile.name}`, selectedFile);
        importUserImageInitiator({
            formData,
        });
    };




    return (<>
            <FormControl
                sx={{
                    // position: "absolute",
                    marginTop: "40px",
                    marginLeft: "-20px",
                    width: "150px"
                }}>
                {
                    selectedFile === null ? (
                        <Button
                            variant="contained"
                            // small="true"
                            component="label"
                            sx={{}}
                        >
                            {`change background`}
                            <input
                                type="file"
                                name="collectionFile"
                                hidden
                                onChange={handleFileDataUploadAddCollection}
                            />
                        </Button>
                    ) : (
                        <Button
                            // disabled={true}
                            // sx={{ marginTop: "20px" }}
                            variant="contained"
                            onClick={handleImportClick}
                        >
                            {`Start Import`}
                        </Button>
                    )}
            </FormControl>
            <FormControl sx={{position: "absolute", marginTop: "-100px", marginLeft: "770px", width: "150px"}}>
                {selectedFile === null ? (
                    <>
                    </>
                ) : (<></>
                )}
            </FormControl>


        </>
    )
}

export default ProfileCardImageForm
// export default memo(ProfileCardImageForm)
