import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  NATIONSTATE_PAGE_INIT,
  NATIONSTATE_DETAIL_INIT,
  NATIONSTATE_DELETE_INIT,
  nationStateError,
  nationStateSuccess,
  nationStateDetailError,
  nationStateDetailSuccess,
  nationStateDeleteError,
  nationStateDeleteSuccess,
  nationStatePageInit
} from "./actions";
import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
// import manageNationStateSaga from './Scrapper/saga';

//NationState API calls
function nationStateCall() {
  return request('get', urls.NATIONSTATE);
}

// function nationStateDetailCall(id) {
//   return request('get', urls.NATIONSTATE+'/'+id);
// }

// Get list of States we have data on, with or without scrapped
function nationStateListCall() {
  // console.log(`payload id`)
  // console.log(id)
  return request('get', urls.NATIONSTATE+`/list`);
}

function nationStateDetailCall(stateName) {
  // console.log(`payload id`)
  // console.log(id)
  return request('post', urls.NATIONSTATE+`/state`, {name: stateName});
}

function nationStateDeleteCall(id) {
  return request('delete', urls.NATIONSTATE+'/'+id);
}

// NationState Workers
// function* nationStateListWorker() {
//   try {
//     let response = yield call(nationStateListCall);
//     response = response.data.data;
//     yield put(nationStateListSuccess(response));
//   } catch (err) {
//     yield put(nationStateListError(err.response.data.data));
//   }
// }

// NationState Workers
function* nationStateWorker() {
  try {
    let response = yield call(nationStateCall);
    response = response.data.data;
    yield put(nationStateSuccess(response));
  } catch (err) {
    yield put(nationStateError(err.response.data.data));
  }
}

function* nationStateDetailWorker(payload) {
  try {
    console.log(`payload`)
    console.log(payload)
    let response = yield call(nationStateDetailCall, payload.id);
    response = response.data.data;
    console.log(`response.data.data`)
    console.log(response)
    yield put(nationStateDetailSuccess(response));
  } catch (err) {
    console.log('Im in error ', err)
    yield put(nationStateDetailError(err.response.data.data));
  }
}

function* nationStateDeleteWorker(payload) {
  try {
    let response = yield call(nationStateDeleteCall, payload.id);
    response = response.data;
    yield put(nationStateDeleteSuccess(response));
    yield put(nationStatePageInit());
  } catch (err) {
    console.log('Im in error ', err)
    // yield put(nationStateDeleteError(err.response.data));
  }
}

// NationState Watcher
export default function* nationStateSaga() {
  yield all([
    // takeLatest(NATIONSTATE_PAGE_INIT, nationStateListWorker),
    takeLatest(NATIONSTATE_PAGE_INIT, nationStateWorker),
    takeLatest(NATIONSTATE_DETAIL_INIT, nationStateDetailWorker),
    takeLatest(NATIONSTATE_DELETE_INIT, nationStateDeleteWorker),
    // manageNationStateSaga()
  ]);
}
