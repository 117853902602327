export const CLIENTSITE_PAGE_INIT = 'CLIENTSITE_PAGE_INIT';
export const CLIENTSITE_ERROR = 'CLIENTSITE_ERROR';
export const CLIENTSITE_SUCCESS = 'CLIENTSITE_SUCCESS';
// export const CLIENTSITE_DETAIL_INIT = 'CLIENTSITE_DETAIL_INIT';
// export const CLIENTSITE_DETAIL_ERROR = 'CLIENTSITE_DETAIL_ERROR';
// export const CLIENTSITE_DETAIL_SUCCESS = 'CLIENTSITE_DETAIL_SUCCESS';
// export const CLIENTSITE_DETAIL_CLOSE = 'CLIENTSITE_DETAIL_CLOSE';
// export const CLIENTSITE_DELETE_INIT = 'CLIENTSITE_DELETE_INIT';
// export const CLIENTSITE_DELETE_ERROR = 'CLIENTSITE_DELETE_ERROR';
// export const CLIENTSITE_DELETE_SUCCESS = 'CLIENTSITE_DELETE_SUCCESS';
export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const CLIENTSITE_LIST_INIT = 'CLIENTSITE_LIST_INIT';
export const CLIENTSITE_LIST_SUCCESS = 'CLIENTSITE_LIST_SUCCESS';
export const CLIENTSITE_LIST_ERROR = 'CLIENTSITE_LIST_ERROR';

export const CLIENTSITE_UPDATE_INIT = 'CLIENTSITE_UPDATE_INIT';
export const CLIENTSITE_UPDATE_SUCCESS = 'CLIENTSITE_UPDATE_SUCCESS';
export const CLIENTSITE_UPDATE_ERROR = 'CLIENTSITE_UPDATE_ERROR';

export const CLIENTSITE_LAUNCH_INIT = 'CLIENTSITE_LAUNCH_INIT';
export const CLIENTSITE_LAUNCH_SUCCESS = 'CLIENTSITE_LAUNCH_SUCCESS';
export const CLIENTSITE_LAUNCH_ERROR = 'CLIENTSITE_LAUNCH_ERROR';

export const CLIENTSITE_START_INIT = 'CLIENTSITE_START_INIT';
export const CLIENTSITE_START_REQUESTING = 'CLIENTSITE_START_REQUESTING';
export const CLIENTSITE_START_SUCCESS = 'CLIENTSITE_START_SUCCESS';
export const CLIENTSITE_START_ERROR = 'CLIENTSITE_START_ERROR';
export const CLIENTSITE_LIST_REFRESH_INIT = 'CLIENTSITE_LIST_REFRESH_INIT';
export const CLIENTSITE_LIST_REFRESH_SUCCESS = 'CLIENTSITE_LIST_REFRESH_SUCCESS';
// export const CLIENTSITE_START_PAGE_INIT = 'CLIENTSITE_START_PAGE_INIT';



export function clientSiteListRefreshInit() {
    return {
        type: CLIENTSITE_LIST_REFRESH_INIT
    };
}

export function clientSiteListRefreshSuccess(payload) {
    return {
        type: CLIENTSITE_LIST_REFRESH_SUCCESS,
        payload
    };
}

export function clientSiteListInit() {
    return {
        type: CLIENTSITE_LIST_INIT
    };
}


export function clientSiteListSuccess(payload) {
    return {
        type: CLIENTSITE_LIST_SUCCESS,
        payload
    };
}

export function clientSiteListError(payload) {
    return {
        type: CLIENTSITE_LIST_ERROR,
        payload
    };
}



export function clientSiteUpdateInit(payload) {
    return {
        type: CLIENTSITE_UPDATE_INIT,
        payload
    };
}

export function clientSiteUpdateSuccess(payload) {
    return {
        type: CLIENTSITE_UPDATE_SUCCESS,
        payload
    };
}

export function clientSiteUpdatetError(payload) {
    return {
        type: CLIENTSITE_UPDATE_ERROR,
        payload
    };
}

// Currently the Launch action
export function clientSiteLaunchInit(payload) {
    return {
        type: CLIENTSITE_LAUNCH_INIT,
        payload
    };
}

export function clientSiteLaunchSuccess(payload) {
    return {
        type: CLIENTSITE_LAUNCH_SUCCESS,
        payload
    };
}

export function clientSiteLaunchtError(payload) {
    return {
        type: CLIENTSITE_LAUNCH_ERROR,
        payload
    };
}








export function clientSitePageInit() {
    return {
        type: CLIENTSITE_PAGE_INIT,
    };
}

export function clientSiteError(error) {
    return {
        type: CLIENTSITE_ERROR,
        error,
    };
}

export function clientSiteSuccess(payload) {
    return {
        type: CLIENTSITE_SUCCESS,
        payload
    };
}


export function clientSiteStartError(error) {
    return {
        type: CLIENTSITE_START_ERROR,
        error,
    };
}

export function clientSiteStartSuccess(payload) {
    return {
        type: CLIENTSITE_START_SUCCESS,
        payload
    };
}
