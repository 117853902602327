import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  CLIENT_PAGE_INIT,
  CLIENT_DETAIL_INIT,
  CLIENT_DELETE_INIT,
  CLIENT_START_INIT,
  clientError,
  clientSuccess,
  clientDetailError,
  clientDetailSuccess,
  clientDeleteError,
  clientDeleteSuccess,
  clientPageInit,
  // CLIENT_START_REQUESTING,
  clientStartSuccess,
  clientStartError,
} from "./actions";


import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
import manageClientSaga from './ManageClient/saga';
// import {browserRedirect} from "../../helpers/helpers";
// import {clientPadAddError, clientPadAddSuccess} from "./ClientPad/actions";

//Client API calls
function clientCall() {
  return request('get', urls.CLIENT);
}

function clientDetailCall(id) {
  return request('get', urls.CLIENT+'/'+id);
}

function clientDeleteCall(id) {
  return request('delete', urls.CLIENT+'/'+id);
}

//clientStart API call
function clientStartCall(id) {
  return request('post', urls.CLIENT+'/'+id);
}


// Client Workers
function* clientWorker() {
  try {
    let response = yield call(clientCall);
    response = response.data.data;
    yield put(clientSuccess(response));
  } catch (err) {
    yield put(clientError(err.response.data.data));
  }
}

function* clientDetailWorker(payload) {
  try {
    let response = yield call(clientDetailCall, payload.id);
    response = response.data.data;
    yield put(clientDetailSuccess(response));
  } catch (err) {
    yield put(clientDetailError(err.response.data.data));
  }
}

function* clientDeleteWorker(payload) {
  try {
    let response = yield call(clientDeleteCall, payload.id);
    response = response.data;
    yield put(clientDeleteSuccess(response));
    yield put(clientPageInit());
  } catch (err) {
    yield put(clientDeleteError(err.response.data));
  }
}


function* clientStartWorker(payload) {
  try {
    let response = yield call(clientStartCall, payload.id);
    response = response.data;
    yield put(clientStartSuccess(response));
    /* success will have the client page reload with the new results */
    yield put(clientPageInit());
  } catch (err) {
    yield put(clientStartError(err.response.data));
  }
}


// Client Watcher
export default function* clientSaga() {
  yield all([
    takeLatest(CLIENT_PAGE_INIT, clientWorker),
    takeLatest(CLIENT_DETAIL_INIT, clientDetailWorker),
    takeLatest(CLIENT_DELETE_INIT, clientDeleteWorker),
    takeLatest(CLIENT_START_INIT, clientStartWorker),
    // takeEvery(CLIENT_START_REQUESTING, clientStartWorker),
    manageClientSaga()
  ]);
}