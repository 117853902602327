import axios from "axios";
const REACT_APP_API_ROOT = process.env.REACT_APP_API_ROOT


/* Common Request handles everything except websocket and sending files to the server */
export const request = (method, url, payload=null, headers=1) => {
    let requestData = {
        method: method,
        url: REACT_APP_API_ROOT + url
    }
    if(payload)
        requestData.data = payload;
    if(headers === 1)
        requestData.headers = {
            'Authorization':'Bearer '+ localStorage.getItem('token') 
        };
    return axios(requestData);
}



export const requestWithFile = (method, url, payload=null, headers=1 ) => {
    let requestData = {
        method: method,
        url: REACT_APP_API_ROOT + url
    }

    if(payload)
        // requestData.data = payload;
        requestData.data = payload.formData;
    // if (payload.fileData)

    // console.log(`payload.formData`)
    // console.log(payload.formData)
    // console.log(``)

    if(headers === 1)
        requestData.headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "content-type":
            `multipart/form-data`,
        };

    // console.log(`requestData`)
    // console.log(requestData)
    // console.log(`requestData.data`)
    // console.log(requestData.data)

    return axios(requestData);
}