import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import List from '@mui/material/List';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import AppMenuItemComponent from './AppMenuItemComponent'
import {ListItemButton, Tooltip} from "@mui/material";
import Fade from '@mui/material/Fade'


const AppMenuItemComponent = ({element, onClick, path, children}) => {
    if (!element) {
        return (
                <ListItemButton
                    id={path}
                    children={children}
                    onClick={onClick}
                />
        )
    }

    // If path, return a ListItem with a path component
    return (
            <ListItemButton
                children={children}
                component={Link}
                to={`${path}`}
            />
    )
}





const AppMenuItem = ({element, name, path, MenuIcon, MenuToolTip, children = []}) => {
    const isExpandable = children && children.length > 0
    const [openCollapse, setOpen] = useState(false)

    function handleClick() {
        setOpen(!openCollapse)
    }

    const MenuItem = (
        /*If there's no menu icon, then it is assumed the user accesses the route not from the left hand menu*/
        ( !!MenuIcon ? (
        <AppMenuItemComponent element={element} MenuToolTip={MenuToolTip} path={path} onClick={handleClick}>
            {/* Display an icon if any */}
                <Tooltip title={MenuToolTip}
                         arrow
                         enterDelay={1000}
                         leaveDelay={200}
                         TransitionComponent={Fade}
                         TransitionProps={{timeout: 600}}
                >
                    <ListItemButton
                        sx={{"&:hover": {backgroundColor: "rgba(0,0,0,0)"},
                                "&:active": {backgroundColor: "rgba(0,0,0,0)"}
                        }}
                        dense>
                        <ListItemIcon> <MenuIcon/> </ListItemIcon> <ListItemText primary={name}/>
                    </ListItemButton>
                </Tooltip>
            {/* Display the expand-menu if the item has children */}
            {isExpandable && !openCollapse && <ExpandMoreIcon sx={{marginLeft: "4px"}}/>}
            {isExpandable && openCollapse && <ExpandLessIcon sx={{marginLeft: "4px"}}/>}
        </AppMenuItemComponent>
           ) : null
        )
    )


    const MenuItemChildren = isExpandable ? (
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List disablePadding>
                {children.map((item, index) => (<AppMenuItem {...item} key={index}/>))}
                <Divider/>
            </List>
        </Collapse>
    ) : null



    return (
        <>
            {MenuItem}
            {MenuItemChildren}
        </>
    )
}


export default AppMenuItem
