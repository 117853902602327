import React, {useEffect, useState} from 'react';

// react-pintura
import {PinturaEditor} from 'react-pintura';

// pintura
import 'pintura/pintura.css';
import {
    // editor
    locale_en_gb,
    createDefaultImageReader,
    createDefaultImageWriter,
    createDefaultShapePreprocessor,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_finetune,
    plugin_finetune_locale_en_gb,
    plugin_finetune_defaults,
    plugin_filter,
    plugin_filter_locale_en_gb,
    plugin_filter_defaults,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
} from 'pintura';
import {useDispatch} from "react-redux";
import {importUserImageInit} from "./actions";
import {Button, Grid, Paper, Tooltip} from "@mui/material";
import Fade from "@mui/material/Fade";
import LockIcon from "@mui/icons-material/Lock";

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = {
    imageCropAspectRatio: 21 / 9,
    utils: ['crop', 'finetune', 'filter', 'annotate'],
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    shapePreprocessor: createDefaultShapePreprocessor(),
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...markup_editor_locale_en_gb,
    },
};

export default function PinturaImageEditor({userAccount, imageData}) {
    // inline
    const [result, setResult] = useState('');
    // const [selectedFile, setSelectedFile] = useState(null);
    // const [sliderValue, setSliderValue] = useState(null);

    const dispatch = useDispatch();
    const importUserImageInitiator = (payload) => dispatch(importUserImageInit(payload));


    const handleImportClick = (destFile) => {
        const formData = new FormData();
        formData.append(`${destFile.name}`, destFile);
        importUserImageInitiator({
            formData,
        });
    };


    useEffect(() => {
    }, [imageData]);

    return (
        <>
                <div>
                    <div style={{height: '70vh'}}>
                        <PinturaEditor
                            {...editorDefaults}
                            // src={userAccount.cardImage}
                            src={imageData}
                            onLoad={(res) => console.log('Loaded Image: ', res)}
                            onProcess={({dest}) => {
                                setResult(URL.createObjectURL(dest))
                                handleImportClick(dest)
                            }}
                        />
                    </div>
                    {/*{!!result.length && (*/}
                    {/*    <p>*/}
                    {/*        <img src={result} alt=""/>*/}
                    {/*    </p>*/}
                    {/*)}*/}
                </div>
        </>
    );
}
