import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from '../../helpers/requests';
import { browserRedirect } from '../../helpers/helpers';
import { urls } from '../../helpers/urls';
import {
    GOOGLE_LOGIN_REQUESTING,
    googleloginSuccess,
} from "./actions";


//Login API call
function googleloginCall(payload) {
  console.log(`calling googlelogincall`)
  return request('post', urls.GOOGLE_LOGIN_URL, payload);
}

// Google LOGIN Worker
function* googleloginWorker({ payload }) {
  try {
    var response = yield call(googleloginCall, payload);
    console.log(`googleloginWorker : response\n`, JSON.stringify(response.data.data))

    localStorage.removeItem('user');
    localStorage.removeItem('roles');
    localStorage.removeItem('token');

    // localStorage.setItem('roles', JSON.stringify([response.data.roles]));
    // localStorage.setItem('user', response.data.data);
    localStorage.setItem('token', response.data.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.data));

    yield put(googleloginSuccess());
    // yield call(browserRedirect,'/app');
    yield call(browserRedirect,'/');
  } catch (err) {
    // yield put(loginError(JSON.stringify([err.response.data])));
    // yield put(googleloginError(err.response.data));
      console.log(`error for googleloginerror \n ${err}`)
  }
}



// Login Watcher
export default function* loginSaga() {
  yield all([
    takeLatest(GOOGLE_LOGIN_REQUESTING, googleloginWorker),
  ]);
}