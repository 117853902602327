import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from '../../../helpers/requests';
import { browserRedirect } from '../../../helpers/helpers';
import { urls } from '../../../helpers/urls';
import {
    STAGING_ADD_REQUESTING,
    stagingAddSuccess,
    stagingAddError,
    STAGING_UPDATE_REQUESTING,
    stagingUpdateSuccess,
    stagingUpdateError
} from "./actions";

//stagingAdd API call
function stagingAddCall(payload) {
  return request('post', urls.STAGING, payload);
}

//stagingUpdate API call
function stagingUpdateCall(id, payload) {
  return request('put', urls.STAGING+"/"+id, payload);
}





// stagingAdd Worker
function* stagingAddWorker({ payload }) {
  try {
    yield call(stagingAddCall, payload);
    yield put(stagingAddSuccess());
    yield call(browserRedirect, '/app/staging');
  } catch (err) {
    yield put(stagingAddError(err.response.data));
  }
}

//stagingUpdate Worker
function* stagingUpdateWorker({id, payload}) {
  try {
    yield call(stagingUpdateCall, id, payload);
    yield put(stagingUpdateSuccess());
    yield call(browserRedirect, '/app/staging');
  } catch (err) {
    yield put(stagingUpdateError(err.response.data));
  }
}



/* Watchers are like subscribers in pub-sub, they listen for the event name and trigger functions */
/*  */
// stagingAdd Watcher
export default function* stagingAddSaga() {
  yield all([
    takeLatest(STAGING_ADD_REQUESTING, stagingAddWorker),
    takeLatest(STAGING_UPDATE_REQUESTING, stagingUpdateWorker),
  ]);
}