import React, {useEffect, memo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {salesforceLaunchesThisMonthPageInit} from "../Salesforce/actions";
import {Collapse} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import Typography from "@mui/material/Typography";
import {TransitionGroup} from "react-transition-group";
import UserCardGeneric from "../User/UserCardGeneric";
import UserCardZeroOccurrences from "../User/UserCardZeroOccurrences";



function LeaderboardService() {
    const dispatch = useDispatch();

    const launchesThisMonthOnPageInit = (payload) => dispatch(salesforceLaunchesThisMonthPageInit(payload));
    const launchesThisMonth = useSelector(
        (state) => state.salesforce.signed_in.launchesThisMonth);
    let sf_session_accessToken = localStorage.getItem("sf_session_accessToken");
    let sf_session_instanceUrl = localStorage.getItem("sf_session_instanceUrl");
    let sf_session_refreshToken = localStorage.getItem("sf_session_refreshToken");

    useEffect(() => {
        launchesThisMonthOnPageInit({
            code: {
                sf_session_accessToken: sf_session_accessToken,
                sf_session_instanceUrl: sf_session_instanceUrl,
                sf_session_refreshToken: sf_session_refreshToken,
            },
        });
    }, []);



return(
        <Grid
            id="serviceMainBox"
            sx={{
                minHeight: "800px",
                // boxShadow: 2,
                // paddingTop: "20px",
                // paddingLeft: "20px",
                // paddingRight: "20px",
                minWidth: "200px",
                maxWidth: "525px",
                width: "525px",
                marginTop: "-30px",
            }}
        >
            <Typography align="center" gutterBottom sx={{fontSize: 30}} >
                Top Launcher
            </Typography>
            <Grid >

                <TransitionGroup>
                    {launchesThisMonth.map((user, index) => (
                        <Collapse key={index}
                                  timeout={{ appear: index * 1000, enter: 10000, exit: 250 }}
                                  style={{ transitionDelay: `${index * 1000}ms` }}
                        >
                            {user.occurrence > 0 ?
                                <UserCardGeneric userAccount={user} index={index} imageData={user.cardImage}/>
                                :
                                <UserCardZeroOccurrences userAccount={user} index={index} imageData={user.cardImage}/>
                            }
                        </Collapse>
                    ))}
                </TransitionGroup>
                {/*<PrettyPrintJson data={leaderboardSorted}/>*/}
            </Grid>
        </Grid>
    
)

}

export default LeaderboardService
// export default memo(LeaderboardService)