import {
    USER_PAGE_INIT,
    USER_ERROR,
    USER_SUCCESS,
    USER_DETAIL_INIT,
    USER_DETAIL_ERROR,
    USER_DETAIL_SUCCESS,
    USER_TEAM_SUCCESS,
    USER_DETAIL_CLOSE,
    USER_DELETE_ERROR,
    USER_DELETE_SUCCESS,
    USER_START_ERROR,
    USER_START_SUCCESS,
    RELEASE_STATE_DATA,
    GET_USER_CARD_IMAGE_SUCCESS,
    IMPORT_USER_IMAGE_SUCCESS,
    SAVE_MONTH_SELECTION_INIT_SUCCESS,
    SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED,
    MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS,
    TEAM_MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS,
    TEAM_SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED
} from './actions';
import { combineReducers } from "redux";
import  manageUserReducer  from './UserAvatar/reducer';
import  userPadReducer  from './UserPad/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    users: [],
    userCardImageBackground: [{
        img:{
            data : "defaultCardBackground.png",
            mimetype: 'image/jpeg',
        }
    }],
    selectedUser: {},
    currentLocalUser: [{}],
    currentLocalUserTeam: [{}],
    // selectedGoalMonthDetail: [{
    //     monthName: 'January',
    //     goalNumber: 2
    // }],
    monthGoalSelect: {},
    teamMonthGoalSelect: {},
    selectedMonthGoalDetailChanged: true,
    selectedTeamMonthGoalDetailChanged: true,
    selectedUserError: {},
    deleteUser: {},
};

const userReducers = function(state = initialState,actions){
    switch(actions.type){
        case MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS:
            return {...state, selectedMonthGoalDetailRetrieved:false, monthGoalSelect: {...actions.payload}};

        case TEAM_MONTH_GOAL_AUTOSELECT_SAVE_SUCCESS:
            return {...state, selectedTeamMonthGoalDetailRetrieved:false, teamMonthGoalSelect: {...actions.payload}};

        case SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED:
            return {...state, selectedMonthGoalDetailRetrieved:true};

        case TEAM_SAVE_MONTH_SELECTION_INIT_SUCCESS_ACKNOWLEDGED:
            return {...state, selectedTeamMonthGoalDetailRetrieved:true};

        case USER_PAGE_INIT:
            return {...state, errors:[], users: []};
        case USER_SUCCESS:
            return {...state, successful: true, users:[...actions.payload]};
        case GET_USER_CARD_IMAGE_SUCCESS:
            return {...state, successful: true, userCardImageBackground:[...actions.payload]};

        case IMPORT_USER_IMAGE_SUCCESS:
            return {...state, successful: true, userCardImageBackground:[...actions.payload]};

        case USER_ERROR:
            return {...state, successful: false, errors:[...actions.error]};
        // case USER_DETAIL_INIT:
        //     return {...state, selectedUserError:{}, selectedUser: {}};

        case USER_DETAIL_SUCCESS:
            return {...state, selectedMonthGoalDetailRetrieved:true, currentLocalUser: [...actions.payload]};

        case USER_TEAM_SUCCESS:
            return {...state, selectedMonthTeamGoalDetailRetrieved:true, currentLocalUserTeam: [...actions.payload]};



        case USER_DETAIL_ERROR:
            return {...state, selectedUserError:{...actions.error}};
        case USER_DETAIL_CLOSE:
            return {...state, selectedUserError:{}, selectedUser: {}};
        case USER_DELETE_SUCCESS:
            return {...state, deleteUser: {...actions.payload}};
        case USER_DELETE_ERROR:
            return {...state, selectedUserError:{...actions.error}};
        case USER_START_SUCCESS:
            return {...state, startUser: {...actions.payload}};
        case USER_START_ERROR:
            return {...state, selectedUserError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], users: [], selectedUser: {},selectedUserError: {},deleteUser: {}}
        default:
            return state;
    }
}

export default combineReducers({
    list_user : userReducers,
    manage_user: manageUserReducer,
    user_pad: userPadReducer
});
