import { createTheme } from '@mui/material/styles';

// Dark theme
const theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#26292C',
            light: 'rgb(81, 91, 95)',
            dark: 'rgb(26, 35, 39)',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#FFB74D',
            light: 'rgb(255, 197, 112)',
            dark: 'rgb(200, 147, 89)',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        titleBar: {
            main: '#555555',
            contrastText: '#ffffff',
        },
        error: {
            main: '#cc4444',
        },
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true,
            },
        },
        MuiPaper: {
            defaultProps: {},
            styleOverrides: {
                root: {},
            },
        },
        MuiButton: {
            defaultProps: {},
            styleOverrides: {
                outlined: { // Targeting the "contained" variant
                    backgroundColor: '#ffffff!important', // blue color in hexadecimal
                    "&:hover": {
                        backgroundColor: 'rgb(81, 91, 95)' // darker blue for hover effect
                    }
                },
            },
        },
        MuiFormControl: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff!important', // blue color in hexadecimal
                    "&:hover": {
                        backgroundColor: 'rgb(81, 91, 95)'
                    }
                },
            },
        }
    }
})


export default theme