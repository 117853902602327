import React, {useEffect, useRef, useState} from 'react';
import {useGoogleLogin} from '@react-oauth/google';
// import './login.css'
import {refreshTokenSetup} from '../../helpers/helpers';
import {Button, Grid} from "@mui/material";
import {googleloginRequest} from './actions';
import {useDispatch} from "react-redux";
// import {AwesomeButton} from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import {SwitchTransition, CSSTransition} from "react-transition-group";
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react'
// import Typography from "@mui/material/Typography";
import Hash from '../Hash/index.js'
import axios from "axios";
// import {AuthContext} from "../../helpers/AuthProvider";
// import axios from "axios";


function GoogleLoginHook() {
    const [text, setText] = useState("Login to ViziSaga");
    // const eleRef = useRef('');
    // const authContextObject = useContext(AuthContext)

    const dispatch = useDispatch();
    const getGoogleLoginRequest = (payload) => dispatch(googleloginRequest(payload));

    const toggleTransition = function (n) {
        setText(text === "Login to ViziSaga" ? `Welcome ${n}` : "Login to ViziSaga");
    };

    const {
        isLoading,
        error,
        data,
    } = useVisitorData();

    // const hash53 = function(str, seed = 0) {
    //     let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    //     for (let i = 0, ch; i < str.length; i++) {
    //         ch = str.charCodeAt(i);
    //         h1 = Math.imul(h1 ^ ch, 2654435761);
    //         h2 = Math.imul(h2 ^ ch, 1597334677);
    //     }
    //     h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    //     h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    //     return 4294967296 * (2097151 & h2) + (h1>>>0);
    // };


    const getUsersName = () => {
        setTimeout(() => {

            if (localStorage.getItem('user')) {
                // need the try block used for other JSON parsing here
                const currentUser = JSON.parse(localStorage.getItem('user'))

                if (Object.hasOwn(currentUser, 'hasVersion')) {
                    // user is up-to-date

                    const usersObject = JSON.parse(localStorage.getItem('user'))
                    let userObjectVersion = usersObject.hasVersion
                    if (userObjectVersion === 1) {
                        console.log(`UserObjectVersion = ${usersObject.hasVersion}`)
                    }

                } else {
                    console.log(`You do not have the current userObject.`)
                    localStorage.removeItem('user');
                    localStorage.removeItem('roles');
                }


                if (Object.hasOwn(currentUser, 'displayName')) {
                    const usersNameBeforeLogin = JSON.parse(localStorage.getItem('user'))
                    let nameOfUser = usersNameBeforeLogin.displayName
                    if (nameOfUser == undefined) {
                    } else {
                        toggleTransition(nameOfUser)
                    }
                } else {
                }
            } else {
                console.log(`No user object detected.`)
                localStorage.removeItem('user');
                localStorage.removeItem('roles');
                localStorage.removeItem('token');
            }
        }, 1000);
    }

    useEffect(() => {
        getUsersName()
    }, []);



    const login = useGoogleLogin({
            onSuccess: async ({access_token}) => {
                const userInfo = await axios.get(
                    'https://www.googleapis.com/oauth2/v3/userinfo',
                    // 'https://www.googleapis.com/oauth2/v3/userinfo',
                    {headers: {Authorization: `Bearer ${access_token}`}},
                );

                // console.log(`userInfo`);
                // console.log(userInfo);
                // isn't `code` but is actually just data
                getGoogleLoginRequest({code:userInfo})
            },



        /* This is for the next version where the verification happens on the backend */
        // onSuccess: async ({ code }) => {
        //     // const tokens = await axios.post('http://localhost:3001/auth/google', {  // http://localhost:3001/auth/google backend that will exchange the code
        //     //     code,
        //     // });
        //     console.log(`code: `, code)
        //     getGoogleLoginRequest({code})
        //     // console.log(tokens);
        // },
        // flow: 'auth-code',

            onFailure: () => {
                console.log(`Error signing in.`)
            },


        });


    return (
        <Button
            onClick={() => {
                login()
            }}
            variant="contained"
        >
            <SwitchTransition mode="out-in">
                <CSSTransition
                    classNames="fade"
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    key={text}
                >
                    <div>{text}</div>
                </CSSTransition>
            </SwitchTransition>
        </Button>
    );
}


export default GoogleLoginHook;
