export const MENU_OPEN_STATUS = 'MENU_OPEN_STATUS';
export const MENU_CLOSED_STATUS = 'MENU_CLOSED_STATUS';

export function menuOpenState() {
    return {
        type: MENU_OPEN_STATUS,
        // payload
    };
}

export function menuClosedState() {
    return {
        type: MENU_CLOSED_STATUS,
        // payload
    };
}

// export function menuIsClosedStatus(payload) {
//     return {
//         type: MENU_CLOSED_STATUS,
//         payload
//     };
// }


