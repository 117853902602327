import React from 'react';
// import './login.css';

import GoogleLoginHook from "./googleLoginHook";
import {Grid, Paper} from "@mui/material";
import Box from "@mui/material/Box";

function Login() {
    return (
        // <Box
        //     sx={{
        //         width: "98vw",
        //         height: "98vh",
        //         // height: "90vh",
        //     }}
        // >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                // className="rainbowShadow"
                sx={{
                    width: "100%",
                    // height: "100%",
                    height: "98vh",
                }}
            >
                <Grid item align="center">
                    <GoogleLoginHook/>
                </Grid>
            </Grid>
        // </Box>
    );
}


export default Login