import {Button, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import './usercard.css'
import styled from 'styled-components'
import {useDispatch, useSelector} from "react-redux";
import {
    goalMonthUpdateInit, saveMonthSelectionInit,
    saveMonthSelectionInitSuccessAcknowledged,
    userDetailInit
} from "./actions";
import FormControl from "@mui/material/FormControl";
import Rating from '@mui/material/Rating';
import RocketIcon from '@mui/icons-material/Rocket';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PaidIcon from '@mui/icons-material/Paid';
import Typography from "@mui/material/Typography";


const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});


function AutoPopulatedGoalsForm() {
    const [newValue, setNewValue] = useState({})
    const dispatch = useDispatch();

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const todaysDate = new Date();
    let currentMonth = monthNames[todaysDate.getMonth()]
    // console.log("The current month is " + monthNames[todaysDate.getMonth()]);


    const userDetailInitiated = (payload) => dispatch(userDetailInit(payload))
    const saveMonthSelectionInitSuccessAcknowledgedInit = () => dispatch(saveMonthSelectionInitSuccessAcknowledged())

    const selectedMonthGoalDetailRetrieved = useSelector((state) => state.user.list_user.selectedMonthGoalDetailRetrieved);

    let localUserObject = JSON.parse(localStorage.getItem("user"))

    const monthGoalUserSelected = useSelector((state) => state.user.list_user.monthGoalSelect);

    const monthGoalUserSelectedName = monthGoalUserSelected.monthName;

    const currentLocalUser = useSelector((state) => state.user.list_user.currentLocalUser[0])

    const currentLocalUserMonthGoals = currentLocalUser.monthGoals


    const finder = (currentUserData) => {
        let newFind = currentUserData.find(obj => {
            // return obj.b === 6
            return obj['monthName'] === monthGoalUserSelectedName
        })
        setNewValue(newFind)
        saveMonthSelectionInitSuccessAcknowledgedInit()


        // const updatedMonthData = currentUserData.map(monthItem =>
        //     monthItem.monthName === monthGoalUserSelectedName.monthName ? monthItem : monthItem);

    }


    const handleSelectorRefresh = () => {
        if (!monthGoalUserSelectedName) {

        } else if (monthGoalUserSelectedName) {

            if (currentLocalUser.monthGoals) {
                console.log(`currentLocalUser.monthGoals`)
                console.log(currentLocalUser.monthGoals)
                finder(currentLocalUserMonthGoals)
                // userDetailInitiated({"userName": localUserObject.condensedName})
                //     let newFind = currentLocalUserMonthGoals.find(obj => {
                // return obj.b === 6
                // return obj['monthName'] === monthGoalUserSelectedName
                // })

            }


        }
    }


    // useEffect(() => {
    //     finder()
    // }, []);

    useEffect(() => {
        handleSelectorRefresh()
    }, [monthGoalUserSelectedName]);

    useEffect(() => {
        // saveMonthSelectionInitSuccessAcknowledgedInit()
    }, [selectedMonthGoalDetailRetrieved]);


    return selectedMonthGoalDetailRetrieved === false ? (
        <></>
    ) : (
        <AutoPopulatedGoalsFormFields selectedGoalMonthDetail={newValue}
                                      currentLocalUserMonthGoals={currentLocalUserMonthGoals}/>
    )
}


function AutoPopulatedGoalsFormFields({selectedGoalMonthDetail, currentLocalUserMonthGoals}) {
    const [goalData, setGoalData] = useState(
        {
            monthName: selectedGoalMonthDetail.monthName == null ? '' : selectedGoalMonthDetail.monthName,
            goalNumber: selectedGoalMonthDetail.goalNumber == null ? 0 : selectedGoalMonthDetail.goalNumber,
            attained: selectedGoalMonthDetail.attained == null ? '' : selectedGoalMonthDetail.attained
        }
    );
    const [allUserMonthData, setAllUserMonthData] = useState(currentLocalUserMonthGoals);


    useEffect(() => {

    }, [selectedGoalMonthDetail]);


    const [hover, setHover] = useState(-1);
    const dispatch = useDispatch();

    const goalMonthUpdateInitiated = (payload) => dispatch(goalMonthUpdateInit(payload));
    let localUserObject = JSON.parse(localStorage.getItem("user"))



    const handleSaveGoal = () => {
        const updatedMonthData = allUserMonthData.map(monthItem =>
            monthItem.monthName === goalData.monthName ? {...goalData} : monthItem);
        // monthItem.monthName === goalData.monthName ? {...currentLocalUserMonthGoals, ...goalData} : monthItem );

        goalMonthUpdateInitiated({
            currentUser: {"userName": localUserObject.condensedName},
            currentPersonalGoal: updatedMonthData,
        });
    };


    const updateFormData = event => {
        setGoalData({
                ...goalData,
                goalNumber: parseInt(event.target.value)
            }
        );
    }


    const labels = {
        1: 'On the board',
        2: 'Momentum',
        3: 'Traction',
        4: 'Experienced',
        5: 'JackRabbit',
        6: 'Seasoned Pilot',
        7: 'Astronaut',
        8: 'Moonwalker',
        9: 'Interstellar',
        10: 'The Chosen One',
    };


    function getLabelText(value) {
        return `${value} ${value !== 1 ? '' : ''}, ${labels[value]}`;
    }


    const {goalNumber, monthName} = goalData


    return (<>
            <Grid
                item
                width="100%"
                align="center"
                sx={{
                    position: "absolute",
                    marginTop: "-80px"
                    // marginLeft: "50%",
                    // marginRight: "150%",
                }}
            ><Typography sx={{fontSize: "30px"}}>
                {goalNumber !== null && (
                    <>{labels[hover !== -1 ? hover : goalNumber]}</>
                )}</Typography>
            </Grid>
            <Grid
                container
                item
                sx={{
                }}>
                <Grid
                    item width="100%" align="center"
                    sx={{
                        // minWidth: "80vw",
                        marginTop: "50px"
                        // minHeight: "300px"
                    }}
                >
                    <StyledRating
                        name="hover-feedback"
                        value={goalNumber}
                        max={10}
                        precision={1}
                        getLabelText={getLabelText}
                        // onChange={(event, newValue) => {
                        //     setGoalData(newValue);
                        // }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        onChange={(e) => {
                            updateFormData(e);
                        }}
                        // onChange={(e) => {
                        //     updateFormData(e);
                        // }}
                        // onChangeActive={(event, newHover) => {
                        //     setHover(newHover);
                        // }}
                        icon={<RocketLaunchIcon fontSize="inherit" sx={{fontSize: "80px"}}/>}
                        emptyIcon={<RocketIcon fontSize="inherit" sx={{fontSize: "80px"}}/>}
                    />

                </Grid>
                <Button
                    variant="contained"
                    onClick={() => handleSaveGoal()}
                    component="label"
                    sx={{marginTop: "30px", width: "100px", marginLeft: "45%"}}
                >
                    {`save`}
                </Button>
            </Grid>
        </>
    )
}

export default AutoPopulatedGoalsForm
// export default memo(GoalsForm)
