import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {CircularProgress, Grid} from "@mui/material";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import { salesForceUserTokens } from "../../helpers/helpers";
import {stagingAccountDetailInit, stagingAutoSelectSave, stagingListInit} from "./actions";


export default function AutocompleteSelection(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const dispatch = useDispatch();
  const saveStagingsAccountSelected = (payload) => dispatch(stagingAutoSelectSave(payload));
  const getStagingsAccountList = (payload) => dispatch(stagingListInit(payload));

  const stagingAccountNames = useSelector((state) => state.stagings.list_staging.stagings);

  useEffect(() => {
    getStagingsAccountList({
      userTokens: salesForceUserTokens(),
    });
  }, []);



  const handleAutocompleteSelectionChange = (valueSelected) => {
    // console.log(`event target value: `);
    // console.log(valueSelected);
      saveStagingsAccountSelected(valueSelected);
  };

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...stagingAccountNames]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          disablePortal
          getOptionLabel={(stagings) => stagings.Name}
          onChange={(event, newValue) => {
            handleAutocompleteSelectionChange(newValue);
          }}
          // onInputChange={(event, accountInformation) => {
          //   handleAutocompleteSelectionChange(accountInformation);
          // }}
          id="controllable-autocomplete"
          options={stagingAccountNames}
          renderInput={(params) => (
            <TextField
                sx={{ minWidth: "500px", width: "100%" }}
              {...params}
              label="Account Name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
  )
}

