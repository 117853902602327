import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";

import {Box, Chip, Container, Grid, Stack, Tooltip} from "@mui/material";
// import DataTableMaterial from "./DataTableMaterial";
// import SecondDrawer from "./SecondDrawer";
import Typography from "@mui/material/Typography";

// import DataTableBusinessListCompleted from "./DataTableBusinessListCompleted";
import DataTableBusinessListProgress from "./DataTableBusinessListProgress";
import Fade from "@mui/material/Fade";
import InfoIcon from "@mui/icons-material/Info";
// import DataTableBusinessListScanned from "./DataTableBusinessListScanned";
// import DataTableBusinessListScannedProgress from "./DataTableBusinessListScannedProgress";
import Map from "../Map";


// export default function leadGenerator() {
export default function leadGenerator() {

    // const finishedScrappedCollections = useSelector((state) => state.leadgenerator.businessListSiteScannedCompleted);


    // useEffect(() => {
    //
    // }, [finishedScrappedCollections]);

    return (
    <>
      <Box
        id="secondDrawer"
        sx={{
          marginTop: "100px",
          zIndex: 1,
          position: "absolute",
        }}
      >
        {/*<SecondDrawer />*/}
      </Box>
      <Grid
        container
        // align="center"
        spacing={3}
        sx={{
          // position: "inherit",
          // display: "inline-block",
          // marginLeft: "15%",
          marginLeft: "300px",
          marginRight: "5%",
          paddingBottom: "20px",
          width: "100%",
        }}
      >
        <Grid item width="100%" align="center">
          <Typography variant="h4">Overview</Typography>
        </Grid>

        <Grid item width="100%" align="center" sx={{ marginTop: "25px" }}>
          <Tooltip
            title={`States and industries with data available`}
            arrow
            enterDelay={300}
            leaveDelay={200}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
          >
            <InfoIcon
              sx={{ marginLeft: "160px", position: "absolute" }}
              fontSize={"small"}
              color="info"
            />
          </Tooltip>
          <Typography variant="h6">Finished Campaigns</Typography>
        </Grid>
        <Grid container item>
            <Map />
            {/*<DataTableBusinessListCompleted />*/}
        </Grid>
          <Grid item width="100%" align="center" sx={{ marginTop: "100px" }}>
              <Tooltip
                  title={`States that are having their counties currently scanned for businesses`}
                  arrow
                  enterDelay={300}
                  leaveDelay={200}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 300 }}
              >
                  <InfoIcon
                      sx={{ marginLeft: "160px", position: "absolute" }}
                      fontSize={"small"}
                      color="info"
                  />
              </Tooltip>
              <Typography variant="h6">Campaign Progress</Typography>
          </Grid>
        {/*  <Grid container item sx={{ }}>*/}
        {/*      <Chip*/}
        {/*          size="small"*/}
        {/*          variant="outlined"*/}
        {/*        label={<Typography*/}
        {/*        sx={{}}*/}
        {/*      >Business Name*/}
        {/*      </Typography>}>*/}
        {/*      </Chip>*/}
        {/*      <Chip*/}
        {/*          variant="outlined"*/}
        {/*          size="small"*/}
        {/*        label={<Typography*/}
        {/*        sx={{}}*/}
        {/*      >Address*/}
        {/*      </Typography>}>*/}
        {/*      </Chip>*/}
        {/*</Grid>*/}
          <Grid container item sx={{ marginBottom: "200px" }}>
          <DataTableBusinessListProgress />
        </Grid>
          {/*<Grid item width="100%" align="center" sx={{ marginTop: "100px" }}>*/}
          {/*    <Tooltip*/}
          {/*        title={`Businesses belonging to a State that have all their sites scanned`}*/}
          {/*        arrow*/}
          {/*        enterDelay={300}*/}
          {/*        leaveDelay={200}*/}
          {/*        TransitionComponent={Fade}*/}
          {/*        TransitionProps={{ timeout: 300 }}*/}
          {/*    >*/}
          {/*        <InfoIcon*/}
          {/*            sx={{ marginLeft: "160px", position: "absolute" }}*/}
          {/*            fontSize={"small"}*/}
          {/*            color="info"*/}
          {/*        />*/}
          {/*    </Tooltip>*/}
          {/*    <Typography variant="h6">Finished Campaigns</Typography>*/}
          {/*</Grid>*/}
        {/*  <Grid container item sx={{ marginBottom: "100px" }}>*/}
        {/*  <DataTableBusinessListScanned />*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
}
// }

leadGenerator.propTypes = {
  nationState: PropTypes.array,
};

// export default leadGenerator;
