import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {CircularProgress, Grid} from "@mui/material";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import { salesForceUserTokens } from "../../helpers/helpers";
import {
    stagingAccountDetailInit,
    stagingAutoSelectForLaunchSave,
    stagingAutoSelectSave,
    stagingListInit,
    stagingCheckServerInit
} from "../Staging/actions";



export default function AutocompleteSelection(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const dispatch = useDispatch();

  const saveStagingsAccountSelected = (payload) => dispatch(stagingAutoSelectForLaunchSave(payload));
  const getStagingsAccountList = () => dispatch(stagingCheckServerInit());


  const stagingsReceivedAccounts = useSelector((state) => state.stagings.list_staging.stagingSitesOnServer);


  useEffect(() => {
    getStagingsAccountList();
  }, []);


   function sortAccounts (acc) {
        var allServerSites = acc;
        var allAccountsSorted = [];

        allServerSites.forEach(eachServerAccount => {
            const stagingRegex = /viziglobal/gi;
            if (stagingRegex.test(eachServerAccount.domain) === true ){
                allAccountsSorted.push({
                    Staging_Site_URL__c: eachServerAccount.domain
                    }
                );
            }
  	    });
  	    return allAccountsSorted
  }



  const handleAutocompleteSelectionChange = (valueSelected) => {
    console.log(`event target value: `);
    console.log(valueSelected);
      saveStagingsAccountSelected(valueSelected);
  };

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...stagingsReceivedAccounts]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
      // <PrettyPrintJson data={stagingsReceivedAccounts} />
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          disablePortal
          getOptionLabel={(stagings) => stagings.Staging_Site_URL__c}
          onChange={(event, newValue) => {
            handleAutocompleteSelectionChange(newValue);
          }}
          id="controllable-autocomplete"
          options={sortAccounts(stagingsReceivedAccounts)}
          renderInput={(params) => (
            <TextField
                sx={{ minWidth: "500px", width: "100%" }}
              {...params}
              label="Staging Sites"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
  )
}

