import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import {withRouter} from '../../helpers/withRouter'
// import {Link} from 'react-router-dom';
// import './unlockableAvatar.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
    unlockableAvatarAddPageInit,
    // unlockableAvatarAddRequest,
    // unlockableAvatarDetailClose,
    // unlockableAvatarDeleteInit,
    // // releaseStateData,
    // unlockableAvatarStartInit
} from './actions';


// import DataTableMaterial from "./DataTableMaterial";
import {Box, Button} from "@mui/material";
// import SecondDrawer from "../Client/SecondDrawer";
// import Map from "../Map";
// import TableSelect from "../NationState/TableSelect";


function UnlockableAvatar(props) {

    const [selectedTerritory, setSelectedTerritory] = React.useState('');
    const [selectedData, setSelectedData] = React.useState('');

    /* The variable after "list_unlockableAvatar" corresponds to what the reducer returns
    *    */
    // const unlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.unlockableAvatar)
    // const unlockableAvatar = useSelector((state) => state.unlockableAvatar)
    // const selectedUnlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatar)
    // const errors = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.errors)
    // const selectedUnlockableAvatarError = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatarError)
    // const deleteUnlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.deleteUnlockableAvatar)

    const dispatch = useDispatch();
    // const getUnlockableAvatars = () => dispatch(unlockableAvatarAddPageInit());
    // const getUnlockableAvatarDetail = (id) => dispatch(unlockableAvatarAddRequest(id));
    // getUnlockableAvatarStartDetail: (id) => dispatch(unlockableAvatarAddRequest(id));
    // const detailModalClose = () => dispatch(unlockableAvatarDetailClose());
    // const deleteUnlockableAvatarRequest = (id) => dispatch(unlockableAvatarDeleteInit(id));
    // const startUnlockableAvatarRequest = (id) => dispatch(unlockableAvatarStartInit(id));
    // const releaseData = () => dispatch(releaseStateData());


    // const [isForDelete, setIsForDelete] = useState(false)
    // const [isForStart, setIsForStart] = useState(false)

    // const unlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.unlockableAvatars)
    // const unlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.unlockableAvatar)
    // const selectedUnlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatar)
    // const errors = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.errors)
    // const selectedUnlockableAvatarError = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatarError)
    // const deleteUnlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.deleteUnlockableAvatar)

    useEffect(() => {
        // getUnlockableAvatars();
        // console.log(unlockableAvatar)
        // dispatch(unlockableAvatarAddPageInit())
    }, [])

    // Runs on exit of component
    // useEffect(() => {

    // return function cleanup() {
    //     mapDispatchToProps.releaseData()
    // Side-effect cleanup...
    // };
    // }, []);

    const handleChangeTerritory = (event) => {
        let id = event.target.value.toLowerCase()
        // console.log(`lowerCaseName = ${id}`)
        setSelectedTerritory(event.target.value);
        setSelectedData(id)
    };

    const handleChangeMapSelection = (statename) => {
        let id = statename.toLowerCase()
        // console.log(`lowerCaseName = ${id}`)
        setSelectedTerritory(statename);
        // setSelectedData(id)
    };

    const avatarMinWidth = 200
    const avatarMaxWidth = 200


    return (
            <Box id="avatarUnlockablesBox"
                 sx={{
                paddingTop: "20px",
                marginTop: "100px",
                marginBottom: "100px",
                paddingBottom: "20px",
                maxWidth: "100%",


                // maxWidth: "82vw"
            }}>
                {/*<Map selectedTerritory={selectedTerritory} handleChangeMapSelection={handleChangeMapSelection}/>*/}

                <Typography gutterBottom variant="h5" component="div">Avatars</Typography>
                <Box id="avatarBox" sx={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: 'row',
                    alignContent: "flex-start",
                    flexWrap: "wrap",
                    height: "auto",
                }}>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingBottom: "20px",
                            maxWidth: "82vw",
                            // display: "flex",
                        }}>
                        <Card sx={{maxWidth: avatarMaxWidth, minWidth: avatarMinWidth}}>
                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    height="140"*/}
                            {/*    image=""*/}
                            {/*    alt="avatar example"*/}
                            {/*/>*/}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    default avatar
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    This is the default avatar that's based on your slack profile image
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button disabled size="small">switch</Button>
                            </CardActions>
                        </Card>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingBottom: "20px",
                            maxWidth: "82vw"
                        }}>
                        <Card sx={{maxWidth: avatarMaxWidth, minWidth: avatarMinWidth}}>
                            {/*<CardMedia*/}
                            {/*    component="img"*/}
                            {/*    height="140"*/}
                            {/*    image=""*/}
                            {/*    alt="avatar example"*/}
                            {/*/>*/}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Custom
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    unlock this theme by being top producer on your team one month
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button disabled size="small">switch</Button>
                                <Button disabled size="small">create</Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Box>
    );

    // }
}


/* Receives an Array */
// const UnlockableAvatarRow = (props) => {
// function UnlockableAvatarRow(props) {
// console.log(props)
// const [isForDelete, setIsForDelete] = useState(false)
// const [isForStart, setIsForStart] = useState(false)
// const [unlockableAvatar, setUnlockableAvatars] = useState([])
// useEffect(() => {
// }, [handleModalShow])

// const dispatch = useDispatch();

// const handleModalStartShowClick = modalType => {
//     return event => {
//         // this is taking the elements attribute : data-id
//         // dispatch(unlockableAvatarAddRequest(event.currentTarget.dataset.id))
//         // dispatch(getUnlockableAvatarDetail(event.currentTarget.dataset.id));
//
//         if (modalType === "view") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForStart(true)
// 			// return (
// 			// 	<UnlockableAvatarModal />
// 			// )
//         }
//         if (modalType === "unlockableAvatar") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForStart(true)
//         }
//         if (modalType === "delete") {
//             console.log(event.currentTarget.dataset.actiontype)
//             setIsForDelete(true)
//
//
//         }
//     }
// }

//     if (props.list.length === 0) {
//     	return (<tr>
//     		<td colSpan="4">No unlockableAvatar present</td>
//     	</tr>)
//     } else {
//     	return props.list.map(function (data, id) {
//     		return (
//     			<tr key={id}>
//     				<th scope="row">{id + 1}</th>
//     				<td>{data.unlockableAvatarAddress}</td>
//     				<td>{data.liveAddress}</td>
//     				<td style={{"padding": "5px"}}>
//     					<button type="button"
//     							className="btn btn-outline-info mr-3"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#exampleModal"
//     							onClick={<UnlockableAvatarModal modalType={"view"} data-id={data._id}/>}>View
//     					</button>
//     					<button type="button"
//     							className="btn btn-outline-info mr-3"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#unlockableAvatarModal"
//     							data-actiontype="unlockableAvatar"
//     							onClick={<UnlockableAvatarModal prop={"unlockableAvatar"}/>}>UnlockableAvatar Pad
//     					</button>
//     					<Link to={"/manage-unlockableAvatar/" + data._id}
//     						  className="btn btn-outline-warning mr-3">Update</Link>
//     					<button type="button"
//     							className="btn btn-outline-danger"
//     							data-id={data._id} data-toggle="modal"
//     							data-target="#exampleModal"
//     							data-actiontype="delete"
//     							onClick={<UnlockableAvatarModal prop={"delete"}/>}>Delete
//     					</button>
//     				</td>
//     			</tr>
//     		);
//     	});
//     }
// }


UnlockableAvatar.propTypes = {
    unlockableAvatar: PropTypes.array
};

// const unlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.unlockableAvatar)
// const selectedUnlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatar)
// const errors = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.errors)
// const selectedUnlockableAvatarError = useSelector((state) =>
// state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatarError)
// const deleteUnlockableAvatar = useSelector((state) => state.unlockableAvatar.list_unlockableAvatar.deleteUnlockableAvatar)

// function mapStateToProps(state) {
// 	return {
// 		unlockableAvatar: state.unlockableAvatar.list_unlockableAvatar.unlockableAvatar,
// 		selectedUnlockableAvatar: state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatar,
// 		errors: state.unlockableAvatar.list_unlockableAvatar.errors,
// 		selectedUnlockableAvatarError: state.unlockableAvatar.list_unlockableAvatar.selectedUnlockableAvatarError,
// 		deleteUnlockableAvatar: state.unlockableAvatar.list_unlockableAvatar.deleteUnlockableAvatar
// 	};
// }

// function mapDispatchToProps(dispatch) {
// 	return {
// 		getUnlockableAvatars: () => dispatch(unlockableAvatarAddPageInit()),
// 		getUnlockableAvatarDetail: (id) => dispatch(unlockableAvatarAddRequest(id)),
// 		// getUnlockableAvatarStartDetail: (id) => dispatch(unlockableAvatarAddRequest(id)),
// 		detailModalClose: () => dispatch(unlockableAvatarDetailClose()),
// 		deleteUnlockableAvatarRequest: (id) => dispatch(unlockableAvatarDeleteInit(id)),
// 		startUnlockableAvatarRequest: (id) => dispatch(unlockableAvatarStartInit(id)),
// 		releaseData: () => dispatch(releaseStateData()),
// 	};
// }

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps,
// )(withRouter(UnlockableAvatar));

export default UnlockableAvatar