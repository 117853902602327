import {
    STAGING_PAD_ADD_PAGE_INIT,
    STAGING_PAD_ADD_ERROR,
    STAGING_PAD_ADD_REQUESTING,
    STAGING_PAD_ADD_SUCCESS,
    STAGING_PAD_UPDATE_ERROR,
    STAGING_PAD_UPDATE_REQUESTING,
    STAGING_PAD_UPDATE_SUCCESS,
} from './actions';

// The initial state of the Login Reducer
export const initialState = {
    requesting: false,
    successful: false,
    messages: [],
    errors: {},
    addedStaging: {},
    updatedStaging: {}
  };

export default function(state = initialState,actions){
    switch(actions.type){
        case STAGING_PAD_ADD_PAGE_INIT:
            return {...state, errors:{}};
        case STAGING_PAD_ADD_REQUESTING:
            return {...state, requesting: true, errors:{}};
        case STAGING_PAD_ADD_SUCCESS:
            return {...state, successful: true, errors:{}, addedStaging:{...actions.payload}};
        case STAGING_PAD_ADD_ERROR:
            return {...state, successful: false, errors:{...actions.error}};
        case STAGING_PAD_UPDATE_REQUESTING:
            return {...state, requesting: true, errors:{}};
        case STAGING_PAD_UPDATE_SUCCESS:
            return {...state, successful: true, errors:{}, updatedStaging:{...actions.payload}};
        case STAGING_PAD_UPDATE_ERROR:
            return {...state, successful: false, errors:{...actions.error}};
        default:        
            return state;
    }
}