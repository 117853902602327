import React, {useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import {useDispatch, useSelector} from "react-redux";
// import {territoryPageInit} from "./actions";
// import {Box} from "@mui/material";
// import { states as territory } from "../Map/data.js";

export default function ThemeSelector({selectedTheme, handleChangeTheme}) {
    return (
            <FormControl sx={{m: 1, minWidth: 200}}>
                <InputLabel id="demo-simple-select-autowidth-label">Theme</InputLabel>
                <Select
                    labelId="simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={selectedTheme}
                    onChange={handleChangeTheme}
                    // onMouseOver={handleChangeTheme}
                    autoWidth
                    label="state"
                >
                    <MenuItem value="normal">default</MenuItem>
                    <MenuItem value="typewriter"><em>typewriter</em></MenuItem>
                    <MenuItem value="nightowl">nightowl</MenuItem>
                    <MenuItem disabled value="batman">Batman</MenuItem>
                    <MenuItem disabled value="wonderwoman">Wonder woman</MenuItem>
                    <MenuItem disabled value="blackpanther">Black Panther</MenuItem>
                    <MenuItem disabled value="chicagoblackhawks">Chicago Blackhawks</MenuItem>
                    <MenuItem disabled value="ironman">Iron Man</MenuItem>
                    <MenuItem disabled value="miamiheat">Miami heat</MenuItem>
                    <MenuItem disabled value="newenglandpatriots">New England Patriots</MenuItem>
                    <MenuItem disabled value="seattlekraken">Seattle Kraken</MenuItem>
                    <MenuItem disabled value="superman">Superman</MenuItem>
                    <MenuItem disabled value="venom">Venom</MenuItem>
                </Select>
            </FormControl>
    )

}