import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  TERRITORY_PAGE_INIT,
  TERRITORY_DETAIL_INIT,
  TERRITORY_DELETE_INIT,
  territoryError,
  territorySuccess,
  territoryDetailError,
  territoryDetailSuccess,
  territoryDeleteError,
  territoryDeleteSuccess,
  territoryPageInit
} from "./actions";
import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
// import manageTerritorySaga from './ManageTerritory/saga';

//Territory API calls
function territoryCall() {
  return request('get', urls.TERRITORY);
}

function territoryDetailCall(id) {
  return request('get', urls.TERRITORY+'/'+id);
}

function territoryDeleteCall(id) {
  return request('delete', urls.TERRITORY+'/'+id);
}

// Territory Workers
function* territoryWorker() {
  try {
    let response = yield call(territoryCall);
    response = response.data.data;
    yield put(territorySuccess(response));
  } catch (err) {
    yield put(territoryError(err.response.data.data));
  }
}

function* territoryDetailWorker(payload) {
  try {
    let response = yield call(territoryDetailCall, payload.id);
    response = response.data.data;
    yield put(territoryDetailSuccess(response));
  } catch (err) {
    yield put(territoryDetailError(err.response.data.data));
  }
}

function* territoryDeleteWorker(payload) {
  try {
    let response = yield call(territoryDeleteCall, payload.id);
    response = response.data;
    yield put(territoryDeleteSuccess(response));
    yield put(territoryPageInit());
  } catch (err) {
    yield put(territoryDeleteError(err.response.data));
  }
}

// Territory Watcher
export default function* territorySaga() {
  yield all([
    takeLatest(TERRITORY_PAGE_INIT, territoryWorker),
    takeLatest(TERRITORY_DETAIL_INIT, territoryDetailWorker),
    takeLatest(TERRITORY_DELETE_INIT, territoryDeleteWorker),
    // manageTerritorySaga()
  ]);
}
