// import { combineReducers } from "redux";

// export const EMPTY_ALL_RES = 'messages/emptyAllRes';
// export const ADD_LIST = 'messages/addList';
// export const INSERT_NEW = 'messages/insertNew';
// export const LIVE_PROMPT = 'messages/livePrompt';
//
// export const emptyAllRes = () => ({
//     type: EMPTY_ALL_RES,
// });
//
// export const addList = payload => ({
//     type: ADD_LIST,
//     payload,
// });
//
// export const insertNew = payload => ({
//     type: INSERT_NEW,
//     payload,
// });
//
// export const livePrompt = payload => ({
//     type: LIVE_PROMPT,
//     payload,
// });
//
// export const getChatSaved = payload => ({
//     type: LIVE_PROMPT,
//     payload,
// });
//
// const initialState = {
//     prompt: '',
//     content: '',
//     _id: null,
//     latest: {
//         prompt: '',
//         content: ''
//     },
//     all: []
// };
//
// // const messagesReducer = function(state = initialState, action){
// export default function(state = initialState, action){
//     switch(action.type){
//         case EMPTY_ALL_RES:
//             return {
//                 prompt: '',
//                 content: '',
//                 _id: null,
//                 latest: {
//                     prompt: '',
//                     content: ''
//                 },
//                 all: []
//             };
//         case ADD_LIST:
//             const { _id, items } = action.payload;
//             return {
//                 ...state,
//                 _id,
//                 all: items
//             };
//         // Handling the INSERT_NEW case would require a lot of conditional logic
//         // based on the contents of the action's payload.
//         case INSERT_NEW:
//             return (state, { payload }) => {
//                 const {
//                     chatsId, content = null,
//                     resume = false, fullContent = null,
//                     _id = null, prompt = null
//                 } = payload
//
//                 if (_id) {
//                     console.log(`if _id activated`)
//                     state._id = _id
//                 }
//
//                 state.latest.id = chatsId
//
//                 if (prompt) {
//                     console.log(`if prompt activated`)
//
//                     state.latest.prompt = prompt
//                 }
//
//
//                 const addToList = (latest) => {
//                     console.log(`addToList activated`)
//                     if (state['all'].find(obj => obj.id === latest.id)) {
//                         state['all'].forEach(obj => {
//                             if (obj.id === latest.id) {
//                                 obj.content = latest.content
//                             }
//                         })
//                     } else {
//                         state['all'].push(latest)
//                     }
//                 }
//
//                 if (content && resume) {
//                     state.latest.content += content
//                     addToList(state.latest)
//
//                 } else if (content) {
//                     state.latest.content = content
//                     console.log(`addToList(state.latest)`)
//                     addToList(state.latest)
//                 }
//
//                 if (fullContent) {
//                     state.content = fullContent
//                 }
//
//                 return state
//
//
//             }
//             // It's up to you how you want to handle this.
//             // The logic from your original code could be pasted here,
//             // with some slight modifications.
//             // For now, just returning state.
//             // return state;
//         case LIVE_PROMPT:
//             return {
//                 ...state,
//                 prompt: action.payload
//             };
//         default:
//             return state;
//     }
// }

// ----------------------------------------------------------------

// export default combineReducers({
//     messages : messagesReducer,
// });



import { createSlice } from "@reduxjs/toolkit";

let messagesSlice = createSlice({
    name: 'messages',
    initialState: {
        prompt: '',
        content: '',
        _id: null,
        latest: {
            prompt: '',
            content: ''
        },
        all: []
    },
    reducers: {
        clearPrompt: (state) => {
            return {
                ...state,
                prompt: '',
            }},
        emptyAllRes: () => {
            return {
                prompt: '',
                content: '',
                _id: null,
                latest: {
                    prompt: '',
                    content: ''
                },
                all: []
            }
        },
        addList: (state, { payload }) => {
            const { _id, items } = payload
            state._id = _id
            state.all = items
            return state
        },
        insertNew: (state, { payload }) => {
            const { chatsId, content = null,
                resume = false, fullContent = null,
                _id = null, prompt = null } = payload

            if (_id) {
                state._id = _id
            }

            state.latest.id = chatsId

            if (prompt) {
                state.latest.prompt = prompt
            }

            const addToList = (latest) => {
                if (state['all'].find(obj => obj.id === latest.id)) {
                    state['all'].forEach(obj => {
                        if (obj.id === latest.id) {
                            obj.content = latest.content
                        }
                    })
                } else {
                    state['all'].push(latest)
                }
            }

            if (content && resume) {
                state.latest.content += content
                addToList(state.latest)

            } else if (content) {
                state.latest.content = content
                addToList(state.latest)
            }

            if (fullContent) {
                state.content = fullContent
            }

            return state
        },
        livePrompt: (state, { payload }) => {
            state.prompt = payload
            return state
        }
    }
})

export const { clearPrompt, emptyAllRes, insertNew, livePrompt, addList } = messagesSlice.actions
export default messagesSlice.reducer