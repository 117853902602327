import React, {useState, useContext, useEffect} from 'react';
import {Button, Container} from "@mui/material";
import {WebSocketContext} from '../../socketHandlers/WebSocket';
import {Provider, useSelector, useDispatch} from 'react-redux'
import {createRoomRequest, setUsername, joinRoomRequest} from './actions';
// import MouseTracking from "../EventTracking/MouseTracking";

function ChatRoom() {
    const [usernameInput, setUsernameInput] = useState("");
    const [msgInput, setMsgInput] = useState("");

    const room = useSelector((state) => state.rooms.list_room.rooms);
    const username = useSelector((state) => state.rooms.list_room.username);
    const chats = useSelector((state) => state.rooms.list_room.chatLog);

    const ws = useContext(WebSocketContext);

    const renderUsername = () => {
        if (ws.socket.id === null) {
            return (
                <>'no id set' {ws.socket.id}</>
            )
        } else if (ws.socket.id) {
            return (
                <>{ws.socket.id}</>
            )
        }
    }

    // useEffect(() => {
    //     renderUsername()
    // }, [ws.socket.id, renderUsername()]);




    const sendChatMessage = () => {
        ws.sendWords(
            // room.id,
            {
                // data:'hello'
                // username: username,
                message: msgInput
            }
        );
    }

    return (
        <Container sx={{
            m: 3
        }}>

            <h3>{renderUsername()}</h3>
            {/*<h3>{room.name}{room.id}</h3>*/}
            {/*<h3>{room.name} ({room.id}) {username ? username : null}</h3>*/}
            {/*{!username &&*/}
            {/*    <div className="user">*/}
            {/*        <input type="text" placeholder="Enter username" value={usernameInput}*/}
            {/*               onChange={(e) => setUsernameInput(e.target.value)}/>*/}
            {/*        <Button onClick={enterRoom}>Enter Room</Button>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{username &&*/}
                <div className="room">
                    <div className="history" style={{
                        width: "400px",
                        border: "1px solid #ccc",
                        height: "100px",
                        textAlign: "left",
                        padding: "10px",
                        overflow: "scroll"
                    }}>
                        {chats.map((c, i) => (
                            <div key={i}><i>{c.username}:</i> {c.message}</div>
                        ))}
                    </div>
                    <div className="control">
                        <input type="text" value={msgInput} onChange={(e) => setMsgInput(e.target.value)}/>
                        <Button onClick={sendChatMessage}>Send</Button>
                    </div>
                    {usernameInput ? `usernameInput: ${usernameInput}` : null}
                    {msgInput ? `msgInput: ${msgInput}` : null}
                </div>
            {/*}*/}

        </Container>
    )
}

function HomeComponent() {
    const [roomName, setRoomName] = useState("");
    const [roomId, setRoomId] = useState("");
    const currentRoom = useSelector((state) => state.rooms.list_room.rooms);

    const dispatch = useDispatch();

    return (
        <>
            {!currentRoom &&
                <Container>
                    <div>
                        <span>Create new room</span>
                        <input type="text" placeholder="Room name" value={roomName}
                               onChange={(e) => setRoomName(e.target.value)}/>
                        <Button onClick={() => dispatch(createRoomRequest(roomName))}>Create</Button>
                    </div>
                    <div>
                        <span>Join existing room</span>
                        <input type="text" placeholder="Room code" value={roomId}
                               onChange={(e) => setRoomId(e.target.value)}/>
                        <Button onClick={() => dispatch(joinRoomRequest(roomId))}>Join</Button>
                    </div>
                </Container>
            }

            {currentRoom &&
                <ChatRoom/>
            }
        </>
    );
}

function Room() {
    return (
        // <Provider store={store}>
        // <WebSocketProvider>
        //     <div className="App">
        <>
        {/*<MouseTracking/>*/}
        <HomeComponent/>
        </>
        // {/*</div>*/}
        // </WebSocketProvider>
        // </Provider>
    )
}

export default Room;
