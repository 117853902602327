import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  ROOM_PAGE_INIT,
  ROOM_DETAIL_INIT,
  ROOM_DELETE_INIT,
  ROOM_START_INIT,
  roomError,
  roomSuccess,
  roomDetailError,
  roomDetailSuccess,
  roomDeleteError,
  roomDeleteSuccess,
  roomPageInit,
  // ROOM_START_REQUESTING,
  roomStartSuccess,
  roomStartError,
} from "./actions";


import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
import manageRoomSaga from './ManageRoom/saga';
// import {browserRedirect} from "../../helpers/helpers";
// import {roomPadAddError, roomPadAddSuccess} from "./ClientPad/actions";

//Client API calls
function roomCall() {
  return request('get', urls.ROOM);
}

function roomDetailCall(id) {
  return request('get', urls.ROOM+'/'+id);
}

function roomDeleteCall(id) {
  return request('delete', urls.ROOM+'/'+id);
}

//roomStart API call
function roomStartCall(id) {
  return request('post', urls.ROOM+'/'+id);
}


// Client Workers
function* roomWorker() {
  try {
    let response = yield call(roomCall);
    response = response.data.data;
    yield put(roomSuccess(response));
  } catch (err) {
    yield put(roomError(err.response.data.data));
  }
}

function* roomDetailWorker(payload) {
  try {
    let response = yield call(roomDetailCall, payload.id);
    response = response.data.data;
    yield put(roomDetailSuccess(response));
  } catch (err) {
    yield put(roomDetailError(err.response.data.data));
  }
}

function* roomDeleteWorker(payload) {
  try {
    let response = yield call(roomDeleteCall, payload.id);
    response = response.data;
    yield put(roomDeleteSuccess(response));
    yield put(roomPageInit());
  } catch (err) {
    yield put(roomDeleteError(err.response.data));
  }
}


function* roomStartWorker(payload) {
  try {
    let response = yield call(roomStartCall, payload.id);
    response = response.data;
    yield put(roomStartSuccess(response));
    /* success will have the room page reload with the new results */
    yield put(roomPageInit());
  } catch (err) {
    yield put(roomStartError(err.response.data));
  }
}


// Client Watcher
export default function* roomSaga() {
  yield all([
    takeLatest(ROOM_PAGE_INIT, roomWorker),
    takeLatest(ROOM_DETAIL_INIT, roomDetailWorker),
    takeLatest(ROOM_DELETE_INIT, roomDeleteWorker),
    takeLatest(ROOM_START_INIT, roomStartWorker),
    // takeEvery(ROOM_START_REQUESTING, roomStartWorker),
    manageRoomSaga()
  ]);
}